import { SearchIcon } from '@heroicons/react/outline'
import { Field } from 'formik'
import { useTranslation } from 'react-i18next'

export const CustomSearchInput = (props: any) => {
  const { className, onChange } = props
  const { t } = useTranslation(['inputs'])

  return (
    <div className={`${className ? className : ''} flex rounded-md shadow-sm`}>
      <div className="flex relative focus-within:z-10 flex-grow items-stretch">
        <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
          <SearchIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
        </div>
        <Field
          type="text"
          name="search"
          id="search"
          onKeyUp={onChange}
          placeholder={t('inputs.searchInput.placeholder')}
          className="block pl-10 w-full sm:text-sm rounded-md border-gray-300 focus:border-brand focus:ring-brand"
        />
      </div>
    </div>
  )
}

export default CustomSearchInput
