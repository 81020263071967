import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import App from './App'
import { config } from './config'
import './i18n'
import './index.css'
import store from './store'
// import reportWebVitals from './reportWebVitals'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: config.sentry,
    integrations: [new Integrations.BrowserTracing()],
    release: process.env.REACT_APP_VERSION,
    environment: process.env.NODE_ENV,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5,
  })
}

const rootElement = document.getElementById('root')

if (rootElement?.hasChildNodes()) {
  ReactDOM.hydrate(renderApp(), rootElement)
} else {
  ReactDOM.render(renderApp(), rootElement)
}

function renderApp() {
  return (
    <Provider store={store}>
      <StrictMode>
        <App />
      </StrictMode>
    </Provider>
  )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
