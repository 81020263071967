import { ICD } from '../../store/modules/icd/types'

export default {
  getICDWhisper: {
    id: ICD.ACTIONS.GET_ICD_WHISPER,
    request: (data: any) => ({
      url: '/v1/icd/whisper',
      method: 'GET',
      params: {
        search: data.search,
      },
    }),
  },
  getICDTitle: {
    id: ICD.ACTIONS.GET_ICD_TITLE,
    request: (data: any) => ({
      url: '/v1/icd/titles',
      method: 'GET',
      params: {
        codes: data.codes,
      },
    }),
  },
  getMedicationWhisper: {
    id: ICD.ACTIONS.GET_MEDICATION_WHISPER,
    request: (data: any) => ({
      url: '/v1/medications/whisper',
      method: 'GET',
      params: {
        search: data.search,
      },
    }),
  },
  getMedicationTitle: {
    id: ICD.ACTIONS.GET_MEDICATION_TITLE,
    request: (data: any) => ({
      url: '/v1/medications/titles',
      method: 'GET',
      params: {
        codes: data.codes,
      },
    }),
  },
}
