import { ArrowRightIcon } from '@heroicons/react/outline'
import { ArrowLeftIcon } from '@heroicons/react/solid'
import { Formik } from 'formik'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'
import {
  ButtonBackgroundColorsEnum,
  ButtonIconPositionsEnum,
  ButtonTextColorsEnum,
  ButtonTypesEnum,
  RegisterStepInterface,
} from '../../../../interfaces'
import { RootState, useAppDispatch } from '../../../../store'
import { getEnums } from '../../../../store/modules/patients/actions'
import { createArrayOfEnumObjectsFromArrayOfValues } from '../../../../store/utils'
import Button from '../../../common/button/Button'
import FormWrapper from '../../../common/form/FormWrapper'
import CustomMultiselect from '../../../common/inputs/components/CustomMultiselect'

export const RegisterAdditionalDataStep2Form = (props: RegisterStepInterface) => {
  const { onSubmit, onPrevious, onSkip } = props
  const { enums } = useSelector((state: RootState) => state.patients)
  const { t } = useTranslation(['register', 'yup'])
  const dispatch = useAppDispatch()

  const registerSchema = Yup.object({
    allergies: Yup.array().required(t('yup:yup.required')),
    movement: Yup.array().required(t('yup:yup.required')),
    other: Yup.array().required(t('yup:yup.required')),
  })

  useEffect(() => {
    loadEnums()
  }, [])

  const loadEnums = () => {
    dispatch(getEnums({ enums: ['Allergy', 'MovementDiseases', 'FamilyAnamnesis\\OtherDiseases'] }))
  }
  const otherDiseases = createArrayOfEnumObjectsFromArrayOfValues(
    'otherDiseases',
    enums['FamilyAnamnesis\\OtherDiseases']
  )
  const movementDiseases = createArrayOfEnumObjectsFromArrayOfValues('movementDiseases', enums['MovementDiseases'])
  const allergies = createArrayOfEnumObjectsFromArrayOfValues('allergy', enums['Allergy'])

  return (
    <>
      <div>
        <h2 className="mt-6 text-3xl font-semibold text-gray-900">{t('register.additionalData.step2Form.title')}</h2>
      </div>
      <div className="mt-8">
        <div className="mt-6">
          <Formik
            initialValues={{
              allergies: [],
              movement: [],
              other: [],
            }}
            validationSchema={registerSchema}
            onSubmit={(values, { setSubmitting }) => onSubmit(values, setSubmitting)}
          >
            {({ isSubmitting, errors, touched, setFieldValue }) => (
              <FormWrapper className="gap-x-6 gap-y-3">
                <CustomMultiselect
                  options={allergies}
                  onChange={e => setFieldValue('allergies', e)}
                  name="allergies"
                  errors={errors}
                  touched={touched}
                  className="col-span-12"
                  label={t('register.additionalData.step2Form.allergies')}
                />

                <CustomMultiselect
                  options={movementDiseases}
                  onChange={e => setFieldValue('movement', e)}
                  name="movement"
                  errors={errors}
                  touched={touched}
                  className="col-span-12"
                  label={t('register.additionalData.step2Form.movementDiseases')}
                />

                <CustomMultiselect
                  options={otherDiseases}
                  onChange={e => setFieldValue('other', e)}
                  name="other"
                  errors={errors}
                  touched={touched}
                  className="col-span-12"
                  label={t('register.additionalData.step2Form.otherDiseases')}
                />

                <div className="flex col-span-12 justify-between mt-12">
                  <Button
                    type={ButtonTypesEnum.Button}
                    onClick={onPrevious}
                    backgroundColor={ButtonBackgroundColorsEnum.White}
                    textColor={ButtonTextColorsEnum.Gray}
                    title={t('register.additionalData.step2Form.previousStepButton')}
                    icon={ArrowLeftIcon}
                    iconPosition={ButtonIconPositionsEnum.Left}
                  />

                  <Button
                    type={ButtonTypesEnum.Button}
                    onClick={onSkip}
                    backgroundColor={ButtonBackgroundColorsEnum.White}
                    textColor={ButtonTextColorsEnum.Gray}
                    title={t('register.additionalData.step2Form.skipButton')}
                  />

                  <Button
                    type={ButtonTypesEnum.Submit}
                    icon={ArrowRightIcon}
                    iconPosition={ButtonIconPositionsEnum.Right}
                    backgroundColor={ButtonBackgroundColorsEnum.Brand}
                    textColor={ButtonTextColorsEnum.White}
                    title={t('register.additionalData.step2Form.nextStepButton')}
                    disabled={isSubmitting}
                  />
                </div>
              </FormWrapper>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}

export default RegisterAdditionalDataStep2Form
