import { CogIcon } from '@heroicons/react/outline'

export const PageLoader = () => {
  return (
    <div className="flex justify-center items-center w-screen h-screen">
      <CogIcon className="w-20 h-20 text-brand animate-spin" aria-label="page-loader" />
    </div>
  )
}

export default PageLoader
