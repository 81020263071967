import { NOTIFICATION } from '../../store/modules/notification/types'

export default {
  getNotifications: {
    id: NOTIFICATION.ACTIONS.GET_NOTIFICATIONS,
    request: (data: any) => ({
      url: '/v1/unread',
      method: 'GET',
      params: {
        ...data,
      },
    }),
  },
  sendNotificationCallback: {
    id: NOTIFICATION.ACTIONS.SEND_NOTIFICATION_CALLBACK,
    request: (data: any) => ({
      url: '/v1/callback',
      method: 'POST',
      data,
    }),
  },
  getNotificationsSettings: {
    id: NOTIFICATION.ACTIONS.GET_NOTIFICATIONS_SETTINGS,
    request: (data: any) => ({
      url: '/v1/settings',
      method: 'GET',
      data,
    }),
  },
  editNotificationsSettings: {
    id: NOTIFICATION.ACTIONS.EDIT_NOTIFICATIONS_SETTINGS,
    request: (data: any) => ({
      url: '/v1/settings',
      method: 'PUT',
      data,
    }),
  },
  markNotifications: {
    id: NOTIFICATION.ACTIONS.MARK_NOTIFICATIONS,
    request: (data: any) => ({
      url: '/v1/mark',
      method: 'POST',
      data,
    }),
  },
}
