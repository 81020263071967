import { configureStore, getDefaultMiddleware, Middleware } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import appReducer from './modules/app/reducer'
import authReducer from './modules/auth/reducer'
import calendarReducer from './modules/calendar/reducer'
import chatReducer from './modules/chat/reducer'
import customMedicationsReducer from './modules/custom-medications/reducer'
import doctorsReducer from './modules/doctors/reducer'
import edgReducer from './modules/edg/reducer'
import filesReducer from './modules/files/reducer'
import icdReducer from './modules/icd/reducer'
import notificationsReducer from './modules/notification/reducer'
import ordersReducer from './modules/orders/reducer'
import patientsReducer from './modules/patients/reducer'
import telerehabilitationReducer from './modules/telerehabilitation/reducer'

const middleware: Middleware[] = [
  /*YOUR CUSTOM MIDDLEWARES HERE*/
  ...getDefaultMiddleware(),
]

const store = configureStore({
  reducer: {
    auth: authReducer,
    patients: patientsReducer,
    calendar: calendarReducer,
    chat: chatReducer,
    orders: ordersReducer,
    notification: notificationsReducer,
    telerehabilitation: telerehabilitationReducer,
    files: filesReducer,
    edg: edgReducer,
    icd: icdReducer,
    doctors: doctorsReducer,
    app: appReducer,
    customMedications: customMedicationsReducer,
  },
  middleware,
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<AppDispatch>()

export default store
