import { Form } from 'formik'
import { FormInterface } from '../../../interfaces'

export const FormWrapper = (props: FormInterface) => {
  const { children, className } = props

  return (
    <Form className="space-y-6">
      <div className={`${className ? className : ''} grid grid-cols-12`}>{children}</div>
    </Form>
  )
}

export default FormWrapper
