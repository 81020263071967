export const TELEREHABILITATION = {
  NAMESPACE: 'telerehabilitation',
  ACTIONS: {
    GET_EXERCISES: 'GET_EXERCISES',
    GET_EXERCISES_DAY: 'GET_EXERCISES_DAY',
    DELETE_EXERCISES_DAY: 'DELETE_EXERCISES_DAY',
    DELETE_EXERCISE: 'DELETE_EXERCISE',
    GET_EXERCISE: 'GET_EXERCISE',
    GET_EXERCISE_TOKEN: 'GET_EXERCISE_TOKEN',
    CREATE_EXERCISE: 'CREATE_EXERCISE',
    CREATE_EXERCISE_VIDEO: 'CREATE_EXERCISE_VIDEO',
    GET_PRESETS: 'GET_PRESETS',
    CREATE_PRESET: 'CREATE_PRESET',
    GET_PRESET: 'GET_PRESET',
    EDIT_PRESET: 'EDIT_PRESET',
    DELETE_PRESET: 'DELETE_PRESET',
    GET_CHARTS: 'GET_CHARTS',
    CREATE_CHART: 'CREATE_CHART',
    GET_CHART: 'GET_CHART',
    EDIT_CHART: 'EDIT_CHART',
    DELETE_CHART: 'DELETE_CHART',
    COPY_EXERCISE_PRESETS: 'COPY_EXERCISE_PRESETS',
    CHECK_PATIENT_EXERCISES: 'CHECK_PATIENT_EXERCISES',
  },
}
