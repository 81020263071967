import { useTranslation } from 'react-i18next'
import Button from '../../components/common/button/Button'
import { ButtonBackgroundColorsEnum, ButtonTextColorsEnum, ButtonTypesEnum } from '../../interfaces'
import { ROUTES } from '../../router/routes'

export const Error404 = () => {
  const { t } = useTranslation(['errors'])

  return (
    <div className="flex justify-center items-center w-full h-screen">
      <div className="flex flex-col items-center">
        <span className="my-4 text-6xl">404</span>
        <Button
          type={ButtonTypesEnum.Link}
          path={ROUTES.DASHBOARD}
          backgroundColor={ButtonBackgroundColorsEnum.Brand}
          textColor={ButtonTextColorsEnum.White}
          title={t('errors.backToDashboardButton')}
        />
      </div>
    </div>
  )
}

export default Error404
