import { Formik } from 'formik'
import moment from 'moment/moment'
import { useTranslation } from 'react-i18next'
import {
  ButtonBackgroundColorsEnum,
  ButtonTextColorsEnum,
  ButtonTypesEnum,
  DescriptionListFormInterface,
  InputByTypeRow,
  InputTypeEnum,
  TextTypeEnum,
} from '../../../../interfaces'
import Button from '../../button/Button'
import FormWrapper from '../../form/FormWrapper'
import InputByType from '../../inputs/InputByType'
import TextByType from '../../inputs/TextByType'

export const DescriptionListForm = (props: DescriptionListFormInterface) => {
  const {
    isInput,
    validationSchema,
    rows,
    onSubmitClick,
    initialValues,
    itemID,
    isCreate,
    hideEmpty = false,
    setIsInput,
  } = props
  const { t } = useTranslation(['descriptionList'])

  const isInputOrCreate = () => {
    return isInput || isCreate
  }

  const formatInitial = (initialValues: any) => {
    if (initialValues) {
      rows.map(row => {
        switch (row.type) {
          case InputTypeEnum.Date: {
            if (initialValues[row.name]) {
              initialValues[row.name] = moment.utc(initialValues[row.name]).format('YYYY-MM-DD')
            } else {
              initialValues[row.name] = ''
            }

            break
          }
          case InputTypeEnum.DateTime: {
            if (initialValues[row.name]) {
              initialValues[row.name] = moment.utc(initialValues[row.name]).format('YYYY-MM-DDTHH:mm')
            } else {
              initialValues[row.name] = ''
            }
            break
          }
        }
      })
    }
    return initialValues
  }

  const isFullWidth = (row: InputByTypeRow) => {
    return row.fullWidth
  }

  const hiddenWhenInput = [InputTypeEnum.ReadOnly, TextTypeEnum.Button]

  const showRow = (row: InputByTypeRow) => {
    if (row.hide) {
      return false
    } else {
      if (hiddenWhenInput.includes(row.type) && isInputOrCreate()) return false
      if (isInputOrCreate()) return true

      if (hideEmpty) {
        if (Array.isArray(row.value)) {
          return row.value.length > 0
        } else {
          if (row.value === null || row.value === undefined) {
            return false
          } else {
            return true
          }
        }
      } else {
        return true
      }
    }
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={formatInitial(initialValues)}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        if (!isCreate && itemID) values._id = itemID
        if (onSubmitClick)
          onSubmitClick(values, setSubmitting).then((status: string) => {
            if (status === 'ok') setIsInput(false)
          })
      }}
    >
      {({ isSubmitting, errors, touched, setFieldValue, values }) => (
        <FormWrapper>
          <div className="col-span-12 px-2 md:px-0 border-t border-gray-200">
            <dl className="divide-y sm:divide-gray-200">
              {rows.map((row, key: number) => {
                return (
                  showRow(row) && (
                    <div key={key} className="sm:grid sm:grid-cols-3 sm:gap-x-4 py-2 sm:py-3 sm:px-6">
                      {row.list_label && <dt className="text-sm font-medium text-gray-500">{row.list_label}</dt>}
                      <dd
                        className={`${
                          isFullWidth(row) ? 'sm:col-span-3' : 'sm:col-span-2'
                        } mt-1 sm:mt-0 text-sm text-gray-900`}
                      >
                        {isInputOrCreate() ? (
                          <InputByType
                            row={row}
                            touched={touched}
                            errors={errors}
                            values={values}
                            setFieldValue={setFieldValue}
                          />
                        ) : (
                          <TextByType
                            row={row}
                            type={row.type}
                            t={'t' in row ? row.t : ''}
                            namespace={'namespace' in row ? row.namespace : ''}
                            suffix={row.suffix}
                            values={values}
                          />
                        )}
                      </dd>
                    </div>
                  )
                )
              })}
              {isInputOrCreate() && (
                <div className="py-4 sm:py-5 sm:px-6">
                  <Button
                    type={ButtonTypesEnum.Submit}
                    backgroundColor={ButtonBackgroundColorsEnum.Brand}
                    textColor={ButtonTextColorsEnum.White}
                    title={t('descriptionList.form.saveButton')}
                    className="w-full"
                    disabled={isSubmitting}
                  />
                </div>
              )}
            </dl>
          </div>
        </FormWrapper>
      )}
    </Formik>
  )
}

export default DescriptionListForm
