import { ArrowRightIcon } from '@heroicons/react/outline'
import { useTranslation } from 'react-i18next'
import icon from '../../../assets/images/register_step_3_icon.svg'
import {
  ButtonBackgroundColorsEnum,
  ButtonIconPositionsEnum,
  ButtonTextColorsEnum,
  ButtonTypesEnum,
  RegisterStepInterface,
} from '../../../interfaces'
import Button from '../../common/button/Button'

export const RegisterStep3Form = (props: RegisterStepInterface) => {
  const { onSubmit } = props
  const { t } = useTranslation(['register'])
  return (
    <>
      <div>
        <h2 className="mt-6 text-3xl font-semibold text-gray-900">{t('register.register.step3Form.title')}</h2>
      </div>
      <div className="mt-8">
        <div className="mt-6">
          <div className="flex justify-center items-center mx-auto w-72">
            <img src={icon} alt="" />
          </div>
          <div className="mt-3 sm:mt-5 text-center">
            <h3 className="text-lg font-medium leading-6 text-brand">{t('register.register.step3Form.iconTitle')}</h3>
            <div className="mt-2">
              <p className="text-sm text-gray-500">{t('register.register.step3Form.iconText')}</p>
            </div>
          </div>
          <div className="flex justify-end mt-12">
            <Button
              type={ButtonTypesEnum.Button}
              onClick={onSubmit}
              icon={ArrowRightIcon}
              iconPosition={ButtonIconPositionsEnum.Right}
              backgroundColor={ButtonBackgroundColorsEnum.Brand}
              textColor={ButtonTextColorsEnum.White}
              title={t('register.register.step3Form.nextStepButton')}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default RegisterStep3Form
