import axios from 'axios'
import { config } from '../config'
import { LOCAL_STORAGE } from '../enums'
import { BaseParamsInterface } from '../interfaces'
import { notifyError } from '../store/utils'

const chatClient = axios.create({
  baseURL: config.rocketChat.chatBaseUrl,
  withCredentials: false,
  method: 'GET',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
  },
})

const createRequest = (route: any) => (data: any) => {
  return send({
    baseUrl: `${config.rocketChat.chatBaseUrl}${route}`,
    ...data,
  })
}

const send = (config: any) => {
  const { method, body, baseUrl } = config
  let { url } = config

  url = `${baseUrl}${url}`

  const headers = config.headers || {}

  return new Promise((resolve, reject) => {
    if (!method) {
      reject(new Error('The request must have method.'))
    }

    if (!url) {
      reject(new Error('The request must have url.'))
    }

    const authToken = localStorage.getItem(LOCAL_STORAGE.USER_CHAT_AUTH_TOKEN)
    if (authToken) {
      headers['X-Auth-Token'] = authToken
    }
    const userId = localStorage.getItem(LOCAL_STORAGE.USER_CHAT_ID)
    if (userId) {
      headers['X-User-Id'] = userId
    }

    resolve(chatClient.request({ ...config, url, headers: { ...headers }, body: JSON.stringify(body) }))
  })
}

const call = ({ requestData, params = null, throwError = false }: any, sender: any) => {
  const { id, errorType, request, reduce } = requestData

  if (!id) {
    throw new Error('Request must have id.')
  }

  return new Promise((resolve, reject) => {
    return sender(request(params))
      .then((res: any) => {
        if (reduce) {
          resolve(reduce(res.data))
        }

        resolve(res.data)
      })
      .catch((err: any) => {
        // eslint-disable-next-line
        console.error('Response ERROR!', err)
        if (err.response) {
          // logout
          if (err.response.status && err.response.status === 401) {
            // store.dispatch(logout())
            // return
          }

          // process error
          // console.log(err.response.data)

          onError(
            id,
            err.response.data?.data?.code ? err.response.data.data.code : 'UNKNOWN',
            errorType,
            err.response.data || []
          )
        } else {
          // unknown error
          onError(id, 'UNKNOWN', errorType)
        }

        // stopSending(store.commit, id)
        if (throwError) {
          reject(err)
        }
      })
  })
}

const onError = (
  id: string,
  errorCode: any,
  errorType: any,
  values?: { data: { error_message: string }; fields?: [] }
) => {
  if (!errorType) {
    // const apiTranslateKey = errorCode === ''
    //   ? `apiErrors.${getTextFromUlozenkaErrorMessage(values.message)}`
    //   : `apiErrors.${errorCode}`
    if (values) {
      if (!values.data?.error_message || values.data.error_message === '') {
        // unknown error
        return
      }

      if (values.fields) {
        // @todo výpis chyb z fields
        notifyError(values.data.error_message)
      } else {
        notifyError(values.data.error_message)
      }
    }
  }
}

const callChatApi = (params: BaseParamsInterface) => call(params, createRequest(config.rocketChat.chatUrl))

export { callChatApi }
