import { createSlice } from '@reduxjs/toolkit'
import { DispatchActionReducerInterface, NotificationStateInterface } from '../../../interfaces'
import { getNotifications, getNotificationsSettings, sendNotificationCallback } from './actions'

import { NOTIFICATION } from './types'

const initialState: NotificationStateInterface = {
  notifications: null,
  getNotificationsLoading: false,

  callbackSending: false,

  notificationsSettings: null,
  getNotificationsSettingLoading: false,
}

const chatSlice = createSlice({
  name: NOTIFICATION.NAMESPACE,
  initialState,
  // Only frontend Logic
  reducers: {
    clearNotifications(state) {
      state.notifications = null
    },
  },
  extraReducers: builder => {
    // Reducers with calling external API
    builder.addCase(getNotifications.pending, (state: NotificationStateInterface) => {
      state.getNotificationsLoading = true
    })
    builder.addCase(
      getNotifications.fulfilled,
      (state: NotificationStateInterface, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.notifications = action.payload.data
        }
        state.getNotificationsLoading = false
      }
    )

    builder.addCase(sendNotificationCallback.pending, (state: NotificationStateInterface) => {
      state.callbackSending = true
    })

    builder.addCase(getNotificationsSettings.pending, (state: NotificationStateInterface) => {
      state.getNotificationsSettingLoading = true
    })
    builder.addCase(
      getNotificationsSettings.fulfilled,
      (state: NotificationStateInterface, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.notificationsSettings = action.payload.data
        }
        state.getNotificationsSettingLoading = false
      }
    )
    builder.addCase(getNotificationsSettings.rejected, (state: NotificationStateInterface) => {
      state.getNotificationsSettingLoading = false
    })
  },
})

// Extract the action creators object and the reducer
const { actions, reducer } = chatSlice
// Extract and export each action creator by name
export const { clearNotifications } = actions
// Export the reducer, either as a default or named export
export default reducer
