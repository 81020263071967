import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import { ChatIconInterface } from '../../../interfaces'

export const ChatIcon = (props: ChatIconInterface) => {
  const { name, theme, unread, isLink, className, path, isOpen, setMobileSidebarOpen } = props
  const [icon, setIcon] = useState('')

  useEffect(() => {
    if (name && name !== '') {
      setIcon(name.substr(0, 1).toUpperCase())
      ReactTooltip.rebuild()
    }
  }, [name])

  return (
    <>
      {isLink && path ? (
        <Link
          to={path}
          onClick={() => {
            setMobileSidebarOpen && setMobileSidebarOpen(false)
          }}
        >
          <div
            className={`${isOpen ? 'bg-green-800' : ''} ${
              className ? className : ''
            } flex items-center justify-between py-3 space-x-4`}
          >
            <div className="flex items-center space-x-4 truncate">
              <div className="flex flex-shrink-0 justify-center items-center w-10 h-10 text-xl text-black bg-gray-100 rounded-sm">
                {icon}
              </div>
              {/*@todo: Improvement -  user chat status*/}
              {/*<div*/}
              {/*  className={`${online ? 'bg-green-600' : 'border-2 border-gray-300'} flex-shrink-0 w-4 h-4 rounded-full `}*/}
              {/*/>*/}
              <span className={`${theme ? 'text-gray-900' : 'text-gray-300'} text-lg truncate`} data-tip={name}>
                {name && name}
              </span>
            </div>
            {unread ? (
              <span className="inline-flex items-center py-0.5 px-2.5 text-xs font-medium text-green-800 bg-green-100 rounded-full">
                {`+${unread}`}
              </span>
            ) : (
              ''
            )}
          </div>
        </Link>
      ) : (
        <div>
          <div
            className={`${isOpen ? 'bg-green-800' : ''} ${
              className ? className : ''
            } flex items-center justify-between py-3 space-x-4`}
          >
            <div className="flex items-center space-x-4">
              <div className="flex flex-shrink-0 justify-center items-center w-10 h-10 text-xl text-black bg-gray-100 rounded-sm">
                {icon}
              </div>
              {/*@todo: Improvement -  user chat status*/}
              {/*<div*/}
              {/*  className={`${online ? 'bg-green-600' : 'border-2 border-gray-300'} flex-shrink-0 w-4 h-4 rounded-full `}*/}
              {/*/>*/}
              <span className={`${theme ? 'text-gray-900' : 'text-gray-300'} text-lg truncate`}>{name && name}</span>
            </div>
            {unread ? (
              <span className="inline-flex items-center py-0.5 px-2.5 text-xs font-medium text-green-800 bg-green-100 rounded-full">
                {`+${unread}`}
              </span>
            ) : (
              ''
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default ChatIcon
