import i18n from 'i18next'
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector'
import XHR from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'
import { LOCAL_STORAGE } from './enums'

export const allowedLanguages = ['default', 'cs', 'en']
export const defaultLanguage = localStorage.getItem(LOCAL_STORAGE.I18NEXT_LNG) || allowedLanguages[0]

i18n
  .use(XHR)
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    backend: {
      loadPath: '/localization/{{lng}}/{{ns}}.json',
      allowMultiLoading: true,
    },
    lng: defaultLanguage,
    fallbackLng: allowedLanguages[0],
    whitelist: allowedLanguages,
    debug: process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'development',
    react: {
      useSuspense: true,
    },
  })

export default i18n
