import React from 'react'
import { Route, Switch } from 'react-router-dom'
import PrivateRoute from '../../../components/common/auth/PrivateRoute'
import AppLayout from '../../../components/common/layout/AppLayout'
import { RouterInterface } from '../../../interfaces'
import { ROUTES } from '../../../router/routes'
import { getRoutesStructure } from '../../../store/utils'
import Error404 from '../../error/Error404'

export const DoctorsPage = () => {
  const routes = getRoutesStructure(ROUTES.DOCTORS).routes

  return (
    <AppLayout>
      <Switch>
        {routes.map((i: RouterInterface) => (
          <PrivateRoute
            key={i.title}
            title={i.title}
            path={i.path}
            component={i.component}
            exact={i.exact}
            role={i.role}
          />
        ))}
        <Route component={Error404} />
      </Switch>
    </AppLayout>
  )
}

export default DoctorsPage
