import { AUTH } from '../../store/modules/auth/types'

export default {
  login: {
    id: AUTH.ACTIONS.LOGIN,
    request: (data: any) => ({
      url: '/v1/oauth/authorize',
      method: 'POST',
      data,
    }),
  },
  checkLogin: {
    id: AUTH.ACTIONS.CHECK_LOGIN,
    request: (data: any) => ({
      url: '/v1/user',
      method: 'GET',
      data,
    }),
  },
  resetPasswordEmail: {
    id: AUTH.ACTIONS.RESET_PASSWORD_EMAIL,
    request: (data: any) => ({
      url: '/v1/forgotten_password',
      method: 'POST',
      data,
    }),
  },
  resetPasswordNewPassword: {
    id: AUTH.ACTIONS.RESET_PASSWORD_NEW_PASSWORD,
    request: (data: any) => ({
      url: '/v1/forgotten_password',
      method: 'PUT',
      data,
    }),
  },
  register: {
    id: AUTH.ACTIONS.REGISTER,
    request: (data: any) => ({
      url: '/v1/onboarding',
      method: 'POST',
      data,
    }),
  },
  registerCheck: {
    id: AUTH.ACTIONS.REGISTER_CHECK,
    request: (data: any) => ({
      url: '/v1/onboarding/dry',
      method: 'POST',
      data,
    }),
  },

  authorizeJitsi: {
    id: AUTH.ACTIONS.AUTHORIZE_JITSI,
    request: (data: any) => ({
      url: '/v1/user/jitsi',
      method: 'GET',
      params: {
        room: data?.room,
      },
    }),
  },
  changePassword: {
    id: AUTH.ACTIONS.CHANGE_PASSWORD,
    request: (data: any) => ({
      url: '/v1/user/password',
      method: 'PUT',
      data,
    }),
  },
}
