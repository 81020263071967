import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { AuthUserRole, DispatchActionInterface } from '../../interfaces'
import { ROUTES } from '../../router/routes'
import { useAppDispatch } from '../../store'
import { checkLogin, login, register, registerCheck } from '../../store/modules/auth/actions'
import {
  setOwnerRoleId,
  setUserBearerToken,
  setUserChatAuthToken,
  setUserChatId,
  setUserRoleId,
} from '../../store/modules/auth/reducer'
import { authorizeChat } from '../../store/modules/chat/actions'
import { getValueFromObject } from '../../store/utils'
import RegisterStep1Form from './components/RegisterStep1Form'
import RegisterStep2Form from './components/RegisterStep2Form'
import RegisterStep3Form from './components/RegisterStep3Form'

export const RegisterHandler = () => {
  const [registerData, setRegisterData] = useState<any | null>(null)
  const [registerStep, setRegisterStep] = useState(1)
  const history = useHistory()
  const dispatch = useAppDispatch()

  const onSubmitStep1 = (values: any, setSubmitting: (arg: boolean) => void) => {
    const send = { ...values }
    send.country = getValueFromObject(send.country)
    send.health_insurance_company_code = getValueFromObject(send.health_insurance_company_code)
    send.phone_number_code = getValueFromObject(send.phone_number_code)
    dispatch(registerCheck(send)).then((action: DispatchActionInterface) => {
      if (action.payload?.status === 'ok') {
        setRegisterData(send)
        setRegisterStep(2)
      }
      setSubmitting(false)
    })
  }
  const onSubmitStep2 = (values: any, setSubmitting: (arg: boolean) => void) => {
    setRegisterData({ ...registerData, ...values })
    dispatch(register({ ...registerData, ...values })).then((action: DispatchActionInterface) => {
      if (action.payload?.status === 'ok') {
        setRegisterStep(3)
      }
      setSubmitting(false)
    })
  }

  const onSubmitStep3 = () => {
    dispatch(
      login({
        username: registerData?.email,
        password: registerData?.password,
        client_id: '60c1db0c7039c43054191ea2',
        client_secret: 'ice_wallow_come',
        scope: 'all',
        grant_type: 'password',
      })
    ).then((action: DispatchActionInterface) => {
      if (action.payload?.status === 'ok') {
        dispatch(setUserBearerToken(action.payload.data.access_token))

        dispatch(checkLogin()).then((action: DispatchActionInterface) => {
          if (action.payload?.status === 'ok') {
            dispatch(setUserRoleId(action.payload?.data.roles[0].id))

            dispatch(
              setOwnerRoleId(
                action.payload.data.roles.find((role: AuthUserRole) => role.patient_access === 'owner')?.id
              )
            )

            dispatch(checkLogin())

            dispatch(authorizeChat()).then((action: DispatchActionInterface) => {
              if (action.payload?.status === 'ok') {
                dispatch(setUserChatAuthToken(action.payload?.data.auth_token))
                dispatch(setUserChatId(action.payload?.data.user_id))
              }
            })
          }
        })
        history.push(ROUTES.REGISTER_ADDITIONAL_DATA)
      }
    })
  }

  return (
    <>
      {registerStep === 1 && (
        <RegisterStep1Form
          onSubmit={(values, setSubmitting) => onSubmitStep1(values, setSubmitting)}
          previousValues={registerData}
        />
      )}
      {registerStep === 2 && (
        <RegisterStep2Form
          onSubmit={(values, setSubmitting) => onSubmitStep2(values, setSubmitting)}
          onPrevious={() => setRegisterStep(1)}
        />
      )}
      {registerStep === 3 && <RegisterStep3Form onSubmit={onSubmitStep3} />}
    </>
  )
}

export default RegisterHandler
