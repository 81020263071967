import { ArrowRightIcon } from '@heroicons/react/outline'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import {
  ButtonBackgroundColorsEnum,
  ButtonIconPositionsEnum,
  ButtonTextColorsEnum,
  ButtonTypesEnum,
  RegisterStepInterface,
} from '../../../../interfaces'
import Button from '../../../common/button/Button'
import FormWrapper from '../../../common/form/FormWrapper'
import CustomInput from '../../../common/inputs/components/CustomInput'

export const RegisterAdditionalDataStep1Form = (props: RegisterStepInterface) => {
  const { onSubmit, previousValues, onSkip } = props
  const { t } = useTranslation(['register', 'yup'])

  const registerSchema = Yup.object({
    height: Yup.number().typeError(t('yup:yup.number')),
    weight: Yup.number().typeError(t('yup:yup.number')),
    diastolic_pressure: Yup.number().typeError(t('yup:yup.number')),
    systolic_pressure: Yup.number().typeError(t('yup:yup.number')),
    bpm: Yup.number().typeError(t('yup:yup.number')),
    cholesterol: Yup.number().typeError(t('yup:yup.number')),
    glucose_level: Yup.number().typeError(t('yup:yup.number')),
  })

  return (
    <>
      <div>
        <h2 className="mt-6 text-3xl font-semibold text-gray-900">{t('register.additionalData.step1Form.title')}</h2>
      </div>
      <div className="mt-8">
        <div className="mt-6">
          <Formik
            initialValues={{
              height: previousValues?.height || '',
              weight: previousValues?.weight || '',
              systolic_pressure: previousValues?.systolic_pressure || '',
              diastolic_pressure: previousValues?.diastolic_pressure || '',
              bpm: previousValues?.bpm || '',
              cholesterol: previousValues?.cholesterol || '',
              glucose_level: previousValues?.glucose_level || '',
            }}
            validationSchema={registerSchema}
            onSubmit={(values, { setSubmitting }) => onSubmit(values, setSubmitting)}
          >
            {({ isSubmitting, errors, touched }) => (
              <FormWrapper className="gap-x-6 gap-y-3">
                <CustomInput
                  type="number"
                  name="height"
                  errors={errors}
                  touched={touched}
                  suffix="cm"
                  label={t('register.additionalData.step1Form.height')}
                  className="col-span-12 sm:col-span-6"
                />

                <CustomInput
                  type="number"
                  name="weight"
                  errors={errors}
                  touched={touched}
                  suffix="kg"
                  label={t('register.additionalData.step1Form.weight')}
                  className="col-span-12 sm:col-span-6"
                />

                <CustomInput
                  type="number"
                  name="systolic_pressure"
                  errors={errors}
                  touched={touched}
                  suffix="mm Hg"
                  label={t('register.additionalData.step1Form.systolicPressure')}
                  className="col-span-12 sm:col-span-6"
                  inputClassName="pr-16"
                />

                <CustomInput
                  type="number"
                  name="diastolic_pressure"
                  errors={errors}
                  touched={touched}
                  suffix="mm Hg"
                  label={t('register.additionalData.step1Form.diastolicPressure')}
                  className="col-span-12 sm:col-span-6"
                  inputClassName="pr-16"
                />

                <CustomInput
                  type="number"
                  name="bpm"
                  errors={errors}
                  touched={touched}
                  suffix="BPM"
                  label={t('register.additionalData.step1Form.bpm')}
                  className="col-span-12 sm:col-span-6"
                  inputClassName="pr-12"
                />

                <CustomInput
                  type="number"
                  name="cholesterol"
                  errors={errors}
                  touched={touched}
                  suffix="mmol/l"
                  label={t('register.additionalData.step1Form.cholesterol')}
                  className="col-span-12 sm:col-span-6"
                  inputClassName="pr-16"
                />

                <CustomInput
                  type="number"
                  name="glucose_level"
                  errors={errors}
                  touched={touched}
                  suffix="mmol/l"
                  label={t('register.additionalData.step1Form.glucoseLevel')}
                  className="col-span-12 sm:col-span-6"
                  inputClassName="pr-16"
                />

                <div className="flex col-span-12 justify-between mt-12">
                  <Button
                    type={ButtonTypesEnum.Button}
                    onClick={onSkip}
                    backgroundColor={ButtonBackgroundColorsEnum.White}
                    textColor={ButtonTextColorsEnum.Gray}
                    title={t('register.additionalData.step1Form.skipButton')}
                  />

                  <Button
                    type={ButtonTypesEnum.Submit}
                    icon={ArrowRightIcon}
                    iconPosition={ButtonIconPositionsEnum.Right}
                    backgroundColor={ButtonBackgroundColorsEnum.Brand}
                    textColor={ButtonTextColorsEnum.White}
                    title={t('register.additionalData.step1Form.nextStepButton')}
                    disabled={isSubmitting}
                  />
                </div>
              </FormWrapper>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}

export default RegisterAdditionalDataStep1Form
