import { SVGProps } from 'react'
import { ButtonBackgroundColorsEnum, ButtonTextColorsEnum, ButtonTypesEnum } from './ButtonInterface'

export interface CustomInputComponentInterface {
  className?: string
  label?: string
  name: string
  errors: any
  touched: any
  disabled?: boolean | ((row?: any) => boolean)
}

export interface CustomInputInterface extends CustomInputComponentInterface {
  type: string
  autoComplete?: string
  inputClassName?: string
  children?: JSX.Element
  icon?: (props: SVGProps<SVGSVGElement>) => JSX.Element
  iconPosition?: IconPositionsEnum
  iconOnClick?: () => void
  as?: string
  placeholder?: string
  suffix?: string

  value?: string
  onChange?: (value: any) => void
}

export enum IconPositionsEnum {
  Left,
  Right,
}

export interface CustomFileInputInterface extends CustomInputComponentInterface {
  inputClassName?: string
  icon?: any
  onChange?: (e: any) => void
  value?: any
}

export interface CustomSelectInterface extends CustomInputComponentInterface {
  value: any
  options?: CustomSelectOptionsInterface[]
  onChange: (current: any) => void
  hideLabel?: boolean
  isChild?: boolean
  loading?: boolean
}

export interface CustomRadioGroupInterface extends CustomInputComponentInterface {
  value?: any
  options: CustomRadioGroupOptionsInterface[]
  onChange: (current: any) => void
  hideLabel?: boolean
  loading?: boolean
  suffix?: string
}

export interface CustomRadioGroupOptionsInterface {
  value: string | number
  title: string
  description?: string
  text_right: string
}

export interface CustomSearchSelectInterface extends CustomInputComponentInterface {
  options?: any[]
  onChange: (e: any) => void
  defaultValue?: SearchSelectValueInterface
  placeholder?: string
  disabled?: boolean
}

export interface CustomApiSearchSelectInterface extends CustomInputComponentInterface {
  options?: any[]
  onChange: (e: any) => void
  defaultValue?: SearchSelectValueInterface
  placeholder?: string
  disabled?: boolean

  searchSelectDispatch?: (value: any) => any
  searchSelectStructure?: SearchSelectStructureInterface
  isMulti: boolean
  prefetch: boolean
}

export interface SearchSelectValueInterface {
  name: string
  value: string | number
}

export interface CustomMultiselectInterface extends CustomInputComponentInterface {
  options: CustomSelectOptionsInterface[]
  onChange: (current: any) => void
  hideLabel?: boolean
  isChild?: boolean
  defaultValue?: SearchSelectValueInterface
  placeholder?: string
}

export interface CustomMaskedInputInterface extends CustomInputComponentInterface {
  mask: any
  onChange: (e: any) => void
  value?: string
}

export interface CustomCheckboxInterface extends CustomInputComponentInterface {
  test?: string
}

export interface CustomSwitchInterface extends CustomInputComponentInterface {
  description: string
  onChange: (e: any) => void
  value: boolean
}

export interface CustomSelectOptionsInterface {
  name: string
  value: string | number
}

export enum TextTypeEnum {
  MultiValue = 'multiValue',
  FileSize = 'fileSize',
  FileType = ' fileType',
  TelerehabilitationStatus = 'telerehabilitationStatus',
  Chart = 'chart',
  WebGLChart = 'webGLChart',
  Download = 'download',
  Button = 'button',
  PatientVerified = 'patientVerified',
  PresetParsed = 'presetParsed',
}

export enum InputTypeEnum {
  ReadOnly = 'read-only',
  Select = 'Select',
  Multiselect = 'multiselect',
  ApiSearchSelect = 'apisearchselect',
  SearchSelect = 'searchselect',
  Checkbox = 'checkbox',
  Textarea = 'textarea',
  Date = 'date',
  DateTime = 'datetime-local',
  Text = 'text',
  Number = 'number',
  File = 'file',
  Video = 'video',
  PIN = 'pin',
  PatientsAttendees = 'patientsAttendees',
  DoctorsAttendees = 'doctorsAttendees',
  Filename = 'filename',
}

export interface InputByTypeBase {
  label?: string
  list_label?: string
  name: string
  value?: any
  className?: string
  disabled?: boolean | ((row?: any) => boolean)
  placeholder?: string
  suffix?: string
  inputClassName?: string

  hide?: boolean
  fullWidth?: boolean
}

export interface InputByTypeDefault extends InputByTypeBase {
  type:
    | InputTypeEnum.PIN
    | InputTypeEnum.Date
    | InputTypeEnum.Textarea
    | InputTypeEnum.Number
    | InputTypeEnum.File
    | InputTypeEnum.Video
    | InputTypeEnum.Filename
    | InputTypeEnum.DateTime
    | TextTypeEnum.FileType
    | TextTypeEnum.Chart
    | TextTypeEnum.WebGLChart
    | TextTypeEnum.Download
    | TextTypeEnum.PatientVerified
}

export interface InputByTypeReadOnly extends InputByTypeBase {
  type: InputTypeEnum.ReadOnly
  t?: any
  namespace?: string
}

export interface InputByTypeInput extends InputByTypeBase {
  type: InputTypeEnum.Text
  t?: any
  namespace?: string
}

export interface InputByTypeCheckbox extends InputByTypeBase {
  type: InputTypeEnum.Checkbox
  t: any
  namespace: string
}

export interface InputByTypeSelect extends InputByTypeBase {
  type: InputTypeEnum.Select | InputTypeEnum.Multiselect | InputTypeEnum.SearchSelect | InputTypeEnum.PatientsAttendees
  options?: CustomSelectOptionsInterface[]
  t?: any
  namespace?: string
}

export interface InputByTypeApiSearchSelect extends InputByTypeBase {
  type: InputTypeEnum.ApiSearchSelect
  searchSelectDispatch: (value: any) => any
  searchSelectStructure: SearchSelectStructureInterface
  searchSelectStructureMulti?: boolean
  prefetch?: boolean
}

export interface InputByTypePatientsAttendees extends InputByTypeBase {
  type: InputTypeEnum.DoctorsAttendees
  searchSelectDispatch: (value: any) => any
  searchSelectStructure: SearchSelectStructureInterface
  searchSelectStructureMulti?: boolean
  t?: any
  namespace?: string
  prefetch?: boolean
}

export interface InputByTypeButton extends InputByTypeBase {
  type: TextTypeEnum.Button
  title: string
  buttonType: ButtonTypesEnum
  backgroundColor: ButtonBackgroundColorsEnum
  textColor: ButtonTextColorsEnum
}

export type InputByTypeRow =
  | InputByTypeButton
  | InputByTypeApiSearchSelect
  | InputByTypeSelect
  | InputByTypeInput
  | InputByTypeDefault
  | InputByTypeCheckbox
  | InputByTypeReadOnly
  | InputByTypePatientsAttendees

export interface SearchSelectStructureInterface {
  name: string | string[]
  value: string
}

export interface TextByTypeColumnsInterface {
  label: string
  values?: string | string[]
  type?: InputTypeEnum | TextTypeEnum
  suffix?: string
  formatter?: (value: any) => any

  t?: any
  namespace?: string
}

export interface InputByTypeInterface {
  row: InputByTypeRow
  errors: any
  touched: any
  values?: any
  setFieldValue: any
}

export interface TextByTypeInterface {
  row: any
  keyName?: string | string[]
  type?: InputTypeEnum | TextTypeEnum
  t?: any
  namespace?: string
  suffix?: string

  values?: any
}
