import { Menu, Transition } from '@headlessui/react'
import { ChevronLeftIcon, ChevronRightIcon, PlusIcon } from '@heroicons/react/outline'
import { DotsHorizontalIcon } from '@heroicons/react/solid'
import { Fragment, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { APP_SETTINGS } from '../../../enums'
import {
  ButtonBackgroundColorsEnum,
  ButtonTextColorsEnum,
  ButtonTypesEnum,
  TableActionComponentInterface,
  TableInterface,
  TextByTypeColumnsInterface,
} from '../../../interfaces'
import { classNames, replaceRouteParams, returnDisabled } from '../../../store/utils'
import Button from '../button/Button'
import EmptyState from '../empty-state/EmptyState'
import TextByType from '../inputs/TextByType'

let timeout: any = 0

export const Table = (props: TableInterface) => {
  const {
    columns,
    actions,
    data,
    pagination = true,
    title,
    onCreateClick,
    fetch,
    search,
    limit,
    loading,
    emptyState = true,
    emptyStateText,
    emptyStateAddButtonTitle,
    onEmptyStateClick,
  } = props
  const { t } = useTranslation(['table'])
  const [internalLimit, setInternalLimit] = useState<number>(limit || 0)
  const [offset, setOffset] = useState(0)
  const [firstLoad, setFirstLoad] = useState(true)
  const [wasLoading, setWasLoading] = useState(false)

  useEffect(() => {
    return () => {
      setFirstLoad(true)
      setWasLoading(false)
    }
  }, [])

  useEffect(() => {
    if (loading) setWasLoading(true)
    if (wasLoading && !loading) setFirstLoad(false)
  }, [loading])

  const hasTitleOrButton = () => {
    return title || onCreateClick
  }

  const previousPageDisabled = () => {
    return data?.prev === data?.number
  }

  const nextPageDisabled = () => {
    return data?.next === data?.number
  }

  const getNextPage = (page: number) => {
    setOffset(internalLimit * page)
  }

  const getPrevPage = (page: number) => {
    setOffset(internalLimit * page)
  }

  useEffect(() => {
    // Fetch only if limit or offset changes
    fetch(internalLimit, offset, search)
  }, [internalLimit, offset])

  useEffect(() => {
    performSearch()

    return () => {
      clearTimeout(timeout)
    }
  }, [search])

  const performSearch = () => {
    if (search !== undefined) {
      timeout = setTimeout(() => {
        fetch(internalLimit, offset, search)
      }, APP_SETTINGS.API_SEARCH_DEBOUNCE)
    }
  }

  if (loading && firstLoad) {
    return (
      <div className="flex flex-col animate-pulse">
        <div className="overflow-x-auto -my-2 sm:-mx-6 lg:-mx-8">
          <div className="inline-block py-2 sm:px-6 lg:px-8 min-w-full align-middle">
            <div className="overflow-hidden rounded-lg border-b border-gray-200 shadow">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    {[...Array(5)].map((value, key) => (
                      <th
                        key={key}
                        scope="col"
                        className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                      >
                        <div className="h-6 bg-gray-300 rounded " />
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {[...Array(5)].map((value, rowKey) => (
                    <tr key={rowKey}>
                      {[...Array(5)].map((value, colKey) => (
                        <td key={colKey} className="py-4 px-6 text-sm text-gray-500 whitespace-nowrap">
                          <div className="h-6 bg-gray-300 rounded" />
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (emptyState && firstLoad) {
    return (
      <EmptyState
        text={
          emptyStateText
            ? emptyStateText
            : onEmptyStateClick
            ? t('table.emptyStateTextWithButton')
            : t('table.emptyStateTextWithoutButton')
        }
        buttonTitle={emptyStateAddButtonTitle ? emptyStateAddButtonTitle : t('table.emptyStateAddButton')}
        onClick={onEmptyStateClick ? onEmptyStateClick : undefined}
      />
    )
  }

  return (
    <div className="overflow-x-auto bg-white rounded-lg shadow">
      <div className="relative z-10">
        {hasTitleOrButton() && (
          <div className="flex justify-between items-center py-5 px-4 sm:px-6">
            {title && <h3 className="text-lg font-medium leading-6 text-brand">{title}</h3>}
            {onCreateClick && (
              <Button
                type={ButtonTypesEnum.Button}
                backgroundColor={ButtonBackgroundColorsEnum.Brand}
                textColor={ButtonTextColorsEnum.White}
                title={t('table.addButton')}
                icon={PlusIcon}
                onClick={onCreateClick}
              />
            )}
          </div>
        )}

        <div className={`flex flex-col ${title ? 'border-t border-gray-200' : ''}`}>
          <div className="inline-block min-w-full align-middle">
            <div className="rounded-lg border-b border-gray-200 ">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    {columns.map((col: TextByTypeColumnsInterface, key: number) => {
                      return (
                        <th
                          key={key}
                          scope="col"
                          className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                        >
                          {col.label}
                        </th>
                      )
                    })}
                    {actions && actions?.length > 0 && (
                      <th scope="col" className="sticky -right-px py-3 px-6 text-right bg-gray-50">
                        <span className="sr-only">{t('table.detailColumn')}</span>
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {data?.current?.length === 0 && (
                    <>
                      <tr>
                        <td className="p-4 text-sm text-gray-700" colSpan={columns.length}>
                          {t('table.empty')}
                        </td>
                      </tr>
                    </>
                  )}
                  {data &&
                    Array.isArray(data.current) &&
                    data.current.map((row: any, key: number) => {
                      return (
                        <tr key={key}>
                          {columns.map((keyName, k) => {
                            const data = { ...row }

                            if (keyName.formatter) {
                              if (typeof keyName.values === 'string')
                                data[keyName.values] = keyName.formatter(row[keyName.values])
                            }

                            return (
                              <td key={k} className="py-4 px-6 text-sm text-gray-500 whitespace-nowrap">
                                <TextByType
                                  type={keyName.type}
                                  keyName={keyName.values}
                                  t={keyName.t}
                                  row={data}
                                  namespace={keyName.namespace}
                                  suffix={keyName.suffix}
                                />
                              </td>
                            )
                          })}
                          {actions && actions?.length > 0 && (
                            <td className="sticky -right-px px-2 text-sm font-medium text-right whitespace-nowrap bg-white">
                              <div className="hidden md:block">
                                {actions.map((action, key) => (
                                  <TableAction key={key} action={action} row={row} />
                                ))}
                              </div>
                              <div className="block md:hidden">
                                <Menu as={Fragment}>
                                  <div>
                                    <Menu.Button className="inline-flex relative justify-center w-full text-sm rounded-md focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus:outline-none">
                                      <DotsHorizontalIcon className="w-5 h-5" aria-hidden="true" />
                                    </Menu.Button>
                                  </div>
                                  <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                  >
                                    <Menu.Items className="absolute top-0 right-16 bg-white rounded-md divide-y divide-gray-100 ring-1 ring-black ring-opacity-5 shadow-lg origin-top-right focus:outline-none">
                                      <div className="flex flex-wrap py-1 px-1">
                                        {actions.map((action, key) => (
                                          <Menu.Item key={key}>
                                            <TableAction action={action} row={row} className="w-full" />
                                          </Menu.Item>
                                        ))}
                                      </div>
                                    </Menu.Items>
                                  </Transition>
                                </Menu>
                              </div>
                            </td>
                          )}
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {pagination && (
        <div className="flex sticky left-0 z-0 justify-between items-center py-3 px-6 w-full bg-gray-50">
          <div className="flex sm:hidden flex-1 justify-between">
            <button
              onClick={() => getPrevPage(data.prev)}
              disabled={previousPageDisabled()}
              className={`${
                previousPageDisabled() && 'cursor-not-allowed'
              } inline-flex relative items-center py-2 px-2 text-sm font-medium text-gray-500 bg-white hover:bg-gray-50 rounded-l-md border border-gray-300`}
            >
              <span className="sr-only">{t('table.prev')}</span>
              <ChevronLeftIcon className="w-5 h-5" aria-hidden="true" />
            </button>

            <button
              onClick={() => getNextPage(data.next)}
              disabled={nextPageDisabled()}
              className={`${
                nextPageDisabled() && 'cursor-not-allowed'
              } inline-flex relative items-center py-2 px-2 text-sm font-medium text-gray-500 bg-white hover:bg-gray-50 rounded-r-md border border-gray-300`}
            >
              <span className="sr-only">{t('table.next')}</span>
              <ChevronRightIcon className="w-5 h-5" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden sm:flex sm:flex-1 sm:justify-between sm:items-center">
            <div>
              {data && (
                <div className="flex justify-center items-center">
                  {limit && (
                    <div className="pr-5">
                      <select
                        onChange={e => {
                          setOffset(0)
                          setInternalLimit(parseInt(e.target.value))
                        }}
                        className="py-0 pr-10 pl-3 mt-1 w-full text-base sm:text-sm rounded-md border-gray-300 focus:border-brand focus:ring-brand focus:outline-none"
                      >
                        {[limit, limit * 2, limit * 5, limit * 10].map(i => (
                          <option key={i} selected={internalLimit === i} value={i}>
                            {i}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  <p className="pt-1 text-sm text-gray-700">
                    {internalLimit && (
                      <Trans
                        i18nKey="table:table.pagination"
                        values={{
                          from: data?.number * internalLimit + (data?.total_filtered_count > 0 ? 1 : 0),
                          to: data?.number * internalLimit + data?.size,
                          total: data?.total_filtered_count,
                        }}
                      />
                    )}
                  </p>
                </div>
              )}
            </div>
            <div>
              <nav className="inline-flex relative z-0 -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                <button
                  onClick={() => getPrevPage(data.prev)}
                  disabled={previousPageDisabled()}
                  className={`${
                    previousPageDisabled() && 'cursor-not-allowed'
                  } inline-flex relative items-center py-2 px-2 text-sm font-medium text-gray-500 bg-white hover:bg-gray-50 rounded-l-md border border-gray-300`}
                >
                  <span className="sr-only">{t('table.prev')}</span>
                  <ChevronLeftIcon className="w-5 h-5" aria-hidden="true" />
                </button>

                <button
                  onClick={() => getNextPage(data.next)}
                  disabled={nextPageDisabled()}
                  className={`${
                    nextPageDisabled() && 'cursor-not-allowed'
                  } inline-flex relative items-center py-2 px-2 text-sm font-medium text-gray-500 bg-white hover:bg-gray-50 rounded-r-md border border-gray-300`}
                >
                  <span className="sr-only">{t('table.next')}</span>
                  <ChevronRightIcon className="w-5 h-5" aria-hidden="true" />
                </button>
              </nav>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

const TableAction = (props: TableActionComponentInterface) => {
  const {
    action: {
      backgroundColor,
      iconPosition,
      icon,
      disabled,
      size,
      onClick,
      textColor,
      title,
      type,
      path,
      dynamicLink,
      onClickKey = '_id',
      tooltip,
    },
    action,
    className,
    row,
  } = props
  return (
    <>
      <Button
        type={type}
        backgroundColor={backgroundColor}
        textColor={textColor}
        title={title}
        icon={icon}
        className={classNames(className ? className : '', action?.className ? action.className : '')}
        disabled={returnDisabled(disabled, row)}
        size={size}
        iconPosition={iconPosition}
        onClick={onClick ? () => onClick(row[onClickKey]) : undefined}
        path={
          dynamicLink
            ? replaceRouteParams(dynamicLink.path, [{ key: dynamicLink.key, value: row[dynamicLink.value] }])
            : path
        }
        tooltip={tooltip}
      />
    </>
  )
}

export default Table
