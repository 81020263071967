import { SVGProps } from 'react'

export interface ButtonInterface {
  type: ButtonTypesEnum
  path?: string
  onClick?: (values?: any, setSubmitting?: any) => void
  disabled?: boolean | ((row?: any) => boolean)
  backgroundColor: ButtonBackgroundColorsEnum
  textColor: ButtonTextColorsEnum
  title?: string | JSX.Element
  tooltip?: string
  icon?: (props: SVGProps<SVGSVGElement>) => JSX.Element
  iconPosition?: ButtonIconPositionsEnum
  className?: string
  size?: ButtonSizesEnum
  buttonRef?: any
  hide?: boolean
}

export enum ButtonBackgroundColorsEnum {
  Brand = 'brand',
  DarkGreen = 'darkGreen',
  White = 'white',
  Red = 'red',
  LightGray = 'lightGray',
  Gray = 'gray',
  Transparent = 'transparent',
}

export enum ButtonTextColorsEnum {
  Gray = 'gray',
  Red = 'red',
  White = 'white',
  Brand = 'brand',
  Black = 'black',
  DarkGreen = 'darkGreen',
}

export enum ButtonTypesEnum {
  Link,
  Button,
  Submit,
  ExternalLink,
}

export enum ButtonIconPositionsEnum {
  Left,
  Right,
}

export enum ButtonSizesEnum {
  small = 'small',
  normal = 'normal',
  medium = 'medium',
}
