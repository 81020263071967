import { useHistory } from 'react-router-dom'
import { DispatchActionInterface } from '../../interfaces'
import { ROUTES } from '../../router/routes'
import { useAppDispatch } from '../../store'
import { login } from '../../store/modules/auth/actions'
import { setUserBearerToken } from '../../store/modules/auth/reducer'
import { LoginForm } from './components/LoginForm'

export const LoginHandler = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()

  const onSubmit = ({ username, password }: any, setSubmitting: any) => {
    dispatch(
      login({
        username: username,
        password: password,
        client_id: '60c1db0c7039c43054191ea2',
        client_secret: 'ice_wallow_come',
        scope: 'all',
        grant_type: 'password',
      })
    ).then((action: DispatchActionInterface) => {
      setSubmitting(false)
      if (action.payload?.status === 'ok') {
        dispatch(setUserBearerToken(action.payload.data.access_token))
        history.push(ROUTES.ROLE_SELECT)
      }
    })
  }

  return <LoginForm onSubmit={onSubmit} />
}
