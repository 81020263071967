import { UsersIcon } from '@heroicons/react/solid'
import React, { Suspense, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import ChatSidebar from '../../../components/app/chat/ChatSidebar'
import PrivateRoute from '../../../components/common/auth/PrivateRoute'
import Button from '../../../components/common/button/Button'
import AppLayout from '../../../components/common/layout/AppLayout'
import ContentLoader from '../../../components/common/loader/ContentLoader'
import { ChatType, PARAMS } from '../../../enums'
import { ButtonBackgroundColorsEnum, ButtonTextColorsEnum, ButtonTypesEnum, RouterInterface } from '../../../interfaces'
import { ROUTES } from '../../../router/routes'
import { RootState } from '../../../store'
import { getRoutesStructure, replaceRouteParams, sortArray } from '../../../store/utils'

export const ChatPage = () => {
  const { getSubscriptionsLoading, subscriptions } = useSelector((state: RootState) => state.chat)
  const routes = getRoutesStructure(ROUTES.CHAT).routes
  const [mobileSidebarOpen, setMobileSidebarOpen] = useState(false)
  const [firstLoad, setFirstLoad] = useState(true)
  const [wasLoading, setWasLoading] = useState(false)

  useEffect(() => {
    return () => {
      setFirstLoad(true)
      setWasLoading(false)
    }
  }, [])

  useEffect(() => {
    if (getSubscriptionsLoading) setWasLoading(true)
    if (wasLoading) setFirstLoad(false)
  }, [getSubscriptionsLoading])

  return (
    <AppLayout>
      <Suspense fallback={<ContentLoader />}>
        <div className="flex overflow-hidden relative w-full h-full">
          <div className="flex overflow-y-auto flex-col w-full h-full">
            <Button
              icon={UsersIcon}
              type={ButtonTypesEnum.Button}
              onClick={() => setMobileSidebarOpen(!mobileSidebarOpen)}
              backgroundColor={ButtonBackgroundColorsEnum.DarkGreen}
              textColor={ButtonTextColorsEnum.White}
              className="lg:hidden m-5 mb-0"
            />
            <Switch>
              {routes.map((i: RouterInterface) => (
                <PrivateRoute
                  key={i.title}
                  title={i.title}
                  path={i.path}
                  component={i.component}
                  exact={i.exact}
                  role={i.role}
                />
              ))}
              {!getSubscriptionsLoading && subscriptions.length > 0 && (
                <Route
                  component={() => {
                    const latest = sortArray(subscriptions, 'ls', 'desc')
                    const route =
                      latest[0]?.t === ChatType.DM
                        ? replaceRouteParams(ROUTES.CHAT_DM, [
                            {
                              key: `:${PARAMS.CHAT_DM_ID}`,
                              value: latest[0]?.rid,
                            },
                          ])
                        : replaceRouteParams(ROUTES.CHAT_GROUP, [
                            {
                              key: `:${PARAMS.CHAT_GROUP_ID}`,
                              value: latest[0]?.rid,
                            },
                          ])
                    return <>{latest && <Redirect to={route} />}</>
                  }}
                />
              )}
            </Switch>
          </div>
          <ChatSidebar
            loading={getSubscriptionsLoading && firstLoad}
            mobileSidebarOpen={mobileSidebarOpen}
            setMobileSidebarOpen={setMobileSidebarOpen}
          />
        </div>
      </Suspense>
    </AppLayout>
  )
}

export default ChatPage
