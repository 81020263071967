import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ContentLoader from '../../components/common/loader/ContentLoader'

export const ErrorBoundaryFallback = ({ error }: any) => {
  const { t } = useTranslation(['errors'])
  console.error({ error })

  useEffect(() => {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/
    if (error?.message && chunkFailedMessage.test(error.message)) {
      if (!getWithExpiry('chunk_failed')) {
        setWithExpiry('chunk_failed', 'true', 10000)
        window.location.reload()
      }
    }
  }, [error])

  const setWithExpiry = (key: string, value: string, ttl: number) => {
    const item = {
      value: value,
      expiry: new Date().getTime() + ttl,
    }
    localStorage.setItem(key, JSON.stringify(item))
  }

  const getWithExpiry = (key: string) => {
    const itemString = window.localStorage.getItem(key)
    if (!itemString) return null

    const item = JSON.parse(itemString)
    const isExpired = new Date().getTime() > item.expiry

    if (isExpired) {
      localStorage.removeItem(key)
      return null
    }

    return item.value
  }
  return (
    <div className="flex justify-center items-center w-full h-screen">
      <div className="flex flex-col items-center">
        <ContentLoader />
        {error?.arguments?.name === 'ChunkLoadError' ? (
          <div>{t('errors.appUpdated')}</div>
        ) : (
          <div>
            <span className="my-4 text-6xl font-medium text-brand">{t('errors.somethingWentWrong')}</span>
            <span className="block">{error?.arguments?.message}</span>
          </div>
        )}
      </div>
    </div>
  )
}

export default ErrorBoundaryFallback
