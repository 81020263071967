import { createSlice } from '@reduxjs/toolkit'
import {
  ChatState,
  DispatchActionReducerInterface,
  DispatchActionReducerRocketChatInterface,
} from '../../../interfaces'
import {
  authorizeChat,
  getChatUsers,
  getDirectMessage,
  getGroupChat,
  getGroupChatMembers,
  getSubscription,
  getSubscriptions,
} from './actions'

import { CHAT } from './types'

const initialState: ChatState = {
  directMessage: [],
  getDirectMessageLoading: false,

  groupChat: [],
  getGroupChatLoading: false,

  groupChatMembers: [],
  getGroupChatMembersLoading: false,

  subscriptions: [],
  getSubscriptionsLoading: false,

  subscription: null,
  getSubscriptionLoading: false,

  authChat: null,
  chatAuthorized: false,

  chatUsers: null,
  getChatUsersLoading: false,
}

const chatSlice = createSlice({
  name: CHAT.NAMESPACE,
  initialState,
  // Only frontend Logic
  reducers: {},
  extraReducers: builder => {
    // Reducers with calling external API
    builder

      .addCase(getDirectMessage.pending, (state: ChatState) => {
        state.getDirectMessageLoading = true
      })
      .addCase(getDirectMessage.fulfilled, (state: ChatState, action: DispatchActionReducerRocketChatInterface) => {
        if (action.payload?.success) {
          state.directMessage = action.payload.messages
        } else {
          state.directMessage = []
        }
        state.getDirectMessageLoading = false
      })
      .addCase(getDirectMessage.rejected, (state: ChatState) => {
        state.directMessage = []
        state.getDirectMessageLoading = false
      })

      .addCase(getGroupChat.pending, (state: ChatState) => {
        state.getGroupChatLoading = true
      })
      .addCase(getGroupChat.fulfilled, (state: ChatState, action: DispatchActionReducerRocketChatInterface) => {
        if (action.payload?.success) {
          state.groupChat = action.payload.messages
        } else {
          state.groupChat = []
        }
        state.getGroupChatLoading = false
      })
      .addCase(getGroupChat.rejected, (state: ChatState) => {
        state.groupChat = []
        state.getGroupChatLoading = false
      })

      .addCase(getGroupChatMembers.pending, (state: ChatState) => {
        state.getGroupChatMembersLoading = true
      })
      .addCase(getGroupChatMembers.fulfilled, (state: ChatState, action: DispatchActionReducerRocketChatInterface) => {
        if (action.payload?.success) {
          state.groupChatMembers = action.payload.members
        } else {
          state.groupChatMembers = []
        }
        state.getGroupChatMembersLoading = false
      })
      .addCase(getGroupChatMembers.rejected, (state: ChatState) => {
        state.groupChatMembers = []
        state.getGroupChatMembersLoading = false
      })

      .addCase(authorizeChat.fulfilled, (state: ChatState, action: DispatchActionReducerInterface) => {
        state.chatAuthorized = action.payload.status === 'ok'
        state.authChat = action.payload.data
      })
      .addCase(authorizeChat.rejected, (state: ChatState) => {
        state.chatAuthorized = false
        state.authChat = null
      })

      .addCase(getChatUsers.pending, (state: ChatState) => {
        state.getChatUsersLoading = true
      })
      .addCase(getChatUsers.fulfilled, (state: ChatState, action: DispatchActionReducerInterface) => {
        state.chatUsers = action.payload.data
        if (action.payload.status === 'ok') {
          state.chatUsers.current = action.payload.data.current.map((user: any) => {
            if (user?.pin) {
              user.badge = user.pin
            } else {
              user.badge = user?.email
            }

            return user
          })
        } else {
          state.chatUsers = {}
        }
        state.getChatUsersLoading = false
      })
      .addCase(getChatUsers.rejected, (state: ChatState) => {
        state.chatUsers = {}
        state.getChatUsersLoading = false
      })

      .addCase(getSubscriptions.pending, (state: ChatState) => {
        state.getSubscriptionsLoading = true
      })
      .addCase(getSubscriptions.fulfilled, (state: ChatState, action: DispatchActionReducerRocketChatInterface) => {
        if (action.payload?.success) {
          state.subscriptions = action.payload.update
        } else {
          state.subscriptions = []
        }
        state.getSubscriptionsLoading = false
      })
      .addCase(getSubscriptions.rejected, (state: ChatState) => {
        state.subscriptions = []
        state.getSubscriptionsLoading = false
      })

      .addCase(getSubscription.pending, (state: ChatState) => {
        state.getSubscriptionLoading = true
      })
      .addCase(getSubscription.fulfilled, (state: ChatState, action: DispatchActionReducerRocketChatInterface) => {
        if (action.payload?.success) {
          state.subscription = action.payload.subscription
        } else {
          state.subscription = null
        }
        state.getSubscriptionLoading = false
      })
      .addCase(getSubscription.rejected, (state: ChatState) => {
        state.subscription = null
        state.getSubscriptionLoading = false
      })
  },
})

// Extract the action creators object and the reducer
const { reducer } = chatSlice
// Extract and export each action creator by name
// export const {  } = actions
// Export the reducer, either as a default or named export
export default reducer
