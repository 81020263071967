import { useEffect, useState } from 'react'
import { InputByTypeInterface, InputTypeEnum, LineChartTypeEnum, TextTypeEnum } from '../../../interfaces'
import { getValueFromObject, returnDisabled } from '../../../store/utils'
import LineChart from '../chart/LineChart'
import CustomApiSearchSelect from './components/CustomApiSearchSelect'
import CustomCheckbox from './components/CustomCheckbox'
import CustomFileInput from './components/CustomFileInput'
import CustomInput from './components/CustomInput'
import CustomMaskedInput from './components/CustomMaskedInput'
import CustomMultiselect from './components/CustomMultiselect'
import CustomSearchSelect from './components/CustomSearchSelect'
import CustomSelect from './components/CustomSelect'

export const InputByType = (props: InputByTypeInterface) => {
  const { row, errors, setFieldValue, touched, values } = props

  const PINMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]

  const getInputByType = () => {
    switch (row.type) {
      case InputTypeEnum.Checkbox: {
        return (
          <CustomCheckbox
            className={row.className}
            name={row.name}
            errors={errors}
            disabled={returnDisabled(row.disabled, values)}
            touched={touched}
            label={row.label}
          />
        )
      }
      case InputTypeEnum.PatientsAttendees:
      case InputTypeEnum.Multiselect: {
        return (
          <>
            {row.options && (
              <CustomMultiselect
                name={row.name}
                options={row.options}
                disabled={returnDisabled(row.disabled, values)}
                defaultValue={getValueFromObject(values, row.name) ? getValueFromObject(values, row.name) : row.value}
                onChange={e => setFieldValue(row.name, e)}
                errors={errors}
                touched={touched}
                className={row.className}
                placeholder={row.placeholder}
                label={row.label}
              />
            )}
          </>
        )
      }
      case InputTypeEnum.Select: {
        return (
          row.options && (
            <>
              <CustomSelect
                name={row.name}
                value={getValueFromObject(values, row.name) ? getValueFromObject(values, row.name) : row.value}
                options={row.options}
                onChange={e => setFieldValue(row.name, e)}
                disabled={returnDisabled(row.disabled, values)}
                errors={errors}
                touched={touched}
                className={row.className}
                label={row.label}
              />
            </>
          )
        )
      }
      case TextTypeEnum.Chart: {
        const series = [
          {
            name: '',
            data: getValueFromObject(row.value, ['time', 'value']),
          },
        ]

        return (
          <>
            <LineChart series={series} chartType={LineChartTypeEnum.Timeline} />
          </>
        )
      }
      case InputTypeEnum.DoctorsAttendees:
      case InputTypeEnum.ApiSearchSelect: {
        return (
          <CustomApiSearchSelect
            name={row.name}
            onChange={e => setFieldValue(row.name, e)}
            errors={errors}
            touched={touched}
            disabled={returnDisabled(row.disabled, values)}
            className={row.className}
            label={row.label}
            placeholder={row.placeholder}
            searchSelectDispatch={row.searchSelectDispatch}
            searchSelectStructure={row.searchSelectStructure}
            defaultValue={values[row.name]}
            isMulti={row?.searchSelectStructureMulti || false}
            prefetch={row?.prefetch || false}
          />
        )
      }
      case InputTypeEnum.SearchSelect: {
        return (
          <CustomSearchSelect
            name={row.name}
            onChange={e => setFieldValue(row.name, e)}
            errors={errors}
            touched={touched}
            disabled={returnDisabled(row.disabled, values)}
            className={row.className}
            label={row.label}
            placeholder={row.placeholder}
            options={row.options}
            defaultValue={values[row.name]}
          />
        )
      }
      case InputTypeEnum.Textarea: {
        return (
          <CustomInput
            as="textarea"
            className={row.className}
            type={row.type}
            name={row.name}
            errors={errors}
            disabled={returnDisabled(row.disabled, values)}
            touched={touched}
            placeholder={row.placeholder}
            label={row.label}
          />
        )
      }
      case InputTypeEnum.Video:
      case InputTypeEnum.File: {
        return (
          <CustomFileInput
            name={row.name}
            errors={errors}
            disabled={returnDisabled(row.disabled, values)}
            touched={touched}
            onChange={event => setFieldValue(row.name, event.target.files[0])}
            className={row.className}
            label={row.label}
            value={values[row.name]}
          />
        )
      }
      case InputTypeEnum.Filename: {
        const [suffix, setSuffix] = useState('')
        const [value, setValue] = useState('')

        useEffect(() => {
          setSuffix(row.value.slice(row.value.lastIndexOf('.')))
          setValue(row.value.slice(0, row.value.lastIndexOf('.')))
        }, [])

        return (
          <CustomInput
            label={row.label}
            type="text"
            name={row.name}
            errors={errors}
            disabled={returnDisabled(row.disabled, values)}
            touched={touched}
            className={row.className}
            placeholder={row.placeholder}
            suffix={suffix}
            inputClassName={row.inputClassName}
            value={value}
            onChange={value => {
              setValue(value.target.value)
              setFieldValue(row.name, `${value.target.value}${suffix}`)
            }}
          />
        )
      }
      case InputTypeEnum.PIN: {
        const [value, setValue] = useState('')

        useEffect(() => {
          setValue(row.value)
        }, [])

        return (
          <CustomMaskedInput
            className={row.className}
            label={row.label}
            name={row.name}
            mask={PINMask}
            onChange={e => {
              setFieldValue(row.name, e.target.value)
              setValue(e.target.value)
            }}
            errors={errors}
            disabled={returnDisabled(row.disabled, values)}
            touched={touched}
            value={value}
          />
        )
      }
      default: {
        return (
          <CustomInput
            label={row.label}
            type={row.type}
            name={row.name}
            errors={errors}
            disabled={returnDisabled(row.disabled, values)}
            touched={touched}
            className={row.className}
            placeholder={row.placeholder}
            suffix={row.suffix}
            inputClassName={row.inputClassName}
          />
        )
      }
    }
  }

  return <>{getInputByType()}</>
}

export default InputByType
