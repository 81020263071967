import { ApexOptions } from 'apexcharts'
import React, { useEffect, useState } from 'react'
import ApexChart from 'react-apexcharts'
import { LineChartInterface, LineChartTypeEnum } from '../../../interfaces'

export const LineChart = (props: LineChartInterface) => {
  const { series, chartType, annotations = {}, max, min } = props
  const [hasData, setHasData] = useState(true)
  const [format, setFormat] = useState('dd.MM.yyyy')
  const [type, setType] = useState<'datetime' | 'category' | 'numeric' | undefined>('datetime')
  const [curve, setCurve] = useState<
    'straight' | 'smooth' | 'stepline' | ('straight' | 'smooth' | 'stepline')[] | undefined
  >('straight')
  const [markersSize, setMarkersSize] = useState(6)

  useEffect(() => {
    getHasData()
    getFormat()
  }, [series])

  const getHasData = () => {
    setHasData(series.some(data => data.data.length > 0))
  }

  const getFormat = () => {
    switch (chartType) {
      case LineChartTypeEnum.Measurement: {
        setFormat('HH:mm dd.MM.yyyy')
        setType('datetime')
        setCurve('straight')
        setMarkersSize(6)
        break
      }
      case LineChartTypeEnum.Timeline: {
        setFormat('')
        setType('numeric')
        setCurve('smooth')
        setMarkersSize(0)
        break
      }
    }
  }
  const cs = require('apexcharts/dist/locales/cs.json')
  const options: ApexOptions = {
    chart: {
      id: 'basic-bar',
      defaultLocale: 'cs',
      locales: [cs],
    },
    xaxis: {
      type: type,
    },
    annotations,
    yaxis: {
      labels: {
        formatter(val: number): string {
          return (Math.round(val * 10) / 10).toString()
        },
      },
      min,
      max,
    },
    markers: {
      size: markersSize,
    },
    stroke: {
      curve: curve,
    },
    tooltip: {
      x: {
        show: false,
        format: format,
      },
      y: {
        formatter(val: number, opts: any): string {
          return `${val.toString()} ${opts.w.config.series[opts.seriesIndex].suffix ?? ''}`
        },
      },
    },
  }

  return <>{hasData && <ApexChart options={options} series={series} type="line" width="100%" height="300" />}</>
}
export default LineChart
