import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Moment from 'react-moment'
import csv from '../../../assets/images/files-icons/csv.svg'
import docx from '../../../assets/images/files-icons/docx.svg'
import image from '../../../assets/images/files-icons/image.svg'
import other from '../../../assets/images/files-icons/other.svg'
import pdf from '../../../assets/images/files-icons/pdf.svg'
import pptx from '../../../assets/images/files-icons/pptx.svg'
import video from '../../../assets/images/files-icons/video.svg'
import zip from '../../../assets/images/files-icons/zip.svg'
import { PARAMS, TELEREHABILITATION_STATUS } from '../../../enums'
import {
  ButtonBackgroundColorsEnum,
  ButtonTextColorsEnum,
  ButtonTypesEnum,
  LineChartTypeEnum,
  DispatchActionInterface,
  InputTypeEnum,
  TextByTypeInterface,
  TextTypeEnum,
} from '../../../interfaces'
import { useAppDispatch } from '../../../store'
import { getFile } from '../../../store/modules/files/actions'
import { download, getValueFromObject, returnDisabled } from '../../../store/utils'
import Button from '../button/Button'
import LineChart from '../chart/LineChart'
import WebGLChart from '../chart/WebGLChart'

export const TextByType = (props: TextByTypeInterface) => {
  const { row, t: translations, namespace, keyName, type, suffix, values } = props
  const dispatch = useAppDispatch()
  const [file, setFile] = useState<{ filename: string; link: string } | null>(null)
  const [text] = useState<string>('-')
  const { t } = useTranslation(['inputs'])

  useEffect(() => {
    if (type && [TextTypeEnum.Download, InputTypeEnum.Video].includes(type)) {
      getLink(getValueFromObject(row, keyName))
    }
  }, [type])

  const getLink = (uuid: string) => {
    if (uuid)
      dispatch(getFile({ [PARAMS.FILE_ID]: uuid })).then((action: DispatchActionInterface) => {
        if (action.payload?.status === 'ok') {
          setFile(action.payload.data)
        }
      })
  }

  const getIconByType = (data: { mime?: string }) => {
    switch (data.mime) {
      case 'text/plain': {
        return <img src={text} alt="Image icon" className="mr-4 w-5 h-5" />
      }
      case 'image/jpeg': {
        return <img src={image} alt="Image icon" className="mr-4 w-5 h-5" />
      }
      case 'text/csv': {
        return <img src={csv} alt="Image icon" className="mr-4 w-5 h-5" />
      }
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {
        return <img src={docx} alt="Image icon" className="mr-4 w-5 h-5" />
      }
      case 'application/pdf': {
        return <img src={pdf} alt="Image icon" className="mr-4 w-5 h-5" />
      }
      case 'video/mp4': {
        return <img src={video} alt="Image icon" className="mr-4 w-5 h-5" />
      }
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation': {
        return <img src={pptx} alt="Image icon" className="mr-4 w-5 h-5" />
      }
      case 'application/zip': {
        return <img src={zip} alt="Image icon" className="mr-4 w-5 h-5" />
      }
      default: {
        return <img src={other} alt="Image icon" className="mr-4 w-5 h-5" />
      }
    }
  }

  const getTextByType = () => {
    switch (type) {
      case TextTypeEnum.MultiValue: {
        const value = getValueFromObject(row, keyName)
        if (Array.isArray(value) && !value.length) return '-'
        if (value === undefined || value === null) return '-'

        if (Array.isArray(value)) {
          let mapped: any = value.map(i => `${translations && namespace ? translations(`${namespace}${i}`) : i} / `)
          mapped = mapped.join('').slice(0, -2)
          return <div className={row.className ? row.className : ''}>{mapped}</div>
        }

        return '-'
      }
      case InputTypeEnum.ApiSearchSelect: {
        const values = getValueFromObject(row, keyName)
        return Array.isArray(values) && values.length > 0 ? (
          values.map((value: string | number, key: number) => {
            if (translations && namespace) {
              return <li key={key}>{translations(`${namespace}${value}`)}</li>
            } else {
              return <li key={key}>{value}</li>
            }
          })
        ) : translations && namespace ? (
          <span>{translations(`${namespace}${values}`)}</span>
        ) : (
          <span>{values}</span>
        )
      }
      case InputTypeEnum.Multiselect: {
        const values = getValueFromObject(row, keyName)
        return Array.isArray(values) && values.length > 0
          ? values.map((value: string | number, key: number) => {
              if (translations && namespace) {
                return <li key={key}>{translations(`${namespace}${value}`)}</li>
              } else {
                return <li key={key}>{value}</li>
              }
            })
          : '-'
      }
      case InputTypeEnum.DoctorsAttendees:
      case InputTypeEnum.PatientsAttendees: {
        const values = getValueFromObject(row, keyName)

        const rsvp: { [key: string]: string } = {
          yes: 'text-green-800 bg-green-100',
          no: 'text-red-800 bg-red-100',
          unknown: 'text-yellow-800 bg-yellow-100',
        }

        return (
          <>
            {Array.isArray(values) &&
              values.map((val: string | number | { value: string | number; badge: string }, key: number) => {
                if (typeof val === 'number' || typeof val === 'string') {
                  if (translations && namespace) {
                    return <li key={key}>{translations(`${namespace}${val}`)}</li>
                  } else {
                    return <li key={key}>{val}</li>
                  }
                }

                if (typeof val === 'object') {
                  if (translations && namespace) {
                    return (
                      <li key={key} className="flex justify-between py-1" data-tip={val.value}>
                        <span className="truncate">{val.value}</span>
                        <span
                          className={`${
                            rsvp[val.badge]
                          } inline-flex items-center py-0.5 px-2.5 ml-2 text-xs font-medium rounded-full`}
                        >
                          {translations(`${namespace}${val.badge}`)}
                        </span>
                      </li>
                    )
                  } else {
                    return (
                      <li key={key}>
                        {val.value}
                        <span
                          className={`${
                            rsvp[val.badge]
                          } inline-flex items-center py-0.5 px-2.5 ml-2 text-xs font-medium rounded-full`}
                        >
                          {translations(`${namespace}${val.badge}`)}
                        </span>
                      </li>
                    )
                  }
                }
              })}
          </>
        )
      }
      case InputTypeEnum.Select: {
        if (getValueFromObject(row, keyName)?.name) {
          return getValueFromObject(row, keyName)?.name
        } else {
          if (getValueFromObject(row, keyName) !== undefined) {
            return translations
              ? translations(namespace + (getValueFromObject(row, keyName) ? getValueFromObject(row, keyName) : false))
              : getValueFromObject(row, keyName)
          } else {
            return '-'
          }
        }
      }
      case InputTypeEnum.DateTime: {
        if (getValueFromObject(row, keyName)) {
          return (
            <Moment utc={true} format="HH:mm DD.MM.YYYY">
              {getValueFromObject(row, keyName)}
            </Moment>
          )
        }
        return undefined
      }
      case InputTypeEnum.Date: {
        if (getValueFromObject(row, keyName)) {
          return (
            <Moment utc={true} format="DD.MM.YYYY">
              {getValueFromObject(row, keyName)}
            </Moment>
          )
        }
        return undefined
      }
      case TextTypeEnum.TelerehabilitationStatus: {
        switch (getValueFromObject(row, keyName)) {
          case TELEREHABILITATION_STATUS.TO_BE_DONE: {
            return (
              <span className="inline-flex items-center py-0.5 px-3 text-sm font-medium text-red-800 bg-red-100 rounded-full">
                {translations(`${namespace}${getValueFromObject(row, keyName)}`)}
              </span>
            )
          }
          case TELEREHABILITATION_STATUS.STARTED: {
            return (
              <span className="inline-flex items-center py-0.5 px-3 text-sm font-medium text-yellow-800 bg-yellow-100 rounded-full">
                {translations(`${namespace}${getValueFromObject(row, keyName)}`)}
              </span>
            )
          }
          case TELEREHABILITATION_STATUS.COMPLETED: {
            return (
              <span className="inline-flex items-center py-0.5 px-3 text-sm font-medium text-green-800 bg-green-100 rounded-full">
                {translations(`${namespace}${getValueFromObject(row, keyName)}`)}
              </span>
            )
          }
        }
        break
      }
      case TextTypeEnum.PatientVerified: {
        if (getValueFromObject(row, keyName)) {
          return (
            <span className="inline-flex items-center py-0.5 px-3 text-sm font-medium text-green-800 bg-green-100 rounded-full">
              {translations(`${namespace}${getValueFromObject(row, keyName)}`)}
            </span>
          )
        }
        return (
          <span className="inline-flex items-center py-0.5 px-3 text-sm font-medium text-red-800 bg-red-100 rounded-full">
            {translations(`${namespace}${getValueFromObject(row, keyName)}`)}
          </span>
        )
      }
      case TextTypeEnum.PresetParsed: {
        if (getValueFromObject(row, keyName)) {
          return (
            <span className="inline-flex items-center py-0.5 px-3 text-sm font-medium text-green-800 bg-green-100 rounded-full">
              {translations(`${namespace}${getValueFromObject(row, keyName)}`)}
            </span>
          )
        }
        return (
          <span className="inline-flex items-center py-0.5 px-3 text-sm font-medium text-yellow-800 bg-yellow-100 rounded-full">
            {translations(`${namespace}${getValueFromObject(row, keyName)}`)}
          </span>
        )
      }
      case TextTypeEnum.Button: {
        return (
          <Button
            title={row.title}
            onClick={row.value}
            path={row.value}
            className={row.className}
            type={row.buttonType}
            textColor={row.textColor}
            backgroundColor={row.backgroundColor}
            disabled={returnDisabled(row.disabled, values)}
          />
        )
      }
      case InputTypeEnum.File: {
        return (
          <div className="flex">
            {getIconByType(row)}
            {getValueFromObject(row, keyName)}
          </div>
        )
      }
      case TextTypeEnum.Chart: {
        const series = [
          {
            name: '',
            data: getValueFromObject(row.value, ['time', 'value']),
          },
        ]

        return (
          <>
            <LineChart series={series} chartType={LineChartTypeEnum.Timeline} />
          </>
        )
      }
      case TextTypeEnum.WebGLChart: {
        return (
          <>
            <WebGLChart data={row.value} />
          </>
        )
      }
      case TextTypeEnum.FileSize: {
        return (parseInt(getValueFromObject(row, keyName)) / 1000000).toPrecision(2) + ' MB'
      }
      case TextTypeEnum.Download: {
        return (
          <>
            {file ? (
              <Button
                title={t('inputs.download.fileDownload')}
                type={ButtonTypesEnum.Button}
                onClick={file ? () => download(file.filename, file.link) : undefined}
                backgroundColor={ButtonBackgroundColorsEnum.Transparent}
                textColor={ButtonTextColorsEnum.Brand}
              />
            ) : (
              <>-</>
            )}
          </>
        )
      }
      case InputTypeEnum.Video: {
        return <div className="flex justify-center">{file && <video src={file.link} controls />}</div>
      }

      case InputTypeEnum.Checkbox: {
        const value = getValueFromObject(row, keyName)
        return (
          <div className={row.className ? row.className : ''}>
            {translations ? translations(namespace + (value ? value : false)) : value}
          </div>
        )
      }
      case TextTypeEnum.FileType: {
        return getValueFromObject(row, keyName)
      }
      default: {
        const value = getValueFromObject(row, keyName)
        const text = translations && namespace ? translations(`${namespace}${value}`) : value

        if (text) {
          return <div className={row.className ? row.className : ''}>{`${text} ${suffix ? suffix : ''}`}</div>
        }
        return '-'
      }
    }
  }

  return <>{getTextByType()}</>
}

export default TextByType
