import { Field } from 'formik'
import { CustomCheckboxInterface } from '../../../../interfaces'

export const CustomCheckbox = (props: CustomCheckboxInterface) => {
  const { errors, label, name, className, touched, disabled } = props

  return (
    <div className={`${className ? className : ''} flex flex-col`}>
      <div className="flex flex-row items-center">
        <Field
          disabled={disabled}
          id={name}
          name={name}
          type="checkbox"
          className="w-4 h-4 text-brand rounded border-gray-300 focus:ring-brand"
        />
        {label && (
          <label htmlFor={name} className="block ml-2 text-sm text-gray-900">
            {label}
          </label>
        )}
      </div>
      {errors[name] && touched[name] && <p className="mb-0 h-5 text-sm text-red-600">{errors[name]}</p>}
    </div>
  )
}

export default CustomCheckbox
