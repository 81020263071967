import preval from 'preval.macro'
import packageJson from '../../../../package.json'

export const VersionControl = () => {
  const appVersion = `${packageJson.version}.${process.env.REACT_APP_VERSION}`

  return (
    <div className="absolute bottom-2 left-2">
      <small className="text-xs text-gray-700">{`Build time: ${preval`module.exports = new Date().toLocaleString('cs-CZ');`}, `}</small>
      <small className="text-xs text-gray-700">{`version: ${appVersion}`}</small>
    </div>
  )
}
