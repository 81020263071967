import { createAsyncThunk } from '@reduxjs/toolkit'
import { API } from '../../../api'
import { callFileApiEndpoint } from '../../utils'

// typePrefix = '${namespace}/functionName'
export const uploadFile: any = createAsyncThunk('files/uploadFile', async (payload, { dispatch }) => {
  return await callFileApiEndpoint(dispatch, {
    requestData: { ...API.files.uploadFile },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const uploadExercise: any = createAsyncThunk('files/uploadExercise', async (payload, { dispatch }) => {
  return await callFileApiEndpoint(dispatch, {
    requestData: { ...API.files.uploadExercise },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const getFile: any = createAsyncThunk('files/getFile', async (payload, { dispatch }) => {
  return await callFileApiEndpoint(dispatch, {
    requestData: { ...API.files.getFile },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})
