import { createAsyncThunk } from '@reduxjs/toolkit'
import { API } from '../../../api'
import { callBaseApiEndpoint } from '../../utils'

// typePrefix = '${namespace}/functionName'
export const registerPatientViaDoctor: any = createAsyncThunk(
  'doctors/registerPatientViaDoctor',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.doctors.registerPatientViaDoctor },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const getDoctors: any = createAsyncThunk('patients/getDoctors', async (payload, { dispatch }) => {
  return await callBaseApiEndpoint(dispatch, {
    requestData: { ...API.doctors.getDoctors },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const getDoctor: any = createAsyncThunk('patients/getDoctor', async (payload, { dispatch }) => {
  return await callBaseApiEndpoint(dispatch, {
    requestData: { ...API.doctors.getDoctor },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const createDoctor: any = createAsyncThunk('patients/createDoctor', async (payload, { dispatch }) => {
  return await callBaseApiEndpoint(dispatch, {
    requestData: { ...API.doctors.createDoctor },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const deleteDoctor: any = createAsyncThunk('patients/deleteDoctor', async (payload, { dispatch }) => {
  return await callBaseApiEndpoint(dispatch, {
    requestData: { ...API.doctors.deleteDoctor },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const editDoctor: any = createAsyncThunk('patients/editDoctor', async (payload, { dispatch }) => {
  return await callBaseApiEndpoint(dispatch, {
    requestData: { ...API.doctors.editDoctor },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const getPatientsDoctors: any = createAsyncThunk(
  'patients/getPatientsDoctors',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.doctors.getPatientsDoctors },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const patientGiveDoctorPatientsAccess: any = createAsyncThunk(
  'patients/patientGiveDoctorPatientsAccess',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.doctors.patientGiveDoctorPatientsAccess },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const patientRemoveDoctorPatientsAccess: any = createAsyncThunk(
  'patients/patientRemoveDoctorPatientsAccess',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.doctors.patientRemoveDoctorPatientsAccess },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const adminGiveDoctorPatientsAccess: any = createAsyncThunk(
  'patients/adminGiveDoctorPatientsAccess',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.doctors.adminGiveDoctorPatientsAccess },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const adminRemoveDoctorPatientsAccess: any = createAsyncThunk(
  'patients/adminRemoveDoctorPatientsAccess',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.doctors.adminRemoveDoctorPatientsAccess },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)
