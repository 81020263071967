export const SHOW_FULL_TIMESTAMP_DAY_THRESHOLD = 1
export const FETCH_ALL_CHATS = 5000
export const FETCH_CHAT = 5000

export enum ChatType {
  Group = 'p',
  DM = 'd',
}

export enum MessageType {
  AddUser = 'au',
  RemovedUser = 'ru',
  UserLeft = 'ul',
  Jitsi = 'jitsi',
}
