import { ButtonInterface, ChatUser, FormikOnSubmitType, Message } from './index'

export interface ChatBoxInterface {
  loading: boolean
  submitHandler: FormikOnSubmitType
  chatMessages: Message[]
  actions?: ButtonInterface[]
}

export interface ChatSidebarInterface {
  loading: boolean
  mobileSidebarOpen: boolean
  setMobileSidebarOpen: (value: boolean) => void
}

export interface ChatIconInterface {
  _id?: string
  name?: string
  online?: boolean
  isLink: boolean
  theme: ChatIconThemeEnum
  unread?: number
  className?: string
  path?: string
  isOpen?: boolean
  setMobileSidebarOpen?: (value: boolean) => void
}

export enum ChatIconThemeEnum {
  Light,
  Dark,
}

export interface ChatSectionInterface {
  name: string
  content: ChatIconInterface[]
  loading?: boolean
  setMobileSidebarOpen: (value: boolean) => void
}

export interface ChatMessageInterface {
  content: string
  ownMessage?: boolean
  timestamp: string
  type?: string
  user: ChatUser
}

export interface SystemMessageInterface {
  children: JSX.Element
  date: string
}

export interface UserMessageInterface {
  ownMessage?: boolean
  children: JSX.Element
  user: ChatUser
  date: string
}
