import { ExclamationCircleIcon } from '@heroicons/react/outline'
import { Field } from 'formik'
import { CustomInputInterface, IconPositionsEnum } from '../../../../interfaces'
import { getValueFromObject } from '../../../../store/utils'

export const CustomInput = (props: CustomInputInterface) => {
  const {
    as,
    disabled,
    icon: Icon,
    iconPosition = IconPositionsEnum.Left,
    iconOnClick,
    inputClassName,
    children,
    className,
    label,
    name,
    errors,
    touched,
    type,
    autoComplete,
    placeholder,
    suffix,
    value,
    onChange,
  } = props

  const iconIsRight = () => {
    return iconPosition === IconPositionsEnum.Right
  }

  return (
    <>
      <div className={`${className ? className : ''}`}>
        {label && (
          <label htmlFor={name} className={`${disabled ? 'text-gray-300' : 'text-gray-700'} block text-sm font-medium`}>
            {label}
          </label>
        )}
        <div className={`mt-1 relative shadow-sm rounded-md`}>
          {children && <div className="flex absolute inset-y-0 left-0 items-center">{children}</div>}
          {Icon && !iconIsRight() && (
            <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
              <Icon className="w-5 h-5 text-gray-700" aria-hidden="true" />
            </div>
          )}
          {value ? (
            <Field
              as={as ? as : 'input'}
              type={type}
              name={name}
              id={name}
              value={value}
              onChange={onChange}
              disabled={disabled}
              autoComplete={autoComplete}
              placeholder={placeholder}
              className={`${
                getValueFromObject(errors, name) && getValueFromObject(touched, name)
                  ? 'border-red-600 focus:ring-red-600 focus:border-red-600'
                  : 'border-gray-300 focus:ring-brand focus:border-brand'
              } ${
                (getValueFromObject(errors, name) && getValueFromObject(touched, name)) || suffix ? 'pr-10' : ''
              } block w-full sm:text-sm rounded-md ${inputClassName ? inputClassName : ''} 
          ${disabled ? 'bg-gray-100' : ''}`}
            />
          ) : (
            <Field
              as={as ? as : 'input'}
              type={type}
              name={name}
              id={name}
              disabled={disabled}
              autoComplete={autoComplete}
              placeholder={placeholder}
              className={`${
                getValueFromObject(errors, name) && getValueFromObject(touched, name)
                  ? 'border-red-600 focus:ring-red-600 focus:border-red-600'
                  : 'border-gray-300 focus:ring-brand focus:border-brand'
              } ${
                (getValueFromObject(errors, name) && getValueFromObject(touched, name)) || suffix ? 'pr-10' : ''
              } block w-full sm:text-sm rounded-md ${inputClassName ? inputClassName : ''} 
          ${disabled ? 'bg-gray-100' : ''}`}
            />
          )}
          {Icon && iconIsRight() && (
            <div
              className={`${iconOnClick ? 'cursor-pointer' : ''} flex absolute inset-y-0 right-0 items-center pr-3`}
              onClick={iconOnClick ? iconOnClick : undefined}
            >
              <Icon className="w-5 h-5 text-gray-700" aria-hidden="true" />
            </div>
          )}
          {suffix ? (
            <div className="flex absolute inset-y-0 right-0 items-center pr-3 pointer-events-none">
              <span className="text-sm text-gray-500" id="price-currency">
                {suffix}
              </span>
            </div>
          ) : (
            getValueFromObject(errors, name) &&
            getValueFromObject(touched, name) && (
              <div className="flex absolute inset-y-0 right-0 items-center pr-3 pointer-events-none">
                <ExclamationCircleIcon className="w-5 h-5 text-red-600" aria-hidden="true" />
              </div>
            )
          )}
        </div>
        {getValueFromObject(errors, name) && getValueFromObject(touched, name) && (
          <p className="mb-0 h-5 text-sm text-red-600">{getValueFromObject(errors, name)}</p>
        )}
      </div>
    </>
  )
}

export default CustomInput
