import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { TABLE_LIMITS } from '../../../enums'
import { RootState, useAppDispatch } from '../../../store'
import { getEvents } from '../../../store/modules/calendar/actions'
import { getPatients } from '../../../store/modules/patients/actions'
import AppLayout from '../../common/layout/AppLayout'
import ContentLayout from '../../common/layout/ContentLayout'
import NotificationList from '../../common/notification-list/NotificationList'
import EventsTable from '../patients/EventsTable'
import PatientsTable from '../patients/PatientsTable'

export const DashboardDoctorView = () => {
  const { patients, getPatientsLoading } = useSelector((state: RootState) => state.patients)
  const dispatch = useAppDispatch()
  const { t } = useTranslation(['dashboard'])

  const loadEvents = (limit: number, offset: number) => {
    dispatch(getEvents({ limit, offset }))
  }

  return (
    <AppLayout>
      <ContentLayout title={t('dashboard.componentTitle')} showRoleButtons={false}>
        <div className="flex flex-col space-y-6">
          <div className="grid grid-cols-12 my-6 space-y-6 md:space-y-0 md:space-x-6">
            <div className="col-span-12 md:col-span-6">
              <NotificationList
                title={t('dashboard.notifications.componentTitle')}
                afterCallbackDispatch={() => loadEvents(TABLE_LIMITS.DASHBOARD_EVENTS, 0)}
              />
            </div>

            <div className="col-span-12 md:col-span-6">
              <EventsTable limit={TABLE_LIMITS.DASHBOARD_EVENTS} />
            </div>
          </div>
          <div className="space-y-6">
            <PatientsTable
              limit={TABLE_LIMITS.DASHBOARD_PATIENTS_LIST}
              isSearchable={false}
              dispatchCall={getPatients}
              data={patients}
              loading={getPatientsLoading}
              canGiveAccess={false}
              canAdd={true}
              canRegister={true}
              canViewDetail={true}
              canRemoveAccess={false}
              canViewTelerehabilitation={true}
            />
          </div>
        </div>
      </ContentLayout>
    </AppLayout>
  )
}

export default DashboardDoctorView
