import { createSlice } from '@reduxjs/toolkit'
import { CalendarState, DispatchActionReducerInterface } from '../../../interfaces'
import { getPatientsAttendees, getDoctorsAttendees, getEvent, getEvents } from './actions'
import { CALENDAR } from './types'

const initialState: CalendarState = {
  events: null,
  getEventsLoading: false,

  event: null,
  getEventLoading: false,

  eventAttendees: null,
  getEventAttendeesLoading: false,
}

const calendarSlice = createSlice({
  name: CALENDAR.NAMESPACE,
  initialState,
  // Only frontend Logic
  reducers: {},
  extraReducers: builder => {
    // Reducers with calling external API
    builder
      .addCase(getEvents.pending, (state: CalendarState) => {
        state.getEventsLoading = true
      })
      .addCase(getEvents.fulfilled, (state: CalendarState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.events = action.payload.data
        } else {
          state.events = null
        }
        state.getEventsLoading = false
      })
      .addCase(getEvents.rejected, (state: CalendarState) => {
        state.events = null
        state.getEventsLoading = false
      })

      .addCase(getEvent.pending, (state: CalendarState) => {
        state.getEventLoading = true
      })
      .addCase(getEvent.fulfilled, (state: CalendarState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.event = action.payload.data
        } else {
          state.event = null
        }
        state.getEventLoading = false
      })
      .addCase(getEvent.rejected, (state: CalendarState) => {
        state.event = null
        state.getEventLoading = false
      })

      .addCase(getPatientsAttendees.pending, (state: CalendarState) => {
        state.getEventAttendeesLoading = true
      })
      .addCase(getPatientsAttendees.fulfilled, (state: CalendarState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.eventAttendees = action.payload.data
        } else {
          state.eventAttendees = null
        }
        state.getEventAttendeesLoading = false
      })
      .addCase(getPatientsAttendees.rejected, (state: CalendarState) => {
        state.eventAttendees = null
        state.getEventAttendeesLoading = false
      })

      .addCase(getDoctorsAttendees.pending, (state: CalendarState) => {
        state.getEventAttendeesLoading = true
      })
      .addCase(getDoctorsAttendees.fulfilled, (state: CalendarState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.eventAttendees = action.payload.data.current
        } else {
          state.eventAttendees = null
        }
        state.getEventAttendeesLoading = false
      })
      .addCase(getDoctorsAttendees.rejected, (state: CalendarState) => {
        state.eventAttendees = null
        state.getEventAttendeesLoading = false
      })
  },
})

// Extract the action creators object and the reducer
const { reducer } = calendarSlice
// Extract and export each action creator by name
// export const {  } = actions
// Export the reducer, either as a default or named export
export default reducer
