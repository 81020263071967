import i18n from 'i18next'

export const StructuredFormErrors = (fields: any) => {
  return (
    <ul>
      {Object.keys(fields).map(key => {
        return (
          <li key={key}>
            {i18n.t(`apiMessages:apiMessages.${key}.${key}`)}:
            <ul className="list-disc list-inside">
              {fields[key].map((error: any, index: number) => {
                return (
                  <li className="" key={index}>
                    {i18n.t(`apiMessages:apiMessages.${key}.${error}`)}
                  </li>
                )
              })}
            </ul>
          </li>
        )
      })}
    </ul>
  )
}

export default StructuredFormErrors
