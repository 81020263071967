import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import { ROUTES } from '../../../router/routes'
import { RootState } from '../../../store'
import PageWithTitle from '../page/PageWithTitle'

export const PrivateRoute = ({ component: Component, title: title, ...rest }: any) => {
  const { isAuthorized, authUser, roleSet } = useSelector((state: RootState) => state.auth)

  const roleCheck = () => {
    // if route has set allowed roles -> check if authUser has needed role
    return !rest.role || rest.role?.some((val: string) => val === authUser?.role.level)
  }

  const isRoleSet = () => {
    return roleSet
  }

  const excludedRoutes = [ROUTES.REGISTER_ADDITIONAL_DATA, ROUTES.ROLE_SELECT]

  const isExcluded = () => {
    return excludedRoutes.includes(rest.path)
  }

  return (
    // Show the component only when the user is logged in and role is correct
    // Otherwise:
    // - if not logged in redirect the user to /login page
    // - if role is not correct redirect the user to /403 page

    <Route
      {...rest}
      render={(routeProps: any) =>
        isAuthorized ? (
          isRoleSet() || isExcluded() ? (
            <>
              {/*{isRoleSet() && isExcluded() && <Redirect to={ROUTES.DASHBOARD} />}*/}

              {roleCheck() ? (
                <PageWithTitle component={Component} title={title} {...routeProps} />
              ) : (
                <Redirect to={ROUTES.ERROR_403} />
              )}
            </>
          ) : (
            <Redirect to={ROUTES.ROLE_SELECT} />
          )
        ) : (
          <Redirect to={ROUTES.LOGIN} />
        )
      }
    />
  )
}

export default PrivateRoute
