export const NOTIFICATION = {
  NAMESPACE: 'notification',
  ACTIONS: {
    GET_NOTIFICATIONS: 'GET_NOTIFICATIONS',
    SEND_NOTIFICATION_CALLBACK: 'SEND_NOTIFICATION_CALLBACK',
    GET_NOTIFICATIONS_SETTINGS: 'GET_NOTIFICATIONS_SETTINGS',
    EDIT_NOTIFICATIONS_SETTINGS: 'EDIT_NOTIFICATIONS_SETTINGS',
    MARK_NOTIFICATIONS: 'MARK_NOTIFICATIONS',
  },
}
