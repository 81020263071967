import { createSlice } from '@reduxjs/toolkit'
import { DispatchActionReducerInterface, FilesState } from '../../../interfaces'
import { getFile } from './actions'

import { FILES } from './types'

const initialState: FilesState = {
  file: null,
  getFileLoading: false,

  fileUploadProgress: 0,
}

const filesSlice = createSlice({
  name: FILES.NAMESPACE,
  initialState,
  // Only frontend Logic
  reducers: {
    setUploadProgress(state, action) {
      state.fileUploadProgress = action.payload
    },
  },
  extraReducers: builder => {
    // Reducers with calling external API
    builder
      .addCase(getFile.pending, (state: FilesState) => {
        state.getFileLoading = true
      })
      .addCase(getFile.fulfilled, (state: FilesState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.file = action.payload.data
        } else {
          state.file = null
        }
        state.getFileLoading = false
      })
      .addCase(getFile.rejected, (state: FilesState) => {
        state.file = null
        state.getFileLoading = false
      })
  },
})

// Extract the action creators object and the reducer
const { actions, reducer } = filesSlice
// Extract and export each action creator by name
export const { setUploadProgress } = actions
// Export the reducer, either as a default or named export
export default reducer
