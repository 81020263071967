import { createSlice } from '@reduxjs/toolkit'
import { DispatchActionReducerInterface, OrdersState } from '../../../interfaces'
import {
  getAccessories,
  getAccessory,
  getCategories,
  getCategory,
  getOrder,
  getOrderHistoryList,
  getPatientOrderHistoryList,
  getService,
  getServices,
} from './actions'

import { ORDERS } from './types'

const initialState: OrdersState = {
  order: null,
  getOrderLoading: false,

  orderHistoryList: null,
  getOrderHistoryListLoading: false,

  patientOrderHistoryList: null,
  getPatientOrderHistoryListLoading: false,

  services: null,
  getServicesLoading: false,

  service: null,
  getServiceLoading: false,

  categories: null,
  getCategoriesLoading: false,

  category: null,
  getCategoryLoading: false,

  accessories: null,
  getAccessoriesLoading: false,

  accessory: null,
  getAccessoryLoading: false,
}

const ordersSlice = createSlice({
  name: ORDERS.NAMESPACE,
  initialState,
  // Only frontend Logic
  reducers: {},
  extraReducers: builder => {
    // Reducers with calling external API
    builder
      .addCase(getOrder.pending, (state: OrdersState) => {
        state.getOrderLoading = true
      })
      .addCase(getOrder.fulfilled, (state: OrdersState, action: DispatchActionReducerInterface) => {
        if (action.payload?.status === 'ok') {
          state.order = action.payload.data
        } else {
          state.order = null
        }
        state.getOrderLoading = false
      })
      .addCase(getOrder.rejected, (state: OrdersState) => {
        state.order = null
        state.getOrderLoading = false
      })

      .addCase(getPatientOrderHistoryList.pending, (state: OrdersState) => {
        state.getPatientOrderHistoryListLoading = true
      })
      .addCase(getPatientOrderHistoryList.fulfilled, (state: OrdersState, action: DispatchActionReducerInterface) => {
        if (action.payload?.status === 'ok') {
          state.patientOrderHistoryList = action.payload.data
        } else {
          state.patientOrderHistoryList = null
        }
        state.getPatientOrderHistoryListLoading = false
      })
      .addCase(getPatientOrderHistoryList.rejected, (state: OrdersState) => {
        state.patientOrderHistoryList = null
        state.getPatientOrderHistoryListLoading = false
      })

      .addCase(getOrderHistoryList.pending, (state: OrdersState) => {
        state.getOrderHistoryListLoading = true
      })
      .addCase(getOrderHistoryList.fulfilled, (state: OrdersState, action: DispatchActionReducerInterface) => {
        if (action.payload?.status === 'ok') {
          state.orderHistoryList = action.payload.data
        } else {
          state.orderHistoryList = null
        }
        state.getOrderHistoryListLoading = false
      })
      .addCase(getOrderHistoryList.rejected, (state: OrdersState) => {
        state.orderHistoryList = null
        state.getOrderHistoryListLoading = false
      })

      .addCase(getServices.pending, (state: OrdersState) => {
        state.getServicesLoading = true
      })
      .addCase(getServices.fulfilled, (state: OrdersState, action: DispatchActionReducerInterface) => {
        if (action.payload?.status === 'ok') {
          state.services = action.payload.data
        } else {
          state.services = null
        }
        state.getServicesLoading = false
      })
      .addCase(getServices.rejected, (state: OrdersState) => {
        state.services = null
        state.getServicesLoading = false
      })

      .addCase(getService.pending, (state: OrdersState) => {
        state.getServiceLoading = true
      })
      .addCase(getService.fulfilled, (state: OrdersState, action: DispatchActionReducerInterface) => {
        if (action.payload?.status === 'ok') {
          state.service = action.payload.data
        } else {
          state.service = null
        }
        state.getServiceLoading = false
      })
      .addCase(getService.rejected, (state: OrdersState) => {
        state.service = null
        state.getServiceLoading = false
      })

      .addCase(getCategories.pending, (state: OrdersState) => {
        state.getCategoriesLoading = true
      })
      .addCase(getCategories.fulfilled, (state: OrdersState, action: DispatchActionReducerInterface) => {
        if (action.payload?.status === 'ok') {
          state.categories = action.payload.data
        } else {
          state.categories = null
        }
        state.getCategoriesLoading = false
      })
      .addCase(getCategories.rejected, (state: OrdersState) => {
        state.categories = null
        state.getCategoriesLoading = false
      })

      .addCase(getCategory.pending, (state: OrdersState) => {
        state.getCategoryLoading = true
      })
      .addCase(getCategory.fulfilled, (state: OrdersState, action: DispatchActionReducerInterface) => {
        if (action.payload?.status === 'ok') {
          state.category = action.payload.data
        } else {
          state.category = null
        }
        state.getCategoryLoading = false
      })
      .addCase(getCategory.rejected, (state: OrdersState) => {
        state.category = null
        state.getCategoryLoading = false
      })

      .addCase(getAccessories.pending, (state: OrdersState) => {
        state.getAccessoriesLoading = true
      })
      .addCase(getAccessories.fulfilled, (state: OrdersState, action: DispatchActionReducerInterface) => {
        if (action.payload?.status === 'ok') {
          state.accessories = action.payload.data
        } else {
          state.accessories = null
        }
        state.getAccessoriesLoading = false
      })
      .addCase(getAccessories.rejected, (state: OrdersState) => {
        state.accessories = null
        state.getAccessoriesLoading = false
      })

      .addCase(getAccessory.pending, (state: OrdersState) => {
        state.getAccessoryLoading = true
      })
      .addCase(getAccessory.fulfilled, (state: OrdersState, action: DispatchActionReducerInterface) => {
        if (action.payload?.status === 'ok') {
          state.accessory = action.payload.data
        } else {
          state.accessory = null
        }
        state.getAccessoryLoading = false
      })
      .addCase(getAccessory.rejected, (state: OrdersState) => {
        state.accessory = null
        state.getAccessoryLoading = false
      })
  },
})

// Extract the action creators object and the reducer
const { reducer } = ordersSlice
// Extract and export each action creator by name
// export const {} = actions
// Export the reducer, either as a default or named export
export default reducer
