import { AppQrCodeType } from '../interfaces'

export const SETTING_CONNECT_APP_STEPS = [
  {
    title: 'settings.apps.step1.title',
    description: 'settings:settings.apps.step1.description',
    qrCode: AppQrCodeType.Connect,
  },
  {
    title: 'settings.apps.step2.title',
    description: 'settings:settings.apps.step2.description',
    qrCode: AppQrCodeType.Authorize,
  },
  {
    title: 'settings.apps.step3.title',
    description: 'settings:settings.apps.step3.description',
  },
]
