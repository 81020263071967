import { ExclamationCircleIcon } from '@heroicons/react/outline'
import MaskedInput from 'react-text-mask'
import { CustomMaskedInputInterface } from '../../../../interfaces'

export const CustomMaskedInput = (props: CustomMaskedInputInterface) => {
  const { value, mask, label, errors, onChange, touched, className, name } = props

  return (
    <div className={`${className ? className : ''}`}>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className="relative mt-1 rounded-md shadow-sm">
        <MaskedInput
          mask={mask}
          id={name}
          type="text"
          guide={false}
          onChange={onChange}
          value={value}
          className={`${
            errors[name] && touched[name]
              ? 'border-red-600 focus:ring-red-600 focus:border-red-600 pr-10'
              : 'border-gray-300 focus:ring-brand focus:border-brand'
          } block w-full sm:text-sm rounded-md`}
        />
        {errors[name] && touched[name] && (
          <div className="flex absolute inset-y-0 right-0 items-center pr-3 pointer-events-none">
            <ExclamationCircleIcon className="w-5 h-5 text-red-600" aria-hidden="true" />
          </div>
        )}
      </div>
      {errors[name] && touched[name] && <p className="mb-0 h-5 text-sm text-red-600">{errors[name]}</p>}
    </div>
  )
}

export default CustomMaskedInput
