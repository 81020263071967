import { PARAMS } from '../../enums'
import { EDG } from '../../store/modules/edg/types'

export default {
  getEdgServicesMy: {
    id: EDG.ACTIONS.GET_EDG_SERVICES_MY,
    request: (data: any) => ({
      url: '/v1/services/my',
      method: 'GET',
      data,
    }),
  },
  getEdgServicesAvailable: {
    id: EDG.ACTIONS.GET_EDG_SERVICES_AVAILABLE,
    request: (data: any) => ({
      url: '/v1/services/available',
      method: 'GET',
      data,
    }),
  },
  connectEdgService: {
    id: EDG.ACTIONS.CONNECT_EDG_SERVICE,
    request: (data: any) => ({
      url: `/v1/services/connect/${data[PARAMS.EDG_SERVICE_TYPE]}`,
      method: 'GET',
      data,
    }),
  },
  getEdgQrCode: {
    id: EDG.ACTIONS.CONNECT_EDG_SERVICE,
    request: (data: any) => ({
      url: `/v1/user/edg`,
      method: 'GET',
      data,
    }),
  },
}
