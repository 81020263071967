import { createSlice } from '@reduxjs/toolkit'
import { CustomMedicationsState, DispatchActionReducerInterface } from '../../../interfaces'
import { getCustomMedication, getCustomMedications } from './actions'
import { CUSTOM_MEDICATIONS } from './types'

const initialState: CustomMedicationsState = {
  customMedications: null,
  getCustomMedicationsLoading: false,

  customMedication: null,
  getCustomMedicationLoading: false,
}

const customMedicationsSlice = createSlice({
  name: CUSTOM_MEDICATIONS.NAMESPACE,
  initialState,
  // Only frontend Logic
  reducers: {},
  extraReducers: builder => {
    // Reducers with calling external API
    builder
      .addCase(getCustomMedications.pending, (state: CustomMedicationsState) => {
        state.getCustomMedicationsLoading = true
      })
      .addCase(
        getCustomMedications.fulfilled,
        (state: CustomMedicationsState, action: DispatchActionReducerInterface) => {
          if (action.payload?.status === 'ok') {
            state.customMedications = action.payload.data
          } else {
            state.customMedications = null
          }
          state.getCustomMedicationsLoading = false
        }
      )
      .addCase(getCustomMedications.rejected, (state: CustomMedicationsState) => {
        state.customMedications = null
        state.getCustomMedicationsLoading = false
      })

      .addCase(getCustomMedication.pending, (state: CustomMedicationsState) => {
        state.getCustomMedicationLoading = true
      })
      .addCase(
        getCustomMedication.fulfilled,
        (state: CustomMedicationsState, action: DispatchActionReducerInterface) => {
          if (action.payload?.status === 'ok') {
            state.customMedication = action.payload.data
          } else {
            state.customMedication = null
          }
          state.getCustomMedicationLoading = false
        }
      )
      .addCase(getCustomMedication.rejected, (state: CustomMedicationsState) => {
        state.customMedication = null
        state.getCustomMedicationLoading = false
      })
  },
})

// Extract the action creators object and the reducer
const { reducer } = customMedicationsSlice
// Extract and export each action creator by name
// export const {  } = actions
// Export the reducer, either as a default or named export
export default reducer
