import { RadioGroup } from '@headlessui/react'
import { useTranslation } from 'react-i18next'
import {
  ButtonBackgroundColorsEnum,
  ButtonSizesEnum,
  ButtonTextColorsEnum,
  ButtonTypesEnum,
  RoleSelectFormInterface,
} from '../../../interfaces'
import { classNames } from '../../../store/utils'
import Button from '../../common/button/Button'

export const RoleSelectForm = (props: RoleSelectFormInterface) => {
  const { selected, setSelected, roles, submitHandler, changeAccountHandler } = props
  const { t } = useTranslation(['roleSelect'])

  return (
    <>
      <RadioGroup value={selected} onChange={setSelected}>
        <RadioGroup.Label className="sr-only">Privacy setting</RadioGroup.Label>
        <div className="-space-y-px bg-white rounded-md">
          {roles.map((role, index) => (
            <RadioGroup.Option
              key={index}
              value={role}
              className={({ checked }) =>
                classNames(
                  index === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                  index === roles.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                  checked ? 'bg-green-50 border-brand z-10' : 'border-gray-200',
                  'relative border p-4 flex cursor-pointer focus:outline-none'
                )
              }
            >
              {({ active, checked }) => (
                <>
                  <span
                    className={classNames(
                      checked ? 'bg-brand border-transparent' : 'bg-white border-gray-300',
                      active ? 'ring-2 ring-offset-2 ring-brand' : '',
                      'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center'
                    )}
                    aria-hidden="true"
                  >
                    <span className="w-1.5 h-1.5 bg-white rounded-full" />
                  </span>
                  <div className="flex justify-between ml-3 w-full">
                    <RadioGroup.Label
                      as="span"
                      className={classNames(checked ? 'text-brand' : 'text-gray-900', 'block text-sm font-medium')}
                    >
                      {t(`roleSelect.roles.${role.level}`)}
                    </RadioGroup.Label>
                    <RadioGroup.Description
                      as="span"
                      className={classNames(checked ? 'text-brand' : 'text-gray-500', 'block text-sm')}
                    >
                      {role.title}
                    </RadioGroup.Description>
                  </div>
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
      <div className="mt-6 space-y-4">
        <Button
          type={ButtonTypesEnum.Button}
          textColor={ButtonTextColorsEnum.White}
          backgroundColor={ButtonBackgroundColorsEnum.Brand}
          title={t('roleSelect.signIn')}
          className="w-full"
          onClick={submitHandler}
          size={ButtonSizesEnum.medium}
        />
        <Button
          type={ButtonTypesEnum.Button}
          textColor={ButtonTextColorsEnum.Brand}
          backgroundColor={ButtonBackgroundColorsEnum.White}
          title={t('roleSelect.changeAccount')}
          className="w-full"
          onClick={changeAccountHandler}
          size={ButtonSizesEnum.medium}
        />
      </div>
    </>
  )
}

export default RoleSelectForm
