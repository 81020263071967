import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AsyncSelect from 'react-select/async'
import { APP_SETTINGS } from '../../../../enums'
import {
  CustomApiSearchSelectInterface,
  CustomSelectOptionsInterface,
  DispatchActionInterface,
} from '../../../../interfaces'
import { useAppDispatch } from '../../../../store'
import { createArrayOfObjectsFromArrayOfObjects } from '../../../../store/utils'

const brand = APP_SETTINGS.BRAND_COLOR

const selectStyles = {
  control: (styles: any) => ({ ...styles, backgroundColor: 'white' }),
  option: (styles: { [x: string]: any }, { isFocused }: any) => {
    return {
      ...styles,
      backgroundColor: isFocused ? brand : 'white',
      color: isFocused ? 'white' : 'black',
    }
  },
  multiValue: (styles: any) => {
    return {
      ...styles,
    }
  },
  multiValueRemove: (styles: any, { data }: any) => ({
    ...styles,
    color: data.color,
    ':hover': {
      backgroundColor: data.color,
      color: brand,
    },
  }),
}

export const CustomApiSearchSelect = (props: CustomApiSearchSelectInterface) => {
  const {
    onChange,
    defaultValue,
    placeholder,
    errors,
    touched,
    className,
    label,
    disabled,
    name,
    searchSelectDispatch,
    searchSelectStructure = { name: 'title', value: '_id' },
    isMulti,
    prefetch,
  } = props
  const [defaultOptions, setDefaultOptions] = useState<CustomSelectOptionsInterface[]>([])
  const { t } = useTranslation(['inputs'])
  const dispatch = useAppDispatch()

  useEffect(() => {
    prefetchHandler()
  }, [prefetch])

  const prefetchHandler = () => {
    if (prefetch) {
      dispatchRequest(APP_SETTINGS.API_SEARCH_PREFETCH, 0).then((data: CustomSelectOptionsInterface[]) => {
        setDefaultOptions(data)
      })
    }
  }

  const promiseOptions = (inputValue: string) => {
    if (inputValue.length >= 3) {
      return dispatchRequest(Number.MAX_VALUE, 0, inputValue)
    }
    return null
  }

  const dispatchRequest = (limit: number, offset: number, search?: string) => {
    if (searchSelectDispatch)
      return dispatch(searchSelectDispatch({ limit, offset, search })).then((action: DispatchActionInterface) => {
        if (action.payload?.status === 'ok') {
          if (action.payload.data?.current) {
            return createArrayOfObjectsFromArrayOfObjects(action.payload.data.current, [
              { name: 'name', value: searchSelectStructure?.name },
              { name: 'value', value: searchSelectStructure?.value },
            ])
          } else {
            return createArrayOfObjectsFromArrayOfObjects(action.payload.data, [
              { name: 'name', value: searchSelectStructure?.name },
              { name: 'value', value: searchSelectStructure?.value },
            ])
          }
        }
      })

    return null
  }

  return (
    <div className={`${className ? className : ''}`}>
      {label && (
        <label htmlFor="allergies" className="block text-sm font-medium text-gray-700">
          {label}
        </label>
      )}
      <div className="mt-1 rounded-md shadow-sm">
        <AsyncSelect
          isMulti={isMulti}
          closeMenuOnSelect={!isMulti}
          styles={selectStyles}
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: brand,
            },
          })}
          isDisabled={disabled}
          defaultValue={defaultValue}
          onChange={onChange}
          // cacheOptions
          noOptionsMessage={() => t('inputs.searchSelect.noOptions')}
          loadingMessage={() => t('inputs.searchSelect.loading')}
          defaultOptions={defaultOptions}
          loadOptions={promiseOptions}
          placeholder={placeholder ? placeholder : t('inputs.searchSelect.placeholder')}
          getOptionLabel={option => `${option.name}`}
          className="custom-search-select"
          classNamePrefix="custom-search-select"
        />
      </div>
      {errors[name] && touched[name] && <p className="mb-0 h-5 text-sm text-red-600">{errors[name]}</p>}
    </div>
  )
}

export default CustomApiSearchSelect
