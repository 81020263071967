export const BREADCRUMBS_NAMES = {
  DASHBOARD: 'breadcrumbs.dashboard',
  PATIENTS: 'breadcrumbs.patients.patients',
  PATIENTS_LIST: 'breadcrumbs.patients.list',
  PATIENTS_DETAIL: 'store.patients.patient.full_name',
  PATIENTS_DETAIL_OVERVIEW: 'breadcrumbs.patients.detail.overview',
  PATIENTS_DETAIL_ANAMNESIS: 'breadcrumbs.patients.detail.anamnesis.anamnesis',
  PATIENTS_DETAIL_ANAMNESIS_PERSONAL: 'breadcrumbs.patients.detail.anamnesis.personal',
  PATIENTS_DETAIL_ANAMNESIS_MEDICATIONS: 'breadcrumbs.patients.detail.anamnesis.medications',
  PATIENTS_DETAIL_ANAMNESIS_ALLERGIES: 'breadcrumbs.patients.detail.anamnesis.allergies',
  PATIENTS_DETAIL_ANAMNESIS_GYNAECOLOGY: 'breadcrumbs.patients.detail.anamnesis.gynaecology',
  PATIENTS_DETAIL_ANAMNESIS_SURGERIES: 'breadcrumbs.patients.detail.anamnesis.surgeries',
  PATIENTS_DETAIL_ANAMNESIS_EPIDEMIOLOGY: 'breadcrumbs.patients.detail.anamnesis.epidemiology',
  PATIENTS_DETAIL_ANAMNESIS_ADDICTIVE_SUBSTANCES: 'breadcrumbs.patients.detail.anamnesis.addictiveSubstances',
  PATIENTS_DETAIL_ANAMNESIS_FAMILY_HISTORY: 'breadcrumbs.patients.detail.anamnesis.familyHistory',
  PATIENTS_DETAIL_ANAMNESIS_WORK_HISTORY: 'breadcrumbs.patients.detail.anamnesis.workHistory',
  PATIENTS_DETAIL_ANAMNESIS_SOCIAL_HISTORY: 'breadcrumbs.patients.detail.anamnesis.socialHistory',
  PATIENTS_DETAIL_ANAMNESIS_SPORTS_HISTORY: 'breadcrumbs.patients.detail.anamnesis.sportsHistory',
  PATIENTS_DETAIL_CHECKUPS: 'breadcrumbs.patients.detail.checkups',
  PATIENTS_DETAIL_MEASUREMENTS: 'breadcrumbs.patients.detail.measurements.measurements',
  PATIENTS_DETAIL_MEASUREMENTS_GLYCEMIA: 'breadcrumbs.patients.detail.measurements.glycemia',
  PATIENTS_DETAIL_MEASUREMENTS_OXYGEN_SATURATION: 'breadcrumbs.patients.detail.measurements.oxygenSaturation',
  PATIENTS_DETAIL_MEASUREMENTS_ECG: 'breadcrumbs.patients.detail.measurements.ecg',
  PATIENTS_DETAIL_MEASUREMENTS_BPM: 'breadcrumbs.patients.detail.measurements.bpm',
  PATIENTS_DETAIL_MEASUREMENTS_SLEEP: 'breadcrumbs.patients.detail.measurements.sleep',
  PATIENTS_DETAIL_MEASUREMENTS_AIR_POLUTION: 'breadcrumbs.patients.detail.measurements.airPolution',
  PATIENTS_DETAIL_MEASUREMENTS_ENDOSCOPY: 'breadcrumbs.patients.detail.measurements.endoscopy',
  PATIENTS_DETAIL_MEASUREMENTS_TEMPERATURE: 'breadcrumbs.patients.detail.measurements.temperature',
  PATIENTS_DETAIL_MEASUREMENTS_WEIGHT: 'breadcrumbs.patients.detail.measurements.weight',
  PATIENTS_DETAIL_MEASUREMENTS_HEIGHT: 'breadcrumbs.patients.detail.measurements.height',
  PATIENTS_DETAIL_MEASUREMENTS_CHOLESTEROL: 'breadcrumbs.patients.detail.measurements.cholesterol',
  PATIENTS_DETAIL_MEASUREMENTS_PRESSURE: 'breadcrumbs.patients.detail.measurements.pressure',
  PATIENTS_DETAIL_FAMILY: 'breadcrumbs.patients.detail.family',
  PATIENTS_DETAIL_DOCTORS: 'breadcrumbs.patients.detail.doctors',
  PATIENTS_DETAIL_STORAGE: 'breadcrumbs.patients.detail.storage.storage',
  PATIENTS_DETAIL_STORAGE_LABORATORY: 'breadcrumbs.patients.detail.storage.laboratory',
  PATIENTS_DETAIL_STORAGE_RADIOLOGY: 'breadcrumbs.patients.detail.storage.radiology',
  PATIENTS_DETAIL_STORAGE_ATTACHMENTS: 'breadcrumbs.patients.detail.storage.attachments',

  CHAT: 'breadcrumbs.chat',

  ORDERS: 'breadcrumbs.orders.orders',
  ORDERS_ADMINISTRATION: 'breadcrumbs.orders.administration.administration',
  ORDERS_ADMINISTRATION_HISTORY: 'breadcrumbs.orders.administration.history',
  ORDERS_ADMINISTRATION_SERVICE_LIST: 'breadcrumbs.orders.administration.serviceList',

  ORDERS_ADMINISTRATION_CATEGORY_LIST: 'breadcrumbs.orders.administration.category.list',
  ORDERS_ADMINISTRATION_CATEGORY_ACCESSORY_LIST: 'breadcrumbs.orders.administration.category.accessoryList',

  ORDERS_PATIENT: 'breadcrumbs.orders.patient.patient',
  ORDERS_PATIENT_HISTORY: 'breadcrumbs.orders.patient.history',
  ORDERS_PATIENT_LIST: 'breadcrumbs.orders.patient.list',
  ORDERS_PATIENT_DETAIL: 'breadcrumbs.orders.patient.detail',

  CUSTOM_MEDICATIONS: 'breadcrumbs.customMedications',

  TELEREHABILITATION: 'breadcrumbs.telerehabilitation.telerehabilitation',
  TELEREHABILITATION_PRESETS: 'breadcrumbs.telerehabilitation.presets',
  TELEREHABILITATION_CHARTS: 'breadcrumbs.telerehabilitation.charts',
  TELEREHABILITATION_PATIENT: 'store.patients.patient.full_name',
  TELEREHABILITATION_PATIENT_DAY: 'store.telerehabilitation.exercise.date',
  TELEREHABILITATION_PATIENT_EXERCISE: 'store.telerehabilitation.exercise.title',

  SETTINGS: 'breadcrumbs.settings.settings',
  SETTINGS_PROFILE: 'breadcrumbs.settings.profile',
  SETTINGS_PASSWORD: 'breadcrumbs.settings.password',
  SETTINGS_NOTIFICATIONS: 'breadcrumbs.settings.notifications',
  SETTINGS_APPS: 'breadcrumbs.settings.apps',
}
