import { CogIcon } from '@heroicons/react/outline'
import { ContentLoaderInterface } from '../../../interfaces'

export const ContentLoader = (props: ContentLoaderInterface) => {
  const { size, color } = props

  function getSizeClasses() {
    switch (size) {
      case 'xs':
        return 'w-4 h-4'
      case 'sm':
        return 'w-6 h-6'
      case 'md':
        return 'w-10 h-10'
      case 'lg':
        return 'w-16 h-16'
      case 'xl':
        return 'w-32 h-32'
      default:
        return 'w-20 h-20'
    }
  }

  function getColor() {
    if (!color) return 'brand'
    return color
  }

  return (
    <div className="flex justify-center items-center w-full h-full">
      <CogIcon className={`${getSizeClasses()} text-${getColor()} animate-spin`} aria-label="page-loader" />
    </div>
  )
}

export default ContentLoader
