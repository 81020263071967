import { createAsyncThunk } from '@reduxjs/toolkit'
import { API } from '../../../api'
import { callBaseApiEndpoint, callCalApiEndpoint } from '../../utils'

// typePrefix = '${namespace}/functionName'
export const getEvents: any = createAsyncThunk('calendar/getEvents', async (payload, { dispatch }) => {
  return await callCalApiEndpoint(dispatch, {
    requestData: { ...API.calendar.getEvents },
    params: payload,
    throwError: true,
  }).then((res: any) => res)
})

export const getEvent: any = createAsyncThunk('calendar/getEvent', async (payload, { dispatch }) => {
  return await callCalApiEndpoint(dispatch, {
    requestData: { ...API.calendar.getEvent },
    params: payload,
    throwError: true,
  }).then((res: any) => res)
})

export const createEvent: any = createAsyncThunk('calendar/createEvent', async (payload, { dispatch }) => {
  return await callCalApiEndpoint(dispatch, {
    requestData: { ...API.calendar.createEvent },
    params: payload,
    throwError: true,
  }).then((res: any) => res)
})

export const editEvent: any = createAsyncThunk('calendar/editEvent', async (payload, { dispatch }) => {
  return await callCalApiEndpoint(dispatch, {
    requestData: { ...API.calendar.editEvent },
    params: payload,
    throwError: true,
  }).then((res: any) => res)
})

export const deleteEvent: any = createAsyncThunk('calendar/deleteEvent', async (payload, { dispatch }) => {
  return await callCalApiEndpoint(dispatch, {
    requestData: { ...API.calendar.deleteEvent },
    params: payload,
    throwError: true,
  }).then((res: any) => res)
})

export const rsvpEvent: any = createAsyncThunk('calendar/rsvpEvent', async (payload, { dispatch }) => {
  return await callCalApiEndpoint(dispatch, {
    requestData: { ...API.calendar.rsvpEvent },
    params: payload,
    throwError: true,
  }).then((res: any) => res)
})

export const getPatientsAttendees: any = createAsyncThunk(
  'patients/getPatientsAttendees',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.doctors.getDoctors },
      params: payload,
      throwError: true,
    }).then((res: any) => res)
  }
)

export const getDoctorsAttendees: any = createAsyncThunk(
  'patients/getDoctorsAttendees',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.calendar.getDoctorsAttendees },
      params: payload,
      throwError: true,
    }).then((res: any) => res)
  }
)
