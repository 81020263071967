import React from 'react'
import { Route, Switch } from 'react-router-dom'
import login_img from '../../assets/images/login.jpg'
import PasswordResetEmailHandler from '../../components/password-reset/PasswordResetEmailHandler'
import PasswordResetNewPasswordHandler from '../../components/password-reset/PasswordResetNewPasswordHandler'
import { ROUTES } from '../../router/routes'

export const PasswordResetPage = () => {
  return (
    <div className="flex min-h-screen bg-white">
      <div className="flex flex-col flex-1 lg:flex-none justify-center py-12 px-4 sm:px-6 lg:px-20 xl:px-24">
        <div className="mx-auto w-full lg:w-96 max-w-sm">
          <Switch>
            <Route path={`${ROUTES.PASSWORD_RESET_EMAIL}`} component={PasswordResetEmailHandler} exact={true} />
            <Route
              path={`${ROUTES.PASSWORD_RESET_NEW_PASSWORD}`}
              component={PasswordResetNewPasswordHandler}
              exact={true}
            />
          </Switch>
        </div>
      </div>
      <div className="hidden lg:block relative flex-1 w-0">
        <img className="object-cover absolute inset-0 w-full h-full" src={login_img} alt="" />
      </div>
    </div>
  )
}

export default PasswordResetPage
