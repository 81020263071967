import { createSlice } from '@reduxjs/toolkit'
import { DispatchActionReducerInterface, PatientState } from '../../../interfaces'
import {
  getAnamnesisAddictiveSubstances,
  getAnamnesisAllergies,
  getAnamnesisEpidemiology,
  getAnamnesisEpidemiologyList,
  getAnamnesisFamily,
  getAnamnesisFamilyMember,
  getAnamnesisGynaecology,
  getAnamnesisGynaecologyMenstruation,
  getAnamnesisGynaecologyMenstruations,
  getAnamnesisGynaecologyPregnancies,
  getAnamnesisGynaecologyPregnancy,
  getAnamnesisMedication,
  getAnamnesisMedications,
  getAnamnesisPersonal,
  getAnamnesisSocial,
  getAnamnesisSport,
  getAnamnesisSurgeries,
  getAnamnesisSurgery,
  getAnamnesisWork,
  getCheckup,
  getCheckups,
  getDoctorsPatients,
  getEnums,
  getFamily,
  getMeasurementAirPolution,
  getMeasurementBPM,
  getMeasurementCholesterol,
  getMeasurementECG,
  getMeasurementEndoscopy,
  getMeasurementGlycemia,
  getMeasurementHeight,
  getMeasurementOxygenSaturation,
  getMeasurementPressure,
  getMeasurementsAirPolution,
  getMeasurementsBPM,
  getMeasurementsCholesterol,
  getMeasurementsECG,
  getMeasurementsEndoscopy,
  getMeasurementsGlycemia,
  getMeasurementsHeight,
  getMeasurementSleep,
  getMeasurementsOxygenSaturation,
  getMeasurementsPressure,
  getMeasurementsSleep,
  getMeasurementsTemperature,
  getMeasurementsWeight,
  getMeasurementTemperature,
  getMeasurementWeight,
  getPatient,
  getPatients,
  getStorageAttachment,
  getStorageAttachments,
  getStorageLaboratory,
  getStorageLaboratoryFile,
  getStorageRadiology,
  getStorageRadiologyFile,
} from './actions'
import { PATIENTS } from './types'

const initialState: PatientState = {
  patients: null,
  getPatientsLoading: false,

  doctorsPatients: null,
  getDoctorsPatientsLoading: false,

  patient: null,
  getPatientLoading: false,

  family: [],
  getFamilyLoading: false,

  anamnesisPersonal: null,
  getAnamnesisPersonalLoading: false,

  anamnesisMedications: null,
  getAnamnesisMedicationsLoading: false,

  anamnesisMedication: null,
  getAnamnesisMedicationLoading: false,

  anamnesisAllergies: null,
  getAnamnesisAllergiesLoading: false,

  anamnesisGynaecology: null,
  getAnamnesisGynaecologyLoading: false,

  anamnesisGynaecologyMenstruation: null,
  getAnamnesisGynaecologyMenstruationLoading: false,

  anamnesisGynaecologyMenstruations: null,
  getAnamnesisGynaecologyMenstruationsLoading: false,

  anamnesisGynaecologyPregnancy: null,
  getAnamnesisGynaecologyPregnancyLoading: false,

  anamnesisGynaecologyPregnancies: null,
  getAnamnesisGynaecologyPregnanciesLoading: false,

  anamnesisSurgeries: null,
  getAnamnesisSurgeriesLoading: false,

  anamnesisSurgery: null,
  getAnamnesisSurgeryLoading: false,

  anamnesisEpidemiologyList: null,
  getAnamnesisEpidemiologyListLoading: false,

  anamnesisEpidemiology: null,
  getAnamnesisEpidemiologyLoading: false,

  anamnesisFamily: null,
  getAnamnesisFamilyLoading: false,

  anamnesisFamilyMember: null,
  getAnamnesisFamilyMemberLoading: false,

  anamnesisWork: null,
  getAnamnesisWorkLoading: false,

  anamnesisSocial: null,
  getAnamnesisSocialLoading: false,

  anamnesisSport: null,
  getAnamnesisSportLoading: false,

  anamnesisAddictiveSubstances: null,
  getAnamnesisAddictiveSubstancesLoading: false,

  checkups: null,
  getCheckupsLoading: false,

  checkup: null,
  getCheckupLoading: false,

  measurementsGlycemia: null,
  getMeasurementsGlycemiaLoading: false,

  measurementGlycemia: null,
  getMeasurementGlycemiaLoading: false,

  measurementsOxygenSaturation: null,
  getMeasurementsOxygenSaturationLoading: false,

  measurementOxygenSaturation: null,
  getMeasurementOxygenSaturationLoading: false,

  measurementsECG: null,
  getMeasurementsECGLoading: false,

  measurementECG: null,
  getMeasurementECGLoading: false,

  measurementsBPM: null,
  getMeasurementsBPMLoading: false,

  measurementBPM: null,
  getMeasurementBPMLoading: false,

  measurementsSleep: null,
  getMeasurementsSleepLoading: false,

  measurementSleep: null,
  getMeasurementSleepLoading: false,

  measurementsAirPolution: null,
  getMeasurementsAirPolutionLoading: false,

  measurementAirPolution: null,
  getMeasurementAirPolutionLoading: false,

  measurementsEndoscopy: null,
  getMeasurementsEndoscopyLoading: false,

  measurementEndoscopy: null,
  getMeasurementEndoscopyLoading: false,

  measurementsTemperature: null,
  getMeasurementsTemperatureLoading: false,

  measurementTemperature: null,
  getMeasurementTemperatureLoading: false,

  measurementsHeight: null,
  getMeasurementsHeightLoading: false,

  measurementHeight: null,
  getMeasurementHeightLoading: false,

  measurementsWeight: null,
  getMeasurementsWeightLoading: false,

  measurementWeight: null,
  getMeasurementWeightLoading: false,

  measurementsCholesterol: null,
  getMeasurementCholesterolLoading: false,

  measurementCholesterol: null,
  getMeasurementsCholesterolLoading: false,

  measurementsPressure: null,
  getMeasurementPressureLoading: false,

  measurementPressure: null,
  getMeasurementsPressureLoading: false,

  storageAttachments: null,
  getStorageAttachmentLoading: false,

  storageAttachment: null,
  getStorageAttachmentsLoading: false,

  storageRadiology: null,
  getStorageRadiologyFileLoading: false,

  storageRadiologyFile: null,
  getStorageRadiologyLoading: false,

  storageLaboratory: null,
  getStorageLaboratoryFileLoading: false,

  storageLaboratoryFile: null,
  getStorageLaboratoryLoading: false,

  enums: {},
  getEnumsLoading: false,
}

const patientsSlice = createSlice({
  name: PATIENTS.NAMESPACE,
  initialState,
  // Only frontend Logic
  reducers: {},
  extraReducers: builder => {
    // Reducers with calling external API
    builder
      .addCase(getPatients.pending, (state: PatientState) => {
        state.getPatientsLoading = true
      })
      .addCase(getPatients.fulfilled, (state: PatientState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.patients = action.payload.data
        } else {
          state.patients = null
        }
        state.getPatientsLoading = false
      })
      .addCase(getPatients.rejected, (state: PatientState) => {
        state.patients = null
        state.getPatientsLoading = false
      })

      .addCase(getDoctorsPatients.pending, (state: PatientState) => {
        state.getDoctorsPatientsLoading = true
      })
      .addCase(getDoctorsPatients.fulfilled, (state: PatientState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.doctorsPatients = action.payload.data
        } else {
          state.doctorsPatients = null
        }
        state.getDoctorsPatientsLoading = false
      })
      .addCase(getDoctorsPatients.rejected, (state: PatientState) => {
        state.doctorsPatients = null
        state.getDoctorsPatientsLoading = false
      })

      .addCase(getPatient.pending, (state: PatientState) => {
        state.getPatientLoading = true
      })
      .addCase(getPatient.fulfilled, (state: PatientState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.patient = action.payload.data
          if (state.patient)
            state.patient.full_name = `${
              action.payload.data?.degrees_before ? action.payload.data.degrees_before : ''
            } ${action.payload.data.first_name} ${
              action.payload.data?.middle_name ? action.payload.data.middle_name : ''
            } ${action.payload.data.last_name} ${
              action.payload.data?.degrees_after ? action.payload.data.degrees_after : ''
            }`
        } else {
          state.patient = null
          state.getPatientLoading = false
        }
      })
      .addCase(getPatient.rejected, (state: PatientState) => {
        state.getPatientLoading = false
        state.patient = null
      })

      .addCase(getFamily.pending, (state: PatientState) => {
        state.getFamilyLoading = true
      })
      .addCase(getFamily.fulfilled, (state: PatientState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.family = action.payload.data
        } else {
          state.family = []
        }
        state.getFamilyLoading = false
      })
      .addCase(getFamily.rejected, (state: PatientState) => {
        state.family = []
        state.getFamilyLoading = false
      })

      .addCase(getAnamnesisPersonal.pending, (state: PatientState) => {
        state.getAnamnesisPersonalLoading = true
      })
      .addCase(getAnamnesisPersonal.fulfilled, (state: PatientState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.anamnesisPersonal = action.payload.data
        } else {
          state.anamnesisPersonal = null
        }
        state.getAnamnesisPersonalLoading = false
      })
      .addCase(getAnamnesisPersonal.rejected, (state: PatientState) => {
        state.anamnesisPersonal = null
        state.getAnamnesisPersonalLoading = false
      })

      .addCase(getAnamnesisMedications.pending, (state: PatientState) => {
        state.getAnamnesisMedicationsLoading = true
      })
      .addCase(getAnamnesisMedications.fulfilled, (state: PatientState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.anamnesisMedications = action.payload.data
        } else {
          state.anamnesisMedications = null
        }
        state.getAnamnesisMedicationsLoading = false
      })
      .addCase(getAnamnesisMedications.rejected, (state: PatientState) => {
        state.anamnesisMedications = null
        state.getAnamnesisMedicationsLoading = false
      })

      .addCase(getAnamnesisMedication.pending, (state: PatientState) => {
        state.getAnamnesisMedicationLoading = true
      })
      .addCase(getAnamnesisMedication.fulfilled, (state: PatientState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.anamnesisMedication = action.payload.data
        } else {
          state.anamnesisMedication = null
        }
        state.getAnamnesisMedicationLoading = false
      })
      .addCase(getAnamnesisMedication.rejected, (state: PatientState) => {
        state.anamnesisMedication = null
        state.getAnamnesisMedicationLoading = false
      })

      .addCase(getAnamnesisAllergies.pending, (state: PatientState) => {
        state.getAnamnesisAllergiesLoading = true
      })
      .addCase(getAnamnesisAllergies.fulfilled, (state: PatientState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.anamnesisAllergies = action.payload.data
        } else {
          state.anamnesisAllergies = null
        }
        state.getAnamnesisAllergiesLoading = false
      })
      .addCase(getAnamnesisAllergies.rejected, (state: PatientState) => {
        state.anamnesisAllergies = null
        state.getAnamnesisAllergiesLoading = false
      })

      .addCase(getAnamnesisGynaecology.pending, (state: PatientState) => {
        state.getAnamnesisGynaecologyLoading = true
      })
      .addCase(getAnamnesisGynaecology.fulfilled, (state: PatientState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.anamnesisGynaecology = action.payload.data
        } else {
          state.anamnesisGynaecology = null
        }
        state.getAnamnesisGynaecologyLoading = false
      })
      .addCase(getAnamnesisGynaecology.rejected, (state: PatientState) => {
        state.anamnesisGynaecology = null
        state.getAnamnesisGynaecologyLoading = false
      })

      .addCase(getAnamnesisGynaecologyPregnancies.pending, (state: PatientState) => {
        state.getAnamnesisGynaecologyPregnanciesLoading = true
      })
      .addCase(
        getAnamnesisGynaecologyPregnancies.fulfilled,
        (state: PatientState, action: DispatchActionReducerInterface) => {
          if (action.payload.status === 'ok') {
            state.anamnesisGynaecologyPregnancies = action.payload.data
          } else {
            state.anamnesisGynaecologyPregnancies = null
          }
          state.getAnamnesisGynaecologyPregnanciesLoading = false
        }
      )
      .addCase(getAnamnesisGynaecologyPregnancies.rejected, (state: PatientState) => {
        state.anamnesisGynaecologyPregnancies = null
        state.getAnamnesisGynaecologyPregnanciesLoading = false
      })

      .addCase(getAnamnesisGynaecologyMenstruations.pending, (state: PatientState) => {
        state.getAnamnesisGynaecologyMenstruationsLoading = true
      })
      .addCase(
        getAnamnesisGynaecologyMenstruations.fulfilled,
        (state: PatientState, action: DispatchActionReducerInterface) => {
          if (action.payload.status === 'ok') {
            state.anamnesisGynaecologyMenstruations = action.payload.data
          } else {
            state.anamnesisGynaecologyMenstruations = null
          }
          state.getAnamnesisGynaecologyMenstruationsLoading = false
        }
      )
      .addCase(getAnamnesisGynaecologyMenstruations.rejected, (state: PatientState) => {
        state.anamnesisGynaecologyMenstruations = null
        state.getAnamnesisGynaecologyMenstruationsLoading = false
      })

      .addCase(getAnamnesisGynaecologyPregnancy.pending, (state: PatientState) => {
        state.getAnamnesisGynaecologyPregnancyLoading = true
      })
      .addCase(
        getAnamnesisGynaecologyPregnancy.fulfilled,
        (state: PatientState, action: DispatchActionReducerInterface) => {
          if (action.payload.status === 'ok') {
            state.anamnesisGynaecologyPregnancy = action.payload.data
          } else {
            state.anamnesisGynaecologyPregnancy = null
          }
          state.getAnamnesisGynaecologyPregnancyLoading = false
        }
      )
      .addCase(getAnamnesisGynaecologyPregnancy.rejected, (state: PatientState) => {
        state.anamnesisGynaecologyPregnancy = null
        state.getAnamnesisGynaecologyPregnancyLoading = false
      })

      .addCase(getAnamnesisGynaecologyMenstruation.pending, (state: PatientState) => {
        state.getAnamnesisGynaecologyMenstruationLoading = true
      })
      .addCase(
        getAnamnesisGynaecologyMenstruation.fulfilled,
        (state: PatientState, action: DispatchActionReducerInterface) => {
          if (action.payload.status === 'ok') {
            state.anamnesisGynaecologyMenstruation = action.payload.data
          } else {
            state.anamnesisGynaecologyMenstruation = null
          }
          state.getAnamnesisGynaecologyMenstruationLoading = false
        }
      )
      .addCase(getAnamnesisGynaecologyMenstruation.rejected, (state: PatientState) => {
        state.anamnesisGynaecologyMenstruation = null
        state.getAnamnesisGynaecologyMenstruationLoading = false
      })

      .addCase(getAnamnesisSurgeries.pending, (state: PatientState) => {
        state.getAnamnesisSurgeriesLoading = true
      })
      .addCase(getAnamnesisSurgeries.fulfilled, (state: PatientState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.anamnesisSurgeries = action.payload.data
        } else {
          state.anamnesisSurgeries = null
        }
        state.getAnamnesisSurgeriesLoading = false
      })
      .addCase(getAnamnesisSurgeries.rejected, (state: PatientState) => {
        state.anamnesisSurgeries = null
        state.getAnamnesisSurgeriesLoading = false
      })

      .addCase(getAnamnesisSurgery.pending, (state: PatientState) => {
        state.getAnamnesisSurgeryLoading = true
      })
      .addCase(getAnamnesisSurgery.fulfilled, (state: PatientState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.anamnesisSurgery = action.payload.data
        } else {
          state.anamnesisSurgery = null
        }
        state.getAnamnesisSurgeryLoading = false
      })
      .addCase(getAnamnesisSurgery.rejected, (state: PatientState) => {
        state.anamnesisSurgery = null
        state.getAnamnesisSurgeryLoading = false
      })

      .addCase(getAnamnesisEpidemiologyList.pending, (state: PatientState) => {
        state.getAnamnesisEpidemiologyListLoading = true
      })
      .addCase(
        getAnamnesisEpidemiologyList.fulfilled,
        (state: PatientState, action: DispatchActionReducerInterface) => {
          if (action.payload.status === 'ok') {
            state.anamnesisEpidemiologyList = action.payload.data
          } else {
            state.anamnesisEpidemiologyList = null
          }
          state.getAnamnesisEpidemiologyListLoading = false
        }
      )
      .addCase(getAnamnesisEpidemiologyList.rejected, (state: PatientState) => {
        state.anamnesisEpidemiologyList = null
        state.getAnamnesisEpidemiologyListLoading = false
      })

      .addCase(getAnamnesisEpidemiology.pending, (state: PatientState) => {
        state.getAnamnesisEpidemiologyLoading = true
      })
      .addCase(getAnamnesisEpidemiology.fulfilled, (state: PatientState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.anamnesisEpidemiology = action.payload.data
        } else {
          state.anamnesisEpidemiology = null
        }
        state.getAnamnesisEpidemiologyLoading = false
      })
      .addCase(getAnamnesisEpidemiology.rejected, (state: PatientState) => {
        state.anamnesisEpidemiology = null
        state.getAnamnesisEpidemiologyLoading = false
      })

      .addCase(getAnamnesisFamily.pending, (state: PatientState) => {
        state.getAnamnesisFamilyLoading = true
      })
      .addCase(getAnamnesisFamily.fulfilled, (state: PatientState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.anamnesisFamily = action.payload.data
        } else {
          state.anamnesisFamily = null
        }
        state.getAnamnesisFamilyLoading = false
      })
      .addCase(getAnamnesisFamily.rejected, (state: PatientState) => {
        state.anamnesisFamily = null
        state.getAnamnesisFamilyLoading = false
      })

      .addCase(getAnamnesisFamilyMember.pending, (state: PatientState) => {
        state.getAnamnesisFamilyMemberLoading = true
      })
      .addCase(getAnamnesisFamilyMember.fulfilled, (state: PatientState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.anamnesisFamilyMember = action.payload.data
        } else {
          state.anamnesisFamilyMember = null
        }
        state.getAnamnesisFamilyMemberLoading = false
      })
      .addCase(getAnamnesisFamilyMember.rejected, (state: PatientState) => {
        state.anamnesisFamilyMember = null
        state.getAnamnesisFamilyMemberLoading = false
      })

      .addCase(getAnamnesisWork.pending, (state: PatientState) => {
        state.getAnamnesisWorkLoading = true
      })
      .addCase(getAnamnesisWork.fulfilled, (state: PatientState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.anamnesisWork = action.payload.data
        } else {
          state.anamnesisWork = null
        }
        state.getAnamnesisWorkLoading = false
      })
      .addCase(getAnamnesisWork.rejected, (state: PatientState) => {
        state.anamnesisWork = null
        state.getAnamnesisWorkLoading = false
      })

      .addCase(getAnamnesisSocial.pending, (state: PatientState) => {
        state.getAnamnesisSocialLoading = true
      })
      .addCase(getAnamnesisSocial.fulfilled, (state: PatientState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.anamnesisSocial = action.payload.data
        } else {
          state.anamnesisSocial = null
        }
        state.getAnamnesisSocialLoading = false
      })
      .addCase(getAnamnesisSocial.rejected, (state: PatientState) => {
        state.anamnesisSocial = null
        state.getAnamnesisSocialLoading = false
      })

      .addCase(getAnamnesisSport.pending, (state: PatientState) => {
        state.getAnamnesisSportLoading = true
      })
      .addCase(getAnamnesisSport.fulfilled, (state: PatientState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.anamnesisSport = action.payload.data
        } else {
          state.anamnesisSport = null
        }
        state.getAnamnesisSportLoading = false
      })
      .addCase(getAnamnesisSport.rejected, (state: PatientState) => {
        state.anamnesisSport = null
        state.getAnamnesisSportLoading = false
      })

      .addCase(getAnamnesisAddictiveSubstances.pending, (state: PatientState) => {
        state.getAnamnesisAddictiveSubstancesLoading = true
      })
      .addCase(
        getAnamnesisAddictiveSubstances.fulfilled,
        (state: PatientState, action: DispatchActionReducerInterface) => {
          if (action.payload.status === 'ok') {
            state.anamnesisAddictiveSubstances = action.payload.data
          } else {
            state.anamnesisAddictiveSubstances = null
          }
          state.getAnamnesisAddictiveSubstancesLoading = false
        }
      )
      .addCase(getAnamnesisAddictiveSubstances.rejected, (state: PatientState) => {
        state.anamnesisAddictiveSubstances = null
        state.getAnamnesisAddictiveSubstancesLoading = false
      })

      .addCase(getCheckups.pending, (state: PatientState) => {
        state.checkups = null
        state.getCheckupsLoading = true
      })
      .addCase(getCheckups.fulfilled, (state: PatientState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.checkups = action.payload.data
        } else {
          state.checkups = null
        }
        state.getCheckupsLoading = false
      })
      .addCase(getCheckups.rejected, (state: PatientState) => {
        state.checkups = null
        state.getCheckupsLoading = false
      })

      .addCase(getCheckup.pending, (state: PatientState) => {
        state.getCheckupLoading = true
      })
      .addCase(getCheckup.fulfilled, (state: PatientState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.checkup = action.payload.data
        } else {
          state.checkup = null
        }
        state.getCheckupLoading = false
      })
      .addCase(getCheckup.rejected, (state: PatientState) => {
        state.checkup = null
        state.getCheckupLoading = false
      })

      .addCase(getMeasurementsGlycemia.pending, (state: PatientState) => {
        state.getMeasurementsGlycemiaLoading = true
      })
      .addCase(getMeasurementsGlycemia.fulfilled, (state: PatientState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.measurementsGlycemia = action.payload.data
        } else {
          state.measurementsGlycemia = null
        }
        state.getMeasurementsGlycemiaLoading = false
      })
      .addCase(getMeasurementsGlycemia.rejected, (state: PatientState) => {
        state.measurementsGlycemia = null
        state.getMeasurementsGlycemiaLoading = false
      })

      .addCase(getMeasurementGlycemia.pending, (state: PatientState) => {
        state.getMeasurementGlycemiaLoading = true
      })
      .addCase(getMeasurementGlycemia.fulfilled, (state: PatientState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.measurementGlycemia = action.payload.data
        } else {
          state.measurementGlycemia = null
        }
        state.getMeasurementGlycemiaLoading = false
      })
      .addCase(getMeasurementGlycemia.rejected, (state: PatientState) => {
        state.measurementGlycemia = null
        state.getMeasurementGlycemiaLoading = false
      })

      .addCase(getMeasurementsOxygenSaturation.pending, (state: PatientState) => {
        state.getMeasurementsOxygenSaturationLoading = true
      })
      .addCase(
        getMeasurementsOxygenSaturation.fulfilled,
        (state: PatientState, action: DispatchActionReducerInterface) => {
          if (action.payload.status === 'ok') {
            state.measurementsOxygenSaturation = action.payload.data
          } else {
            state.measurementsOxygenSaturation = null
          }
          state.getMeasurementsOxygenSaturationLoading = false
        }
      )
      .addCase(getMeasurementsOxygenSaturation.rejected, (state: PatientState) => {
        state.measurementsOxygenSaturation = null
        state.getMeasurementsOxygenSaturationLoading = false
      })

      .addCase(getMeasurementOxygenSaturation.pending, (state: PatientState) => {
        state.getMeasurementOxygenSaturationLoading = true
      })
      .addCase(
        getMeasurementOxygenSaturation.fulfilled,
        (state: PatientState, action: DispatchActionReducerInterface) => {
          if (action.payload.status === 'ok') {
            state.measurementOxygenSaturation = action.payload.data
          } else {
            state.measurementOxygenSaturation = null
          }
          state.getMeasurementOxygenSaturationLoading = false
        }
      )
      .addCase(getMeasurementOxygenSaturation.rejected, (state: PatientState) => {
        state.measurementOxygenSaturation = null
        state.getMeasurementOxygenSaturationLoading = false
      })

      .addCase(getMeasurementsSleep.pending, (state: PatientState) => {
        state.getMeasurementsSleepLoading = true
      })
      .addCase(getMeasurementsSleep.fulfilled, (state: PatientState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.measurementsSleep = action.payload.data
        } else {
          state.measurementsSleep = null
        }
        state.getMeasurementsSleepLoading = false
      })
      .addCase(getMeasurementsSleep.rejected, (state: PatientState) => {
        state.measurementsSleep = null
        state.getMeasurementsSleepLoading = false
      })

      .addCase(getMeasurementSleep.pending, (state: PatientState) => {
        state.getMeasurementSleepLoading = true
      })
      .addCase(getMeasurementSleep.fulfilled, (state: PatientState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.measurementSleep = action.payload.data
        } else {
          state.measurementSleep = null
        }
        state.getMeasurementSleepLoading = false
      })
      .addCase(getMeasurementSleep.rejected, (state: PatientState) => {
        state.measurementSleep = null
        state.getMeasurementSleepLoading = false
      })

      .addCase(getMeasurementsECG.pending, (state: PatientState) => {
        state.getMeasurementsECGLoading = true
      })
      .addCase(getMeasurementsECG.fulfilled, (state: PatientState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.measurementsECG = action.payload.data
        } else {
          state.measurementsECG = null
        }
        state.getMeasurementsECGLoading = false
      })
      .addCase(getMeasurementsECG.rejected, (state: PatientState) => {
        state.measurementsECG = null
        state.getMeasurementsECGLoading = false
      })

      .addCase(getMeasurementECG.pending, (state: PatientState) => {
        state.getMeasurementECGLoading = true
      })
      .addCase(getMeasurementECG.fulfilled, (state: PatientState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.measurementECG = action.payload.data
        } else {
          state.measurementECG = null
        }
        state.getMeasurementECGLoading = false
      })
      .addCase(getMeasurementECG.rejected, (state: PatientState) => {
        state.measurementECG = null
        state.getMeasurementECGLoading = false
      })

      .addCase(getMeasurementsBPM.pending, (state: PatientState) => {
        state.getMeasurementsBPMLoading = true
      })
      .addCase(getMeasurementsBPM.fulfilled, (state: PatientState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.measurementsBPM = action.payload.data
        } else {
          state.measurementsBPM = null
        }
        state.getMeasurementsBPMLoading = false
      })
      .addCase(getMeasurementsBPM.rejected, (state: PatientState) => {
        state.measurementsBPM = null
        state.getMeasurementsBPMLoading = false
      })

      .addCase(getMeasurementBPM.pending, (state: PatientState) => {
        state.getMeasurementBPMLoading = true
      })
      .addCase(getMeasurementBPM.fulfilled, (state: PatientState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.measurementBPM = action.payload.data
        } else {
          state.measurementBPM = null
        }
        state.getMeasurementBPMLoading = false
      })
      .addCase(getMeasurementBPM.rejected, (state: PatientState) => {
        state.measurementBPM = null
        state.getMeasurementBPMLoading = false
      })

      .addCase(getMeasurementsAirPolution.pending, (state: PatientState) => {
        state.getMeasurementsAirPolutionLoading = true
      })
      .addCase(getMeasurementsAirPolution.fulfilled, (state: PatientState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.measurementsAirPolution = action.payload.data
        } else {
          state.measurementsAirPolution = null
        }
        state.getMeasurementsAirPolutionLoading = false
      })
      .addCase(getMeasurementsAirPolution.rejected, (state: PatientState) => {
        state.measurementsAirPolution = null
        state.getMeasurementsAirPolutionLoading = false
      })

      .addCase(getMeasurementAirPolution.pending, (state: PatientState) => {
        state.getMeasurementAirPolutionLoading = true
      })
      .addCase(getMeasurementAirPolution.fulfilled, (state: PatientState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.measurementAirPolution = action.payload.data
        } else {
          state.measurementAirPolution = null
        }
        state.getMeasurementAirPolutionLoading = false
      })
      .addCase(getMeasurementAirPolution.rejected, (state: PatientState) => {
        state.measurementAirPolution = null
        state.getMeasurementAirPolutionLoading = false
      })

      .addCase(getMeasurementsTemperature.pending, (state: PatientState) => {
        state.getMeasurementsTemperatureLoading = true
      })
      .addCase(getMeasurementsTemperature.fulfilled, (state: PatientState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.measurementsTemperature = action.payload.data
        } else {
          state.measurementsTemperature = null
        }
        state.getMeasurementsTemperatureLoading = false
      })
      .addCase(getMeasurementsTemperature.rejected, (state: PatientState) => {
        state.measurementsTemperature = null
        state.getMeasurementsTemperatureLoading = false
      })

      .addCase(getMeasurementTemperature.pending, (state: PatientState) => {
        state.getMeasurementTemperatureLoading = true
      })
      .addCase(getMeasurementTemperature.fulfilled, (state: PatientState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.measurementTemperature = action.payload.data
        } else {
          state.measurementTemperature = null
        }
        state.getMeasurementTemperatureLoading = false
      })
      .addCase(getMeasurementTemperature.rejected, (state: PatientState) => {
        state.measurementTemperature = null
        state.getMeasurementTemperatureLoading = false
      })

      .addCase(getMeasurementsEndoscopy.pending, (state: PatientState) => {
        state.getMeasurementsEndoscopyLoading = true
      })
      .addCase(getMeasurementsEndoscopy.fulfilled, (state: PatientState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.measurementsEndoscopy = action.payload.data
        } else {
          state.measurementsEndoscopy = null
        }
        state.getMeasurementsEndoscopyLoading = false
      })
      .addCase(getMeasurementsEndoscopy.rejected, (state: PatientState) => {
        state.measurementsEndoscopy = null
        state.getMeasurementsEndoscopyLoading = false
      })

      .addCase(getMeasurementEndoscopy.pending, (state: PatientState) => {
        state.getMeasurementEndoscopyLoading = true
      })
      .addCase(getMeasurementEndoscopy.fulfilled, (state: PatientState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.measurementEndoscopy = action.payload.data
        } else {
          state.measurementEndoscopy = null
        }
        state.getMeasurementEndoscopyLoading = false
      })
      .addCase(getMeasurementEndoscopy.rejected, (state: PatientState) => {
        state.measurementEndoscopy = null
        state.getMeasurementEndoscopyLoading = false
      })

      .addCase(getMeasurementsHeight.pending, (state: PatientState) => {
        state.getMeasurementsHeightLoading = true
      })
      .addCase(getMeasurementsHeight.fulfilled, (state: PatientState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.measurementsHeight = action.payload.data
        } else {
          state.measurementsHeight = null
        }
        state.getMeasurementsHeightLoading = false
      })
      .addCase(getMeasurementsHeight.rejected, (state: PatientState) => {
        state.measurementsHeight = null
        state.getMeasurementsHeightLoading = false
      })

      .addCase(getMeasurementHeight.pending, (state: PatientState) => {
        state.getMeasurementHeightLoading = true
      })
      .addCase(getMeasurementHeight.fulfilled, (state: PatientState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.measurementHeight = action.payload.data
        } else {
          state.measurementHeight = null
        }
        state.getMeasurementHeightLoading = false
      })
      .addCase(getMeasurementHeight.rejected, (state: PatientState) => {
        state.measurementHeight = null
        state.getMeasurementHeightLoading = false
      })

      .addCase(getMeasurementsWeight.pending, (state: PatientState) => {
        state.getMeasurementsWeightLoading = true
      })
      .addCase(getMeasurementsWeight.fulfilled, (state: PatientState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.measurementsWeight = action.payload.data
        } else {
          state.measurementsWeight = null
        }
        state.getMeasurementsWeightLoading = false
      })
      .addCase(getMeasurementsWeight.rejected, (state: PatientState) => {
        state.measurementsWeight = null
        state.getMeasurementsWeightLoading = false
      })

      .addCase(getMeasurementWeight.pending, (state: PatientState) => {
        state.getMeasurementWeightLoading = true
      })
      .addCase(getMeasurementWeight.fulfilled, (state: PatientState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.measurementWeight = action.payload.data
        } else {
          state.measurementWeight = null
        }
        state.getMeasurementWeightLoading = false
      })
      .addCase(getMeasurementWeight.rejected, (state: PatientState) => {
        state.measurementWeight = null
        state.getMeasurementWeightLoading = false
      })

      .addCase(getMeasurementsCholesterol.pending, (state: PatientState) => {
        state.getMeasurementsCholesterolLoading = true
      })
      .addCase(getMeasurementsCholesterol.fulfilled, (state: PatientState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.measurementsCholesterol = action.payload.data
        } else {
          state.measurementsCholesterol = null
        }
        state.getMeasurementsCholesterolLoading = false
      })
      .addCase(getMeasurementsCholesterol.rejected, (state: PatientState) => {
        state.measurementsCholesterol = null
        state.getMeasurementsCholesterolLoading = false
      })

      .addCase(getMeasurementCholesterol.pending, (state: PatientState) => {
        state.getMeasurementCholesterolLoading = true
      })
      .addCase(getMeasurementCholesterol.fulfilled, (state: PatientState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.measurementCholesterol = action.payload.data
        } else {
          state.measurementCholesterol = null
        }
        state.getMeasurementCholesterolLoading = false
      })
      .addCase(getMeasurementCholesterol.rejected, (state: PatientState) => {
        state.measurementCholesterol = null
        state.getMeasurementCholesterolLoading = false
      })

      .addCase(getMeasurementsPressure.pending, (state: PatientState) => {
        state.getMeasurementsPressureLoading = true
      })
      .addCase(getMeasurementsPressure.fulfilled, (state: PatientState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.measurementsPressure = action.payload.data
        } else {
          state.measurementsPressure = null
        }
        state.getMeasurementsPressureLoading = false
      })
      .addCase(getMeasurementsPressure.rejected, (state: PatientState) => {
        state.measurementsPressure = null
        state.getMeasurementsPressureLoading = false
      })

      .addCase(getMeasurementPressure.pending, (state: PatientState) => {
        state.getMeasurementPressureLoading = true
      })
      .addCase(getMeasurementPressure.fulfilled, (state: PatientState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.measurementPressure = action.payload.data
        } else {
          state.measurementPressure = null
        }
        state.getMeasurementPressureLoading = false
      })
      .addCase(getMeasurementPressure.rejected, (state: PatientState) => {
        state.measurementPressure = null
        state.getMeasurementPressureLoading = false
      })

      .addCase(getStorageAttachments.pending, (state: PatientState) => {
        state.getStorageAttachmentsLoading = true
      })
      .addCase(getStorageAttachments.fulfilled, (state: PatientState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.storageAttachments = action.payload.data
        } else {
          state.storageAttachments = null
        }
        state.getStorageAttachmentsLoading = false
      })
      .addCase(getStorageAttachments.rejected, (state: PatientState) => {
        state.storageAttachments = null
        state.getStorageAttachmentsLoading = false
      })

      .addCase(getStorageAttachment.pending, (state: PatientState) => {
        state.getStorageAttachmentLoading = true
      })
      .addCase(getStorageAttachment.fulfilled, (state: PatientState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.storageAttachment = action.payload.data
        } else {
          state.storageAttachment = null
        }
        state.getStorageAttachmentLoading = false
      })
      .addCase(getStorageAttachment.rejected, (state: PatientState) => {
        state.storageAttachment = null
        state.getStorageAttachmentLoading = false
      })

      .addCase(getStorageRadiology.pending, (state: PatientState) => {
        state.getStorageRadiologyLoading = true
      })
      .addCase(getStorageRadiology.fulfilled, (state: PatientState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.storageRadiology = action.payload.data
        } else {
          state.storageRadiology = null
        }
        state.getStorageRadiologyLoading = false
      })
      .addCase(getStorageRadiology.rejected, (state: PatientState) => {
        state.storageRadiology = null
        state.getStorageRadiologyLoading = false
      })

      .addCase(getStorageRadiologyFile.pending, (state: PatientState) => {
        state.getStorageRadiologyFileLoading = true
      })
      .addCase(getStorageRadiologyFile.fulfilled, (state: PatientState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.storageRadiologyFile = action.payload.data
        } else {
          state.storageRadiologyFile = null
        }
        state.getStorageRadiologyFileLoading = false
      })
      .addCase(getStorageRadiologyFile.rejected, (state: PatientState) => {
        state.storageRadiologyFile = null
        state.getStorageRadiologyFileLoading = false
      })

      .addCase(getStorageLaboratory.pending, (state: PatientState) => {
        state.getStorageLaboratoryLoading = true
      })
      .addCase(getStorageLaboratory.fulfilled, (state: PatientState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.storageLaboratory = action.payload.data
        } else {
          state.storageLaboratory = null
        }
        state.getStorageLaboratoryLoading = false
      })
      .addCase(getStorageLaboratory.rejected, (state: PatientState) => {
        state.storageLaboratory = null
        state.getStorageLaboratoryLoading = false
      })

      .addCase(getStorageLaboratoryFile.pending, (state: PatientState) => {
        state.getStorageLaboratoryFileLoading = true
      })
      .addCase(getStorageLaboratoryFile.fulfilled, (state: PatientState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.storageLaboratoryFile = action.payload.data
        } else {
          state.storageLaboratoryFile = null
        }
        state.getStorageLaboratoryFileLoading = false
      })
      .addCase(getStorageLaboratoryFile.rejected, (state: PatientState) => {
        state.storageLaboratoryFile = null
        state.getStorageLaboratoryFileLoading = false
      })

      .addCase(getEnums.pending, (state: PatientState) => {
        state.getEnumsLoading = true
      })
      .addCase(getEnums.fulfilled, (state: PatientState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.enums = { ...state.enums, ...action.payload.data }
          state.getEnumsLoading = false
        } else {
          state.enums = {}
          state.getEnumsLoading = false
        }
      })
      .addCase(getEnums.rejected, (state: PatientState) => {
        state.enums = {}
        state.getEnumsLoading = false
      })
  },
})

// Extract the action creators object and the reducer
const { reducer } = patientsSlice
// Extract and export each action creator by name
// export const { clearNotifications } = actions
// Export the reducer, either as a default or named export
export default reducer
