import { ChevronLeftIcon, ChevronRightIcon, EyeOffIcon } from '@heroicons/react/outline'
import moment from 'moment'
import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import { TABLE_LIMITS } from '../../../enums'
import {
  ButtonBackgroundColorsEnum,
  ButtonSizesEnum,
  ButtonTextColorsEnum,
  ButtonTypesEnum,
  CallbackNotificationList,
  NotificationBaseModel,
  NotificationListInterface,
} from '../../../interfaces'
import { RootState, useAppDispatch } from '../../../store'
import {
  getNotifications,
  markNotifications,
  sendNotificationCallback,
} from '../../../store/modules/notification/actions'
import { clearNotifications } from '../../../store/modules/notification/reducer'
import Button from '../button/Button'
import SmallEmptyState from '../empty-state/SmallEmptyState'
import ContentLoader from '../loader/ContentLoader'

export const NotificationList = (props: NotificationListInterface) => {
  const { title, afterCallbackDispatch } = props
  const { t } = useTranslation(['errors', 'dashboard'])
  const { notifications, getNotificationsLoading } = useSelector((state: RootState) => state.notification)
  const dispatch = useAppDispatch()

  useEffect(() => {
    loadNotifications(TABLE_LIMITS.NOTIFICATIONS, 0)

    return () => {
      dispatch(clearNotifications())
    }
  }, [])

  const loadNotifications = (limit: number, offset = 0) => {
    dispatch(getNotifications({ limit, offset })).then(() => ReactTooltip.rebuild())
  }

  function getCallbacks(notification: NotificationBaseModel) {
    const data: CallbackNotificationList[] = Object.keys(notification.callbacks).map(i => {
      return {
        cbs: notification.callbacks[i],
        action: i,
      }
    })
    return data
  }

  const sendCallback = (action: string, id: string) => {
    dispatch(
      sendNotificationCallback({
        action,
        id,
      })
    ).then(() => {
      loadNotifications(TABLE_LIMITS.NOTIFICATIONS, 0)
      if (afterCallbackDispatch) afterCallbackDispatch()
    })
  }

  const markAsRead = (_id: string) => {
    dispatch(markNotifications({ ids: [_id], new_state: 'read' })).then(() => {
      loadNotifications(TABLE_LIMITS.NOTIFICATIONS, 0)
    })
  }

  const previousPageDisabled = () => {
    return notifications?.prev === notifications?.number
  }

  const nextPageDisabled = () => {
    return notifications?.next === notifications?.number
  }

  const isPagination = () => {
    if (notifications?.total_count) return notifications?.total_count > TABLE_LIMITS.NOTIFICATIONS

    return false
  }

  return (
    <div className="rounded-lg shadow">
      <div className="py-5 px-4 sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-brand">{title}</h3>
      </div>

      <div className="flex flex-col border-t border-gray-200">
        {getNotificationsLoading && <ContentLoader />}
        {notifications?.total_count === 0 && (
          <div className="py-4">
            <SmallEmptyState text={t('errors.empty')} />
          </div>
        )}

        {notifications?.current?.map((notification, key) => (
          <div key={key} className="flex justify-between py-4 px-4 sm:px-6 text-sm text-gray-500">
            <div>
              <span className="font-medium text-gray-900">{notification.text}</span>
              <span className="ml-4">{moment.utc(notification.time).format('DD.MM.YYYY HH:mm')}</span>
            </div>

            <div className="flex pl-4 space-x-3">
              {getCallbacks(notification).map((callback, key) => {
                return (
                  <small key={key}>
                    <div data-tip={callback.cbs.title} className="cursor-pointer">
                      <div
                        onClick={() => sendCallback(callback.action, notification.id)}
                        dangerouslySetInnerHTML={{ __html: callback.cbs.icon }}
                      />
                    </div>
                  </small>
                )
              })}
              <small>
                <div data-tip={t('dashboard:dashboard.notifications.hide')}>
                  <Button
                    type={ButtonTypesEnum.Button}
                    onClick={() => markAsRead(notification.id)}
                    backgroundColor={ButtonBackgroundColorsEnum.Transparent}
                    textColor={ButtonTextColorsEnum.Gray}
                    size={ButtonSizesEnum.small}
                    icon={EyeOffIcon}
                    className="p-0.5"
                  />
                </div>
              </small>
            </div>
          </div>
        ))}
        {isPagination() && (
          <div className="flex justify-between items-center py-4 px-6 bg-gray-50 border-t">
            <div className="flex sm:hidden flex-1 justify-between">
              <a
                href="#"
                className="inline-flex relative items-center py-2 px-4 text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 rounded-md border border-gray-300"
              >
                Previous
              </a>
              <a
                href="#"
                className="inline-flex relative items-center py-2 px-4 ml-3 text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 rounded-md border border-gray-300"
              >
                Next
              </a>
            </div>
            <div className="hidden sm:flex sm:flex-1 sm:justify-between sm:items-center">
              <div>
                {notifications && (
                  <p className="text-sm text-gray-700">
                    <Trans
                      i18nKey="table:table.pagination"
                      values={{
                        from: notifications?.number * TABLE_LIMITS.NOTIFICATIONS + 1,
                        to: notifications?.number * TABLE_LIMITS.NOTIFICATIONS + notifications?.size,
                        total: notifications?.total_count,
                      }}
                    />
                  </p>
                )}
              </div>
              <div>
                <nav className="inline-flex relative z-0 -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                  <button
                    onClick={() => loadNotifications(TABLE_LIMITS.NOTIFICATIONS, notifications?.prev)}
                    disabled={previousPageDisabled()}
                    className="inline-flex relative items-center py-2 px-2 text-sm font-medium text-gray-500 bg-white hover:bg-gray-50 rounded-l-md border border-gray-300"
                  >
                    <span className="sr-only">Previous</span>
                    <ChevronLeftIcon className="w-5 h-5" aria-hidden="true" />
                  </button>

                  <button
                    onClick={() => loadNotifications(TABLE_LIMITS.NOTIFICATIONS, notifications?.next)}
                    disabled={nextPageDisabled()}
                    className="inline-flex relative items-center py-2 px-2 text-sm font-medium text-gray-500 bg-white hover:bg-gray-50 rounded-r-md border border-gray-300"
                  >
                    <span className="sr-only">Next</span>
                    <ChevronRightIcon className="w-5 h-5" aria-hidden="true" />
                  </button>
                </nav>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default NotificationList
