import {
  BeakerIcon,
  ChartPieIcon,
  ChatIcon,
  ClockIcon,
  CogIcon,
  CreditCardIcon,
  FolderOpenIcon,
  HeartIcon,
  HomeIcon,
  NewspaperIcon,
  UserGroupIcon,
  UsersIcon,
  VideoCameraIcon,
} from '@heroicons/react/outline'
import { lazy } from 'react'
import { BREADCRUMBS_NAMES, NAVBAR_NAMES, ROLES, ROUTE_NAMES, TAB_NAMES } from '../enums'
import { NavbarPositionEnum, RouterInterface } from '../interfaces'
import ChatPage from '../views/app/chat/ChatPage'
import CustomMedicationsPage from '../views/app/custom-medications/CustomMedicationsPage'
import DashboardPage from '../views/app/dashboard/DashboardPage'
import DoctorsPage from '../views/app/doctors/DoctorsPage'
import OrdersPage from '../views/app/orders/OrdersPage'
import PatientsPage from '../views/app/patients/PatientsPage'
import SettingsPage from '../views/app/settings/SettingsPage'
import TelerehabilitationPage from '../views/app/telerehabilitation/TelerehabilitationPage'
import LoginPage from '../views/login/LoginPage'
import PasswordResetPage from '../views/password-reset/PasswordResetPage'
import RegisterAdditionalDataPage from '../views/register/additional-data/RegisterAdditionalDataPage'
import RegisterPage from '../views/register/RegisterPage'
import RoleSelectPage from '../views/role-select/RoleSelectPage'
import { ROUTES } from './routes'

// {
//   path: string,
//   title: string,
//   component: Component,
//   exact?: boolean = false,
//   private?: boolean = true,
//   breadcrumb: {
//     name: string,
//     isLink?: boolean = true,
//     show?: boolean  = true,
//   },
//   tab: {
//     name: string
//   },
//   routes: {
//     ...
//   }
// }

export const ROUTER: RouterInterface[] = [
  {
    path: ROUTES.LOGIN,
    title: ROUTE_NAMES.LOGIN,
    // component: lazy(() => import('../views/login/LoginPage')),
    component: LoginPage,
    exact: true,
    private: false,
  },
  {
    path: ROUTES.REGISTER,
    title: ROUTE_NAMES.REGISTER,
    // component: lazy(() => import('../views/register/RegisterPage')),
    component: RegisterPage,
    exact: true,
    private: false,
  },
  {
    path: ROUTES.REGISTER_ADDITIONAL_DATA,
    title: ROUTE_NAMES.REGISTER_ADDITIONAL_DATA,
    // component: lazy(() => import('../views/register/RegisterPage')),
    component: RegisterAdditionalDataPage,
    exact: true,
    private: true,
  },
  {
    path: ROUTES.PASSWORD_RESET_EMAIL,
    title: ROUTE_NAMES.PASSWORD_RESET_EMAIL,
    // component: lazy(() => import('../views/password-reset/PasswordResetPage')),
    component: PasswordResetPage,
    exact: false,
    private: false,
  },
  {
    path: ROUTES.ROLE_SELECT,
    title: ROUTE_NAMES.ROLE_SELECT,
    // component: lazy(() => import('../views/register/RegisterPage')),
    component: RoleSelectPage,
    exact: true,
    private: true,
  },
  {
    path: ROUTES.DASHBOARD,
    title: ROUTE_NAMES.DASHBOARD,
    // component: lazy(() => import('../views/app/dashboard/DashboardPage')),
    component: DashboardPage,
    exact: true,
    private: true,
    breadcrumb: {
      name: BREADCRUMBS_NAMES.DASHBOARD,
    },
    navbar: {
      name: NAVBAR_NAMES.DASHBOARD,
      icon: HomeIcon,
      role: [ROLES.DOCTOR, ROLES.ADMIN],
    },
  },
  {
    path: ROUTES.PATIENTS,
    title: ROUTE_NAMES.PATIENTS,
    component: PatientsPage,
    exact: false,
    private: true,
    breadcrumb: {
      name: BREADCRUMBS_NAMES.PATIENTS,
      redirect: ROUTES.PATIENTS_LIST,
      role: [ROLES.DOCTOR],
    },
    navbar: {
      name: NAVBAR_NAMES.PATIENTS,
      icon: UsersIcon,
      redirect: ROUTES.PATIENTS_LIST,
      role: [ROLES.DOCTOR, ROLES.ADMIN],
    },
    routes: [
      {
        path: ROUTES.PATIENTS_LIST,
        title: ROUTE_NAMES.PATIENTS_LIST,
        component: lazy(() => import('../views/app/patients/PatientsListPage')),
        exact: true,
        breadcrumb: {
          name: BREADCRUMBS_NAMES.PATIENTS_LIST,
          show: false,
        },
        role: [ROLES.DOCTOR, ROLES.ADMIN],
      },
      {
        path: ROUTES.PATIENTS_DETAIL,
        title: ROUTE_NAMES.PATIENTS_DETAIL,
        component: lazy(() => import('../views/app/patients/PatientsDetailPage')),
        breadcrumb: {
          name: BREADCRUMBS_NAMES.PATIENTS_DETAIL,
          redirect: ROUTES.PATIENTS_DETAIL_OVERVIEW,
        },
        routes: [
          {
            path: ROUTES.PATIENTS_DETAIL_OVERVIEW,
            title: ROUTE_NAMES.PATIENTS_DETAIL_OVERVIEW,
            component: lazy(() => import('../views/app/patients/PatientsDetailOverviewPage')),
            exact: true,
            breadcrumb: {
              name: BREADCRUMBS_NAMES.PATIENTS_DETAIL_OVERVIEW,
            },
            tab: {
              name: TAB_NAMES.PATIENTS_DETAIL_OVERVIEW,
            },
            navbar: {
              name: NAVBAR_NAMES.PATIENTS_DETAIL_OVERVIEW,
              icon: HomeIcon,
              role: [ROLES.PATIENT],
            },
          },
          {
            path: ROUTES.PATIENTS_DETAIL_ANAMNESIS,
            title: ROUTE_NAMES.PATIENTS_DETAIL_ANAMNESIS,
            component: lazy(() => import('../views/app/patients/anamnesis/PatientsDetailAnamnesisPage')),
            breadcrumb: {
              name: BREADCRUMBS_NAMES.PATIENTS_DETAIL_ANAMNESIS,
              isLink: false,
            },
            tab: {
              name: TAB_NAMES.PATIENTS_DETAIL_ANAMNESIS,
            },
            navbar: {
              name: NAVBAR_NAMES.PATIENTS_DETAIL_ANAMNESIS,
              icon: NewspaperIcon,
              role: [ROLES.PATIENT],
              redirect: ROUTES.PATIENTS_DETAIL_ANAMNESIS_PERSONAL,
            },
            routes: [
              {
                path: ROUTES.PATIENTS_DETAIL_ANAMNESIS_PERSONAL,
                title: ROUTE_NAMES.PATIENTS_DETAIL_ANAMNESIS_PERSONAL,
                component: lazy(() => import('../views/app/patients/anamnesis/PatientsDetailAnamnesisPersonalPage')),
                exact: true,
                breadcrumb: {
                  name: BREADCRUMBS_NAMES.PATIENTS_DETAIL_ANAMNESIS_PERSONAL,
                },
                tab: {
                  name: TAB_NAMES.PATIENTS_DETAIL_ANAMNESIS_PERSONAL,
                },
              },
              {
                path: ROUTES.PATIENTS_DETAIL_ANAMNESIS_MEDICATIONS,
                title: ROUTE_NAMES.PATIENTS_DETAIL_ANAMNESIS_MEDICATIONS,
                component: lazy(() => import('../views/app/patients/anamnesis/PatientsDetailAnamnesisMedicationsPage')),
                exact: true,
                breadcrumb: {
                  name: BREADCRUMBS_NAMES.PATIENTS_DETAIL_ANAMNESIS_MEDICATIONS,
                },
                tab: {
                  name: TAB_NAMES.PATIENTS_DETAIL_ANAMNESIS_MEDICATIONS,
                },
              },
              {
                path: ROUTES.PATIENTS_DETAIL_ANAMNESIS_ALLERGIES,
                title: ROUTE_NAMES.PATIENTS_DETAIL_ANAMNESIS_ALLERGIES,
                component: lazy(() => import('../views/app/patients/anamnesis/PatientsDetailAnamnesisAllergiesPage')),
                breadcrumb: {
                  name: BREADCRUMBS_NAMES.PATIENTS_DETAIL_ANAMNESIS_ALLERGIES,
                },
                tab: {
                  name: TAB_NAMES.PATIENTS_DETAIL_ANAMNESIS_ALLERGIES,
                },
              },
              {
                path: ROUTES.PATIENTS_DETAIL_ANAMNESIS_GYNAECOLOGY,
                title: ROUTE_NAMES.PATIENTS_DETAIL_ANAMNESIS_GYNAECOLOGY,
                component: lazy(() => import('../views/app/patients/anamnesis/PatientsDetailAnamnesisGynaecologyPage')),
                breadcrumb: {
                  name: BREADCRUMBS_NAMES.PATIENTS_DETAIL_ANAMNESIS_GYNAECOLOGY,
                },
                tab: {
                  name: TAB_NAMES.PATIENTS_DETAIL_ANAMNESIS_GYNAECOLOGY,
                },
              },
              {
                path: ROUTES.PATIENTS_DETAIL_ANAMNESIS_SURGERIES,
                title: ROUTE_NAMES.PATIENTS_DETAIL_ANAMNESIS_SURGERIES,
                component: lazy(() => import('../views/app/patients/anamnesis/PatientsDetailAnamnesisSurgeriesPage')),
                breadcrumb: {
                  name: BREADCRUMBS_NAMES.PATIENTS_DETAIL_ANAMNESIS_SURGERIES,
                },
                tab: {
                  name: TAB_NAMES.PATIENTS_DETAIL_ANAMNESIS_SURGERIES,
                },
              },
              {
                path: ROUTES.PATIENTS_DETAIL_ANAMNESIS_EPIDEMIOLOGY,
                title: ROUTE_NAMES.PATIENTS_DETAIL_ANAMNESIS_EPIDEMIOLOGY,
                component: lazy(
                  () => import('../views/app/patients/anamnesis/PatientsDetailAnamnesisEpidemiologyPage')
                ),
                breadcrumb: {
                  name: BREADCRUMBS_NAMES.PATIENTS_DETAIL_ANAMNESIS_EPIDEMIOLOGY,
                },
                tab: {
                  name: TAB_NAMES.PATIENTS_DETAIL_ANAMNESIS_EPIDEMIOLOGY,
                },
              },
              {
                path: ROUTES.PATIENTS_DETAIL_ANAMNESIS_ADDICTIVE_SUBSTANCES,
                title: ROUTE_NAMES.PATIENTS_DETAIL_ANAMNESIS_ADDICTIVE_SUBSTANCES,
                component: lazy(
                  () => import('../views/app/patients/anamnesis/PatientsDetailAnamnesisAddictiveSubstancesPage')
                ),
                breadcrumb: {
                  name: BREADCRUMBS_NAMES.PATIENTS_DETAIL_ANAMNESIS_ADDICTIVE_SUBSTANCES,
                },
                tab: {
                  name: TAB_NAMES.PATIENTS_DETAIL_ANAMNESIS_ADDICTIVE_SUBSTANCES,
                },
              },
              {
                path: ROUTES.PATIENTS_DETAIL_ANAMNESIS_FAMILY,
                title: ROUTE_NAMES.PATIENTS_DETAIL_ANAMNESIS_FAMILY_HISTORY,
                component: lazy(
                  () => import('../views/app/patients/anamnesis/PatientsDetailAnamnesisFamilyHistoryPage')
                ),
                breadcrumb: {
                  name: BREADCRUMBS_NAMES.PATIENTS_DETAIL_ANAMNESIS_FAMILY_HISTORY,
                },
                tab: {
                  name: TAB_NAMES.PATIENTS_DETAIL_ANAMNESIS_FAMILY_HISTORY,
                },
              },
              {
                path: ROUTES.PATIENTS_DETAIL_ANAMNESIS_WORK,
                title: ROUTE_NAMES.PATIENTS_DETAIL_ANAMNESIS_WORK_HISTORY,
                component: lazy(() => import('../views/app/patients/anamnesis/PatientsDetailAnamnesisWorkPage')),
                breadcrumb: {
                  name: BREADCRUMBS_NAMES.PATIENTS_DETAIL_ANAMNESIS_WORK_HISTORY,
                },
                tab: {
                  name: TAB_NAMES.PATIENTS_DETAIL_ANAMNESIS_WORK_HISTORY,
                },
              },
              {
                path: ROUTES.PATIENTS_DETAIL_ANAMNESIS_SOCIAL,
                title: ROUTE_NAMES.PATIENTS_DETAIL_ANAMNESIS_SOCIAL_HISTORY,
                component: lazy(() => import('../views/app/patients/anamnesis/PatientsDetailAnamnesisSocialPage')),
                breadcrumb: {
                  name: BREADCRUMBS_NAMES.PATIENTS_DETAIL_ANAMNESIS_SOCIAL_HISTORY,
                },
                tab: {
                  name: TAB_NAMES.PATIENTS_DETAIL_ANAMNESIS_SOCIAL_HISTORY,
                },
              },
              {
                path: ROUTES.PATIENTS_DETAIL_ANAMNESIS_SPORT,
                title: ROUTE_NAMES.PATIENTS_DETAIL_ANAMNESIS_SPORTS_HISTORY,
                component: lazy(() => import('../views/app/patients/anamnesis/PatientsDetailAnamnesisSportPage')),
                breadcrumb: {
                  name: BREADCRUMBS_NAMES.PATIENTS_DETAIL_ANAMNESIS_SPORTS_HISTORY,
                },
                tab: {
                  name: TAB_NAMES.PATIENTS_DETAIL_ANAMNESIS_SPORTS_HISTORY,
                },
              },
            ],
          },
          {
            path: ROUTES.PATIENTS_DETAIL_CHECKUPS,
            title: ROUTE_NAMES.PATIENTS_DETAIL_CHECKUPS,
            component: lazy(() => import('../views/app/patients/PatientsDetailCheckupsPage')),
            breadcrumb: {
              name: BREADCRUMBS_NAMES.PATIENTS_DETAIL_CHECKUPS,
            },
            tab: {
              name: TAB_NAMES.PATIENTS_DETAIL_CHECKUPS,
            },
            navbar: {
              name: NAVBAR_NAMES.PATIENTS_DETAIL_CHECKUPS,
              icon: ClockIcon,
              role: [ROLES.PATIENT],
            },
          },
          {
            path: ROUTES.PATIENTS_DETAIL_MEASUREMENTS,
            title: ROUTE_NAMES.PATIENTS_DETAIL_MEASUREMENTS,
            component: lazy(() => import('../views/app/patients/measurements/PatientsDetailMeasurementsPage')),
            breadcrumb: {
              name: BREADCRUMBS_NAMES.PATIENTS_DETAIL_MEASUREMENTS,
              isLink: false,
            },
            tab: {
              name: TAB_NAMES.PATIENTS_DETAIL_MEASUREMENTS,
            },
            navbar: {
              name: NAVBAR_NAMES.PATIENTS_DETAIL_MEASUREMENTS,
              icon: ChartPieIcon,
              role: [ROLES.PATIENT],
              redirect: ROUTES.PATIENTS_DETAIL_MEASUREMENTS_GLYCEMIA,
            },
            routes: [
              {
                path: ROUTES.PATIENTS_DETAIL_MEASUREMENTS_GLYCEMIA,
                title: ROUTE_NAMES.PATIENTS_DETAIL_MEASUREMENTS_GLYCEMIA,
                component: lazy(
                  () => import('../views/app/patients/measurements/PatientsDetailMeasurementsGlycemiaPage')
                ),
                breadcrumb: {
                  name: BREADCRUMBS_NAMES.PATIENTS_DETAIL_MEASUREMENTS_GLYCEMIA,
                },
                tab: {
                  name: TAB_NAMES.PATIENTS_DETAIL_MEASUREMENTS_GLYCEMIA,
                },
              },
              {
                path: ROUTES.PATIENTS_DETAIL_MEASUREMENTS_OXYGEN_SATURATION,
                title: ROUTE_NAMES.PATIENTS_DETAIL_MEASUREMENTS_OXYGEN_SATURATION,
                component: lazy(
                  () => import('../views/app/patients/measurements/PatientsDetailMeasurementsOxygenSaturationPage')
                ),
                breadcrumb: {
                  name: BREADCRUMBS_NAMES.PATIENTS_DETAIL_MEASUREMENTS_OXYGEN_SATURATION,
                },
                tab: {
                  name: TAB_NAMES.PATIENTS_DETAIL_MEASUREMENTS_OXYGEN_SATURATION,
                },
              },
              {
                path: ROUTES.PATIENTS_DETAIL_MEASUREMENTS_ECG,
                title: ROUTE_NAMES.PATIENTS_DETAIL_MEASUREMENTS_ECG,
                component: lazy(() => import('../views/app/patients/measurements/PatientsDetailMeasurementsECGPage')),
                breadcrumb: {
                  name: BREADCRUMBS_NAMES.PATIENTS_DETAIL_MEASUREMENTS_ECG,
                },
                tab: {
                  name: TAB_NAMES.PATIENTS_DETAIL_MEASUREMENTS_ECG,
                },
              },
              {
                path: ROUTES.PATIENTS_DETAIL_MEASUREMENTS_BPM,
                title: ROUTE_NAMES.PATIENTS_DETAIL_MEASUREMENTS_BPM,
                component: lazy(() => import('../views/app/patients/measurements/PatientsDetailMeasurementsBPMPage')),
                breadcrumb: {
                  name: BREADCRUMBS_NAMES.PATIENTS_DETAIL_MEASUREMENTS_BPM,
                },
                tab: {
                  name: TAB_NAMES.PATIENTS_DETAIL_MEASUREMENTS_BPM,
                },
              },
              {
                path: ROUTES.PATIENTS_DETAIL_MEASUREMENTS_SLEEP,
                title: ROUTE_NAMES.PATIENTS_DETAIL_MEASUREMENTS_SLEEP,
                component: lazy(() => import('../views/app/patients/measurements/PatientsDetailMeasurementsSleepPage')),
                breadcrumb: {
                  name: BREADCRUMBS_NAMES.PATIENTS_DETAIL_MEASUREMENTS_SLEEP,
                },
                tab: {
                  name: TAB_NAMES.PATIENTS_DETAIL_MEASUREMENTS_SLEEP,
                },
              },
              {
                path: ROUTES.PATIENTS_DETAIL_MEASUREMENTS_AIR_POLUTION,
                title: ROUTE_NAMES.PATIENTS_DETAIL_MEASUREMENTS_AIR_POLUTION,
                component: lazy(
                  () => import('../views/app/patients/measurements/PatientsDetailMeasurementsAirPolutionPage')
                ),
                breadcrumb: {
                  name: BREADCRUMBS_NAMES.PATIENTS_DETAIL_MEASUREMENTS_AIR_POLUTION,
                },
                tab: {
                  name: TAB_NAMES.PATIENTS_DETAIL_MEASUREMENTS_AIR_POLUTION,
                },
              },
              {
                path: ROUTES.PATIENTS_DETAIL_MEASUREMENTS_ENDOSCOPY,
                title: ROUTE_NAMES.PATIENTS_DETAIL_MEASUREMENTS_ENDOSCOPY,
                component: lazy(
                  () => import('../views/app/patients/measurements/PatientsDetailMeasurementsEndoscopyPage')
                ),
                breadcrumb: {
                  name: BREADCRUMBS_NAMES.PATIENTS_DETAIL_MEASUREMENTS_ENDOSCOPY,
                },
                tab: {
                  name: TAB_NAMES.PATIENTS_DETAIL_MEASUREMENTS_ENDOSCOPY,
                },
              },
              {
                path: ROUTES.PATIENTS_DETAIL_MEASUREMENTS_TEMPERATURE,
                title: ROUTE_NAMES.PATIENTS_DETAIL_MEASUREMENTS_TEMPERATURE,
                component: lazy(
                  () => import('../views/app/patients/measurements/PatientsDetailMeasurementsTemperaturePage')
                ),
                breadcrumb: {
                  name: BREADCRUMBS_NAMES.PATIENTS_DETAIL_MEASUREMENTS_TEMPERATURE,
                },
                tab: {
                  name: TAB_NAMES.PATIENTS_DETAIL_MEASUREMENTS_TEMPERATURE,
                },
              },
              {
                path: ROUTES.PATIENTS_DETAIL_MEASUREMENTS_WEIGHT,
                title: ROUTE_NAMES.PATIENTS_DETAIL_MEASUREMENTS_WEIGHT,
                component: lazy(
                  () => import('../views/app/patients/measurements/PatientsDetailMeasurementsWeightPage')
                ),
                breadcrumb: {
                  name: BREADCRUMBS_NAMES.PATIENTS_DETAIL_MEASUREMENTS_WEIGHT,
                },
                tab: {
                  name: TAB_NAMES.PATIENTS_DETAIL_MEASUREMENTS_WEIGHT,
                },
              },
              {
                path: ROUTES.PATIENTS_DETAIL_MEASUREMENTS_HEIGHT,
                title: ROUTE_NAMES.PATIENTS_DETAIL_MEASUREMENTS_HEIGHT,
                component: lazy(
                  () => import('../views/app/patients/measurements/PatientsDetailMeasurementsHeightPage')
                ),
                breadcrumb: {
                  name: BREADCRUMBS_NAMES.PATIENTS_DETAIL_MEASUREMENTS_HEIGHT,
                },
                tab: {
                  name: TAB_NAMES.PATIENTS_DETAIL_MEASUREMENTS_HEIGHT,
                },
              },
              {
                path: ROUTES.PATIENTS_DETAIL_MEASUREMENTS_CHOLESTEROL,
                title: ROUTE_NAMES.PATIENTS_DETAIL_MEASUREMENTS_CHOLESTEROL,
                component: lazy(
                  () => import('../views/app/patients/measurements/PatientsDetailMeasurementsCholesterolPage')
                ),
                breadcrumb: {
                  name: BREADCRUMBS_NAMES.PATIENTS_DETAIL_MEASUREMENTS_CHOLESTEROL,
                },
                tab: {
                  name: TAB_NAMES.PATIENTS_DETAIL_MEASUREMENTS_CHOLESTEROL,
                },
              },
              {
                path: ROUTES.PATIENTS_DETAIL_MEASUREMENTS_PRESSURE,
                title: ROUTE_NAMES.PATIENTS_DETAIL_MEASUREMENTS_PRESSURE,
                component: lazy(
                  () => import('../views/app/patients/measurements/PatientsDetailMeasurementsPressurePage')
                ),
                breadcrumb: {
                  name: BREADCRUMBS_NAMES.PATIENTS_DETAIL_MEASUREMENTS_PRESSURE,
                },
                tab: {
                  name: TAB_NAMES.PATIENTS_DETAIL_MEASUREMENTS_PRESSURE,
                },
              },
            ],
          },
          {
            path: ROUTES.PATIENTS_DETAIL_DOCTORS,
            title: ROUTE_NAMES.PATIENTS_DETAIL_DOCTORS,
            component: lazy(() => import('../views/app/patients/PatientsDetailDoctorsPage')),
            // component: PatientsDetailDoctorsPage,
            breadcrumb: {
              name: BREADCRUMBS_NAMES.PATIENTS_DETAIL_DOCTORS,
            },
            tab: {
              name: TAB_NAMES.PATIENTS_DETAIL_DOCTORS,
            },
            navbar: {
              name: NAVBAR_NAMES.PATIENTS_DETAIL_DOCTORS,
              icon: UsersIcon,
              role: [ROLES.PATIENT],
            },
          },
          {
            path: ROUTES.PATIENTS_DETAIL_FAMILY,
            title: ROUTE_NAMES.PATIENTS_DETAIL_FAMILY,
            component: lazy(() => import('../views/app/patients/PatientsDetailFamilyPage')),
            exact: true,
            private: true,
            breadcrumb: {
              name: BREADCRUMBS_NAMES.PATIENTS_DETAIL_FAMILY,
            },
            navbar: {
              name: NAVBAR_NAMES.PATIENTS_DETAIL_FAMILY,
              icon: HeartIcon,
              role: [ROLES.PATIENT],
            },
            tab: {
              name: TAB_NAMES.PATIENTS_DETAIL_FAMILY,
            },
          },
          {
            path: ROUTES.PATIENTS_DETAIL_STORAGE,
            title: ROUTE_NAMES.PATIENTS_DETAIL_STORAGE,
            component: lazy(() => import('../views/app/patients/storage/PatientsDetailStoragePage')),
            breadcrumb: {
              name: BREADCRUMBS_NAMES.PATIENTS_DETAIL_STORAGE,
            },
            tab: {
              name: TAB_NAMES.PATIENTS_DETAIL_STORAGE,
            },
            navbar: {
              name: NAVBAR_NAMES.PATIENTS_DETAIL_STORAGE,
              icon: FolderOpenIcon,
              role: [ROLES.PATIENT],
              redirect: ROUTES.PATIENTS_DETAIL_STORAGE_ATTACHMENTS,
            },
            routes: [
              {
                path: ROUTES.PATIENTS_DETAIL_STORAGE_LABORATORY,
                title: ROUTE_NAMES.PATIENTS_DETAIL_STORAGE_LABORATORY,
                component: lazy(() => import('../views/app/patients/storage/PatientsDetailStorageLaboratoryPage')),
                breadcrumb: {
                  name: BREADCRUMBS_NAMES.PATIENTS_DETAIL_STORAGE_LABORATORY,
                },
                tab: {
                  name: TAB_NAMES.PATIENTS_DETAIL_STORAGE_LABORATORY,
                },
              },
              {
                path: ROUTES.PATIENTS_DETAIL_STORAGE_RADIOLOGY,
                title: ROUTE_NAMES.PATIENTS_DETAIL_STORAGE_RADIOLOGY,
                component: lazy(() => import('../views/app/patients/storage/PatientsDetailStorageRadiologyPage')),
                breadcrumb: {
                  name: BREADCRUMBS_NAMES.PATIENTS_DETAIL_STORAGE_RADIOLOGY,
                },
                tab: {
                  name: TAB_NAMES.PATIENTS_DETAIL_STORAGE_RADIOLOGY,
                },
              },
              {
                path: ROUTES.PATIENTS_DETAIL_STORAGE_ATTACHMENTS,
                title: ROUTE_NAMES.PATIENTS_DETAIL_STORAGE_ATTACHMENTS,
                component: lazy(() => import('../views/app/patients/storage/PatientsDetailStorageAttachmentsPage')),
                breadcrumb: {
                  name: BREADCRUMBS_NAMES.PATIENTS_DETAIL_STORAGE_ATTACHMENTS,
                },
                tab: {
                  name: TAB_NAMES.PATIENTS_DETAIL_STORAGE_ATTACHMENTS,
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: ROUTES.DOCTORS,
    title: ROUTE_NAMES.DOCTORS,
    component: DoctorsPage,
    exact: false,
    private: true,
    navbar: {
      name: NAVBAR_NAMES.DOCTORS,
      icon: UserGroupIcon,
      role: [ROLES.DOCTOR, ROLES.ADMIN],
      redirect: ROUTES.DOCTORS_LIST,
    },
    routes: [
      {
        path: ROUTES.DOCTORS_LIST,
        title: ROUTE_NAMES.DOCTORS_LIST,
        component: lazy(() => import('../views/app/doctors/DoctorsListPage')),
        exact: true,
        private: true,
        role: [ROLES.ADMIN, ROLES.DOCTOR],
      },
      {
        path: ROUTES.DOCTORS_DETAIL,
        title: ROUTE_NAMES.DOCTORS_DETAIL,
        component: lazy(() => import('../views/app/doctors/DoctorsDetailPage')),
        exact: true,
        private: true,
        role: [ROLES.ADMIN],
      },
    ],
  },
  {
    path: ROUTES.CHAT,
    title: ROUTE_NAMES.CHAT,
    component: ChatPage,
    exact: false,
    private: true,
    navbar: {
      name: NAVBAR_NAMES.CHAT,
      icon: ChatIcon,
      badge: 'store.chat.subscriptions',
    },
    routes: [
      {
        path: ROUTES.CHAT_DM,
        title: ROUTE_NAMES.CHAT,
        component: lazy(() => import('../views/app/chat/ChatDmPage')),
        exact: true,
        private: true,
      },
      {
        path: ROUTES.CHAT_GROUP,
        title: ROUTE_NAMES.CHAT,
        component: lazy(() => import('../views/app/chat/ChatGroupPage')),
        exact: true,
        private: true,
      },
    ],
  },
  {
    path: ROUTES.TELEREHABILITATION,
    title: ROUTE_NAMES.TELEREHABILITATION,
    component: TelerehabilitationPage,
    // component: lazy(() => import('../views/app/chat/ChatPage')),
    exact: false,
    private: true,
    navbar: {
      name: NAVBAR_NAMES.TELEREHABILITATION,
      icon: VideoCameraIcon,
      redirect: {
        [ROLES.ADMIN]: ROUTES.TELEREHABILITATION_PRESETS,
        [ROLES.PATIENT]: ROUTES.TELEREHABILITATION_PATIENT,
      },
      role: [ROLES.PATIENT, ROLES.ADMIN],
    },
    breadcrumb: {
      name: BREADCRUMBS_NAMES.TELEREHABILITATION,
      redirect: {
        [ROLES.ADMIN]: ROUTES.TELEREHABILITATION_PRESETS,
        [ROLES.PATIENT]: ROUTES.TELEREHABILITATION_PATIENT,
      },
    },
    routes: [
      {
        path: ROUTES.TELEREHABILITATION_PRESETS,
        title: ROUTE_NAMES.TELEREHABILITATION_PRESETS,
        component: lazy(() => import('../views/app/telerehabilitation/TelerehabilitationPresetsPage')),
        exact: true,
        private: true,
        breadcrumb: {
          name: BREADCRUMBS_NAMES.TELEREHABILITATION_PRESETS,
        },
        tab: {
          name: TAB_NAMES.TELEREHABILITATION_PRESETS,
        },
        role: [ROLES.ADMIN],
      },
      {
        path: ROUTES.TELEREHABILITATION_CHARTS,
        title: ROUTE_NAMES.TELEREHABILITATION_CHARTS,
        component: lazy(() => import('../views/app/telerehabilitation/TelerehabilitationChartsPage')),
        exact: true,
        private: true,
        breadcrumb: {
          name: BREADCRUMBS_NAMES.TELEREHABILITATION_CHARTS,
        },
        tab: {
          name: TAB_NAMES.TELEREHABILITATION_CHARTS,
        },
        role: [ROLES.ADMIN],
      },
      {
        path: ROUTES.TELEREHABILITATION_PATIENT,
        title: ROUTE_NAMES.TELEREHABILITATION_PATIENT,
        component: lazy(() => import('../views/app/telerehabilitation/TelerehabilitationPatientPage')),
        exact: true,
        private: true,
        breadcrumb: {
          name: BREADCRUMBS_NAMES.TELEREHABILITATION_PATIENT,
        },
      },
      {
        path: ROUTES.TELEREHABILITATION_PATIENT_DAY,
        title: ROUTE_NAMES.TELEREHABILITATION_PATIENT_DAY,
        component: lazy(() => import('../views/app/telerehabilitation/TelerehabilitationPatientDayPage')),
        exact: true,
        private: true,
        breadcrumb: {
          name: BREADCRUMBS_NAMES.TELEREHABILITATION_PATIENT_DAY,
          isLink: false,
          role: [ROLES.PATIENT, ROLES.DOCTOR, ROLES.ADMIN],
        },
        role: [ROLES.PATIENT],
      },
      {
        path: ROUTES.TELEREHABILITATION_PATIENT_EXERCISE,
        title: ROUTE_NAMES.TELEREHABILITATION_PATIENT_EXERCISE,
        component: lazy(() => import('../views/app/telerehabilitation/TelerehabilitationPatientExercisePage')),
        exact: true,
        private: true,
        breadcrumb: {
          name: BREADCRUMBS_NAMES.TELEREHABILITATION_PATIENT_EXERCISE,
        },
      },
    ],
  },
  {
    path: ROUTES.ORDERS,
    title: ROUTE_NAMES.ORDERS,
    component: OrdersPage,
    exact: false,
    private: true,
    breadcrumb: {
      name: BREADCRUMBS_NAMES.ORDERS,
      redirect: {
        [ROLES.PATIENT]: ROUTES.ORDERS_PATIENT_LIST,
        [ROLES.ADMIN]: ROUTES.ORDERS_ADMINISTRATION_SERVICE_LIST,
      },
    },
    navbar: {
      name: NAVBAR_NAMES.ORDERS,
      icon: CreditCardIcon,
      role: [ROLES.PATIENT, ROLES.ADMIN],
      redirect: {
        [ROLES.PATIENT]: ROUTES.ORDERS_PATIENT_LIST,
        [ROLES.ADMIN]: ROUTES.ORDERS_ADMINISTRATION_SERVICE_LIST,
      },
    },
    routes: [
      {
        path: ROUTES.ORDERS_ADMINISTRATION,
        title: ROUTE_NAMES.ORDERS_ADMINISTRATION,
        component: lazy(() => import('../views/app/orders/administration/OrdersAdministrationPage')),
        exact: false,
        private: true,
        breadcrumb: {
          name: BREADCRUMBS_NAMES.ORDERS_ADMINISTRATION,
          isLink: false,
        },
        role: [ROLES.ADMIN],
        routes: [
          {
            path: ROUTES.ORDERS_ADMINISTRATION_HISTORY,
            title: ROUTE_NAMES.ORDERS_ADMINISTRATION_HISTORY,
            component: lazy(() => import('../views/app/orders/administration/OrdersAdministrationHistoryPage')),
            exact: true,
            private: true,
            breadcrumb: {
              name: BREADCRUMBS_NAMES.ORDERS_ADMINISTRATION_HISTORY,
            },
            tab: {
              name: TAB_NAMES.ORDERS_ADMINISTRATION_HISTORY,
            },
          },
          {
            path: ROUTES.ORDERS_ADMINISTRATION_SERVICE_LIST,
            title: ROUTE_NAMES.ORDERS_ADMINISTRATION_SERVICE_LIST,
            component: lazy(() => import('../views/app/orders/administration/OrdersAdministrationServiceListPage')),
            exact: true,
            private: true,
            breadcrumb: {
              name: BREADCRUMBS_NAMES.ORDERS_ADMINISTRATION_SERVICE_LIST,
            },
            tab: {
              name: TAB_NAMES.ORDERS_ADMINISTRATION_SERVICE_LIST,
            },
          },
          {
            path: ROUTES.ORDERS_ADMINISTRATION_CATEGORY,
            title: ROUTE_NAMES.ORDERS_ADMINISTRATION_CATEGORY,
            component: lazy(() => import('../views/app/orders/administration/OrdersAdministrationCategoryPage')),
            exact: false,
            private: true,
            tab: {
              name: TAB_NAMES.ORDERS_ADMINISTRATION_CATEGORY,
              redirect: ROUTES.ORDERS_ADMINISTRATION_CATEGORY_LIST,
            },
            routes: [
              {
                path: ROUTES.ORDERS_ADMINISTRATION_CATEGORY_LIST,
                title: ROUTE_NAMES.ORDERS_ADMINISTRATION_CATEGORY_LIST,
                component: lazy(
                  () => import('../views/app/orders/administration/OrdersAdministrationCategoryListPage')
                ),
                exact: true,
                private: true,
                breadcrumb: {
                  name: BREADCRUMBS_NAMES.ORDERS_ADMINISTRATION_CATEGORY_LIST,
                },
              },
              {
                path: ROUTES.ORDERS_ADMINISTRATION_CATEGORY_ACCESSORY_LIST,
                title: ROUTE_NAMES.ORDERS_ADMINISTRATION_CATEGORY_ACCESSORY_LIST,
                component: lazy(
                  () => import('../views/app/orders/administration/OrdersAdministrationCategoryAccessoriesList')
                ),
                exact: true,
                private: true,
                breadcrumb: {
                  name: BREADCRUMBS_NAMES.ORDERS_ADMINISTRATION_CATEGORY_ACCESSORY_LIST,
                },
              },
            ],
          },
        ],
      },
      {
        path: ROUTES.ORDERS_PATIENT,
        title: ROUTE_NAMES.ORDERS_PATIENT,
        component: lazy(() => import('../views/app/orders/patient/OrdersPatientPage')),
        exact: false,
        private: true,
        breadcrumb: {
          name: BREADCRUMBS_NAMES.ORDERS_PATIENT,
          isLink: false,
        },
        routes: [
          {
            path: ROUTES.ORDERS_PATIENT_HISTORY,
            title: ROUTE_NAMES.ORDERS_PATIENT_HISTORY,
            component: lazy(() => import('../views/app/orders/patient/OrdersPatientHistoryPage')),
            exact: true,
            private: true,
            breadcrumb: {
              name: BREADCRUMBS_NAMES.ORDERS_PATIENT_HISTORY,
            },
          },
          {
            path: ROUTES.ORDERS_PATIENT_LIST,
            title: ROUTE_NAMES.ORDERS_PATIENT_LIST,
            component: lazy(() => import('../views/app/orders/patient/OrdersPatientServiceListPage')),
            exact: true,
            private: true,
            breadcrumb: {
              name: BREADCRUMBS_NAMES.ORDERS_PATIENT_LIST,
            },
          },
          {
            path: ROUTES.ORDERS_PATIENT_CHECKOUT,
            title: ROUTE_NAMES.ORDERS_PATIENT_CHECKOUT,
            component: lazy(() => import('../views/app/orders/patient/OrdersPatientCheckoutPage')),
            exact: true,
            private: true,
          },
          {
            path: ROUTES.ORDERS_PATIENT_DETAIL,
            title: ROUTE_NAMES.ORDERS_PATIENT_DETAIL,
            component: lazy(() => import('../views/app/orders/patient/OrdersPatientDetailPage')),
            exact: true,
            private: true,
            breadcrumb: {
              name: BREADCRUMBS_NAMES.ORDERS_PATIENT_DETAIL,
            },
          },
        ],
      },
    ],
  },
  {
    path: ROUTES.CUSTOM_MEDICATIONS,
    title: ROUTE_NAMES.CUSTOM_MEDICATIONS,
    component: CustomMedicationsPage,
    exact: false,
    private: true,
    breadcrumb: {
      name: BREADCRUMBS_NAMES.CUSTOM_MEDICATIONS,
      redirect: ROUTES.CUSTOM_MEDICATIONS,
    },
    navbar: {
      name: NAVBAR_NAMES.CUSTOM_MEDICATIONS,
      icon: BeakerIcon,
      redirect: ROUTES.CUSTOM_MEDICATIONS,
    },
    role: [ROLES.DOCTOR],
  },
  {
    path: ROUTES.SETTINGS,
    title: ROUTE_NAMES.SETTINGS,
    // component: lazy(() => import('../views/app/settings/SettingsPage')),
    component: SettingsPage,
    exact: false,
    private: true,
    breadcrumb: {
      name: BREADCRUMBS_NAMES.SETTINGS,
      redirect: {
        [ROLES.PATIENT]: ROUTES.SETTINGS_PROFILE,
        [ROLES.DOCTOR]: ROUTES.SETTINGS_PASSWORD,
        [ROLES.ADMIN]: ROUTES.SETTINGS_PASSWORD,
      },
    },
    navbar: {
      name: NAVBAR_NAMES.SETTINGS,
      icon: CogIcon,
      position: NavbarPositionEnum.Bottom,
      redirect: {
        [ROLES.PATIENT]: ROUTES.SETTINGS_PROFILE,
        [ROLES.DOCTOR]: ROUTES.SETTINGS_PASSWORD,
        [ROLES.ADMIN]: ROUTES.SETTINGS_PASSWORD,
      },
    },
    routes: [
      {
        path: ROUTES.SETTINGS_PROFILE,
        title: ROUTE_NAMES.SETTINGS_PROFILE,
        component: lazy(() => import('../views/app/settings/SettingsProfilePage')),
        exact: false,
        private: true,
        breadcrumb: {
          name: BREADCRUMBS_NAMES.SETTINGS_PROFILE,
        },
        tab: {
          name: TAB_NAMES.SETTINGS_PROFILE,
        },
        role: [ROLES.PATIENT],
      },
      {
        path: ROUTES.SETTINGS_PASSWORD,
        title: ROUTE_NAMES.SETTINGS_PASSWORD,
        component: lazy(() => import('../views/app/settings/SettingsPasswordPage')),
        exact: false,
        private: true,
        breadcrumb: {
          name: BREADCRUMBS_NAMES.SETTINGS_PASSWORD,
        },
        tab: {
          name: TAB_NAMES.SETTINGS_PASSWORD,
        },
      },
      {
        path: ROUTES.SETTINGS_NOTIFICATIONS,
        title: ROUTE_NAMES.SETTINGS_NOTIFICATIONS,
        component: lazy(() => import('../views/app/settings/SettingsNotificationPage')),
        exact: false,
        private: true,
        breadcrumb: {
          name: BREADCRUMBS_NAMES.SETTINGS_NOTIFICATIONS,
        },
        tab: {
          name: TAB_NAMES.SETTINGS_NOTIFICATIONS,
        },
      },
      {
        path: ROUTES.SETTINGS_APPS,
        title: ROUTE_NAMES.SETTINGS_APPS,
        component: lazy(() => import('../views/app/settings/SettingsAppsPage')),
        exact: false,
        private: true,
        breadcrumb: {
          name: BREADCRUMBS_NAMES.SETTINGS_APPS,
        },
        tab: {
          name: TAB_NAMES.SETTINGS_APPS,
        },
        role: [ROLES.PATIENT],
      },
    ],
  },
  {
    path: ROUTES.JITSI,
    title: ROUTE_NAMES.JITSI,
    component: lazy(() => import('../views/app/jitsi/JitsiPage')),
    exact: true,
    private: true,
  },
  {
    path: ROUTES.TELEREHABILITATION_PATIENT_EXERCISE_UPLOAD,
    title: ROUTE_NAMES.TELEREHABILITATION_PATIENT_EXERCISE_UPLOAD,
    component: lazy(() => import('../views/app/telerehabilitation/TelerehabilitationPatientDayUploadPage')),
    exact: true,
    private: false,
  },
  {
    path: ROUTES.ERROR_403,
    title: ROUTE_NAMES.ERROR_403,
    component: lazy(() => import('../views/error/Error403')),
    exact: true,
    private: true,
  },
  {
    path: ROUTES.ERROR_404,
    title: ROUTE_NAMES.ERROR_404,
    component: lazy(() => import('../views/error/Error404')),
    exact: true,
    private: true,
  },
]

export default ROUTER
