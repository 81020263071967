import { PARAMS } from '../../enums'
import { AUTH } from '../../store/modules/auth/types'
import { PATIENTS } from '../../store/modules/patients/types'

export default {
  getPatients: {
    id: PATIENTS.ACTIONS.GET_PATIENTS,
    request: (data: any) => ({
      url: `/v1/patients`,
      method: 'GET',
      params: {
        limit: data?.limit,
        offset: data?.offset,
        search: data?.search,
      },
    }),
  },
  getDoctorsPatients: {
    id: PATIENTS.ACTIONS.GET_DOCTORS_PATIENTS,
    request: (data: any) => ({
      url: `/v1/doctors/doctor/${data[PARAMS.DOCTOR_ID]}/patients`,
      method: 'GET',
      params: {
        limit: data?.limit,
        offset: data?.offset,
        search: data?.search,
      },
    }),
  },
  getPatient: {
    id: PATIENTS.ACTIONS.GET_PATIENT,
    request: (data: any) => ({
      url: `/v1/patient/${data[PARAMS.PATIENT_ID]}`,
      method: 'GET',
      data,
    }),
  },
  deletePatient: {
    id: PATIENTS.ACTIONS.DELETE_PATIENT,
    request: (data: any) => ({
      url: `/v1/patients/${data[PARAMS.PATIENT_ID]}`,
      method: 'DELETE',
      data,
    }),
  },
  getPatientByPIN: {
    id: PATIENTS.ACTIONS.GET_PATIENT_BY_PIN,
    request: (data: any) => ({
      url: `/v1/patients/search`,
      method: 'GET',
      params: {
        search: data.search,
      },
    }),
  },

  getFamily: {
    id: PATIENTS.ACTIONS.GET_FAMILY,
    request: (data: any) => ({
      url: `/v1/family-members/${data[PARAMS.PATIENT_ID]}`,
      method: 'GET',
      data,
    }),
  },
  createFamilyMember: {
    id: PATIENTS.ACTIONS.CREATE_FAMILY_MEMBER,
    request: (data: any) => ({
      url: `/v1/family-members/new`,
      method: 'POST',
      data,
    }),
  },
  deleteFamilyMember: {
    id: PATIENTS.ACTIONS.DELETE_FAMILY_MEMBER,
    request: (data: any) => ({
      url: `/v1/family-members/${data[PARAMS.FAMILY_MEMBER_ID]}`,
      method: 'DELETE',
      data,
    }),
  },

  getPatientsRequestAccess: {
    id: PATIENTS.ACTIONS.GET_PATIENTS_REQUEST_ACCESS,
    request: (data: any) => ({
      url: `/v1/patients/request-access`,
      method: 'POST',
      data,
    }),
  },
  getAnamnesisPersonal: {
    id: PATIENTS.ACTIONS.GET_ANAMNESIS_PERSONAL,
    request: (data: any) => ({
      url: `/v1/anamnesis/${data[PARAMS.PATIENT_ID]}/personal`,
      method: 'GET',
      data,
    }),
  },
  editAnamnesisPersonal: {
    id: PATIENTS.ACTIONS.EDIT_ANAMNESIS_PERSONAL,
    request: (data: any) => ({
      url: `/v1/anamnesis/${data[PARAMS.PATIENT_ID]}/personal`,
      method: 'PUT',
      data,
    }),
  },
  getAnamnesisAllergies: {
    id: PATIENTS.ACTIONS.GET_ANAMNESIS_PERSONAL,
    request: (data: any) => ({
      url: `/v1/anamnesis/${data[PARAMS.PATIENT_ID]}/allergies`,
      method: 'GET',
      data,
    }),
  },
  editAnamnesisAllergies: {
    id: PATIENTS.ACTIONS.EDIT_ANAMNESIS_PERSONAL,
    request: (data: any) => ({
      url: `/v1/anamnesis/${data[PARAMS.PATIENT_ID]}/allergies`,
      method: 'PUT',
      data,
    }),
  },
  getAnamnesisMedications: {
    id: PATIENTS.ACTIONS.GET_ANAMNESIS_MEDICATIONS,
    request: (data: any) => ({
      url: `/v1/anamnesis/${data[PARAMS.PATIENT_ID]}/medications`,
      method: 'GET',
      params: {
        limit: data?.limit,
        offset: data?.offset,
        search: data?.search,
      },
    }),
  },
  getAnamnesisMedication: {
    id: PATIENTS.ACTIONS.GET_ANAMNESIS_MEDICATION,
    request: (data: any) => ({
      url: `/v1/anamnesis/${data[PARAMS.PATIENT_ID]}/medications/${data[PARAMS.MEDICATION_ID]}`,
      method: 'GET',
      data,
    }),
  },
  createAnamnesisMedication: {
    id: PATIENTS.ACTIONS.CREATE_ANAMNESIS_MEDICATION,
    request: (data: any) => ({
      url: `/v1/anamnesis/${data[PARAMS.PATIENT_ID]}/medications`,
      method: 'POST',
      data,
    }),
  },
  editAnamnesisMedication: {
    id: PATIENTS.ACTIONS.EDIT_ANAMNESIS_MEDICATION,
    request: (data: any) => ({
      url: `/v1/anamnesis/${data[PARAMS.PATIENT_ID]}/medications/${data[PARAMS.MEDICATION_ID]}`,
      method: 'PUT',
      data,
    }),
  },
  deleteAnamnesisMedication: {
    id: PATIENTS.ACTIONS.DELETE_ANAMNESIS_MEDICATION,
    request: (data: any) => ({
      url: `/v1/anamnesis/${data[PARAMS.PATIENT_ID]}/medications/${data[PARAMS.MEDICATION_ID]}`,
      method: 'DELETE',
      data,
    }),
  },
  getAnamnesisGynaecology: {
    id: PATIENTS.ACTIONS.GET_ANAMNESIS_GYNAECOLOGY,
    request: (data: any) => ({
      url: `/v1/anamnesis/${data[PARAMS.PATIENT_ID]}/gynaecology`,
      method: 'GET',
      data,
    }),
  },
  getAnamnesisGynaecologyPregnancies: {
    id: PATIENTS.ACTIONS.GET_ANAMNESIS_GYNAECOLOGY_PREGNANCIES,
    request: (data: any) => ({
      url: `/v1/anamnesis/${data[PARAMS.PATIENT_ID]}/gynaecology/pregnancies`,
      method: 'GET',
      params: {
        limit: data.limit,
        offset: data.offset,
      },
    }),
  },
  getAnamnesisGynaecologyPregnancy: {
    id: PATIENTS.ACTIONS.GET_ANAMNESIS_GYNAECOLOGY_PREGNANCY,
    request: (data: any) => ({
      url: `/v1/anamnesis/${data[PARAMS.PATIENT_ID]}/gynaecology/pregnancies/${data[PARAMS.PREGNANCY_ID]}`,
      method: 'GET',
      data,
    }),
  },
  createAnamnesisGynaecologyPregnancy: {
    id: PATIENTS.ACTIONS.CREATE_ANAMNESIS_GYNAECOLOGY_PREGNANCY,
    request: (data: any) => ({
      url: `/v1/anamnesis/${data[PARAMS.PATIENT_ID]}/gynaecology/pregnancies`,
      method: 'POST',
      data,
    }),
  },
  editAnamnesisGynaecologyPregnancy: {
    id: PATIENTS.ACTIONS.EDIT_ANAMNESIS_GYNAECOLOGY_PREGNANCY,
    request: (data: any) => ({
      url: `/v1/anamnesis/${data[PARAMS.PATIENT_ID]}/gynaecology/pregnancies/${data[PARAMS.PREGNANCY_ID]}`,
      method: 'PUT',
      data,
    }),
  },
  deleteAnamnesisGynaecologyPregnancy: {
    id: PATIENTS.ACTIONS.DELETE_ANAMNESIS_GYNAECOLOGY_PREGNANCY,
    request: (data: any) => ({
      url: `/v1/anamnesis/${data[PARAMS.PATIENT_ID]}/gynaecology/pregnancies/${data[PARAMS.PREGNANCY_ID]}`,
      method: 'DELETE',
      data,
    }),
  },
  getAnamnesisGynaecologyMenstruations: {
    id: PATIENTS.ACTIONS.GET_ANAMNESIS_GYNAECOLOGY_MENSTRUATIONS,
    request: (data: any) => ({
      url: `/v1/anamnesis/${data[PARAMS.PATIENT_ID]}/gynaecology/menstruations`,
      method: 'GET',
      params: {
        limit: data.limit,
        offset: data.offset,
      },
    }),
  },
  getAnamnesisGynaecologyMenstruation: {
    id: PATIENTS.ACTIONS.GET_ANAMNESIS_GYNAECOLOGY_MENSTRUATION,
    request: (data: any) => ({
      url: `/v1/anamnesis/${data[PARAMS.PATIENT_ID]}/gynaecology/menstruations/${data[PARAMS.MENSTRUATION_ID]}`,
      method: 'GET',
      data,
    }),
  },
  createAnamnesisGynaecologyMenstruation: {
    id: PATIENTS.ACTIONS.CREATE_ANAMNESIS_GYNAECOLOGY_MENSTRUATION,
    request: (data: any) => ({
      url: `/v1/anamnesis/${data[PARAMS.PATIENT_ID]}/gynaecology/menstruations`,
      method: 'POST',
      data,
    }),
  },
  editAnamnesisGynaecologyMenstruation: {
    id: PATIENTS.ACTIONS.EDIT_ANAMNESIS_GYNAECOLOGY_MENSTRUATION,
    request: (data: any) => ({
      url: `/v1/anamnesis/${data[PARAMS.PATIENT_ID]}/gynaecology/menstruations/${data[PARAMS.MENSTRUATION_ID]}`,
      method: 'PUT',
      data,
    }),
  },
  deleteAnamnesisGynaecologyMenstruation: {
    id: PATIENTS.ACTIONS.DELETE_ANAMNESIS_GYNAECOLOGY_MENSTRUATION,
    request: (data: any) => ({
      url: `/v1/anamnesis/${data[PARAMS.PATIENT_ID]}/gynaecology/menstruations/${data[PARAMS.MENSTRUATION_ID]}`,
      method: 'DELETE',
      data,
    }),
  },
  editAnamnesisGynaecology: {
    id: PATIENTS.ACTIONS.EDIT_ANAMNESIS_GYNAECOLOGY,
    request: (data: any) => ({
      url: `/v1/anamnesis/${data[PARAMS.PATIENT_ID]}/gynaecology`,
      method: 'PUT',
      data,
    }),
  },
  getAnamnesisSurgeries: {
    id: PATIENTS.ACTIONS.GET_ANAMNESIS_SURGERIES,
    request: (data: any) => ({
      url: `/v1/anamnesis/${data[PARAMS.PATIENT_ID]}/surgeries`,
      method: 'GET',
      params: {
        limit: data?.limit,
        offset: data?.offset,
        search: data?.search,
      },
    }),
  },
  getAnamnesisSurgery: {
    id: PATIENTS.ACTIONS.GET_ANAMNESIS_SURGERY,
    request: (data: any) => ({
      url: `/v1/anamnesis/${data[PARAMS.PATIENT_ID]}/surgeries/${data[PARAMS.SURGERY_ID]}`,
      method: 'GET',
      data,
    }),
  },
  createAnamnesisSurgery: {
    id: PATIENTS.ACTIONS.CREATE_ANAMNESIS_SURGERY,
    request: (data: any) => ({
      url: `/v1/anamnesis/${data[PARAMS.PATIENT_ID]}/surgeries`,
      method: 'POST',
      data,
    }),
  },
  editAnamnesisSurgery: {
    id: PATIENTS.ACTIONS.EDIT_ANAMNESIS_SURGERY,
    request: (data: any) => ({
      url: `/v1/anamnesis/${data[PARAMS.PATIENT_ID]}/surgeries/${data[PARAMS.SURGERY_ID]}`,
      method: 'PUT',
      data,
    }),
  },
  deleteAnamnesisSurgery: {
    id: PATIENTS.ACTIONS.DELETE_ANAMNESIS_SURGERY,
    request: (data: any) => ({
      url: `/v1/anamnesis/${data[PARAMS.PATIENT_ID]}/surgeries/${data[PARAMS.SURGERY_ID]}`,
      method: 'DELETE',
      data,
    }),
  },
  getAnamnesisEpidemiologyList: {
    id: PATIENTS.ACTIONS.GET_ANAMNESIS_EPIDEMIOLOGY_LIST,
    request: (data: any) => ({
      url: `/v1/anamnesis/${data[PARAMS.PATIENT_ID]}/epidemiology`,
      method: 'GET',
      params: {
        limit: data?.limit,
        offset: data?.offset,
        search: data?.search,
      },
    }),
  },
  getAnamnesisEpidemiology: {
    id: PATIENTS.ACTIONS.GET_ANAMNESIS_EPIDEMIOLOGY,
    request: (data: any) => ({
      url: `/v1/anamnesis/${data[PARAMS.PATIENT_ID]}/epidemiology/${data[PARAMS.EPIDEMIOLOGY_ID]}`,
      method: 'GET',
      data,
    }),
  },
  createAnamnesisEpidemiology: {
    id: PATIENTS.ACTIONS.CREATE_ANAMNESIS_EPIDEMIOLOGY,
    request: (data: any) => ({
      url: `/v1/anamnesis/${data[PARAMS.PATIENT_ID]}/epidemiology`,
      method: 'POST',
      data,
    }),
  },
  editAnamnesisEpidemiology: {
    id: PATIENTS.ACTIONS.EDIT_ANAMNESIS_EPIDEMIOLOGY,
    request: (data: any) => ({
      url: `/v1/anamnesis/${data[PARAMS.PATIENT_ID]}/epidemiology/${data[PARAMS.EPIDEMIOLOGY_ID]}`,
      method: 'PUT',
      data,
    }),
  },
  deleteAnamnesisEpidemiology: {
    id: PATIENTS.ACTIONS.DELETE_ANAMNESIS_EPIDEMIOLOGY,
    request: (data: any) => ({
      url: `/v1/anamnesis/${data[PARAMS.PATIENT_ID]}/epidemiology/${data[PARAMS.EPIDEMIOLOGY_ID]}`,
      method: 'DELETE',
      data,
    }),
  },

  getAnamnesisFamily: {
    id: PATIENTS.ACTIONS.GET_ANAMNESIS_FAMILY,
    request: (data: any) => ({
      url: `/v1/anamnesis/${data[PARAMS.PATIENT_ID]}/family`,
      method: 'GET',
      params: {
        limit: data?.limit,
        offset: data?.offset,
        search: data?.search,
      },
    }),
  },
  getAnamnesisFamilyMember: {
    id: PATIENTS.ACTIONS.GET_ANAMNESIS_FAMILY,
    request: (data: any) => ({
      url: `/v1/anamnesis/${data[PARAMS.PATIENT_ID]}/family/${data[PARAMS.FAMILY_MEMBER_ID]}`,
      method: 'GET',
      data,
    }),
  },
  createAnamnesisFamilyMember: {
    id: PATIENTS.ACTIONS.CREATE_ANAMNESIS_FAMILY,
    request: (data: any) => ({
      url: `/v1/anamnesis/${data[PARAMS.PATIENT_ID]}/family`,
      method: 'POST',
      data,
    }),
  },
  editAnamnesisFamilyMember: {
    id: PATIENTS.ACTIONS.EDIT_ANAMNESIS_FAMILY,
    request: (data: any) => ({
      url: `/v1/anamnesis/${data[PARAMS.PATIENT_ID]}/family/${data[PARAMS.FAMILY_MEMBER_ID]}`,
      method: 'PUT',
      data,
    }),
  },
  deleteAnamnesisFamilyMember: {
    id: PATIENTS.ACTIONS.DELETE_ANAMNESIS_FAMILY,
    request: (data: any) => ({
      url: `/v1/anamnesis/${data[PARAMS.PATIENT_ID]}/family/${data[PARAMS.FAMILY_MEMBER_ID]}`,
      method: 'DELETE',
      data,
    }),
  },

  getAnamnesisWork: {
    id: PATIENTS.ACTIONS.GET_ANAMNESIS_WORK,
    request: (data: any) => ({
      url: `/v1/anamnesis/${data[PARAMS.PATIENT_ID]}/work`,
      method: 'GET',
      data,
    }),
  },
  editAnamnesisWork: {
    id: PATIENTS.ACTIONS.EDIT_ANAMNESIS_WORK,
    request: (data: any) => ({
      url: `/v1/anamnesis/${data[PARAMS.PATIENT_ID]}/work`,
      method: 'PUT',
      data,
    }),
  },

  getAnamnesisSocial: {
    id: PATIENTS.ACTIONS.GET_ANAMNESIS_SOCIAL,
    request: (data: any) => ({
      url: `/v1/anamnesis/${data[PARAMS.PATIENT_ID]}/social`,
      method: 'GET',
      data,
    }),
  },
  editAnamnesisSocial: {
    id: PATIENTS.ACTIONS.EDIT_ANAMNESIS_SOCIAL,
    request: (data: any) => ({
      url: `/v1/anamnesis/${data[PARAMS.PATIENT_ID]}/social`,
      method: 'PUT',
      data,
    }),
  },

  getAnamnesisSport: {
    id: PATIENTS.ACTIONS.GET_ANAMNESIS_SPORT,
    request: (data: any) => ({
      url: `/v1/anamnesis/${data[PARAMS.PATIENT_ID]}/sport`,
      method: 'GET',
      data,
    }),
  },
  editAnamnesisSport: {
    id: PATIENTS.ACTIONS.EDIT_ANAMNESIS_SPORT,
    request: (data: any) => ({
      url: `/v1/anamnesis/${data[PARAMS.PATIENT_ID]}/sport`,
      method: 'PUT',
      data,
    }),
  },

  getAnamnesisAddictiveSubstances: {
    id: PATIENTS.ACTIONS.GET_ANAMNESIS_ADDICTIVE_SUBSTANCES,
    request: (data: any) => ({
      url: `/v1/anamnesis/${data[PARAMS.PATIENT_ID]}/addictive_substances`,
      method: 'GET',
      data,
    }),
  },
  editAnamnesisAddictiveSubstances: {
    id: PATIENTS.ACTIONS.EDIT_ANAMNESIS_ADDICTIVE_SUBSTANCES,
    request: (data: any) => ({
      url: `/v1/anamnesis/${data[PARAMS.PATIENT_ID]}/addictive_substances`,
      method: 'PUT',
      data,
    }),
  },

  getCheckups: {
    id: PATIENTS.ACTIONS.GET_CHECKUPS,
    request: (data: any) => ({
      url: `/v1/checkups/${data[PARAMS.PATIENT_ID]}`,
      method: 'GET',
      params: {
        limit: data.limit,
        offset: data.offset,
      },
    }),
  },
  getCheckup: {
    id: PATIENTS.ACTIONS.GET_CHECKUP,
    request: (data: any) => ({
      url: `/v1/checkups/${data[PARAMS.PATIENT_ID]}/${data[PARAMS.CHECKUP_ID]}`,
      method: 'GET',
      data,
    }),
  },
  createCheckup: {
    id: PATIENTS.ACTIONS.CREATE_CHECKUP,
    request: (data: any) => ({
      url: `/v1/checkups/${data[PARAMS.PATIENT_ID]}`,
      method: 'POST',
      data,
    }),
  },
  editCheckup: {
    id: PATIENTS.ACTIONS.EDIT_CHECKUP,
    request: (data: any) => ({
      url: `/v1/checkups/${data[PARAMS.PATIENT_ID]}/${data[PARAMS.CHECKUP_ID]}`,
      method: 'PUT',
      data,
    }),
  },
  deleteCheckup: {
    id: PATIENTS.ACTIONS.DELETE_CHECKUP,
    request: (data: any) => ({
      url: `/v1/checkups/${data[PARAMS.PATIENT_ID]}/${data[PARAMS.CHECKUP_ID]}`,
      method: 'DELETE',
      data,
    }),
  },
  getMeasurementsGlycemia: {
    id: PATIENTS.ACTIONS.GET_MEASUREMENTS_GLYCEMIA,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/glycemia`,
      method: 'GET',
      params: {
        limit: data.limit,
        offset: data.offset,
      },
    }),
  },
  getMeasurementGlycemia: {
    id: PATIENTS.ACTIONS.GET_MEASUREMENT_GLYCEMIA,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/glycemia/${data[PARAMS.GLYCEMIA_ID]}`,
      method: 'GET',
      data,
    }),
  },
  createMeasurementGlycemia: {
    id: PATIENTS.ACTIONS.CREATE_MEASUREMENT_GLYCEMIA,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/glycemia`,
      method: 'POST',
      data,
    }),
  },
  editMeasurementGlycemia: {
    id: PATIENTS.ACTIONS.EDIT_MEASUREMENT_GLYCEMIA,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/glycemia/${data[PARAMS.GLYCEMIA_ID]}`,
      method: 'PUT',
      data,
    }),
  },
  deleteMeasurementGlycemia: {
    id: PATIENTS.ACTIONS.DELETE_MEASUREMENT_GLYCEMIA,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/glycemia/${data[PARAMS.GLYCEMIA_ID]}`,
      method: 'DELETE',
      data,
    }),
  },
  getMeasurementsOxygenSaturation: {
    id: PATIENTS.ACTIONS.GET_MEASUREMENTS_OXYGEN_SATURATION,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/oxygen_saturation`,
      method: 'GET',
      params: {
        limit: data.limit,
        offset: data.offset,
      },
    }),
  },
  getMeasurementOxygenSaturation: {
    id: PATIENTS.ACTIONS.GET_MEASUREMENT_OXYGEN_SATURATION,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/oxygen_saturation/${data[PARAMS.OXYGEN_ID]}`,
      method: 'GET',
      data,
    }),
  },
  createMeasurementOxygenSaturation: {
    id: PATIENTS.ACTIONS.CREATE_MEASUREMENT_OXYGEN_SATURATION,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/oxygen_saturation`,
      method: 'POST',
      data,
    }),
  },
  editMeasurementOxygenSaturation: {
    id: PATIENTS.ACTIONS.EDIT_MEASUREMENT_OXYGEN_SATURATION,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/oxygen_saturation/${data[PARAMS.OXYGEN_ID]}`,
      method: 'PUT',
      data,
    }),
  },
  deleteMeasurementOxygenSaturation: {
    id: PATIENTS.ACTIONS.DELETE_MEASUREMENT_OXYGEN_SATURATION,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/oxygen_saturation/${data[PARAMS.OXYGEN_ID]}`,
      method: 'DELETE',
      data,
    }),
  },
  getMeasurementsECG: {
    id: PATIENTS.ACTIONS.GET_MEASUREMENTS_ECG,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/ecg`,
      method: 'GET',
      params: {
        limit: data.limit,
        offset: data.offset,
      },
    }),
  },
  getMeasurementECG: {
    id: PATIENTS.ACTIONS.GET_MEASUREMENT_ECG,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/ecg/${data[PARAMS.ECG_ID]}`,
      method: 'GET',
      data,
    }),
  },
  createMeasurementECG: {
    id: PATIENTS.ACTIONS.CREATE_MEASUREMENT_ECG,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/ecg`,
      method: 'POST',
      data,
    }),
  },
  editMeasurementECG: {
    id: PATIENTS.ACTIONS.EDIT_MEASUREMENT_ECG,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/ecg/${data[PARAMS.ECG_ID]}`,
      method: 'PUT',
      data,
    }),
  },
  deleteMeasurementECG: {
    id: PATIENTS.ACTIONS.DELETE_MEASUREMENT_ECG,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/ecg/${data[PARAMS.ECG_ID]}`,
      method: 'DELETE',
      data,
    }),
  },

  getMeasurementsBPM: {
    id: PATIENTS.ACTIONS.GET_MEASUREMENTS_BPM,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/bpm`,
      method: 'GET',
      params: {
        limit: data.limit,
        offset: data.offset,
      },
    }),
  },
  getMeasurementBPM: {
    id: PATIENTS.ACTIONS.GET_MEASUREMENT_BPM,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/bpm/${data[PARAMS.BPM_ID]}`,
      method: 'GET',
      data,
    }),
  },
  createMeasurementBPM: {
    id: PATIENTS.ACTIONS.CREATE_MEASUREMENT_BPM,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/bpm`,
      method: 'POST',
      data,
    }),
  },
  editMeasurementBPM: {
    id: PATIENTS.ACTIONS.EDIT_MEASUREMENT_BPM,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/bpm/${data[PARAMS.BPM_ID]}`,
      method: 'PUT',
      data,
    }),
  },
  deleteMeasurementBPM: {
    id: PATIENTS.ACTIONS.DELETE_MEASUREMENT_BPM,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/bpm/${data[PARAMS.BPM_ID]}`,
      method: 'DELETE',
      data,
    }),
  },

  getMeasurementsAirPolution: {
    id: PATIENTS.ACTIONS.GET_MEASUREMENTS_AIR_POLUTION,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/air_polution`,
      method: 'GET',
      params: {
        limit: data.limit,
        offset: data.offset,
      },
    }),
  },
  getMeasurementAirPolution: {
    id: PATIENTS.ACTIONS.GET_MEASUREMENT_AIR_POLUTION,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/air_polution/${data[PARAMS.AIR_POLUTION_ID]}`,
      method: 'GET',
      data,
    }),
  },
  createMeasurementAirPolution: {
    id: PATIENTS.ACTIONS.CREATE_MEASUREMENT_AIR_POLUTION,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/air_polution`,
      method: 'POST',
      data,
    }),
  },
  editMeasurementAirPolution: {
    id: PATIENTS.ACTIONS.EDIT_MEASUREMENT_AIR_POLUTION,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/air_polution/${data[PARAMS.AIR_POLUTION_ID]}`,
      method: 'PUT',
      data,
    }),
  },
  deleteMeasurementAirPolution: {
    id: PATIENTS.ACTIONS.DELETE_MEASUREMENT_AIR_POLUTION,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/air_polution/${data[PARAMS.AIR_POLUTION_ID]}`,
      method: 'DELETE',
      data,
    }),
  },

  getMeasurementsSleep: {
    id: PATIENTS.ACTIONS.GET_MEASUREMENTS_SLEEP,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/sleep`,
      method: 'GET',
      params: {
        limit: data.limit,
        offset: data.offset,
      },
    }),
  },
  getMeasurementSleep: {
    id: PATIENTS.ACTIONS.GET_MEASUREMENT_SLEEP,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/sleep/${data[PARAMS.SLEEP_ID]}`,
      method: 'GET',
      data,
    }),
  },
  createMeasurementSleep: {
    id: PATIENTS.ACTIONS.CREATE_MEASUREMENT_SLEEP,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/sleep`,
      method: 'POST',
      data,
    }),
  },
  editMeasurementSleep: {
    id: PATIENTS.ACTIONS.EDIT_MEASUREMENT_SLEEP,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/sleep/${data[PARAMS.SLEEP_ID]}`,
      method: 'PUT',
      data,
    }),
  },
  deleteMeasurementSleep: {
    id: PATIENTS.ACTIONS.DELETE_MEASUREMENT_SLEEP,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/sleep/${data[PARAMS.SLEEP_ID]}`,
      method: 'DELETE',
      data,
    }),
  },

  getMeasurementsEndoscopy: {
    id: PATIENTS.ACTIONS.GET_MEASUREMENTS_ENDOSCOPY,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/endoscopy`,
      method: 'GET',
      params: {
        limit: data.limit,
        offset: data.offset,
      },
    }),
  },
  getMeasurementEndoscopy: {
    id: PATIENTS.ACTIONS.GET_MEASUREMENT_ENDOSCOPY,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/endoscopy/${data[PARAMS.ENDOSCOPY_ID]}`,
      method: 'GET',
      data,
    }),
  },
  createMeasurementEndoscopy: {
    id: PATIENTS.ACTIONS.CREATE_MEASUREMENT_ENDOSCOPY,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/endoscopy`,
      method: 'POST',
      data,
    }),
  },
  editMeasurementEndoscopy: {
    id: PATIENTS.ACTIONS.EDIT_MEASUREMENT_ENDOSCOPY,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/endoscopy/${data[PARAMS.ENDOSCOPY_ID]}`,
      method: 'PUT',
      data,
    }),
  },
  deleteMeasurementEndoscopy: {
    id: PATIENTS.ACTIONS.DELETE_MEASUREMENT_ENDOSCOPY,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/endoscopy/${data[PARAMS.ENDOSCOPY_ID]}`,
      method: 'DELETE',
      data,
    }),
  },

  getMeasurementsTemperature: {
    id: PATIENTS.ACTIONS.GET_MEASUREMENTS_TEMPERATURE,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/temperature`,
      method: 'GET',
      params: {
        limit: data.limit,
        offset: data.offset,
      },
    }),
  },
  getMeasurementTemperature: {
    id: PATIENTS.ACTIONS.GET_MEASUREMENT_TEMPERATURE,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/temperature/${data[PARAMS.TEMPERATURE_ID]}`,
      method: 'GET',
      data,
    }),
  },
  createMeasurementTemperature: {
    id: PATIENTS.ACTIONS.CREATE_MEASUREMENT_TEMPERATURE,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/temperature`,
      method: 'POST',
      data,
    }),
  },
  editMeasurementTemperature: {
    id: PATIENTS.ACTIONS.EDIT_MEASUREMENT_TEMPERATURE,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/temperature/${data[PARAMS.TEMPERATURE_ID]}`,
      method: 'PUT',
      data,
    }),
  },
  deleteMeasurementTemperature: {
    id: PATIENTS.ACTIONS.DELETE_MEASUREMENT_TEMPERATURE,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/temperature/${data[PARAMS.TEMPERATURE_ID]}`,
      method: 'DELETE',
      data,
    }),
  },

  getMeasurementsHeight: {
    id: PATIENTS.ACTIONS.GET_MEASUREMENTS_HEIGHT,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/height`,
      method: 'GET',
      params: {
        limit: data.limit,
        offset: data.offset,
      },
    }),
  },
  getMeasurementHeight: {
    id: PATIENTS.ACTIONS.GET_MEASUREMENT_HEIGHT,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/height/${data[PARAMS.HEIGHT_ID]}`,
      method: 'GET',
      data,
    }),
  },
  createMeasurementHeight: {
    id: PATIENTS.ACTIONS.CREATE_MEASUREMENT_HEIGHT,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/height`,
      method: 'POST',
      data,
    }),
  },
  editMeasurementHeight: {
    id: PATIENTS.ACTIONS.EDIT_MEASUREMENT_HEIGHT,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/height/${data[PARAMS.HEIGHT_ID]}`,
      method: 'PUT',
      data,
    }),
  },
  deleteMeasurementHeight: {
    id: PATIENTS.ACTIONS.DELETE_MEASUREMENT_HEIGHT,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/height/${data[PARAMS.HEIGHT_ID]}`,
      method: 'DELETE',
      data,
    }),
  },

  getMeasurementsWeight: {
    id: PATIENTS.ACTIONS.GET_MEASUREMENTS_WEIGHT,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/weight`,
      method: 'GET',
      params: {
        limit: data.limit,
        offset: data.offset,
      },
    }),
  },
  getMeasurementWeight: {
    id: PATIENTS.ACTIONS.GET_MEASUREMENT_WEIGHT,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/weight/${data[PARAMS.WEIGHT_ID]}`,
      method: 'GET',
      data,
    }),
  },
  createMeasurementWeight: {
    id: PATIENTS.ACTIONS.CREATE_MEASUREMENT_WEIGHT,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/weight`,
      method: 'POST',
      data,
    }),
  },
  editMeasurementWeight: {
    id: PATIENTS.ACTIONS.EDIT_MEASUREMENT_WEIGHT,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/weight/${data[PARAMS.WEIGHT_ID]}`,
      method: 'PUT',
      data,
    }),
  },
  deleteMeasurementWeight: {
    id: PATIENTS.ACTIONS.DELETE_MEASUREMENT_WEIGHT,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/weight/${data[PARAMS.WEIGHT_ID]}`,
      method: 'DELETE',
      data,
    }),
  },

  getMeasurementsCholesterol: {
    id: PATIENTS.ACTIONS.GET_MEASUREMENTS_CHOLESTEROL,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/cholesterol`,
      method: 'GET',
      params: {
        limit: data.limit,
        offset: data.offset,
      },
    }),
  },
  getMeasurementCholesterol: {
    id: PATIENTS.ACTIONS.GET_MEASUREMENT_CHOLESTEROL,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/cholesterol/${data[PARAMS.CHOLESTEROL_ID]}`,
      method: 'GET',
      data,
    }),
  },
  createMeasurementCholesterol: {
    id: PATIENTS.ACTIONS.CREATE_MEASUREMENT_CHOLESTEROL,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/cholesterol`,
      method: 'POST',
      data,
    }),
  },
  editMeasurementCholesterol: {
    id: PATIENTS.ACTIONS.EDIT_MEASUREMENT_CHOLESTEROL,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/cholesterol/${data[PARAMS.CHOLESTEROL_ID]}`,
      method: 'PUT',
      data,
    }),
  },
  deleteMeasurementCholesterol: {
    id: PATIENTS.ACTIONS.DELETE_MEASUREMENT_CHOLESTEROL,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/cholesterol/${data[PARAMS.CHOLESTEROL_ID]}`,
      method: 'DELETE',
      data,
    }),
  },
  getMeasurementsPressure: {
    id: PATIENTS.ACTIONS.GET_MEASUREMENTS_PRESSURE,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/blood_pressure`,
      method: 'GET',
      params: {
        limit: data.limit,
        offset: data.offset,
      },
    }),
  },
  getMeasurementPressure: {
    id: PATIENTS.ACTIONS.GET_MEASUREMENT_PRESSURE,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/blood_pressure/${data[PARAMS.PRESSURE_ID]}`,
      method: 'GET',
      data,
    }),
  },
  createMeasurementPressure: {
    id: PATIENTS.ACTIONS.CREATE_MEASUREMENT_PRESSURE,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/blood_pressure`,
      method: 'POST',
      data,
    }),
  },
  editMeasurementPressure: {
    id: PATIENTS.ACTIONS.EDIT_MEASUREMENT_PRESSURE,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/blood_pressure/${data[PARAMS.PRESSURE_ID]}`,
      method: 'PUT',
      data,
    }),
  },
  deleteMeasurementPressure: {
    id: PATIENTS.ACTIONS.DELETE_MEASUREMENT_PRESSURE,
    request: (data: any) => ({
      url: `/v1/measurements/${data[PARAMS.PATIENT_ID]}/blood_pressure/${data[PARAMS.PRESSURE_ID]}`,
      method: 'DELETE',
      data,
    }),
  },
  getStorageAttachments: {
    id: PATIENTS.ACTIONS.GET_STORAGE_ATTACHMENTS,
    request: (data: any) => ({
      url: `/v1/attachments/${data[PARAMS.PATIENT_ID]}`,
      method: 'GET',
      params: {
        limit: data.limit,
        offset: data.offset,
        type: data.type,
      },
    }),
  },
  getStorageAttachment: {
    id: PATIENTS.ACTIONS.GET_STORAGE_ATTACHMENT,
    request: (data: any) => ({
      url: `/v1/attachments/${data[PARAMS.PATIENT_ID]}/attachment/${data[PARAMS.STORAGE_ATTACHMENT_ID]}`,
      method: 'GET',
      data,
    }),
  },
  createStorageAttachment: {
    id: PATIENTS.ACTIONS.CREATE_STORAGE_ATTACHMENT,
    request: (data: any) => ({
      url: `/v1/attachments/${data[PARAMS.PATIENT_ID]}`,
      method: 'POST',
      data,
    }),
  },
  editStorageAttachment: {
    id: PATIENTS.ACTIONS.EDIT_STORAGE_ATTACHMENT,
    request: (data: any) => ({
      url: `/v1/attachments/${data[PARAMS.PATIENT_ID]}/attachment/${data[PARAMS.STORAGE_ATTACHMENT_ID]}`,
      method: 'PUT',
      data,
    }),
  },
  deleteStorageAttachment: {
    id: PATIENTS.ACTIONS.DELETE_STORAGE_ATTACHMENT,
    request: (data: any) => ({
      url: `/v1/attachments/${data[PARAMS.PATIENT_ID]}/attachment/${data[PARAMS.STORAGE_ATTACHMENT_ID]}`,
      method: 'DELETE',
      data,
    }),
  },
  downloadStorageAttachment: {
    id: PATIENTS.ACTIONS.DOWNLOAD_STORAGE_ATTACHMENT,
    request: (data: any) => ({
      url: `/v1/attachments/${data[PARAMS.PATIENT_ID]}/attachment/${data[PARAMS.STORAGE_ATTACHMENT_ID]}/link`,
      method: 'GET',
      data,
    }),
  },
  getStorageRadiology: {
    id: PATIENTS.ACTIONS.GET_STORAGE_RADIOLOGY,
    request: (data: any) => ({
      url: `/v1/attachments/${data[PARAMS.PATIENT_ID]}`,
      method: 'GET',
      params: {
        limit: data.limit,
        offset: data.offset,
        type: data.type,
      },
    }),
  },
  getStorageRadiologyFile: {
    id: PATIENTS.ACTIONS.GET_STORAGE_RADIOLOGY_FILE,
    request: (data: any) => ({
      url: `/v1/attachments/${data[PARAMS.PATIENT_ID]}/attachment/${data[PARAMS.STORAGE_RADIOLOGY_ID]}`,
      method: 'GET',
      data,
    }),
  },
  createStorageRadiologyFile: {
    id: PATIENTS.ACTIONS.CREATE_STORAGE_RADIOLOGY_FILE,
    request: (data: any) => ({
      url: `/v1/attachments/${data[PARAMS.PATIENT_ID]}`,
      method: 'POST',
      data,
    }),
  },
  editStorageRadiologyFile: {
    id: PATIENTS.ACTIONS.EDIT_STORAGE_RADIOLOGY_FILE,
    request: (data: any) => ({
      url: `/v1/attachments/${data[PARAMS.PATIENT_ID]}/attachment/${data[PARAMS.STORAGE_RADIOLOGY_ID]}`,
      method: 'PUT',
      data,
    }),
  },
  deleteStorageRadiologyFile: {
    id: PATIENTS.ACTIONS.DELETE_STORAGE_RADIOLOGY_FILE,
    request: (data: any) => ({
      url: `/v1/attachments/${data[PARAMS.PATIENT_ID]}/attachment/${data[PARAMS.STORAGE_RADIOLOGY_ID]}`,
      method: 'DELETE',
      data,
    }),
  },
  downloadStorageRadiologyFile: {
    id: PATIENTS.ACTIONS.DOWNLOAD_STORAGE_RADIOLOGY_FILE,
    request: (data: any) => ({
      url: `/v1/attachments/${data[PARAMS.PATIENT_ID]}/attachment/${data[PARAMS.STORAGE_RADIOLOGY_ID]}/link`,
      method: 'GET',
      data,
    }),
  },
  getStorageLaboratory: {
    id: PATIENTS.ACTIONS.GET_STORAGE_LABORATORY,
    request: (data: any) => ({
      url: `/v1/attachments/${data[PARAMS.PATIENT_ID]}`,
      method: 'GET',
      params: {
        limit: data.limit,
        offset: data.offset,
        type: data.type,
      },
    }),
  },
  getStorageLaboratoryFile: {
    id: PATIENTS.ACTIONS.GET_STORAGE_LABORATORY_FILE,
    request: (data: any) => ({
      url: `/v1/attachments/${data[PARAMS.PATIENT_ID]}/attachment/${data[PARAMS.STORAGE_LABORATORY_ID]}`,
      method: 'GET',
      data,
    }),
  },
  createStorageLaboratoryFile: {
    id: PATIENTS.ACTIONS.CREATE_STORAGE_LABORATORY_FILE,
    request: (data: any) => ({
      url: `/v1/attachments/${data[PARAMS.PATIENT_ID]}`,
      method: 'POST',
      data,
    }),
  },
  editStorageLaboratoryFile: {
    id: PATIENTS.ACTIONS.EDIT_STORAGE_LABORATORY_FILE,
    request: (data: any) => ({
      url: `/v1/attachments/${data[PARAMS.PATIENT_ID]}/attachment/${data[PARAMS.STORAGE_LABORATORY_ID]}`,
      method: 'PUT',
      data,
    }),
  },
  deleteStorageLaboratoryFile: {
    id: PATIENTS.ACTIONS.DELETE_STORAGE_LABORATORY_FILE,
    request: (data: any) => ({
      url: `/v1/attachments/${data[PARAMS.PATIENT_ID]}/attachment/${data[PARAMS.STORAGE_LABORATORY_ID]}`,
      method: 'DELETE',
      data,
    }),
  },
  downloadStorageLaboratoryFile: {
    id: PATIENTS.ACTIONS.DOWNLOAD_STORAGE_LABORATORY_FILE,
    request: (data: any) => ({
      url: `/v1/attachments/${data[PARAMS.PATIENT_ID]}/attachment/${data[PARAMS.STORAGE_LABORATORY_ID]}/link`,
      method: 'GET',
      data,
    }),
  },
  getEnums: {
    id: PATIENTS.ACTIONS.GET_ENUM,
    request: (data: any) => ({
      url: `/v1/enums`,
      method: 'GET',
      params: {
        enums: data.enums,
      },
    }),
  },
  editPersonalData: {
    id: AUTH.ACTIONS.EDIT_PERSONAL_DATA,
    request: (data: any) => ({
      url: `/v1/patient/${data[PARAMS.PATIENT_ID]}`,
      method: 'PUT',
      data,
    }),
  },
  editPatientData: {
    id: AUTH.ACTIONS.EDIT_PATIENT_DATA,
    request: (data: any) => ({
      url: `/v1/patients/${data[PARAMS.PATIENT_ID]}`,
      method: 'PUT',
      data,
    }),
  },
  verifyPatient: {
    id: AUTH.ACTIONS.VERIFY_PATIENT,
    request: (data: any) => ({
      url: `/v1/patients/verify/${data[PARAMS.PATIENT_ID]}`,
      method: 'PUT',
      data,
    }),
  },
}
