import { ArrowRightIcon, LockClosedIcon, MailIcon } from '@heroicons/react/outline'
import { Formik } from 'formik'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { PHONE_NUMBER_CODES } from '../../../enums'
import {
  ButtonBackgroundColorsEnum,
  ButtonIconPositionsEnum,
  ButtonTextColorsEnum,
  ButtonTypesEnum,
  RegisterStepInterface,
} from '../../../interfaces'
import { ROUTES } from '../../../router/routes'
import { RootState, useAppDispatch } from '../../../store'
import { getEnums } from '../../../store/modules/patients/actions'
import { createArrayOfEnumObjectsFromArrayOfValues, createEnumObjectFromValue } from '../../../store/utils'
import Button from '../../common/button/Button'
import FormWrapper from '../../common/form/FormWrapper'
import CustomInput from '../../common/inputs/components/CustomInput'
import CustomMaskedInput from '../../common/inputs/components/CustomMaskedInput'
import CustomSelect from '../../common/inputs/components/CustomSelect'

const personalIdentityNumberMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]

export const RegisterStep1Form = (props: RegisterStepInterface) => {
  const { enums, getEnumsLoading } = useSelector((state: RootState) => state.patients)
  const { onSubmit, previousValues } = props
  const { t } = useTranslation(['register', 'enums', 'yup'])
  const dispatch = useAppDispatch()

  const registerSchema = Yup.object({
    first_name: Yup.string().required(t('yup:yup.required')),
    last_name: Yup.string().required(t('yup:yup.required')),
    degrees_before: Yup.string().optional(),
    degrees_after: Yup.string().optional(),
    country: Yup.object().required(t('yup:yup.required')),
    email: Yup.string().email('Invalid email address').required(t('yup:yup.required')),
    phone_number: Yup.number().required(t('yup:yup.required')).typeError(t('yup:yup.number')),
    phone_number_code: Yup.object().required(t('yup:yup.required')),
    pin: Yup.string().length(11).required(t('yup:yup.required')),
    health_insurance_company_code: Yup.object().required(t('yup:yup.required')),
    password: Yup.string()
      .min(8, t('yup:yup.min', { number: 8 }))
      .required(t('yup:yup.required')),
    password_match: Yup.string()
      .min(8, t('yup:yup.min', { number: 8 }))
      .oneOf([Yup.ref('password')], t('yup:yup.passwordMatch'))
      .required(t('yup:yup.required')),
  })

  const countries = createArrayOfEnumObjectsFromArrayOfValues('country', enums['Country'])
  const healthInsuranceCompanies = createArrayOfEnumObjectsFromArrayOfValues(
    'healthInsuranceCompany',
    enums['HealthInsuranceCompany']
  )

  useEffect(() => {
    dispatch(getEnums({ enums: ['Country', 'HealthInsuranceCompany'] }))
  }, [])

  return (
    <>
      <div>
        <h2 className="mt-6 text-3xl font-semibold text-gray-900">{t('register.register.step1Form.title')}</h2>
        <p className="mt-1 text-sm">{t('register.register.step1Form.subtitle')}</p>
      </div>
      <div className="mt-8">
        <div className="mt-6">
          <Formik
            enableReinitialize={true}
            initialValues={{
              first_name: previousValues?.first_name || '',
              middle_name: previousValues?.middle_name || '',
              last_name: previousValues?.last_name || '',
              degrees_before: previousValues?.degrees_before || '',
              degrees_after: previousValues?.degrees_after || '',
              country: createEnumObjectFromValue('country', previousValues?.country) || countries[0],
              email: previousValues?.email || '',
              phone_number: previousValues?.phone_number || '',
              phone_number_code:
                createEnumObjectFromValue(undefined, previousValues?.phone_number_code) || PHONE_NUMBER_CODES[0],
              pin: previousValues?.pin || '',
              health_insurance_company_code:
                createEnumObjectFromValue('healthInsuranceCompany', previousValues?.health_insurance_company_code) ||
                healthInsuranceCompanies[0],
              password: previousValues?.password || '',
              password_match: previousValues?.password_match || '',
            }}
            validationSchema={registerSchema}
            onSubmit={(values, { setSubmitting }) => onSubmit(values, setSubmitting)}
          >
            {({ isSubmitting, errors, values, touched, setFieldValue, handleChange }) => (
              <FormWrapper className="gap-x-6 gap-y-3">
                <CustomInput
                  type="text"
                  label={t('register.register.step1Form.firstName')}
                  name="first_name"
                  errors={errors}
                  touched={touched}
                  className="col-span-12 sm:col-span-4"
                  autoComplete="given-name"
                />

                <CustomInput
                  type="text"
                  label={t('register.register.step1Form.middleName')}
                  name="middle_name"
                  errors={errors}
                  touched={touched}
                  className="col-span-12 sm:col-span-4"
                />

                <CustomInput
                  type="text"
                  label={t('register.register.step1Form.lastName')}
                  name="last_name"
                  errors={errors}
                  touched={touched}
                  className="col-span-12 sm:col-span-4"
                />

                <CustomInput
                  type="text"
                  label={t('register.register.step1Form.degreesBefore')}
                  name="degrees_before"
                  errors={errors}
                  touched={touched}
                  className="col-span-12 sm:col-span-4"
                />

                <CustomInput
                  type="text"
                  label={t('register.register.step1Form.degreesAfter')}
                  name="degrees_after"
                  errors={errors}
                  touched={touched}
                  className="col-span-12 sm:col-span-4"
                />

                <CustomSelect
                  label={t('register.register.step1Form.country')}
                  name="country"
                  errors={errors}
                  touched={touched}
                  value={values.country}
                  options={countries}
                  loading={getEnumsLoading}
                  onChange={(current: any) => setFieldValue('country', current)}
                  className="col-span-12 sm:col-span-4"
                />

                <CustomInput
                  type="email"
                  label={t('register.register.step1Form.email')}
                  name="email"
                  errors={errors}
                  touched={touched}
                  className="col-span-12 sm:col-span-7"
                  inputClassName="pl-10"
                  icon={MailIcon}
                />

                <CustomInput
                  className="col-span-12 sm:col-span-5"
                  inputClassName="pl-24"
                  label={t('register.register.step1Form.phoneNumber')}
                  name="phone_number"
                  type="text"
                  errors={errors}
                  touched={touched}
                >
                  <CustomSelect
                    label={t('register.register.step1Form.phoneNumberCode')}
                    name="phone_number_code"
                    errors={errors}
                    touched={touched}
                    hideLabel={true}
                    value={values.phone_number_code}
                    options={PHONE_NUMBER_CODES}
                    onChange={(current: any) => {
                      setFieldValue('phone_number_code', current)
                    }}
                    isChild={true}
                  />
                </CustomInput>

                <CustomMaskedInput
                  className="col-span-12 sm:col-span-4"
                  label={t('register.register.step1Form.pin')}
                  name="pin"
                  mask={personalIdentityNumberMask}
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  value={values.pin}
                />

                <CustomSelect
                  className="col-span-12 sm:col-span-8"
                  label={t('register.register.step1Form.healthInsuranceCompany')}
                  name="health_insurance_company_code"
                  errors={errors}
                  touched={touched}
                  value={values.health_insurance_company_code}
                  options={healthInsuranceCompanies}
                  loading={getEnumsLoading}
                  onChange={(current: any) => setFieldValue('health_insurance_company_code', current)}
                />

                <CustomInput
                  type="password"
                  label={t('register.register.step1Form.password')}
                  name="password"
                  errors={errors}
                  touched={touched}
                  className="col-span-12 sm:col-span-6"
                  inputClassName="pl-10"
                  icon={LockClosedIcon}
                  autoComplete="new-password"
                />

                <CustomInput
                  type="password"
                  label={t('register.register.step1Form.passwordMatch')}
                  name="password_match"
                  errors={errors}
                  touched={touched}
                  className="col-span-12 sm:col-span-6"
                  inputClassName="pl-10"
                  icon={LockClosedIcon}
                  autoComplete="new-password"
                />

                <div className="flex col-span-12 justify-between items-center mt-12">
                  <p className="text-sm">
                    {t('register.register.step1Form.alreadyHaveAccount')}{' '}
                    <Link className="text-brand" to={ROUTES.LOGIN}>
                      {t('register.register.step1Form.loginLink')}
                    </Link>
                  </p>

                  <Button
                    type={ButtonTypesEnum.Submit}
                    backgroundColor={ButtonBackgroundColorsEnum.Brand}
                    textColor={ButtonTextColorsEnum.White}
                    title={t('register.register.step1Form.nextStepButton')}
                    disabled={isSubmitting}
                    icon={ArrowRightIcon}
                    iconPosition={ButtonIconPositionsEnum.Right}
                  />
                </div>
              </FormWrapper>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}

export default RegisterStep1Form
