import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import DashboardAdminView from '../../../components/app/dashboard/DashboardAdminView'
import DashboardDoctorView from '../../../components/app/dashboard/DashboardDoctorView'
import { PARAMS, ROLES } from '../../../enums'
import { ROUTES } from '../../../router/routes'
import { RootState } from '../../../store'
import { checkRole, replaceRouteParams } from '../../../store/utils'

export const DashboardPage = () => {
  const { authUser, roleSet } = useSelector((state: RootState) => state.auth)
  const history = useHistory()

  useEffect(() => {
    if (roleSet && checkRole(authUser, ROLES.PATIENT)) {
      history.push(
        replaceRouteParams(ROUTES.PATIENTS_DETAIL_OVERVIEW, [
          { key: `:${PARAMS.PATIENT_ID}`, value: authUser?.role.object_id },
        ])
      )
    }
  }, [authUser, roleSet])

  return (
    <>
      {checkRole(authUser, [ROLES.ADMIN]) && <DashboardAdminView />}
      {checkRole(authUser, [ROLES.DOCTOR]) && <DashboardDoctorView />}
    </>
  )
}

export default DashboardPage
