import { PARAMS } from '../../enums'
import { DOCTORS } from '../../store/modules/doctors/types'
import { PATIENTS } from '../../store/modules/patients/types'

export default {
  registerPatientViaDoctor: {
    id: DOCTORS.ACTIONS.REGISTER_PATIENT_VIA_DOCTOR,
    request: (data: any) => ({
      url: '/v1/patients',
      method: 'POST',
      data,
    }),
  },
  getPatientsDoctors: {
    id: PATIENTS.ACTIONS.GET_PATIENTS_DOCTORS,
    request: (data: any) => ({
      url: `/v1/doctors/${data[PARAMS.PATIENT_ID]}`,
      method: 'GET',
      data,
    }),
  },
  getDoctors: {
    id: PATIENTS.ACTIONS.GET_DOCTORS,
    request: (data: any) => ({
      url: `/v1/doctors`,
      method: 'GET',
      params: {
        limit: data?.limit,
        offset: data?.offset,
        search: data?.search,
      },
    }),
  },
  getDoctor: {
    id: PATIENTS.ACTIONS.GET_DOCTOR,
    request: (data: any) => ({
      url: `/v1/doctors/doctor/${data[PARAMS.DOCTOR_ID]}`,
      method: 'GET',
      data,
    }),
  },
  editDoctor: {
    id: PATIENTS.ACTIONS.EDIT_DOCTOR,
    request: (data: any) => ({
      url: `/v1/doctors/doctor/${data[PARAMS.DOCTOR_ID]}`,
      method: 'PUT',
      data,
    }),
  },
  deleteDoctor: {
    id: PATIENTS.ACTIONS.DELETE_DOCTOR,
    request: (data: any) => ({
      url: `/v1/doctors/doctor/${data[PARAMS.DOCTOR_ID]}`,
      method: 'DELETE',
      data,
    }),
  },
  createDoctor: {
    id: PATIENTS.ACTIONS.CREATE_DOCTOR,
    request: (data: any) => ({
      url: `/v1/doctors`,
      method: 'POST',
      data,
    }),
  },
  patientGiveDoctorPatientsAccess: {
    id: PATIENTS.ACTIONS.PATIENT_GIVE_DOCTOR_PATIENTS_ACCESS,
    request: (data: any) => ({
      url: `/v1/doctors/${data[PARAMS.DOCTOR_ID]}/access`,
      method: 'POST',
      data,
    }),
  },
  patientRemoveDoctorPatientsAccess: {
    id: PATIENTS.ACTIONS.PATIENT_REMOVE_DOCTOR_PATIENTS_ACCESS,
    request: (data: any) => ({
      url: `/v1/doctors/${data[PARAMS.DOCTOR_ID]}`,
      method: 'DELETE',
      data,
    }),
  },
  adminGiveDoctorPatientsAccess: {
    id: PATIENTS.ACTIONS.ADMIN_GIVE_DOCTOR_PATIENTS_ACCESS,
    request: (data: any) => ({
      url: `/v1/access`,
      method: 'POST',
      data,
    }),
  },
  adminRemoveDoctorPatientsAccess: {
    id: PATIENTS.ACTIONS.ADMIN_REMOVE_DOCTOR_PATIENTS_ACCESS,
    request: (data: any) => ({
      url: `/v1/access`,
      method: 'DELETE',
      data,
    }),
  },
}
