import React from 'react'
import { useTranslation } from 'react-i18next'
import login_img from '../../assets/images/login.jpg'
import { VersionControl } from '../../components/common/util/VersionControl'
import { LoginHandler } from '../../components/login/LoginHandler'

export const LoginPage = () => {
  const { t } = useTranslation(['login'])

  return (
    <div className="flex min-h-screen bg-white">
      <div className="flex flex-col flex-1 lg:flex-none justify-center py-12 px-4 sm:px-6 lg:px-20 xl:px-24">
        <div className="mx-auto w-full lg:w-96 max-w-sm">
          <div>
            <h2 className="mt-6 text-3xl font-extrabold text-gray-900">{t('login.title')}</h2>
          </div>
          <div className="mt-8">
            <div className="mt-6">
              <LoginHandler />
            </div>
          </div>
        </div>
      </div>
      <div className="hidden lg:block relative flex-1 w-0">
        <img className="object-cover absolute inset-0 w-full h-full" src={login_img} alt="" />
      </div>
      <VersionControl />
    </div>
  )
}

export default LoginPage
