export const CALENDAR = {
  NAMESPACE: 'calendar',
  ACTIONS: {
    GET_EVENTS: 'GET_EVENTS',
    GET_EVENT: 'GET_EVENT',
    CREATE_EVENT: 'CREATE_EVENT',
    EDIT_EVENT: 'EDIT_EVENT',
    DELETE_EVENT: 'DELETE_EVENT',
    RSVP_EVENT: 'RSVP_EVENT',
    GET_DOCTORS_ATTENDEES: 'GET_DOCTORS_ATTENDEES',
  },
}
