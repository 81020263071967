export enum TELEREHABILITATION_STATUS {
  TO_BE_DONE = 'toBeDone',
  STARTED = 'started',
  COMPLETED = 'completed',
}

export const TELEREHABILITATION_PATIENT_EXERCISE_UPLOAD_STEPS = [
  {
    title: 'telerehabilitation.patientExercise.uploadModal.step1.title',
    description: 'telerehabilitation.patientExercise.uploadModal.step1.description',
  },
  {
    title: 'telerehabilitation.patientExercise.uploadModal.step2.title',
    description: 'telerehabilitation.patientExercise.uploadModal.step2.description',
  },
  {
    title: 'telerehabilitation.patientExercise.uploadModal.step3.title',
    description: 'telerehabilitation.patientExercise.uploadModal.step3.description',
  },
  {
    title: 'telerehabilitation.patientExercise.uploadModal.step4.title',
    description: 'telerehabilitation.patientExercise.uploadModal.step4.description',
  },
]

export const TELEREHABILITATION_SUPPORTED_FORMATS = [
  'video/3gpp',
  'video/ogg',
  'video/mp4',
  'video/mpeg',
  'video/quicktime',
  'video/webm',
  'video/x-m4v',
  'video/ms-asf',
  'video/x-msvideo',
  'video/x-ms-wmv',
]
