import { createAsyncThunk } from '@reduxjs/toolkit'
import { API } from '../../../api'
import { callBaseApiEndpoint } from '../../utils'

// typePrefix = '${namespace}/functionName'
export const getPatients: any = createAsyncThunk('patients/getPatients', async (payload, { dispatch }) => {
  return await callBaseApiEndpoint(dispatch, {
    requestData: { ...API.patients.getPatients },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const getDoctorsPatients: any = createAsyncThunk(
  'patients/getDoctorsPatients',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.getDoctorsPatients },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const getPatient: any = createAsyncThunk('patients/getPatient', async (payload, { dispatch }) => {
  return await callBaseApiEndpoint(dispatch, {
    requestData: { ...API.patients.getPatient },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const deletePatient: any = createAsyncThunk('patients/deletePatient', async (payload, { dispatch }) => {
  return await callBaseApiEndpoint(dispatch, {
    requestData: { ...API.patients.deletePatient },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const getPatientByPIN: any = createAsyncThunk('patients/getPatientByPIN', async (payload, { dispatch }) => {
  return await callBaseApiEndpoint(dispatch, {
    requestData: { ...API.patients.getPatientByPIN },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const getFamily: any = createAsyncThunk('patients/getFamily', async (payload, { dispatch }) => {
  return await callBaseApiEndpoint(dispatch, {
    requestData: { ...API.patients.getFamily },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const createFamilyMember: any = createAsyncThunk(
  'patients/createFamilyMember',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.createFamilyMember },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const deleteFamilyMember: any = createAsyncThunk(
  'patients/deleteFamilyMember',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.deleteFamilyMember },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const getPatientsRequestAccess: any = createAsyncThunk(
  'patients/getPatientsRequestAccess',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.getPatientsRequestAccess },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const getAnamnesisPersonal: any = createAsyncThunk(
  'patients/getAnamnesisPersonal',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.getAnamnesisPersonal },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const editAnamnesisPersonal: any = createAsyncThunk(
  'patients/editAnamnesisPersonal',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.editAnamnesisPersonal },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const getAnamnesisAllergies: any = createAsyncThunk(
  'patients/getAnamnesisAllergies',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.getAnamnesisAllergies },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const editAnamnesisAllergies: any = createAsyncThunk(
  'patients/editAnamnesisAllergies',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.editAnamnesisAllergies },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const getAnamnesisGynaecology: any = createAsyncThunk(
  'patients/getAnamnesisGynaecology',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.getAnamnesisGynaecology },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)
export const getAnamnesisGynaecologyPregnancies: any = createAsyncThunk(
  'patients/getAnamnesisGynaecologyPregnancies',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.getAnamnesisGynaecologyPregnancies },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const getAnamnesisGynaecologyPregnancy: any = createAsyncThunk(
  'patients/getAnamnesisGynaecologyPregnancy',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.getAnamnesisGynaecologyPregnancy },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const createAnamnesisGynaecologyPregnancy: any = createAsyncThunk(
  'patients/createAnamnesisGynaecologyPregnancy',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.createAnamnesisGynaecologyPregnancy },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const editAnamnesisGynaecologyPregnancy: any = createAsyncThunk(
  'patients/editAnamnesisGynaecologyPregnancy',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.editAnamnesisGynaecologyPregnancy },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const deleteAnamnesisGynaecologyPregnancy: any = createAsyncThunk(
  'patients/deleteAnamnesisGynaecologyPregnancy',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.deleteAnamnesisGynaecologyPregnancy },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const getAnamnesisGynaecologyMenstruations: any = createAsyncThunk(
  'patients/getAnamnesisGynaecologyMenstruations',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.getAnamnesisGynaecologyMenstruations },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const getAnamnesisGynaecologyMenstruation: any = createAsyncThunk(
  'patients/getAnamnesisGynaecologyMenstruation',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.getAnamnesisGynaecologyMenstruation },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const createAnamnesisGynaecologyMenstruation: any = createAsyncThunk(
  'patients/createAnamnesisGynaecologyMenstruation',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.createAnamnesisGynaecologyMenstruation },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const editAnamnesisGynaecologyMenstruation: any = createAsyncThunk(
  'patients/editAnamnesisGynaecologyMenstruation',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.editAnamnesisGynaecologyMenstruation },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const deleteAnamnesisGynaecologyMenstruation: any = createAsyncThunk(
  'patients/deleteAnamnesisGynaecologyMenstruation',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.deleteAnamnesisGynaecologyMenstruation },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const editAnamnesisGynaecology: any = createAsyncThunk(
  'patients/editAnamnesisGynaecology',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.editAnamnesisGynaecology },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const getAnamnesisSurgeries: any = createAsyncThunk(
  'patients/getAnamnesisSurgeries',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.getAnamnesisSurgeries },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const getAnamnesisSurgery: any = createAsyncThunk(
  'patients/getAnamnesisSurgery',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.getAnamnesisSurgery },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const createAnamnesisSurgery: any = createAsyncThunk(
  'patients/createAnamnesisSurgery',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.createAnamnesisSurgery },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const editAnamnesisSurgery: any = createAsyncThunk(
  'patients/editAnamnesisSurgery',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.editAnamnesisSurgery },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const deleteAnamnesisSurgery: any = createAsyncThunk(
  'patients/deleteAnamnesisSurgery',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.deleteAnamnesisSurgery },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const getAnamnesisMedications: any = createAsyncThunk(
  'patients/getAnamnesisMedications',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.getAnamnesisMedications },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const getAnamnesisMedication: any = createAsyncThunk(
  'patients/getAnamnesisMedication',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.getAnamnesisMedication },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const createAnamnesisMedication: any = createAsyncThunk(
  'patients/createAnamnesisMedication',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.createAnamnesisMedication },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const editAnamnesisMedication: any = createAsyncThunk(
  'patients/editAnamnesisMedication',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.editAnamnesisMedication },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const deleteAnamnesisMedication: any = createAsyncThunk(
  'patients/deleteAnamnesisMedication',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.deleteAnamnesisMedication },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const getAnamnesisEpidemiologyList: any = createAsyncThunk(
  'patients/getAnamnesisEpidemiologyList',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.getAnamnesisEpidemiologyList },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const getAnamnesisEpidemiology: any = createAsyncThunk(
  'patients/getAnamnesisEpidemiology',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.getAnamnesisEpidemiology },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const createAnamnesisEpidemiology: any = createAsyncThunk(
  'patients/createAnamnesisEpidemiology',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.createAnamnesisEpidemiology },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const editAnamnesisEpidemiology: any = createAsyncThunk(
  'patients/editAnamnesisEpidemiology',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.editAnamnesisEpidemiology },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const deleteAnamnesisEpidemiology: any = createAsyncThunk(
  'patients/deleteAnamnesisEpidemiology',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.deleteAnamnesisEpidemiology },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const getAnamnesisFamily: any = createAsyncThunk(
  'patients/getAnamnesisFamily',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.getAnamnesisFamily },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const getAnamnesisFamilyMember: any = createAsyncThunk(
  'patients/getAnamnesisFamilyMember',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.getAnamnesisFamilyMember },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const createAnamnesisFamilyMember: any = createAsyncThunk(
  'patients/createAnamnesisFamilyMember',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.createAnamnesisFamilyMember },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const editAnamnesisFamilyMember: any = createAsyncThunk(
  'patients/editAnamnesisFamilyMember',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.editAnamnesisFamilyMember },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const deleteAnamnesisFamilyMember: any = createAsyncThunk(
  'patients/deleteAnamnesisFamilyMember',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.deleteAnamnesisFamilyMember },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const getAnamnesisWork: any = createAsyncThunk('patients/getAnamnesisWork', async (payload, { dispatch }) => {
  return await callBaseApiEndpoint(dispatch, {
    requestData: { ...API.patients.getAnamnesisWork },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const editAnamnesisWork: any = createAsyncThunk('patients/editAnamnesisWork', async (payload, { dispatch }) => {
  return await callBaseApiEndpoint(dispatch, {
    requestData: { ...API.patients.editAnamnesisWork },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const getAnamnesisSocial: any = createAsyncThunk(
  'patients/getAnamnesisSocial',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.getAnamnesisSocial },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const editAnamnesisSocial: any = createAsyncThunk(
  'patients/editAnamnesisSocial',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.editAnamnesisSocial },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const getAnamnesisSport: any = createAsyncThunk('patients/getAnamnesisSport', async (payload, { dispatch }) => {
  return await callBaseApiEndpoint(dispatch, {
    requestData: { ...API.patients.getAnamnesisSport },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const editAnamnesisSport: any = createAsyncThunk(
  'patients/editAnamnesisSport',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.editAnamnesisSport },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const getAnamnesisAddictiveSubstances: any = createAsyncThunk(
  'patients/getAnamnesisAddictiveSubstances',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.getAnamnesisAddictiveSubstances },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const editAnamnesisAddictiveSubstances: any = createAsyncThunk(
  'patients/editAnamnesisAddictiveSubstances',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.editAnamnesisAddictiveSubstances },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const getCheckups: any = createAsyncThunk('patients/getCheckups', async (payload, { dispatch }) => {
  return await callBaseApiEndpoint(dispatch, {
    requestData: { ...API.patients.getCheckups },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const getCheckup: any = createAsyncThunk('patients/getCheckup', async (payload, { dispatch }) => {
  return await callBaseApiEndpoint(dispatch, {
    requestData: { ...API.patients.getCheckup },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const createCheckup: any = createAsyncThunk('patients/createCheckup', async (payload, { dispatch }) => {
  return await callBaseApiEndpoint(dispatch, {
    requestData: { ...API.patients.createCheckup },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const editCheckup: any = createAsyncThunk('patients/editCheckup', async (payload, { dispatch }) => {
  return await callBaseApiEndpoint(dispatch, {
    requestData: { ...API.patients.editCheckup },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const deleteCheckup: any = createAsyncThunk('patients/deleteCheckup', async (payload, { dispatch }) => {
  return await callBaseApiEndpoint(dispatch, {
    requestData: { ...API.patients.deleteCheckup },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const getMeasurementsGlycemia: any = createAsyncThunk(
  'patients/getMeasurementsGlycemia',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.getMeasurementsGlycemia },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const getMeasurementGlycemia: any = createAsyncThunk(
  'patients/getMeasurementGlycemia',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.getMeasurementGlycemia },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const createMeasurementGlycemia: any = createAsyncThunk(
  'patients/createMeasurementGlycemia',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.createMeasurementGlycemia },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const editMeasurementGlycemia: any = createAsyncThunk(
  'patients/editMeasurementGlycemia',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.editMeasurementGlycemia },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const deleteMeasurementGlycemia: any = createAsyncThunk(
  'patients/deleteMeasurementGlycemia',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.deleteMeasurementGlycemia },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const getMeasurementsOxygenSaturation: any = createAsyncThunk(
  'patients/getMeasurementsOxygenSaturation',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.getMeasurementsOxygenSaturation },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const getMeasurementOxygenSaturation: any = createAsyncThunk(
  'patients/getMeasurementOxygenSaturation',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.getMeasurementOxygenSaturation },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const createMeasurementOxygenSaturation: any = createAsyncThunk(
  'patients/createMeasurementOxygenSaturation',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.createMeasurementOxygenSaturation },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const editMeasurementOxygenSaturation: any = createAsyncThunk(
  'patients/editMeasurementOxygenSaturation',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.editMeasurementOxygenSaturation },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const deleteMeasurementOxygenSaturation: any = createAsyncThunk(
  'patients/deleteMeasurementOxygenSaturation',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.deleteMeasurementOxygenSaturation },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const getMeasurementsAirPolution: any = createAsyncThunk(
  'patients/getMeasurementsAirPolution',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.getMeasurementsAirPolution },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const getMeasurementAirPolution: any = createAsyncThunk(
  'patients/getMeasurementAirPolution',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.getMeasurementAirPolution },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const createMeasurementAirPolution: any = createAsyncThunk(
  'patients/createMeasurementAirPolution',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.createMeasurementAirPolution },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const editMeasurementAirPolution: any = createAsyncThunk(
  'patients/editMeasurementAirPolution',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.editMeasurementAirPolution },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const deleteMeasurementAirPolution: any = createAsyncThunk(
  'patients/deleteMeasurementAirPolution',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.deleteMeasurementAirPolution },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const getMeasurementsECG: any = createAsyncThunk(
  'patients/getMeasurementsECG',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.getMeasurementsECG },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const getMeasurementECG: any = createAsyncThunk('patients/getMeasurementECG', async (payload, { dispatch }) => {
  return await callBaseApiEndpoint(dispatch, {
    requestData: { ...API.patients.getMeasurementECG },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const createMeasurementECG: any = createAsyncThunk(
  'patients/createMeasurementECG',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.createMeasurementECG },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const editMeasurementECG: any = createAsyncThunk(
  'patients/editMeasurementECG',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.editMeasurementECG },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const deleteMeasurementECG: any = createAsyncThunk(
  'patients/deleteMeasurementECG',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.deleteMeasurementECG },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const getMeasurementsBPM: any = createAsyncThunk(
  'patients/getMeasurementsBPM',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.getMeasurementsBPM },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const getMeasurementBPM: any = createAsyncThunk('patients/getMeasurementBPM', async (payload, { dispatch }) => {
  return await callBaseApiEndpoint(dispatch, {
    requestData: { ...API.patients.getMeasurementBPM },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const createMeasurementBPM: any = createAsyncThunk(
  'patients/createMeasurementBPM',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.createMeasurementBPM },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const editMeasurementBPM: any = createAsyncThunk(
  'patients/editMeasurementBPM',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.editMeasurementBPM },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const deleteMeasurementBPM: any = createAsyncThunk(
  'patients/deleteMeasurementBPM',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.deleteMeasurementBPM },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const getMeasurementsSleep: any = createAsyncThunk(
  'patients/getMeasurementsSleep',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.getMeasurementsSleep },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const getMeasurementSleep: any = createAsyncThunk(
  'patients/getMeasurementSleep',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.getMeasurementSleep },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const createMeasurementSleep: any = createAsyncThunk(
  'patients/createMeasurementSleep',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.createMeasurementSleep },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const editMeasurementSleep: any = createAsyncThunk(
  'patients/editMeasurementSleep',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.editMeasurementSleep },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const deleteMeasurementSleep: any = createAsyncThunk(
  'patients/deleteMeasurementSleep',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.deleteMeasurementSleep },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const getMeasurementsEndoscopy: any = createAsyncThunk(
  'patients/getMeasurementsEndoscopy',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.getMeasurementsEndoscopy },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const getMeasurementEndoscopy: any = createAsyncThunk(
  'patients/getMeasurementEndoscopy',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.getMeasurementEndoscopy },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const createMeasurementEndoscopy: any = createAsyncThunk(
  'patients/createMeasurementEndoscopy',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.createMeasurementEndoscopy },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const editMeasurementEndoscopy: any = createAsyncThunk(
  'patients/editMeasurementEndoscopy',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.editMeasurementEndoscopy },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const deleteMeasurementEndoscopy: any = createAsyncThunk(
  'patients/deleteMeasurementEndoscopy',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.deleteMeasurementEndoscopy },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const getMeasurementsTemperature: any = createAsyncThunk(
  'patients/getMeasurementsTemperature',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.getMeasurementsTemperature },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const getMeasurementTemperature: any = createAsyncThunk(
  'patients/getMeasurementTemperature',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.getMeasurementTemperature },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const createMeasurementTemperature: any = createAsyncThunk(
  'patients/createMeasurementTemperature',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.createMeasurementTemperature },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const editMeasurementTemperature: any = createAsyncThunk(
  'patients/editMeasurementTemperature',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.editMeasurementTemperature },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const deleteMeasurementTemperature: any = createAsyncThunk(
  'patients/deleteMeasurementTemperature',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.deleteMeasurementTemperature },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const getMeasurementsHeight: any = createAsyncThunk(
  'patients/getMeasurementsHeight',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.getMeasurementsHeight },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const getMeasurementHeight: any = createAsyncThunk(
  'patients/getMeasurementHeight',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.getMeasurementHeight },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const createMeasurementHeight: any = createAsyncThunk(
  'patients/createMeasurementHeight',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.createMeasurementHeight },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const editMeasurementHeight: any = createAsyncThunk(
  'patients/editMeasurementHeight',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.editMeasurementHeight },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const deleteMeasurementHeight: any = createAsyncThunk(
  'patients/deleteMeasurementHeight',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.deleteMeasurementHeight },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const getMeasurementsWeight: any = createAsyncThunk(
  'patients/getMeasurementsWeight',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.getMeasurementsWeight },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const getMeasurementWeight: any = createAsyncThunk(
  'patients/getMeasurementWeight',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.getMeasurementWeight },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const createMeasurementWeight: any = createAsyncThunk(
  'patients/createMeasurementWeight',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.createMeasurementWeight },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const editMeasurementWeight: any = createAsyncThunk(
  'patients/editMeasurementWeight',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.editMeasurementWeight },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const deleteMeasurementWeight: any = createAsyncThunk(
  'patients/deleteMeasurementWeight',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.deleteMeasurementWeight },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const getMeasurementsCholesterol: any = createAsyncThunk(
  'patients/getMeasurementsCholesterol',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.getMeasurementsCholesterol },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const getMeasurementCholesterol: any = createAsyncThunk(
  'patients/getMeasurementCholesterol',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.getMeasurementCholesterol },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const createMeasurementCholesterol: any = createAsyncThunk(
  'patients/createMeasurementCholesterol',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.createMeasurementCholesterol },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const editMeasurementCholesterol: any = createAsyncThunk(
  'patients/editMeasurementCholesterol',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.editMeasurementCholesterol },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const deleteMeasurementCholesterol: any = createAsyncThunk(
  'patients/deleteMeasurementCholesterol',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.deleteMeasurementCholesterol },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const getMeasurementsPressure: any = createAsyncThunk(
  'patients/getMeasurementsPressure',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.getMeasurementsPressure },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const getMeasurementPressure: any = createAsyncThunk(
  'patients/getMeasurementPressure',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.getMeasurementPressure },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const createMeasurementPressure: any = createAsyncThunk(
  'patients/createMeasurementPressure',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.createMeasurementPressure },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const editMeasurementPressure: any = createAsyncThunk(
  'patients/editMeasurementPressure',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.editMeasurementPressure },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const deleteMeasurementPressure: any = createAsyncThunk(
  'patients/deleteMeasurementPressure',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.deleteMeasurementPressure },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const getStorageAttachments: any = createAsyncThunk(
  'patients/getStorageAttachments',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.getStorageAttachments },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const getStorageAttachment: any = createAsyncThunk(
  'patients/getStorageAttachment',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.getStorageAttachment },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const createStorageAttachment: any = createAsyncThunk(
  'patients/createStorageAttachment',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.createStorageAttachment },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const editStorageAttachment: any = createAsyncThunk(
  'patients/editStorageAttachment',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.editStorageAttachment },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const deleteStorageAttachment: any = createAsyncThunk(
  'patients/deleteStorageAttachment',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.deleteStorageAttachment },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const downloadStorageAttachment: any = createAsyncThunk(
  'patients/downloadStorageAttachment',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.downloadStorageAttachment },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const getStorageRadiology: any = createAsyncThunk(
  'patients/getStorageRadiology',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.getStorageRadiology },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const getStorageRadiologyFile: any = createAsyncThunk(
  'patients/getStorageRadiologyFile',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.getStorageRadiologyFile },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const createStorageRadiologyFile: any = createAsyncThunk(
  'patients/createStorageRadiologyFile',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.createStorageRadiologyFile },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const editStorageRadiologyFile: any = createAsyncThunk(
  'patients/editStorageRadiologyFile',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.editStorageRadiologyFile },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const deleteStorageRadiologyFile: any = createAsyncThunk(
  'patients/deleteStorageRadiologyFile',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.deleteStorageRadiologyFile },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const downloadStorageRadiologyFile: any = createAsyncThunk(
  'patients/downloadStorageRadiologyFile',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.downloadStorageRadiologyFile },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const getStorageLaboratory: any = createAsyncThunk(
  'patients/getStorageLaboratory',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.getStorageLaboratory },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const getStorageLaboratoryFile: any = createAsyncThunk(
  'patients/getStorageLaboratoryFile',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.getStorageLaboratoryFile },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const createStorageLaboratoryFile: any = createAsyncThunk(
  'patients/createStorageLaboratoryFile',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.createStorageLaboratoryFile },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const editStorageLaboratoryFile: any = createAsyncThunk(
  'patients/editStorageLaboratoryFile',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.editStorageLaboratoryFile },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const deleteStorageLaboratoryFile: any = createAsyncThunk(
  'patients/deleteStorageLaboratoryFile',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.deleteStorageLaboratoryFile },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const downloadStorageLaboratoryFile: any = createAsyncThunk(
  'patients/downloadStorageLaboratoryFile',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.patients.downloadStorageLaboratoryFile },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const getEnums: any = createAsyncThunk('patients/getEnums', async (payload, { dispatch }) => {
  return await callBaseApiEndpoint(dispatch, {
    requestData: { ...API.patients.getEnums },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const editPersonalData: any = createAsyncThunk('patients/editPersonalData', async (payload, { dispatch }) => {
  return await callBaseApiEndpoint(dispatch, {
    requestData: { ...API.patients.editPersonalData },
    params: payload,
    throwError: true,
    showSuccess: true,
  }).then((res: any) => res)
})

export const editPatientData: any = createAsyncThunk('patients/editPatientData', async (payload, { dispatch }) => {
  return await callBaseApiEndpoint(dispatch, {
    requestData: { ...API.patients.editPatientData },
    params: payload,
    throwError: true,
    showSuccess: true,
  }).then((res: any) => res)
})

export const verifyPatient: any = createAsyncThunk('patients/verifyPatient', async (payload, { dispatch }) => {
  return await callBaseApiEndpoint(dispatch, {
    requestData: { ...API.patients.verifyPatient },
    params: payload,
    throwError: true,
    showSuccess: true,
  }).then((res: any) => res)
})
