export default {
  backend: {
    apiBaseUrl: '%api-base-url-placeholder%',
    baseUrl: '%base-url-placeholder%',
    authUrl: '%auth-url-placeholder%',
    calUrl: '%cal-url-placeholder%',
    fileUrl: '%file-url-placeholder%',
    orderUrl: '%order-url-placeholder%',
    notificationUrl: '%notification-url-placeholder%',
    telerehabilitationUrl: '%telerehabilitation-url-placeholder%',
    edgUrl: '%edg-url-placeholder%',
  },
  rocketChat: {
    chatBaseUrl: '%chat-base-url-placeholder%',
    chatUrl: '%chat-url-placeholder%',
  },
  sentry: 'https://0af11321d7634d1da1aba30c87fba3b3@o809071.ingest.sentry.io/5840778',
}
