import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import {
  DispatchActionInterface,
  DispatchActionReducerRocketChatInterface,
  RouterInterface,
  RouterUtilsInterface,
} from '../interfaces'
import ROUTER from '../router'
import { ROUTES } from '../router/routes'
import {
  callAuthApi,
  callBaseApi,
  callCalApi,
  callEdgApi,
  callFileApi,
  callNotificationApi,
  callOrderApi,
  callTelerehabilitationApi,
} from '../services/fetch'
import { callChatApi } from '../services/rocketChat'
import store from './index'
import { authorizeJitsi } from './modules/auth/actions'
import { createDirectMessage, sendMessage } from './modules/chat/actions'

export const callAuthApiEndpoint = (dispatch: any, payload: any) => {
  return callAuthApi(payload)
}

export const callBaseApiEndpoint = (dispatch: any, payload: any) => {
  return callBaseApi(payload)
}

export const callNotificationApiEndpoint = (dispatch: any, payload: any) => {
  return callNotificationApi(payload)
}

export const callCalApiEndpoint = (dispatch: any, payload: any) => {
  return callCalApi(payload)
}

export const callChatApiEndpoint = (dispatch: any, payload: any) => {
  return callChatApi(payload)
}

export const callFileApiEndpoint = (dispatch: any, payload: any) => {
  return callFileApi(payload)
}

export const callOrderApiEndpoint = (dispatch: any, payload: any) => {
  return callOrderApi(payload)
}

export const callTelerehabilitationApiEndpoint = (dispatch: any, payload: any) => {
  return callTelerehabilitationApi(payload)
}

export const callEdgApiEndpoint = (dispatch: any, payload: any) => {
  return callEdgApi(payload)
}

export const replaceRouteParams = (route: string, params?: { key: string; value?: string | null }[]) => {
  if (params) {
    params.map(param => {
      if (param.value) {
        route = route?.replace(param.key, param.value)
      }
    })

    return route
  } else {
    return route
  }
}

export function bytesToSize(bytes: number) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes == 0) return '0 Byte'
  const i = Math.floor(Math.log(bytes) / Math.log(1024))
  return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i]
}

export const isCurrentRoute = (route: string, params?: { key: string; value?: string }[], exact = false) => {
  if (exact) {
    return window.location.pathname === replaceRouteParams(route, params)
  } else {
    return window.location.pathname.search(replaceRouteParams(route, params)) === 0
  }
}

export const getBreadcrumbs = (routeStructure: RouterInterface[], params?: { key: string; value?: string }[]) => {
  const breadcrumbs: RouterUtilsInterface[] = []
  const { authUser } = store.getState().auth

  const findMatchBreadcrumbs = (routes: RouterInterface[], params?: { key: string; value?: string }[]) => {
    routes.map((route: RouterInterface) => {
      if (route.breadcrumb) {
        let role = route.breadcrumb.role
        if (!route.breadcrumb.role) role = route.role
        if (!role || role.some(val => val === authUser?.role.level)) {
          if (
            route.breadcrumb?.show !== false &&
            window.location.pathname.includes(replaceRouteParams(route.path, params))
          ) {
            breadcrumbs.push({
              title: route.title,
              path: replaceRouteParams(route.path, params),
              breadcrumb: {
                name: route.breadcrumb.name,
                isLink: route.breadcrumb.isLink !== undefined ? route.breadcrumb.isLink : true,
                redirect: route.breadcrumb?.redirect
                  ? replaceRouteParams(getRedirectByRole(route.breadcrumb.redirect), params)
                  : undefined,
              },
            })
          }
        }
      }

      if (route.routes) {
        findMatchBreadcrumbs(route.routes, params)
      }
    })
  }

  findMatchBreadcrumbs(routeStructure, params)
  return breadcrumbs
}

export const getTabs = (path: string, params?: { key: string; value?: string }[], includeParent = false) => {
  const { authUser } = store.getState().auth
  const { patient } = store.getState().patients

  const findMatchTabs = (routes: RouterInterface[], params?: { key: string; value?: string }[]) => {
    const tabs: RouterUtilsInterface[] = []
    routes.map((route: RouterInterface) => {
      if (route.tab) {
        let role = route.tab.role
        if (!route.tab.role) role = route.role
        if (!role || role.some(val => val === authUser?.role.level)) {
          if (route.path === ROUTES.PATIENTS_DETAIL_ANAMNESIS_GYNAECOLOGY && patient?.flags.hide_gynaecology) return
          tabs.push({
            title: route.title,
            path: replaceRouteParams(route.path, params),
            tab: {
              name: route.tab.name,
              redirect: route.tab?.redirect
                ? replaceRouteParams(getRedirectByRole(route.tab.redirect), params)
                : undefined,
            },
            routes:
              route.routes && route.routes?.find(route => route.tab !== undefined)
                ? findMatchTabs(route.routes, params)
                : undefined,
          })
        }
      }
    })
    return tabs
  }

  if (includeParent) {
    return findMatchTabs([getRoutesStructure(path, params)], params)
  } else {
    return findMatchTabs(getRoutesStructure(path, params).routes, params)
  }
}

export const getRoutesStructure = (path: string, params?: { key: string; value?: string }[]) => {
  let found: any = []

  const findMatch = (routes: RouterInterface[]) => {
    routes.map((route: RouterInterface) => {
      if (replaceRouteParams(route.path, params) === replaceRouteParams(path, params)) {
        found = route
      } else if (route.routes) {
        findMatch(route.routes)
      }
    })
  }

  findMatch(ROUTER)
  return found
}

export const getNavbar = (params?: { key: string; value: string }[]) => {
  const navbar: RouterUtilsInterface[] = []
  const { authUser } = store.getState().auth

  const findMatch = (routes: RouterInterface[], params?: { key: string; value: string }[]) => {
    routes.map((route: RouterInterface) => {
      if (route.navbar) {
        let role = route.navbar.role
        if (!route.navbar.role) role = route.role
        if (!role || role.some(val => val === authUser?.role.level)) {
          navbar.push({
            title: route.title,
            path: replaceRouteParams(route.path, params),
            navbar: {
              name: route.navbar.name,
              icon: route.navbar.icon,
              role: route.navbar.role,
              position: route.navbar.position,
              redirect: route.navbar?.redirect
                ? replaceRouteParams(getRedirectByRole(route.navbar.redirect), params)
                : undefined,
              badge: route.navbar.badge,
            },
          })
        }
      }
      if (route.routes) {
        findMatch(route.routes, params)
      }
    })
  }

  findMatch(ROUTER, params)
  return navbar
}

export const getRedirectByRole = (path: string | { [field: string]: string }) => {
  const { authUser } = store.getState().auth
  const role = authUser?.role.level || ''

  if (typeof path === 'string') {
    return path
  } else {
    return path[role]
  }
}

export const checkRole = (user: null | { role: { level: string } }, role: string | string[]) => {
  if (user?.role.level) {
    if (Array.isArray(role)) {
      return role.includes(user.role.level)
    } else {
      return user.role.level === role
    }
  }

  return false
}

export const getValueFromStore = (name?: string) => {
  let needles, finalValue
  const appStore = store.getState()

  if (name && name.includes('store')) {
    needles = name.replace('store.', '')
    finalValue = recursivelyFindValue(appStore, needles)[0]
  }

  if (finalValue !== undefined) {
    return finalValue
  } else {
    return name
  }
}

export const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(' ')
}

export const notifySuccess = (text: string | JSX.Element) => {
  toast.success(text, {
    position: 'bottom-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    className: 'bg-brand',
  })
}

export const notifyWarning = (text: string | JSX.Element) => {
  toast.warning(text, {
    position: 'bottom-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  })
}

export const notifyError = (text: string | JSX.Element) => {
  toast.error(text, {
    position: 'bottom-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    className: 'bg-red-600',
  })
}

export const recursivelyFindValue = (object: any, keyName: string) => {
  let needles
  const finalValue: any = []

  if (keyName.includes('.')) {
    needles = keyName.split('.')
  } else {
    needles = [keyName]
  }

  let tmp = { ...object }

  needles.map((needle, j) => {
    if (tmp[needle] !== null && tmp[needle] !== undefined) {
      tmp = tmp[needle]

      if (j + 1 === needles.length) {
        finalValue.push(tmp)
      }
    }
  })

  return finalValue
}

export const getValueFromObject = (objects: any, keyNames: string | string[] = 'value') => {
  let finalValue: any = []

  if (Array.isArray(objects)) {
    // If object is an array
    objects.map((object: any, index) => {
      finalValue[index] = []

      if (Array.isArray(keyNames)) {
        // If keyNames is an array and object is an array
        keyNames.map(keyName => {
          if (recursivelyFindValue(object, keyName).length !== 0)
            finalValue[index].push(recursivelyFindValue(object, keyName)[0])
        })
      } else {
        // If keyNames is not an array and object is an array
        if (recursivelyFindValue(object, keyNames).length !== 0)
          finalValue[index] = recursivelyFindValue(object, keyNames)[0]
      }
    })
  } else {
    // If object is not an array and keyNames is an array
    if (Array.isArray(keyNames)) {
      keyNames.map(keyName => {
        if (recursivelyFindValue(objects, keyName).length !== 0) finalValue.push(recursivelyFindValue(objects, keyName))
      })
    } else {
      // If object is not an array and keyNames is not an array
      finalValue = recursivelyFindValue(objects, keyNames)[0]
    }
  }

  return finalValue
}

export const createEnumObjectFromValue = (namespace?: string, value?: string) => {
  const { t }: any = useTranslation(['enums'])

  if (value) {
    if (namespace) {
      return { name: t(`enums.${namespace}.${value}`), value: value }
    } else {
      return { name: value, value: value }
    }
  }
  return null
}

export const createArrayOfEnumObjectsFromArrayOfValues = (namespace: string, values?: string[]) => {
  const { t }: any = useTranslation(['enums'])

  if (values && values.length > 0) {
    return values.map((val: any) => ({ name: t(`enums.${namespace}.${val}`), value: val }))
  }
  return []
}

export const createArrayOfObjectsFromArrayOfObjects = (
  values?: any[],
  keyNames?: { name: string; value?: string | string[] }[]
) => {
  const array: any = []

  if (values && values.length > 0) {
    values?.map((val: any, index) => {
      array[index] = {}
      keyNames?.map(keyName => {
        if (keyName?.value) {
          if (Array.isArray(keyName.value)) {
            let composite = ''
            keyName.value.map(key => {
              composite += `${val[key] ? val[key] : key} `
            })
            array[index][keyName.name] = composite
          } else {
            array[index][keyName.name] = val[keyName.value]
          }
        }
        return undefined
      })
    })
    return array
  }

  return []
}

export const sortArray = (array: any[], key: string, order: 'asc' | 'desc') => {
  const ordering = {
    asc: [1, -1],
    desc: [-1, 1],
  }
  if (Array.isArray(array) && array.length > 0) {
    return [...array].sort((a, b) => (b[key] < a[key] ? ordering[order][0] : ordering[order][1]))
  }
  return []
}

export const isFunctionOrBoolean = (functionToCheck?: boolean | (() => boolean)) => {
  if (typeof functionToCheck === 'function') {
    return functionToCheck()
  }
  return functionToCheck
}

export const returnDisabled = (disabled: any, row: any) => {
  if (!disabled) return false
  if (typeof disabled === 'function') {
    return disabled(row)
  }
  return disabled
}

export const download = (fileName: string, url: string) => {
  fetch(url, {
    method: 'GET',
  })
    .then(response => response.blob())
    .then(blob => {
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName)

      // Append to html link element page
      document.body.appendChild(link)

      // Start download
      link.click()

      // Clean up and remove the link
      link?.parentNode?.removeChild(link)
    })
}

export const callHandler = (rocketChatUsername?: string, rid?: string) => {
  if (rid) {
    return store.dispatch(authorizeJitsi()).then((jitsiAction: DispatchActionInterface) => {
      if (jitsiAction.payload?.status === 'ok') {
        window.open(jitsiAction.payload.data.url)

        return store.dispatch(
          sendMessage({
            message: {
              msg: jitsiAction.payload.data.room_id,
              rid: rid,
              t: 'jitsi',
            },
          })
        )
      }
    })
  }

  return store
    .dispatch(createDirectMessage({ username: rocketChatUsername }))
    .then((chatAction: DispatchActionReducerRocketChatInterface) => {
      if (chatAction.payload?.success) {
        return store.dispatch(authorizeJitsi()).then((jitsiAction: DispatchActionInterface) => {
          if (jitsiAction.payload?.status === 'ok') {
            window.open(jitsiAction.payload.data.url)

            return store.dispatch(
              sendMessage({
                message: {
                  msg: jitsiAction.payload.data.room_id,
                  rid: chatAction.payload.room.rid,
                  t: 'jitsi',
                },
              })
            )
          }
        })
      }
    })
}

export const getMinValue = (values?: any[]) => {
  let min = 999

  values?.map(value => {
    if (value.value < min) {
      min = value.value
    }
  })

  return min
}

export const getMaxValue = (values?: any[]) => {
  let max = -999
  values?.map(value => {
    if (value.value > max) {
      max = value.value
    }
  })

  return max
}
