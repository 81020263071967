import { FormikValues } from 'formik'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { PARAMS } from '../../../enums'
import { DispatchActionInterface, FormikOnSubmitType } from '../../../interfaces'
import { ROUTES } from '../../../router/routes'
import { RootState, useAppDispatch } from '../../../store'
import { createCheckup, editAnamnesisAllergies, editAnamnesisPersonal } from '../../../store/modules/patients/actions'
import { getValueFromObject, replaceRouteParams } from '../../../store/utils'

import RegisterAdditionalDataStep1Form from './components/RegisterAdditionalDataStep1Form'
import RegisterAdditionalDataStep2Form from './components/RegisterAdditionalDataStep2Form'

export const RegisterAdditionalDataHandler = () => {
  const { authUser } = useSelector((state: RootState) => state.auth)
  const [additionalDataStep, setAdditionalDataStep] = useState(1)
  const [additionalData, setAdditionalData] = useState<FormikValues | null>(null)
  const history = useHistory()
  const dispatch = useAppDispatch()

  const onSubmitStep1: FormikOnSubmitType = (values, setSubmitting) => {
    values[PARAMS.PATIENT_ID] = authUser?.role.object_id
    setSubmitting(false)
    setAdditionalData(values)
    setAdditionalDataStep(2)
  }

  const onSubmitStep2: FormikOnSubmitType = (values, setSubmitting) => {
    const send = { ...values }

    // Step 1 submit
    if (additionalData) {
      dispatch(createCheckup(additionalData))
    }

    // Step 2 submit

    if (getValueFromObject(send.allergies).length > 0) {
      const allergies = { allergies: getValueFromObject(send.allergies), other_allergies: false }
      dispatch(editAnamnesisAllergies({ [PARAMS.PATIENT_ID]: authUser?.role.object_id, ...allergies }))
    }

    if (getValueFromObject(send.other).length > 0 && getValueFromObject(send.movement).length > 0) {
      const diseases = {
        other: getValueFromObject(send.other),
        movement: getValueFromObject(send.movement),
      }

      dispatch(editAnamnesisPersonal({ [PARAMS.PATIENT_ID]: authUser?.role.object_id, ...diseases }))
    }

    setSubmitting(false)
    history.push(
      replaceRouteParams(ROUTES.PATIENTS_DETAIL_OVERVIEW, [
        { key: `:${PARAMS.PATIENT_ID}`, value: authUser?.role.object_id },
      ])
    )
  }

  const skipLastStepHandler = () => {
    let success = true

    if (additionalData) {
      dispatch(createCheckup(additionalData)).then((action: DispatchActionInterface) => {
        success = action.payload?.status === 'ok'
      })
    }

    if (success)
      history.push(
        replaceRouteParams(ROUTES.PATIENTS_DETAIL_OVERVIEW, [
          { key: `:${PARAMS.PATIENT_ID}`, value: authUser?.role.object_id },
        ])
      )
  }

  return (
    <>
      {additionalDataStep === 1 && (
        <RegisterAdditionalDataStep1Form
          previousValues={additionalData}
          onSkip={() => {
            setAdditionalData(null)
            setAdditionalDataStep(2)
          }}
          onSubmit={(values, setSubmitting) => onSubmitStep1(values, setSubmitting)}
        />
      )}
      {additionalDataStep === 2 && (
        <RegisterAdditionalDataStep2Form
          onSubmit={(values, setSubmitting) => onSubmitStep2(values, setSubmitting)}
          onSkip={skipLastStepHandler}
          onPrevious={() => setAdditionalDataStep(1)}
        />
      )}
    </>
  )
}

export default RegisterAdditionalDataHandler
