import { Link } from 'react-router-dom'
import { BACKGROUND_COLORS, TEXT_COLORS } from '../../../enums'
import { ButtonIconPositionsEnum, ButtonInterface, ButtonTypesEnum } from '../../../interfaces'
import { isFunctionOrBoolean } from '../../../store/utils'

const sizes = {
  small: '',
  normal: 'px-3 py-2',
  medium: 'px-5 py-3',
}

export const Button = (props: ButtonInterface) => {
  const {
    className,
    size,
    path,
    textColor,
    backgroundColor,
    title,
    type,
    onClick,
    icon,
    iconPosition,
    disabled,
    buttonRef,
    tooltip,
  } = props
  const Icon = icon

  const isIconRight = () => {
    return iconPosition === ButtonIconPositionsEnum.Right
  }

  const getButton = () => {
    switch (type) {
      case ButtonTypesEnum.Button: {
        return (
          <button
            disabled={isFunctionOrBoolean(disabled)}
            type="button"
            className={`${BACKGROUND_COLORS[backgroundColor]} ${TEXT_COLORS[textColor]} ${className ? className : ''} ${
              size ? sizes[size] : sizes['normal']
            } inline-flex justify-center items-center text-sm leading-4 font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2`}
            onClick={onClick}
            ref={buttonRef}
            data-tip={tooltip}
          >
            {!isIconRight() && Icon && (
              <Icon className={`${TEXT_COLORS[textColor]} h-5 w-5 ${title ? 'mr-1' : ''}`} aria-hidden="true" />
            )}
            {title}
            {isIconRight() && Icon && (
              <Icon className={`${TEXT_COLORS[textColor]} h-5 w-5 ${title ? 'ml-1' : ''}`} aria-hidden="true" />
            )}
          </button>
        )
      }
      case ButtonTypesEnum.ExternalLink: {
        return (
          <a
            href={path}
            className={`${BACKGROUND_COLORS[backgroundColor]} ${TEXT_COLORS[textColor]} ${className ? className : ''} ${
              size ? sizes[size] : sizes['normal']
            } ${
              disabled ? 'disabled cursor-not-allowed' : ''
            } inline-flex justify-center items-center text-sm leading-4 font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2`}
            target="_blank"
            data-tip={tooltip}
          >
            {!isIconRight() && Icon && (
              <Icon className={`${TEXT_COLORS[textColor]} h-5 w-5 ${title ? 'mr-1' : ''}`} aria-hidden="true" />
            )}
            {title}
            {isIconRight() && Icon && (
              <Icon className={`${TEXT_COLORS[textColor]} h-5 w-5 ${title ? 'ml-1' : ''}`} aria-hidden="true" />
            )}
          </a>
        )
      }
      case ButtonTypesEnum.Link: {
        return (
          path && (
            <Link
              to={`${disabled ? '#' : path}`}
              className={`${className ? className : ''} ${size ? sizes[size] : sizes['normal']} ${
                disabled
                  ? 'disabled cursor-not-allowed text-gray-400'
                  : `${BACKGROUND_COLORS[backgroundColor]} ${TEXT_COLORS[textColor]}`
              } inline-flex justify-center items-center text-sm leading-4 font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2`}
              ref={buttonRef}
              data-tip={tooltip}
            >
              {!isIconRight() && Icon && (
                <Icon className={`${TEXT_COLORS[textColor]} h-5 w-5 ${title ? 'mr-1' : ''}`} aria-hidden="true" />
              )}
              {title}
              {isIconRight() && Icon && (
                <Icon className={`${TEXT_COLORS[textColor]} h-5 w-5 ${title ? 'ml-1' : ''}`} aria-hidden="true" />
              )}
            </Link>
          )
        )
      }
      case ButtonTypesEnum.Submit: {
        return (
          <button
            type="submit"
            className={`${BACKGROUND_COLORS[backgroundColor]} ${TEXT_COLORS[textColor]} ${className ? className : ''} ${
              size ? sizes[size] : sizes['normal']
            } inline-flex justify-center items-center text-sm leading-4 font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2`}
            disabled={isFunctionOrBoolean(disabled)}
            ref={buttonRef}
            data-tip={tooltip}
          >
            {!isIconRight() && Icon && (
              <Icon className={`${TEXT_COLORS[textColor]} h-5 w-5 ${title ? 'mr-1' : ''}`} aria-hidden="true" />
            )}
            {title}
            {isIconRight() && Icon && (
              <Icon className={`${TEXT_COLORS[textColor]} h-5 w-5 ${title ? 'ml-1' : ''}`} aria-hidden="true" />
            )}
          </button>
        )
      }
    }
  }

  return <>{getButton()}</>
}

export default Button
