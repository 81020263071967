import { createAsyncThunk } from '@reduxjs/toolkit'
import { API } from '../../../api'
import { callNotificationApiEndpoint } from '../../utils'

// typePrefix = '${namespace}/functionName'
export const getNotifications: any = createAsyncThunk(
  'notifications/getNotifications',
  async (payload, { dispatch }) => {
    return await callNotificationApiEndpoint(dispatch, {
      requestData: { ...API.notification.getNotifications },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const sendNotificationCallback: any = createAsyncThunk(
  'notifications/sendNotificationCallback',
  async (payload, { dispatch }) => {
    return await callNotificationApiEndpoint(dispatch, {
      requestData: { ...API.notification.sendNotificationCallback },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const getNotificationsSettings: any = createAsyncThunk(
  'notifications/getNotificationsSettings',
  async (payload, { dispatch }) => {
    return await callNotificationApiEndpoint(dispatch, {
      requestData: { ...API.notification.getNotificationsSettings },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const editNotificationsSettings: any = createAsyncThunk(
  'notifications/editNotificationsSettings',
  async (payload, { dispatch }) => {
    return await callNotificationApiEndpoint(dispatch, {
      requestData: { ...API.notification.editNotificationsSettings },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const markNotifications: any = createAsyncThunk(
  'notifications/markNotifications',
  async (payload, { dispatch }) => {
    return await callNotificationApiEndpoint(dispatch, {
      requestData: { ...API.notification.markNotifications },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)
