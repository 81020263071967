import { createAsyncThunk } from '@reduxjs/toolkit'
import { API } from '../../../api'
import { callAuthApiEndpoint, callChatApiEndpoint } from '../../utils'

// typePrefix = '${namespace}/functionName'
export const getDirectMessage: any = createAsyncThunk('chat/getDirectMessage', async (payload, { dispatch }) => {
  return await callChatApiEndpoint(dispatch, {
    requestData: { ...API.chat.getDirectMessage },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const createDirectMessage: any = createAsyncThunk('chat/createDirectMessage', async (payload, { dispatch }) => {
  return await callChatApiEndpoint(dispatch, {
    requestData: { ...API.chat.createDirectMessage },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const getGroupChat: any = createAsyncThunk('chat/getGroupChat', async (payload, { dispatch }) => {
  return await callChatApiEndpoint(dispatch, {
    requestData: { ...API.chat.getGroupChat },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const addGroupChatOwner: any = createAsyncThunk('chat/addGroupChatOwner', async (payload, { dispatch }) => {
  return await callChatApiEndpoint(dispatch, {
    requestData: { ...API.chat.addGroupChatOwner },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const deleteGroupChat: any = createAsyncThunk('chat/deleteGroupChat', async (payload, { dispatch }) => {
  return await callChatApiEndpoint(dispatch, {
    requestData: { ...API.chat.deleteGroupChat },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const inviteGroupChatMember: any = createAsyncThunk(
  'chat/inviteGroupChatMember',
  async (payload, { dispatch }) => {
    return await callChatApiEndpoint(dispatch, {
      requestData: { ...API.chat.inviteGroupChatMember },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const kickGroupChatMember: any = createAsyncThunk('chat/kickGroupChatMember', async (payload, { dispatch }) => {
  return await callChatApiEndpoint(dispatch, {
    requestData: { ...API.chat.kickGroupChatMember },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const removeGroupChatOwner: any = createAsyncThunk(
  'chat/removeGroupChatOwner',
  async (payload, { dispatch }) => {
    return await callChatApiEndpoint(dispatch, {
      requestData: { ...API.chat.removeGroupChatOwner },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const getGroupChatMembers: any = createAsyncThunk('chat/getGroupChatMembers', async (payload, { dispatch }) => {
  return await callChatApiEndpoint(dispatch, {
    requestData: { ...API.chat.getGroupChatMembers },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const leaveGroupChat: any = createAsyncThunk('chat/leaveGroupChat', async (payload, { dispatch }) => {
  return await callChatApiEndpoint(dispatch, {
    requestData: { ...API.chat.leaveGroupChat },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const createGroupChat: any = createAsyncThunk('chat/createGroupChat', async (payload, { dispatch }) => {
  return await callChatApiEndpoint(dispatch, {
    requestData: { ...API.chat.createGroupChat },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const sendMessage: any = createAsyncThunk('chat/sendMessage', async (payload, { dispatch }) => {
  return await callChatApiEndpoint(dispatch, {
    requestData: { ...API.chat.sendMessage },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const authorizeChat: any = createAsyncThunk('auth/authorizeChat', async (payload, { dispatch }) => {
  return await callAuthApiEndpoint(dispatch, {
    requestData: { ...API.chat.authorizeChat },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const getChatUsers: any = createAsyncThunk('chat/getChatUsers', async (payload, { dispatch }) => {
  return await callAuthApiEndpoint(dispatch, {
    requestData: { ...API.chat.getChatUsers },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const getSubscriptions: any = createAsyncThunk('chat/getSubscriptions', async (payload, { dispatch }) => {
  return await callChatApiEndpoint(dispatch, {
    requestData: { ...API.chat.getSubscriptions },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const getSubscription: any = createAsyncThunk('chat/getSubscription', async (payload, { dispatch }) => {
  return await callChatApiEndpoint(dispatch, {
    requestData: { ...API.chat.getSubscription },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const markChatAsRead: any = createAsyncThunk('chat/markChatAsRead', async (payload, { dispatch }) => {
  return await callChatApiEndpoint(dispatch, {
    requestData: { ...API.chat.markChatAsRead },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})
