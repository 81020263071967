import { ArrowRightIcon } from '@heroicons/react/outline'
import { ArrowLeftIcon } from '@heroicons/react/solid'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import {
  ButtonBackgroundColorsEnum,
  ButtonIconPositionsEnum,
  ButtonTextColorsEnum,
  ButtonTypesEnum,
  RegisterStepInterface,
} from '../../../interfaces'
import Button from '../../common/button/Button'
import FormWrapper from '../../common/form/FormWrapper'
import CustomCheckbox from '../../common/inputs/components/CustomCheckbox'
import CustomInput from '../../common/inputs/components/CustomInput'

export const RegisterStep2Form = (props: RegisterStepInterface) => {
  const { onSubmit, onPrevious } = props
  const { t } = useTranslation(['register', 'yup'])

  const registerSchema = Yup.object({
    permanent_residence_address: Yup.object({
      street: Yup.string().required(t('yup:yup.required')),
      street_number: Yup.number().required(t('yup:yup.required')).typeError(t('yup:yup.number')),
      city: Yup.string().required(t('yup:yup.required')),
      postal_code: Yup.number().required(t('yup:yup.required')).typeError(t('yup:yup.number')),
    }),
    different_correspondence: Yup.boolean(),
    correspondence_address: Yup.object({
      street: Yup.string().when('different_correspondence', {
        is: true,
        then: Yup.string().required(t('yup:yup.required')),
      }),
      street_number: Yup.number().when('different_correspondence', {
        is: true,
        then: Yup.number().required(t('yup:yup.required')).typeError(t('yup:yup.number')),
      }),
      city: Yup.string().when('different_correspondence', {
        is: true,
        then: Yup.string().required(t('yup:yup.required')),
      }),
      postal_code: Yup.number().when('different_correspondence', {
        is: true,
        then: Yup.number().required(t('yup:yup.required')).typeError(t('yup:yup.number')),
      }),
    }),
  })

  return (
    <>
      <div>
        <h2 className="mt-6 text-3xl font-semibold text-gray-900">{t('register.register.step2Form.title')}</h2>
        <p className="mt-1 text-sm">{t('register.register.step2Form.subtitle')}</p>
      </div>
      <div className="mt-8">
        <div className="mt-6">
          <Formik
            initialValues={{
              permanent_residence_address: {
                street: '',
                street_number: '',
                city: '',
                postal_code: '',
              },
              different_correspondence: false,
              correspondence_address: {
                street: '',
                street_number: '',
                city: '',
                postal_code: '',
              },
            }}
            validationSchema={registerSchema}
            onSubmit={(values, { setSubmitting }) => onSubmit(values, setSubmitting)}
          >
            {({ isSubmitting, errors, values, touched }) => (
              <FormWrapper className="gap-x-6 gap-y-3">
                <CustomInput
                  type="text"
                  label={t('register.register.step2Form.street')}
                  name="permanent_residence_address.street"
                  errors={errors}
                  touched={touched}
                  className="col-span-12 sm:col-span-6"
                />

                <CustomInput
                  type="text"
                  label={t('register.register.step2Form.streetNumber')}
                  name="permanent_residence_address.street_number"
                  errors={errors}
                  touched={touched}
                  className="col-span-12 sm:col-span-6"
                />

                <CustomInput
                  type="text"
                  label={t('register.register.step2Form.city')}
                  name="permanent_residence_address.city"
                  errors={errors}
                  touched={touched}
                  className="col-span-12 sm:col-span-8"
                />

                <CustomInput
                  type="text"
                  label={t('register.register.step2Form.postalCode')}
                  name="permanent_residence_address.postal_code"
                  errors={errors}
                  touched={touched}
                  className="col-span-12 sm:col-span-4"
                />

                <CustomCheckbox
                  label={t('register.register.step2Form.differentCorrespondence')}
                  name="different_correspondence"
                  errors={errors}
                  touched={touched}
                  className="col-span-12 my-6"
                />

                <CustomInput
                  type="text"
                  label={t('register.register.step2Form.correspondenceStreet')}
                  name="correspondence_address.street"
                  errors={errors}
                  touched={touched}
                  className="col-span-12 sm:col-span-6"
                  disabled={!values.different_correspondence}
                />

                <CustomInput
                  type="text"
                  label={t('register.register.step2Form.correspondenceStreetNumber')}
                  name="correspondence_address.street_number"
                  errors={errors}
                  touched={touched}
                  className="col-span-12 sm:col-span-6"
                  disabled={!values.different_correspondence}
                />

                <CustomInput
                  type="text"
                  label={t('register.register.step2Form.correspondenceCity')}
                  name="correspondence_address.city"
                  errors={errors}
                  touched={touched}
                  className="col-span-12 sm:col-span-8"
                  disabled={!values.different_correspondence}
                />

                <CustomInput
                  type="text"
                  label={t('register.register.step2Form.correspondencePostalCode')}
                  name="correspondence_address.postal_code"
                  errors={errors}
                  touched={touched}
                  className="col-span-12 sm:col-span-4"
                  disabled={!values.different_correspondence}
                />

                <div className="flex col-span-12 justify-between mt-12">
                  <Button
                    type={ButtonTypesEnum.Button}
                    backgroundColor={ButtonBackgroundColorsEnum.White}
                    textColor={ButtonTextColorsEnum.Gray}
                    title={t('register.register.step2Form.previousStepButton')}
                    onClick={onPrevious}
                    icon={ArrowLeftIcon}
                    iconPosition={ButtonIconPositionsEnum.Left}
                  />

                  <Button
                    type={ButtonTypesEnum.Submit}
                    backgroundColor={ButtonBackgroundColorsEnum.Brand}
                    textColor={ButtonTextColorsEnum.White}
                    title={t('register.register.step2Form.nextStepButton')}
                    disabled={isSubmitting}
                    icon={ArrowRightIcon}
                    iconPosition={ButtonIconPositionsEnum.Right}
                  />
                </div>
              </FormWrapper>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}

export default RegisterStep2Form
