import { Dialog, Transition } from '@headlessui/react'
import { ExclamationIcon } from '@heroicons/react/outline'
import { Fragment, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ButtonBackgroundColorsEnum,
  ButtonTextColorsEnum,
  ButtonTypesEnum,
  ConfirmModalInterface,
} from '../../../interfaces'
import Button from '../button/Button'

export const ConfirmModal = (props: ConfirmModalInterface) => {
  const { isConfirmOpen, setIsConfirmOpen, onConfirm, customText, customTitle, customButton, customIcon } = props
  const closeButtonRef = useRef(null)
  const { t } = useTranslation(['modals'])

  return (
    <Transition.Root show={isConfirmOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="overflow-y-auto fixed inset-0 z-10"
        open={isConfirmOpen}
        onClose={() => setIsConfirmOpen(false)}
        initialFocus={closeButtonRef}
      >
        <div className="flex sm:block justify-center items-center sm:p-0 px-4 pt-4 pb-20 min-h-screen text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block overflow-hidden sm:p-6 px-4 pt-5 pb-4 sm:my-8 sm:w-full sm:max-w-sm text-left align-bottom sm:align-middle bg-white rounded-lg shadow-xl transition-all transform">
              <div>
                <div
                  className={`${
                    customIcon?.backgroundColor ? customIcon.backgroundColor : 'bg-red-100'
                  } flex justify-center items-center mx-auto w-12 h-12 rounded-full`}
                >
                  {customIcon?.icon ? (
                    <customIcon.icon
                      className={`${customIcon?.textColor ? customIcon.textColor : 'bg-red-600'} w-6 h-6`}
                      aria-hidden="true"
                    />
                  ) : (
                    <ExclamationIcon className="w-6 h-6 text-red-600" aria-hidden="true" />
                  )}
                </div>
                <div className="mt-3 sm:mt-5 text-center">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    {customTitle ? customTitle : t('modals.deleteModal.title')}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">{customText ? customText : t('modals.deleteModal.text')}</p>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-2 grid-flow-row-dense gap-3 mt-6">
                <Button
                  type={ButtonTypesEnum.Button}
                  textColor={customButton?.textColor ? customButton.textColor : ButtonTextColorsEnum.White}
                  backgroundColor={
                    customButton?.backgroundColor ? customButton.backgroundColor : ButtonBackgroundColorsEnum.Red
                  }
                  title={customButton?.title ? customButton.title : t('modals.deleteModal.deleteButton')}
                  onClick={onConfirm}
                />

                <Button
                  type={ButtonTypesEnum.Button}
                  textColor={ButtonTextColorsEnum.Gray}
                  backgroundColor={ButtonBackgroundColorsEnum.White}
                  title={t('modals.deleteModal.cancelButton')}
                  onClick={() => setIsConfirmOpen(false)}
                  buttonRef={closeButtonRef}
                />
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default ConfirmModal
