export const ROUTE_NAMES = {
  LOGIN: 'routes.login',
  ROLE_SELECT: 'routes.roleSelect',

  DASHBOARD: 'routes.dashboard',
  REGISTER: 'routes.register',
  REGISTER_ADDITIONAL_DATA: 'routes.registerAdditionalData',

  PASSWORD_RESET_EMAIL: 'routes.passwordReset.email',
  PASSWORD_RESET_NEW_PASSWORD: 'routes.passwordReset.password',

  PATIENTS: 'routes.patients.patients',
  PATIENTS_LIST: 'routes.patients.list',
  PATIENTS_DETAIL: 'store.patients.patient.full_name',
  PATIENTS_DETAIL_OVERVIEW: 'routes.patients.detail.overview',
  PATIENTS_DETAIL_ANAMNESIS: 'routes.patients.detail.anamnesis.anamnesis',
  PATIENTS_DETAIL_ANAMNESIS_PERSONAL: 'routes.patients.detail.anamnesis.personal',
  PATIENTS_DETAIL_ANAMNESIS_MEDICATIONS: 'routes.patients.detail.anamnesis.medications',
  PATIENTS_DETAIL_ANAMNESIS_ALLERGIES: 'routes.patients.detail.anamnesis.allergies',
  PATIENTS_DETAIL_ANAMNESIS_GYNAECOLOGY: 'routes.patients.detail.anamnesis.gynaecology',
  PATIENTS_DETAIL_ANAMNESIS_SURGERIES: 'routes.patients.detail.anamnesis.surgeries',
  PATIENTS_DETAIL_ANAMNESIS_EPIDEMIOLOGY: 'routes.patients.detail.anamnesis.epidemiology',
  PATIENTS_DETAIL_ANAMNESIS_ADDICTIVE_SUBSTANCES: 'routes.patients.detail.anamnesis.addictiveSubstances',
  PATIENTS_DETAIL_ANAMNESIS_FAMILY_HISTORY: 'routes.patients.detail.anamnesis.familyHistory',
  PATIENTS_DETAIL_ANAMNESIS_WORK_HISTORY: 'routes.patients.detail.anamnesis.workHistory',
  PATIENTS_DETAIL_ANAMNESIS_SOCIAL_HISTORY: 'routes.patients.detail.anamnesis.socialHistory',
  PATIENTS_DETAIL_ANAMNESIS_SPORTS_HISTORY: 'routes.patients.detail.anamnesis.sportsHistory',

  PATIENTS_DETAIL_CHECKUPS: 'routes.patients.detail.checkups',
  PATIENTS_DETAIL_MEASUREMENTS: 'routes.patients.detail.measurements.measurements',
  PATIENTS_DETAIL_MEASUREMENTS_GLYCEMIA: 'routes.patients.detail.measurements.glycemia',
  PATIENTS_DETAIL_MEASUREMENTS_OXYGEN_SATURATION: 'routes.patients.detail.measurements.oxygenSaturation',
  PATIENTS_DETAIL_MEASUREMENTS_ECG: 'routes.patients.detail.measurements.ecg',
  PATIENTS_DETAIL_MEASUREMENTS_BPM: 'routes.patients.detail.measurements.bpm',
  PATIENTS_DETAIL_MEASUREMENTS_SLEEP: 'routes.patients.detail.measurements.sleep',
  PATIENTS_DETAIL_MEASUREMENTS_AIR_POLUTION: 'routes.patients.detail.measurements.airPolution',
  PATIENTS_DETAIL_MEASUREMENTS_ENDOSCOPY: 'routes.patients.detail.measurements.endoscopy',
  PATIENTS_DETAIL_MEASUREMENTS_TEMPERATURE: 'routes.patients.detail.measurements.temperature',
  PATIENTS_DETAIL_MEASUREMENTS_WEIGHT: 'routes.patients.detail.measurements.weight',
  PATIENTS_DETAIL_MEASUREMENTS_HEIGHT: 'routes.patients.detail.measurements.height',
  PATIENTS_DETAIL_MEASUREMENTS_CHOLESTEROL: 'routes.patients.detail.measurements.cholesterol',
  PATIENTS_DETAIL_MEASUREMENTS_PRESSURE: 'routes.patients.detail.measurements.pressure',
  PATIENTS_DETAIL_FAMILY: 'routes.patients.detail.family',
  PATIENTS_DETAIL_DOCTORS: 'routes.patients.detail.doctors',
  PATIENTS_DETAIL_STORAGE: 'routes.patients.detail.storage.storage',
  PATIENTS_DETAIL_STORAGE_LABORATORY: 'routes.patients.detail.storage.laboratory',
  PATIENTS_DETAIL_STORAGE_RADIOLOGY: 'routes.patients.detail.storage.radiology',
  PATIENTS_DETAIL_STORAGE_ATTACHMENTS: 'routes.patients.detail.storage.attachments',

  DOCTORS: 'routes.doctors.doctors',
  DOCTORS_LIST: 'routes.doctors.list',
  DOCTORS_DETAIL: 'store.doctors.doctor.full_name',

  CHAT: 'routes.chat',
  TELEREHABILITATION: 'routes.telerehabilitation.telerehabilitation',
  TELEREHABILITATION_PRESETS: 'routes.telerehabilitation.presets',
  TELEREHABILITATION_CHARTS: 'routes.telerehabilitation.charts',
  TELEREHABILITATION_PATIENT: 'store.patients.patient.full_name',
  TELEREHABILITATION_PATIENT_DAY: 'store.telerehabilitation.exercisesDay.date',
  TELEREHABILITATION_PATIENT_EXERCISE: 'store.telerehabilitation.exercise.title',
  TELEREHABILITATION_PATIENT_EXERCISE_UPLOAD: 'routes.telerehabilitation.patientExerciseUpload',

  ORDERS: 'routes.orders.orders',
  ORDERS_ADMINISTRATION: 'routes.orders.administration.administration',
  ORDERS_ADMINISTRATION_HISTORY: 'routes.orders.administration.history',
  ORDERS_ADMINISTRATION_SERVICE_LIST: 'routes.orders.administration.serviceList',

  ORDERS_ADMINISTRATION_CATEGORY: 'routes.orders.administration.category.category',
  ORDERS_ADMINISTRATION_CATEGORY_LIST: 'routes.orders.administration.category.list',
  ORDERS_ADMINISTRATION_CATEGORY_ACCESSORY_LIST: 'routes.orders.administration.category.accessoryList',

  ORDERS_PATIENT: 'routes.orders.patient.patient',
  ORDERS_PATIENT_HISTORY: 'routes.orders.patient.history',
  ORDERS_PATIENT_CHECKOUT: 'routes.orders.patient.order',
  ORDERS_PATIENT_LIST: 'routes.orders.patient.list',
  ORDERS_PATIENT_DETAIL: 'routes.orders.patient.detail',

  CUSTOM_MEDICATIONS: 'routes.customMedications',

  SETTINGS: 'routes.settings.settings',
  SETTINGS_PROFILE: 'routes.settings.profile',
  SETTINGS_PASSWORD: 'routes.settings.password',
  SETTINGS_NOTIFICATIONS: 'routes.settings.notifications',
  SETTINGS_APPS: 'routes.settings.apps',

  JITSI: 'routes.jitsi',

  ERROR_403: 'routes.errors.error403',
  ERROR_404: 'routes.errors.error404',
}
