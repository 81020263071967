import React from 'react'
import { Route, Switch } from 'react-router-dom'
import PrivateRoute from '../../../components/common/auth/PrivateRoute'
import AppLayout from '../../../components/common/layout/AppLayout'
import ContentLayout from '../../../components/common/layout/ContentLayout'
import Tabs from '../../../components/common/tabs/Tabs'
import { RouterInterface } from '../../../interfaces'
import { ROUTES } from '../../../router/routes'
import { getRoutesStructure, getTabs } from '../../../store/utils'
import Error404 from '../../error/Error404'

export const SettingsPage = () => {
  const routes = getRoutesStructure(ROUTES.SETTINGS).routes
  const tabs = getTabs(ROUTES.SETTINGS)

  return (
    <AppLayout>
      <ContentLayout title="Nastavení" showRoleButtons={false}>
        <Tabs tabs={tabs} />

        <Switch>
          {routes.map((route: RouterInterface) => (
            <PrivateRoute
              key={route.title}
              title={route.title}
              path={route.path}
              component={route.component}
              exact={route.exact}
              role={route.role}
            />
          ))}
          <Route component={Error404} />
        </Switch>
      </ContentLayout>
    </AppLayout>
  )
}

export default SettingsPage
