import { PARAMS } from '../../enums'
import { ORDERS } from '../../store/modules/orders/types'

export default {
  createOrder: {
    id: ORDERS.ACTIONS.CREATE_ORDER,
    request: (data: any) => ({
      url: `/v1/orders`,
      method: 'POST',
      data,
    }),
  },
  getOrder: {
    id: ORDERS.ACTIONS.GET_ORDER,
    request: (data: any) => ({
      url: `/v1/orders/${data[PARAMS.ORDER_ID]}`,
      method: 'GET',
      data,
    }),
  },
  getPatientOrderHistoryList: {
    id: ORDERS.ACTIONS.GET_PATIENT_ORDER_HISTORY_LIST,
    request: (data: any) => ({
      url: `/v1/orders/my`,
      method: 'GET',
      params: {
        limit: data.limit,
        offset: data.offset,
      },
    }),
  },
  getOrderHistoryList: {
    id: ORDERS.ACTIONS.GET_ORDER_HISTORY_LIST,
    request: (data: any) => ({
      url: `/v1/orders`,
      method: 'GET',
      params: {
        limit: data.limit,
        offset: data.offset,
      },
    }),
  },
  downloadOrderInvoice: {
    id: ORDERS.ACTIONS.GET_ORDER_HISTORY_LIST,
    request: (data: any) => ({
      url: `/v1/orders/${data[PARAMS.ORDER_ID]}/invoice`,
      method: 'GET',
      data,
    }),
  },
  cancelOrder: {
    id: ORDERS.ACTIONS.CANCEL_ORDER,
    request: (data: any) => ({
      url: `/v1/orders/${data[PARAMS.ORDER_ID]}`,
      method: 'PUT',
      data,
    }),
  },
  getServices: {
    id: ORDERS.ACTIONS.GET_SERVICES,
    request: (data: any) => ({
      url: `/v1/services`,
      method: 'GET',
      params: {
        limit: data?.limit,
        offset: data?.offset,
      },
    }),
  },
  createService: {
    id: ORDERS.ACTIONS.CREATE_SERVICE,
    request: (data: any) => ({
      url: `/v1/services`,
      method: 'POST',
      data,
    }),
  },
  getService: {
    id: ORDERS.ACTIONS.GET_SERVICE,
    request: (data: any) => ({
      url: `/v1/services/${data[PARAMS.SERVICE_ID]}`,
      method: 'GET',
      data,
    }),
  },
  editService: {
    id: ORDERS.ACTIONS.EDIT_SERVICE,
    request: (data: any) => ({
      url: `/v1/services/${data[PARAMS.SERVICE_ID]}`,
      method: 'PUT',
      data,
    }),
  },
  deleteService: {
    id: ORDERS.ACTIONS.DELETE_SERVICE,
    request: (data: any) => ({
      url: `/v1/services/${data[PARAMS.SERVICE_ID]}`,
      method: 'DELETE',
      data,
    }),
  },
  getCategories: {
    id: ORDERS.ACTIONS.GET_CATEGORIES,
    request: (data: any) => ({
      url: `/v1/accessories/categories`,
      method: 'GET',
      params: {
        limit: data?.limit,
        offset: data?.offset,
        search: data?.search,
      },
    }),
  },
  createCategory: {
    id: ORDERS.ACTIONS.CREATE_CATEGORY,
    request: (data: any) => ({
      url: `/v1/accessories/categories`,
      method: 'POST',
      data,
    }),
  },
  getCategory: {
    id: ORDERS.ACTIONS.GET_CATEGORY,
    request: (data: any) => ({
      url: `/v1/accessories/categories/${data[PARAMS.ACCESSORY_CATEGORY_ID]}`,
      method: 'GET',
      data,
    }),
  },
  editCategory: {
    id: ORDERS.ACTIONS.EDIT_CATEGORY,
    request: (data: any) => ({
      url: `/v1/accessories/categories/${data[PARAMS.ACCESSORY_CATEGORY_ID]}`,
      method: 'PUT',
      data,
    }),
  },
  deleteCategory: {
    id: ORDERS.ACTIONS.DELETE_CATEGORY,
    request: (data: any) => ({
      url: `/v1/accessories/categories/${data[PARAMS.ACCESSORY_CATEGORY_ID]}`,
      method: 'DELETE',
      data,
    }),
  },
  getAccessories: {
    id: ORDERS.ACTIONS.GET_ACCESSORIES,
    request: (data: any) => ({
      url: `/v1/accessories/categories/${data[PARAMS.ACCESSORY_CATEGORY_ID]}/items`,
      method: 'GET',
      params: {
        limit: data?.limit,
        offset: data?.offset,
      },
    }),
  },
  createAccessory: {
    id: ORDERS.ACTIONS.CREATE_ACCESSORY,
    request: (data: any) => ({
      url: `/v1/accessories/categories/${data[PARAMS.ACCESSORY_CATEGORY_ID]}/items`,
      method: 'POST',
      data,
    }),
  },
  getAccessory: {
    id: ORDERS.ACTIONS.GET_ACCESSORY,
    request: (data: any) => ({
      url: `/v1/accessories/categories/${data[PARAMS.ACCESSORY_CATEGORY_ID]}/items/${data[PARAMS.ACCESSORY_ID]}`,
      method: 'GET',
      data,
    }),
  },
  editAccessory: {
    id: ORDERS.ACTIONS.EDIT_ACCESSORY,
    request: (data: any) => ({
      url: `/v1/accessories/categories/${data[PARAMS.ACCESSORY_CATEGORY_ID]}/items/${data[PARAMS.ACCESSORY_ID]}`,
      method: 'PUT',
      data,
    }),
  },
  deleteAccessory: {
    id: ORDERS.ACTIONS.DELETE_ACCESSORY,
    request: (data: any) => ({
      url: `/v1/accessories/categories/${data[PARAMS.ACCESSORY_CATEGORY_ID]}/items/${data[PARAMS.ACCESSORY_ID]}`,
      method: 'DELETE',
      data,
    }),
  },
}
