import { CHAT } from '../../store/modules/chat/types'

export default {
  getDirectMessage: {
    id: CHAT.ACTIONS.GET_DIRECT_MESSAGE,
    request: (data: any) => ({
      url: '/im.messages',
      method: 'GET',
      params: {
        username: data.username,
      },
    }),
  },
  createDirectMessage: {
    id: CHAT.ACTIONS.CREATE_DIRECT_MESSAGE,
    request: (data: any) => ({
      url: '/im.create',
      method: 'POST',
      data,
    }),
  },
  getGroupChat: {
    id: CHAT.ACTIONS.GET_GROUP_CHAT,
    request: (data: any) => ({
      url: '/groups.messages',
      method: 'GET',
      params: {
        roomId: data?.roomId,
      },
    }),
  },
  deleteGroupChat: {
    id: CHAT.ACTIONS.DELETE_GROUP_CHAT,
    request: (data: any) => ({
      url: '/groups.delete',
      method: 'POST',
      data,
    }),
  },
  addGroupChatOwner: {
    id: CHAT.ACTIONS.ADD_GROUP_CHAT_OWNER,
    request: (data: any) => ({
      url: '/groups.addOwner',
      method: 'POST',
      data,
    }),
  },
  inviteGroupChatMember: {
    id: CHAT.ACTIONS.INVITE_GROUP_CHAT_MEMBER,
    request: (data: any) => ({
      url: '/groups.invite',
      method: 'POST',
      data,
    }),
  },
  kickGroupChatMember: {
    id: CHAT.ACTIONS.KICK_GROUP_CHAT_MEMBER,
    request: (data: any) => ({
      url: '/groups.kick',
      method: 'POST',
      data,
    }),
  },
  removeGroupChatOwner: {
    id: CHAT.ACTIONS.REMOVE_GROUP_CHAT_OWNER,
    request: (data: any) => ({
      url: '/groups.removeOwner',
      method: 'POST',
      data,
    }),
  },
  getGroupChatMembers: {
    id: CHAT.ACTIONS.GET_GROUP_CHAT_MEMBERS,
    request: (data: any) => ({
      url: '/groups.members',
      method: 'GET',
      params: {
        roomId: data?.roomId,
      },
    }),
  },
  leaveGroupChat: {
    id: CHAT.ACTIONS.LEAVE_GROUP_CHAT,
    request: (data: any) => ({
      url: '/groups.leave',
      method: 'POST',
      data,
    }),
  },
  createGroupChat: {
    id: CHAT.ACTIONS.CREATE_GROUP_CHAT,
    request: (data: any) => ({
      url: '/groups.create',
      method: 'POST',
      data,
    }),
  },
  sendMessage: {
    id: CHAT.ACTIONS.SEND_MESSAGE,
    request: (data: any) => ({
      url: '/chat.sendMessage',
      method: 'POST',
      data,
    }),
  },
  authorizeChat: {
    id: CHAT.ACTIONS.AUTHORIZE_CHAT,
    request: (data: any) => ({
      url: '/v1/user/rocket_chat',
      method: 'GET',
      data,
    }),
  },

  getChatUsers: {
    id: CHAT.ACTIONS.GET_CHAT_USERS,
    request: (data: any) => ({
      url: '/v1/rocketchat/users',
      method: 'GET',
      params: {
        limit: data?.limit,
        offset: data?.offset,
        search: data?.search,
      },
    }),
  },
  getSubscriptions: {
    id: CHAT.ACTIONS.GET_SUBSCRIPTIONS,
    request: (data: any) => ({
      url: '/subscriptions.get',
      method: 'GET',
      data,
    }),
  },
  getSubscription: {
    id: CHAT.ACTIONS.GET_SUBSCRIPTION,
    request: (data: any) => ({
      url: '/subscriptions.getOne',
      method: 'GET',
      params: {
        roomId: data.roomId,
      },
    }),
  },
  markChatAsRead: {
    id: CHAT.ACTIONS.MARK_CHAT_AS_READ,
    request: (data: any) => ({
      url: '/subscriptions.read',
      method: 'POST',
      data,
    }),
  },
}
