export const BACKGROUND_COLORS = {
  brand: 'border border-transparent bg-brand hover:bg-brand-dark focus:ring-brand shadow-sm',
  darkGreen: 'border border-transparent bg-green-900 hover:bg-green-900 focus:ring-green-900 shadow-sm',
  white: 'border border-gray-300 bg-white hover:bg-gray-100 focus:ring-brand shadow-sm',
  lightGray: 'border border-gray-300 bg-gray-200 hover:bg-gray-300 focus:ring-gray-300 shadow-sm',
  gray: 'border border-gray-300 bg-gray-700 hover:bg-gray-800 focus:ring-gray-800 shadow-sm',
  red: 'border border-transparent bg-red-600 hover:bg-red-700 focus:ring-red-600 shadow-sm',
  transparent: 'focus:ring-brand',
}

export const TEXT_COLORS = {
  gray: 'text-gray-700',
  red: 'text-red-400',
  white: 'text-white',
  black: 'text-black',
  brand: 'text-brand hover:text-brand-dark',
  darkGreen: 'text-green-900',
}

export enum COLORS_ENUM {
  RED = '#EF4444',
  GREEN = '#22C55E',
  PURPLE = '#C084FC',
  BLUE = '#3B82F6',
  WHITE = '#FFFFFF',
  YELLOW = '#FDE047',
  LIGHT_GREY = '#6B7280',
}
