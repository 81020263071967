export const AUTH = {
  NAMESPACE: 'auth',
  ACTIONS: {
    LOGIN: 'LOGIN',
    CHECK_LOGIN: 'CHECK_LOGIN',
    LOGIN_START: 'LOGIN_START',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    RESET_PASSWORD_EMAIL: 'RESET_PASSWORD_EMAIL',
    RESET_PASSWORD_NEW_PASSWORD: 'RESET_PASSWORD_NEW_PASSWORD',
    REGISTER: 'REGISTER',
    REGISTER_CHECK: 'REGISTER_CHECK',
    AUTHORIZE_JITSI: 'AUTHORIZE_JITSI',
    CHANGE_PASSWORD: 'CHANGE_PASSWORD',
    EDIT_PERSONAL_DATA: 'EDIT_PERSONAL_DATA',
    EDIT_PATIENT_DATA: 'EDIT_PATIENT_DATA',
    VERIFY_PATIENT: 'VERIFY_PATIENT',
  },
}
