import { ChatIcon, PhoneOutgoingIcon, PlusIcon } from '@heroicons/react/outline'
import { Formik } from 'formik'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { PARAMS, PHONE_NUMBER_CODES } from '../../../enums'
import {
  BaseParamsInterface,
  ButtonBackgroundColorsEnum,
  ButtonTextColorsEnum,
  ButtonTypesEnum,
  DispatchActionInterface,
  DispatchActionReducerRocketChatInterface,
  DoctorsTableInterface,
  FormikOnSubmitType,
  InputByTypeRow,
  InputTypeEnum,
  TableActionsInterface,
  TextByTypeColumnsInterface,
} from '../../../interfaces'
import { ROUTES } from '../../../router/routes'
import { RootState, useAppDispatch } from '../../../store'
import { createDirectMessage } from '../../../store/modules/chat/actions'
import {
  adminGiveDoctorPatientsAccess,
  adminRemoveDoctorPatientsAccess,
  createDoctor,
  deleteDoctor,
  getDoctors,
} from '../../../store/modules/doctors/actions'
import { getEnums } from '../../../store/modules/patients/actions'
import {
  callHandler,
  createArrayOfEnumObjectsFromArrayOfValues,
  getValueFromObject,
  replaceRouteParams,
} from '../../../store/utils'
import Button from '../../common/button/Button'
import DescriptionListForm from '../../common/description-list/components/DescriptionListForm'
import DescriptionList from '../../common/description-list/DescriptionList'
import FormWrapper from '../../common/form/FormWrapper'
import CustomSearchInput from '../../common/inputs/components/CustomSearchInput'
import ConfirmModal from '../../common/modals/ConfirmModal'
import CustomInputModal from '../../common/modals/CustomInputModal'
import TableModal from '../../common/table/components/TableModal'
import Table from '../../common/table/Table'

export const DoctorsTable = (props: DoctorsTableInterface) => {
  const {
    dispatchCall,
    data,
    limit,
    isSearchable,
    canViewDetail,
    canAdd,
    canAddAccess,
    canRemoveAccess,
    canCreateCall,
    canCreateChat,
    loading,
  } = props
  const { authUser } = useSelector((state: RootState) => state.auth)
  const { enums, getEnumsLoading } = useSelector((state: RootState) => state.patients)
  const { doctor, getDoctorsLoading } = useSelector((state: RootState) => state.doctors)
  const [firstLoad, setFirstLoad] = useState(true)
  const [isOpen, setIsOpen] = useState(false)
  const [isCreate, setIsCreate] = useState(false)
  const [isOpenAddAccessModal, setIsOpenAddAccessModal] = useState(false)
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  const [actions, setActions] = useState<TableActionsInterface[]>([])
  const [search, setSearch] = useState('')
  const [doctorId, setDoctorId] = useState('')
  const dispatch = useAppDispatch()
  const history = useHistory()
  const params: BaseParamsInterface = useParams()
  const { t } = useTranslation(['doctors'])

  useEffect(() => {
    loadEnums()
  }, [])

  useEffect(() => {
    getActions()
  }, [authUser])

  const loadEnums = () => {
    dispatch(getEnums({ enums: ['Expertise'] }))
  }

  const loadDoctors = (limit: number, offset: number, search?: string) => {
    dispatch(dispatchCall({ [PARAMS.PATIENT_ID]: params[PARAMS.PATIENT_ID], limit, offset, search })).then(() =>
      setFirstLoad(false)
    )
  }

  const expertises = createArrayOfEnumObjectsFromArrayOfValues('expertises', enums['Expertise'])

  const doctorsColumns: TextByTypeColumnsInterface[] = [
    {
      label: t('doctors.doctorsList.fullName'),
      values: 'full_name',
    },
    {
      label: t('doctors.doctorsList.email'),
      values: 'email',
    },
    {
      label: t('doctors.doctorsList.phoneNumber'),
      values: 'phone_number',
    },
  ]

  const doctorRows: InputByTypeRow[] = [
    {
      list_label: t('doctors.doctorsList.firstName'),
      name: 'first_name',
      value: doctor?.first_name,
      type: InputTypeEnum.Text,
      fullWidth: true,
    },
    {
      list_label: t('doctors.doctorsList.middleName'),
      name: 'middle_name',
      value: doctor?.middle_name,
      type: InputTypeEnum.Text,
      fullWidth: true,
    },
    {
      list_label: t('doctors.doctorsList.lastName'),
      name: 'last_name',
      value: doctor?.last_name,
      type: InputTypeEnum.Text,
      fullWidth: true,
    },
    {
      list_label: t('doctors.doctorsList.degreesBefore'),
      name: 'degrees_before',
      value: doctor?.degrees_before,
      type: InputTypeEnum.Text,
      fullWidth: true,
    },
    {
      list_label: t('doctors.doctorsList.degreesAfter'),
      name: 'degrees_after',
      value: doctor?.degrees_after,
      type: InputTypeEnum.Text,
      fullWidth: true,
    },
    {
      list_label: t('doctors.doctorsList.email'),
      name: 'email',
      value: doctor?.email,
      type: InputTypeEnum.Text,
      fullWidth: true,
    },
    {
      list_label: t('doctors.doctorsList.phoneNumberCode'),
      name: 'phone_number_code',
      value: doctor?.phone_number_code,
      type: InputTypeEnum.Select,
      options: PHONE_NUMBER_CODES,
      fullWidth: true,
    },
    {
      list_label: t('doctors.doctorsList.phoneNumber'),
      name: 'phone_number',
      value: doctor?.phone_number,
      type: InputTypeEnum.Text,
      fullWidth: true,
    },
    {
      list_label: t('doctors.doctorsList.classification'),
      name: 'classification',
      value: doctor?.classification,
      type: InputTypeEnum.Select,
      options: expertises,
      t,
      namespace: 'enums:enums.expertises.',
      fullWidth: true,
    },
  ]

  const openCreateModal = () => {
    setIsCreate(true)
    setIsOpen(true)
  }

  const doctorDeleteHandler = (_id: string) => {
    dispatch(deleteDoctor({ [PARAMS.DOCTOR_ID]: _id })).then((action: DispatchActionInterface) => {
      if (action.payload?.status === 'ok') {
        setIsOpen(false)
        loadDoctors(limit, 0)
      }
    })
  }

  const doctorSubmitHandler: FormikOnSubmitType = (values, setSubmitting) => {
    const send = { ...values }
    send.phone_number_code = getValueFromObject(values.phone_number_code)
    send.classification = getValueFromObject(values.classification)

    dispatch(createDoctor(send)).then((action: DispatchActionInterface) => {
      setSubmitting(false)
      if (action.payload?.status === 'ok') {
        setIsOpen(false)
        loadDoctors(limit, 0)
      }
    })
  }

  const removeAccessHandler = (_id: string) => {
    dispatch(
      adminRemoveDoctorPatientsAccess({ [PARAMS.PATIENT_ID]: params[PARAMS.PATIENT_ID], [PARAMS.DOCTOR_ID]: _id })
    ).then((action: DispatchActionInterface) => {
      if (action.payload?.status === 'ok') {
        loadDoctors(limit, 0)
        setIsConfirmOpen(false)
      }
    })
  }

  const onAddAccessSubmitHandler: FormikOnSubmitType = (values, setSubmitting) => {
    const send = { ...values }
    send[PARAMS.DOCTOR_ID] = getValueFromObject(send.doctor)
    send[PARAMS.PATIENT_ID] = params[PARAMS.PATIENT_ID]

    dispatch(adminGiveDoctorPatientsAccess(send)).then((action: DispatchActionInterface) => {
      setSubmitting(false)
      if (action.payload?.status === 'ok') {
        setIsOpenAddAccessModal(false)
        loadDoctors(limit, 0)
      }
    })
  }

  const chatHandler = (rocketChatUsername: string) => {
    dispatch(createDirectMessage({ username: rocketChatUsername })).then(
      (action: DispatchActionReducerRocketChatInterface) => {
        if (action.payload?.success) {
          history.push(
            replaceRouteParams(ROUTES.CHAT_DM, [{ key: `:${PARAMS.CHAT_DM_ID}`, value: action.payload.room.rid }])
          )
        }
      }
    )
  }

  const getActions = () => {
    const tmp: TableActionsInterface[] = []

    if (canCreateCall) {
      tmp.push({
        tooltip: t('doctors.doctorsList.callButton'),
        icon: PhoneOutgoingIcon,
        type: ButtonTypesEnum.Button,
        onClickKey: 'rocketchat_username',
        onClick: callHandler,
        backgroundColor: ButtonBackgroundColorsEnum.Transparent,
        textColor: ButtonTextColorsEnum.Brand,
      })
    }

    if (canCreateChat) {
      tmp.push({
        tooltip: t('doctors.doctorsList.chatButton'),
        icon: ChatIcon,
        type: ButtonTypesEnum.Button,
        onClickKey: 'rocketchat_username',
        onClick: chatHandler,
        backgroundColor: ButtonBackgroundColorsEnum.Transparent,
        textColor: ButtonTextColorsEnum.Brand,
      })
    }

    if (canViewDetail) {
      tmp.push({
        title: t('doctors.doctorsList.detailButton'),
        type: ButtonTypesEnum.Link,
        dynamicLink: {
          path: ROUTES.DOCTORS_DETAIL,
          key: `:${PARAMS.DOCTOR_ID}`,
          value: '_id',
        },
        backgroundColor: ButtonBackgroundColorsEnum.Transparent,
        textColor: ButtonTextColorsEnum.Brand,
      })
    }

    if (canRemoveAccess) {
      tmp.push({
        title: t('doctors.doctorsList.removeAccessButton'),
        type: ButtonTypesEnum.Button,
        onClick: _id => {
          setIsConfirmOpen(true)
          setDoctorId(_id)
        },
        backgroundColor: ButtonBackgroundColorsEnum.Transparent,
        textColor: ButtonTextColorsEnum.Red,
      })
    }
    setActions(tmp)
  }

  const giveAccessRows: InputByTypeRow[] = [
    {
      label: t('doctors.doctorsList.doctor.label'),
      name: 'doctor',
      type: InputTypeEnum.ApiSearchSelect,
      className: 'col-span-12',
      placeholder: t('doctors.doctorsList.doctor.placeholder'),
      searchSelectDispatch: getDoctors,
      searchSelectStructure: { name: ['first_name', 'last_name'], value: '_id' },
    },
  ]

  const initialValues = {
    first_name: '',
    middle_name: '',
    last_name: '',
    degrees_before: '',
    degrees_after: '',
    email: '',
    phone_number_code: PHONE_NUMBER_CODES[0],
    phone_number: '',
    classification: expertises[0],
  }

  const addAccessInitialValues = {
    doctor_id: '',
  }

  const addAccessValidationSchema = Yup.object({
    doctor: Yup.object().typeError(t('yup:yup.required')),
  })

  const createDoctorValidationSchema = Yup.object({
    first_name: Yup.string().required(t('yup:yup.required')),
    middle_name: Yup.string().typeError(t('yup:yup.required')),
    last_name: Yup.string().required(t('yup:yup.required')),
    degrees_before: Yup.string().typeError(t('yup:yup.required')),
    degrees_after: Yup.string().typeError(t('yup:yup.required')),
    email: Yup.string().required(t('yup:yup.required')),
    phone_number_code: Yup.object().typeError(t('yup:yup.required')),
    phone_number: Yup.string().required(t('yup:yup.required')),
    classification: Yup.object().typeError(t('yup:yup.required')),
  })

  return (
    <>
      <div className="flex justify-between">
        <div>
          <h2 className="text-3xl font-bold">{t('doctors.doctorsList.componentTitle')}</h2>
        </div>

        <div className="flex space-x-3">
          {canAdd && (
            <Button
              type={ButtonTypesEnum.Button}
              icon={PlusIcon}
              onClick={openCreateModal}
              title={t('doctors.doctorsList.addRecordButton')}
              backgroundColor={ButtonBackgroundColorsEnum.Brand}
              textColor={ButtonTextColorsEnum.White}
            />
          )}
          {canAddAccess && (
            <Button
              type={ButtonTypesEnum.Button}
              icon={PlusIcon}
              onClick={() => setIsOpenAddAccessModal(true)}
              title={t('doctors.doctorsList.addAccessButton')}
              backgroundColor={ButtonBackgroundColorsEnum.Brand}
              textColor={ButtonTextColorsEnum.White}
            />
          )}
        </div>
      </div>
      {isSearchable && (
        <div>
          <Formik
            initialValues={{
              search: '',
            }}
            onSubmit={({ search }) => {
              setSearch('')
              setSearch(search)
            }}
          >
            {() => (
              <FormWrapper>
                <CustomSearchInput
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
                  className="col-start-1 col-end-13 md:col-end-6"
                />
              </FormWrapper>
            )}
          </Formik>
        </div>
      )}
      <Table
        columns={doctorsColumns}
        data={data}
        loading={getDoctorsLoading && firstLoad}
        pagination
        fetch={loadDoctors}
        limit={limit}
        emptyState={false}
        actions={actions}
        search={search}
      />
      <TableModal isOpen={isOpen} setIsOpen={setIsOpen}>
        <DescriptionList
          title={isCreate ? t('doctors.doctorsList.createModalTitle') : doctor?.full_name}
          itemID={doctor?._id}
          loading={loading || getEnumsLoading}
          isCreate={isCreate}
          isEditable={true}
          isDeletable={true}
          onDeleteClick={doctorDeleteHandler}
        >
          <DescriptionListForm
            itemID={doctor?._id}
            rows={doctorRows}
            isCreate={isCreate}
            initialValues={initialValues}
            onSubmitClick={doctorSubmitHandler}
            validationSchema={createDoctorValidationSchema}
          />
        </DescriptionList>
      </TableModal>
      {canAddAccess && (
        <CustomInputModal
          title={t('doctors.doctorsList.addAccessModal')}
          rows={giveAccessRows}
          isCustomModalOpen={isOpenAddAccessModal}
          setIsCustomModalOpen={setIsOpenAddAccessModal}
          onSubmit={onAddAccessSubmitHandler}
          initialValues={addAccessInitialValues}
          validationSchema={addAccessValidationSchema}
        />
      )}
      <ConfirmModal
        isConfirmOpen={isConfirmOpen}
        setIsConfirmOpen={setIsConfirmOpen}
        onConfirm={() => removeAccessHandler(doctorId)}
      />
    </>
  )
}

export default DoctorsTable
