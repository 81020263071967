import { createAsyncThunk } from '@reduxjs/toolkit'
import { API } from '../../../api'
import { callAuthApiEndpoint, callEdgApiEndpoint } from '../../utils'

// typePrefix = '${namespace}/functionName'
export const getEdgServicesMy: any = createAsyncThunk('edg/getEdgServicesMy', async (payload, { dispatch }) => {
  return await callEdgApiEndpoint(dispatch, {
    requestData: { ...API.edg.getEdgServicesMy },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const getEdgServicesAvailable: any = createAsyncThunk(
  'edg/getEdgServicesAvailable',
  async (payload, { dispatch }) => {
    return await callEdgApiEndpoint(dispatch, {
      requestData: { ...API.edg.getEdgServicesAvailable },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const connectEdgService: any = createAsyncThunk('edg/connectEdgService', async (payload, { dispatch }) => {
  return await callEdgApiEndpoint(dispatch, {
    requestData: { ...API.edg.connectEdgService },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const getEdgQrCode: any = createAsyncThunk('edg/getEdgQrCode', async (payload, { dispatch }) => {
  return await callAuthApiEndpoint(dispatch, {
    requestData: { ...API.edg.getEdgQrCode },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})
