import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import login_img from '../../assets/images/login.jpg'
import RoleSelectHandler from '../../components/role-select/RoleSelectHandler'
import { RootState } from '../../store'

export const RoleSelectPage = () => {
  const { authUser } = useSelector((state: RootState) => state.auth)
  const { t } = useTranslation(['roleSelect'])

  return (
    <>
      <div className="flex min-h-screen bg-white">
        <div className="flex flex-col flex-1 lg:flex-none justify-center py-12 px-4 sm:px-6 lg:px-20 xl:px-24">
          <div className="mx-auto w-full lg:w-96 max-w-sm">
            <div>
              <h2 className="mt-6 text-3xl font-extrabold text-gray-900">{t('roleSelect.title')}</h2>
              <p className="mt-1 text-sm">{`${t('roleSelect.subtitle')} ${authUser?.full_name}`} </p>
            </div>
            <div className="mt-8">
              <div className="mt-6">
                <RoleSelectHandler />
              </div>
            </div>
          </div>
        </div>
        <div className="hidden lg:block relative flex-1 w-0">
          <img className="object-cover absolute inset-0 w-full h-full" src={login_img} alt="" />
        </div>
      </div>
    </>
  )
}

export default RoleSelectPage
