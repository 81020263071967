import { createSlice } from '@reduxjs/toolkit'
import { LOCAL_STORAGE } from '../../../enums'
import { AuthState, DispatchActionReducerInterface } from '../../../interfaces'
import { checkLogin, login, register, resetPasswordEmail, resetPasswordNewPassword } from './actions'
import { AUTH } from './types'

const initialState: AuthState = {
  authUser: null,
  ownerRoleId: null,

  isAuthorized: false,
  tokenChecked: false,
  checkLoginLoading: false,

  roleSet: false,
  resetPasswordEmailSend: false,
  resetPasswordNewPasswordChanged: false,
  registerSuccess: false,
}

const authSlice = createSlice({
  name: AUTH.NAMESPACE,
  initialState,
  // Only frontend Logic
  reducers: {
    setUserBearerToken(state, action) {
      localStorage.setItem(LOCAL_STORAGE.USER_BEARER_TOKEN, action.payload)
    },
    removeUserBearerToken() {
      localStorage.removeItem(LOCAL_STORAGE.USER_BEARER_TOKEN)
    },
    setUserRoleId(state, action) {
      localStorage.setItem(LOCAL_STORAGE.USER_ROLE_ID, action.payload)
    },
    removeUserRoleId() {
      localStorage.removeItem(LOCAL_STORAGE.USER_ROLE_ID)
    },
    setUserChatAuthToken(state, action) {
      localStorage.setItem(LOCAL_STORAGE.USER_CHAT_AUTH_TOKEN, action.payload)
    },
    setUserChatId(state, action) {
      localStorage.setItem(LOCAL_STORAGE.USER_CHAT_ID, action.payload)
    },
    setOwnerRoleId(state, action) {
      localStorage.setItem(LOCAL_STORAGE.OWNER_ROLE_ID, action.payload)
      state.ownerRoleId = action.payload
    },
    getOwnerRoleId(state) {
      state.ownerRoleId = localStorage.getItem(LOCAL_STORAGE.OWNER_ROLE_ID)
    },
    logout(state) {
      state.authUser = null
      state.roleSet = false
      state.isAuthorized = false
      state.ownerRoleId = null
      localStorage.removeItem(LOCAL_STORAGE.OWNER_ROLE_ID)
      localStorage.removeItem(LOCAL_STORAGE.USER_BEARER_TOKEN)
      localStorage.removeItem(LOCAL_STORAGE.USER_ROLE_ID)
      localStorage.removeItem(LOCAL_STORAGE.USER_CHAT_AUTH_TOKEN)
      localStorage.removeItem(LOCAL_STORAGE.USER_CHAT_ID)
    },
  },
  extraReducers: builder => {
    // Reducers with calling external API
    builder
      .addCase(login.fulfilled, (state: AuthState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.isAuthorized = true
        } else {
          state.isAuthorized = false
        }
      })
      .addCase(login.rejected, (state: AuthState) => {
        state.isAuthorized = false
      })

      .addCase(checkLogin.pending, (state: AuthState) => {
        state.checkLoginLoading = true
      })
      .addCase(checkLogin.fulfilled, (state: AuthState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.authUser = action.payload.data
          state.tokenChecked = true
          state.isAuthorized = true

          if (state?.authUser) {
            state.authUser.full_name = `${state.authUser?.first_name} ${state.authUser?.last_name}`
          }

          if (action.payload.data.role.id) {
            state.roleSet = true
          } else {
            state.roleSet = false
          }
        } else {
          state.authUser = null
          state.tokenChecked = true
          state.isAuthorized = false
          state.roleSet = false
        }

        state.checkLoginLoading = false
      })
      .addCase(checkLogin.rejected, (state: AuthState) => {
        state.authUser = null
        state.tokenChecked = true
        state.isAuthorized = false
        state.roleSet = false

        state.checkLoginLoading = false
      })

      .addCase(resetPasswordEmail.fulfilled, (state: AuthState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.resetPasswordEmailSend = true
        } else {
          state.resetPasswordEmailSend = false
        }
      })
      .addCase(resetPasswordEmail.rejected, (state: AuthState) => {
        state.resetPasswordEmailSend = false
      })
      .addCase(resetPasswordNewPassword.fulfilled, (state: AuthState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.resetPasswordNewPasswordChanged = true
        } else {
          state.resetPasswordNewPasswordChanged = false
        }
      })
      .addCase(resetPasswordNewPassword.rejected, (state: AuthState) => {
        state.resetPasswordNewPasswordChanged = false
      })

      .addCase(register.fulfilled, (state: AuthState) => {
        state.registerSuccess = true
      })
      .addCase(register.rejected, (state: AuthState) => {
        state.registerSuccess = false
      })
  },
})

// Extract the action creators object and the reducer
const { actions, reducer } = authSlice
// Extract and export each action creator by name
export const {
  logout,
  removeUserBearerToken,
  removeUserRoleId,
  setUserRoleId,
  setUserBearerToken,
  setUserChatAuthToken,
  setUserChatId,
  getOwnerRoleId,
  setOwnerRoleId,
} = actions
// Export the reducer, either as a default or named export
export default reducer
