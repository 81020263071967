import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { PARAMS, ROLES } from '../../enums'
import { AuthUserRole, DispatchActionInterface } from '../../interfaces'
import { ROUTES } from '../../router/routes'
import { RootState, useAppDispatch } from '../../store'
import { checkLogin } from '../../store/modules/auth/actions'
import {
  logout,
  setOwnerRoleId,
  setUserChatAuthToken,
  setUserChatId,
  setUserRoleId,
} from '../../store/modules/auth/reducer'
import { authorizeChat } from '../../store/modules/chat/actions'
import { checkRole, replaceRouteParams } from '../../store/utils'
import ContentLoader from '../common/loader/ContentLoader'
import RoleSelectForm from './components/RoleSelectForm'

export const RoleSelectHandler = () => {
  const { checkLoginLoading } = useSelector((state: RootState) => state.auth)
  const [roles, setRoles] = useState<AuthUserRole[]>([])
  const [selected, setSelected] = useState<AuthUserRole | null>(null)
  const dispatch = useAppDispatch()

  const history = useHistory()

  useEffect(() => {
    dispatch(checkLogin()).then((action: DispatchActionInterface) => {
      if (action.payload?.status === 'ok') {
        dispatch(
          setOwnerRoleId(action.payload.data.roles.find((role: AuthUserRole) => role.patient_access === 'owner')?.id)
        )

        if (action.payload.data.roles.length === 1) {
          setRole(action.payload?.data.roles[0].id)
        } else {
          setRoles(action.payload.data.roles)
          setSelected(action.payload.data.roles[0])
        }
      }
    })
  }, [])

  const submitHandler = () => {
    if (selected) setRole(selected.id)
  }

  const changeAccountHandler = () => {
    dispatch(logout())
  }

  const setRole = (roleId: string) => {
    dispatch(setUserRoleId(roleId))
    dispatch(checkLogin()).then((action: DispatchActionInterface) => {
      dispatch(authorizeChat()).then((action: DispatchActionInterface) => {
        if (action.payload?.status === 'ok') {
          dispatch(setUserChatAuthToken(action.payload?.data.auth_token))
          dispatch(setUserChatId(action.payload?.data.user_id))
        }
      })

      if (checkRole(action.payload?.data, ROLES.PATIENT)) {
        history.push(
          replaceRouteParams(ROUTES.PATIENTS_DETAIL_OVERVIEW, [
            { key: `:${PARAMS.PATIENT_ID}`, value: action.payload?.data?.role.object_id },
          ])
        )
      }

      if (checkRole(action.payload?.data, [ROLES.DOCTOR, ROLES.ADMIN])) {
        history.push(ROUTES.DASHBOARD)
      }
    })
  }

  if (checkLoginLoading) {
    return <ContentLoader />
  }

  return (
    <RoleSelectForm
      submitHandler={submitHandler}
      changeAccountHandler={changeAccountHandler}
      setSelected={setSelected}
      selected={selected}
      roles={roles}
    />
  )
}

export default RoleSelectHandler
