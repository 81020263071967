import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { Formik } from 'formik'
import React, { Fragment, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonBackgroundColorsEnum, ButtonTextColorsEnum, ButtonTypesEnum, CustomModal } from '../../../interfaces'
import Button from '../button/Button'
import FormWrapper from '../form/FormWrapper'
import InputByType from '../inputs/InputByType'

export const CustomInputModal = (props: CustomModal) => {
  const {
    rows,
    isCustomModalOpen,
    setIsCustomModalOpen,
    onSubmit,
    initialValues,
    title,
    validationSchema,
    closeButtonTitle,
    submitButtonTitle,
  } = props
  const cancelButtonRef = useRef(null)
  const { t } = useTranslation(['modals'])

  return (
    <Transition.Root show={isCustomModalOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="overflow-y-auto fixed inset-0 z-10"
        open={isCustomModalOpen}
        onClose={() => setIsCustomModalOpen(false)}
        initialFocus={cancelButtonRef}
      >
        <div className="flex sm:block justify-center items-center sm:p-0 px-4 pt-4 pb-20 min-h-screen text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block px-6 pt-5 pb-4 sm:my-8 w-full max-w-lg text-left align-bottom sm:align-middle bg-white rounded-lg shadow-xl transition-all transform">
              {title && (
                <Dialog.Title as="h3" className="mb-4 text-lg font-medium leading-6 text-gray-900">
                  {title}
                </Dialog.Title>
              )}
              <Formik
                onSubmit={(values, { setSubmitting }) => {
                  onSubmit(values, setSubmitting)
                }}
                initialValues={initialValues}
                validationSchema={validationSchema}
              >
                {({ errors, touched, values, isSubmitting, setFieldValue }) => (
                  <>
                    <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                      <button
                        type="button"
                        className="text-gray-400 hover:text-gray-500 bg-white rounded-md focus:ring-2 focus:ring-brand focus:ring-offset-2 focus:outline-none"
                        onClick={() => setIsCustomModalOpen(false)}
                      >
                        <span className="sr-only">Close</span>
                        <XIcon className="w-6 h-6" aria-hidden="true" />
                      </button>
                    </div>
                    <FormWrapper className="space-y-4 w-full">
                      {rows.map((row: any, key) => (
                        <InputByType
                          key={key}
                          row={row}
                          errors={errors}
                          touched={touched}
                          values={values}
                          setFieldValue={setFieldValue}
                        />
                      ))}

                      <div className="flex flex-row-reverse col-span-12 mt-5">
                        <Button
                          title={submitButtonTitle ? t(submitButtonTitle) : t('modals.customInputModal.submitButton')}
                          className="mx-2"
                          type={ButtonTypesEnum.Submit}
                          backgroundColor={ButtonBackgroundColorsEnum.Brand}
                          textColor={ButtonTextColorsEnum.White}
                          disabled={isSubmitting}
                        />
                        <Button
                          title={closeButtonTitle ? t(closeButtonTitle) : t('modals.customInputModal.closeButton')}
                          className="mx-2"
                          type={ButtonTypesEnum.Button}
                          backgroundColor={ButtonBackgroundColorsEnum.White}
                          textColor={ButtonTextColorsEnum.Gray}
                          onClick={() => setIsCustomModalOpen(false)}
                          buttonRef={cancelButtonRef}
                        />
                      </div>
                    </FormWrapper>
                  </>
                )}
              </Formik>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default CustomInputModal
