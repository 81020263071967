import { createSlice } from '@reduxjs/toolkit'
import { DispatchActionReducerInterface, EdgState } from '../../../interfaces'
import { getEdgQrCode, getEdgServicesAvailable, getEdgServicesMy } from './actions'

import { EDG } from './types'

const initialState: EdgState = {
  edgServicesMy: [],
  getEdgServicesMyLoading: false,

  edgServicesAvailable: [],
  getEdgServicesAvailableLoading: false,

  edgQrCode: null,
  getEdgQrCodeLoading: false,
}

const chatSlice = createSlice({
  name: EDG.NAMESPACE,
  initialState,
  // Only frontend Logic
  reducers: {},
  extraReducers: builder => {
    // Reducers with calling external API
    builder
      .addCase(getEdgServicesMy.pending, (state: EdgState) => {
        state.getEdgServicesMyLoading = true
      })
      .addCase(getEdgServicesMy.fulfilled, (state: EdgState, action: DispatchActionReducerInterface) => {
        if (action.payload?.status === 'ok') {
          state.edgServicesMy = action.payload.data
        } else {
          state.edgServicesMy = []
        }
        state.getEdgServicesMyLoading = false
      })
      .addCase(getEdgServicesMy.rejected, (state: EdgState) => {
        state.edgServicesMy = []
        state.getEdgServicesMyLoading = false
      })

      .addCase(getEdgServicesAvailable.pending, (state: EdgState) => {
        state.getEdgServicesAvailableLoading = true
      })
      .addCase(getEdgServicesAvailable.fulfilled, (state: EdgState, action: DispatchActionReducerInterface) => {
        if (action.payload?.status === 'ok') {
          state.edgServicesAvailable = action.payload.data
        } else {
          state.edgServicesAvailable = []
        }
        state.getEdgServicesAvailableLoading = false
      })
      .addCase(getEdgServicesAvailable.rejected, (state: EdgState) => {
        state.edgServicesAvailable = []
        state.getEdgServicesAvailableLoading = false
      })

      .addCase(getEdgQrCode.pending, (state: EdgState) => {
        state.getEdgQrCodeLoading = true
      })
      .addCase(getEdgQrCode.fulfilled, (state: EdgState, action: DispatchActionReducerInterface) => {
        if (action.payload?.status === 'ok') {
          state.edgQrCode = action.payload.data
        } else {
          state.edgQrCode = null
        }
        state.getEdgQrCodeLoading = false
      })
      .addCase(getEdgQrCode.rejected, (state: EdgState) => {
        state.edgQrCode = null
        state.getEdgQrCodeLoading = false
      })
  },
})

// Extract the action creators object and the reducer
const { reducer } = chatSlice
// Extract and export each action creator by name
// export const {} = actions
// Export the reducer, either as a default or named export
export default reducer
