import { PencilAltIcon } from '@heroicons/react/outline'
import { XIcon } from '@heroicons/react/solid'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ChatType, PARAMS, ROLES } from '../../../enums'
import {
  ButtonBackgroundColorsEnum,
  ButtonTextColorsEnum,
  ButtonTypesEnum,
  ChatIconInterface,
  ChatIconThemeEnum,
  ChatSidebarInterface,
  DispatchActionInterface,
  DispatchActionReducerRocketChatInterface,
  FormikOnSubmitType,
  InputByTypeRow,
  InputTypeEnum,
  Subscription,
} from '../../../interfaces'
import { ROUTES } from '../../../router/routes'
import { RootState, useAppDispatch } from '../../../store'
import { createDirectMessage, createGroupChat, getChatUsers } from '../../../store/modules/chat/actions'
import { getPatient } from '../../../store/modules/patients/actions'
import {
  checkRole,
  classNames,
  getValueFromObject,
  isCurrentRoute,
  replaceRouteParams,
  sortArray,
} from '../../../store/utils'
import Button from '../../common/button/Button'
import CustomInputModal from '../../common/modals/CustomInputModal'
import ChatIcon from './ChatIcon'
import ChatSection from './ChatSection'

export const ChatSidebar = (props: ChatSidebarInterface) => {
  const { loading, mobileSidebarOpen, setMobileSidebarOpen } = props
  const { authChat, subscriptions } = useSelector((state: RootState) => state.chat)
  const { authUser } = useSelector((state: RootState) => state.auth)
  const [dms, setDMs] = useState<ChatIconInterface[]>([])
  const [chatGroups, setChatGroups] = useState<ChatIconInterface[]>([])
  const { t } = useTranslation(['chat'])
  const [currentChatUser, setCurrentChatUser] = useState<null | string>(null)
  const [createChatModalOpen, setCreateChatModalOpen] = useState(false)
  const dispatch = useAppDispatch()
  const history = useHistory()

  useEffect(() => {
    const tmpDM: ChatIconInterface[] = []
    subscriptions.map(chat => {
      if (chat.t === ChatType.DM) {
        tmpDM.push({
          _id: chat.rid,
          name: chat.fname,
          theme: 0,
          unread: chat.unread,
          isLink: true,
          isOpen: isCurrentRoute(ROUTES.CHAT_DM, [{ key: `:${PARAMS.CHAT_DM_ID}`, value: chat.rid }]),
          path: replaceRouteParams(ROUTES.CHAT_DM, [{ key: `:${PARAMS.CHAT_DM_ID}`, value: chat.rid }]),
        })
      }
    })

    const tmpGroup: ChatIconInterface[] = []
    subscriptions.map((chat: Subscription) => {
      if (chat.t === ChatType.Group) {
        tmpGroup.push({
          _id: chat.rid,
          name: chat.fname,
          theme: 0,
          unread: chat.unread,
          isLink: true,
          isOpen: isCurrentRoute(ROUTES.CHAT_GROUP, [{ key: `:${PARAMS.CHAT_GROUP_ID}`, value: chat.rid }]),
          path: replaceRouteParams(ROUTES.CHAT_GROUP, [{ key: `:${PARAMS.CHAT_GROUP_ID}`, value: chat.rid }]),
        })
      }
    })

    setDMs(sortArray(tmpDM, 'unread', 'desc'))
    setChatGroups(sortArray(tmpGroup, 'unread', 'desc'))
  }, [subscriptions, authChat?.user_id])

  useEffect(() => {
    if (authUser) {
      if (checkRole(authUser, ROLES.PATIENT)) {
        dispatch(getPatient({ [PARAMS.PATIENT_ID]: authUser.role.object_id })).then(
          (action: DispatchActionInterface) => {
            if (action.payload?.status === 'ok') {
              setCurrentChatUser(`${action.payload.data.first_name} ${action.payload.data.last_name}`)
            }
          }
        )
      } else {
        setCurrentChatUser(authUser.full_name)
      }
    }
  }, [authUser])

  const createChatHandler: FormikOnSubmitType = (values, setSubmitting) => {
    const send = { ...values }
    send.members = getValueFromObject(values.members)

    if (send.members.length > 1) {
      dispatch(createGroupChat({ name: send.name, members: send.members })).then(
        (action: DispatchActionReducerRocketChatInterface) => {
          setSubmitting(false)
          if (action.payload?.success) {
            setCreateChatModalOpen(false)
            history.push(
              replaceRouteParams(ROUTES.CHAT_GROUP, [
                { key: `:${PARAMS.CHAT_GROUP_ID}`, value: action.payload.group._id },
              ])
            )
          }
        }
      )
    } else {
      dispatch(createDirectMessage({ usernames: send.members.toString() })).then(
        (action: DispatchActionReducerRocketChatInterface) => {
          setSubmitting(false)
          if (action.payload?.success) {
            setCreateChatModalOpen(false)
            history.push(
              replaceRouteParams(ROUTES.CHAT_DM, [{ key: `:${PARAMS.CHAT_DM_ID}`, value: action.payload.room._id }])
            )
          }
        }
      )
    }
  }

  const createChatRows: InputByTypeRow[] = [
    {
      label: t('chat.sidebar.groupName'),
      name: 'name',
      type: InputTypeEnum.Text,
      className: 'col-span-12',
      disabled: row => !(row.members.length > 1),
    },
    {
      label: t('chat.sidebar.createChat'),
      name: 'members',
      type: InputTypeEnum.ApiSearchSelect,
      placeholder: t('chat.sidebar.searchPlaceholder'),
      searchSelectStructureMulti: true,
      searchSelectStructure: {
        name: ['first_name', 'last_name', '-', 'badge'],
        value: 'rocketchat_username',
      },
      searchSelectDispatch: getChatUsers,
      className: 'col-span-12',
    },
  ]

  const createChatInitialValues = {
    members: [],
  }

  return (
    <>
      <div
        className={classNames(
          mobileSidebarOpen ? 'right-0' : '-right-full',
          'flex absolute lg:static top-0 flex-shrink-0 bg-green-900 transition-all'
        )}
      >
        <div className="flex flex-col w-screen md:w-80 h-screen">
          <div className="flex overflow-y-auto flex-col flex-grow">
            <div className="flex sticky top-0 justify-between py-3 px-6 bg-green-900 border-b border-gray-300">
              <ChatIcon name={currentChatUser ? currentChatUser : ''} isLink={false} theme={ChatIconThemeEnum.Light} />
              <div className="flex items-center">
                {checkRole(authUser, [ROLES.ADMIN, ROLES.DOCTOR]) && (
                  <>
                    <Button
                      icon={PencilAltIcon}
                      type={ButtonTypesEnum.Button}
                      onClick={() => {
                        setCreateChatModalOpen(true)
                        setMobileSidebarOpen(false)
                      }}
                      backgroundColor={ButtonBackgroundColorsEnum.Transparent}
                      textColor={ButtonTextColorsEnum.White}
                      tooltip={t('chat.sidebar.createChat')}
                    />
                  </>
                )}
                <Button
                  icon={XIcon}
                  type={ButtonTypesEnum.Button}
                  onClick={() => setMobileSidebarOpen(false)}
                  backgroundColor={ButtonBackgroundColorsEnum.Transparent}
                  textColor={ButtonTextColorsEnum.White}
                  className="lg:hidden"
                />
              </div>
            </div>

            <div className="flex flex-col py-4 space-y-4">
              {chatGroups?.length > 0 && (
                <ChatSection
                  name={t('chat.sidebar.groups')}
                  content={chatGroups}
                  loading={loading}
                  setMobileSidebarOpen={setMobileSidebarOpen}
                />
              )}

              {dms?.length > 0 && (
                <ChatSection
                  name={t('chat.sidebar.directMessages')}
                  content={dms}
                  loading={loading}
                  setMobileSidebarOpen={setMobileSidebarOpen}
                />
              )}

              {!(subscriptions?.length > 0) && (
                <div className="py-24 px-6 text-center text-white">{t('chat.sidebar.empty')}</div>
              )}
            </div>
          </div>
        </div>

        <CustomInputModal
          rows={createChatRows}
          isCustomModalOpen={createChatModalOpen}
          setIsCustomModalOpen={setCreateChatModalOpen}
          onSubmit={createChatHandler}
          initialValues={createChatInitialValues}
          submitButtonTitle={`modals.customInputModal.createButton`}
        />
      </div>
    </>
  )
}

export default ChatSidebar
