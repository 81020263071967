import { useEffect, useState } from 'react'
import Plot from 'react-plotly.js'
import { WebGLChartInterface, WebGLDataChartInterface } from '../../../interfaces'

export const WebGLChart = (props: WebGLChartInterface) => {
  const { data } = props
  const [lines, setLines] = useState<any[]>([])

  useEffect(() => {
    getLines(data)
  }, [data])

  const getLines = (data: WebGLDataChartInterface[]) => {
    if (data) {
      if (data.length > 0) {
        setLines(
          data.map(value => {
            const line: any = { ...value }
            line.type = 'scattergl'
            line.mode = 'lines+markers'

            return line
          })
        )
      } else {
        const line: any = { ...data }
        line.type = 'scattergl'
        line.mode = 'lines+markers'
        setLines([line])
      }
    }
  }

  return (
    <>
      <Plot
        style={{ width: '100%' }}
        data={lines}
        layout={{
          margin: {
            l: 30,
            r: 10,
            b: 40,
            t: 40,
            pad: 4,
          },
        }}
      />
    </>
  )
}

export default WebGLChart
