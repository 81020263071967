import { createSlice } from '@reduxjs/toolkit'
import { DispatchActionReducerInterface, Doctor, DoctorsState } from '../../../interfaces'
import { getDoctor, getDoctors, getPatientsDoctors } from './actions'

import { DOCTORS } from './types'

const initialState: DoctorsState = {
  doctors: null,
  getDoctorsLoading: false,

  doctor: null,
  getDoctorLoading: false,

  patientsDoctors: null,
  getPatientsDoctorsLoading: false,
}

const doctorsSlice = createSlice({
  name: DOCTORS.NAMESPACE,
  initialState,
  // Only frontend Logic
  reducers: {},
  extraReducers: builder => {
    // Reducers with calling external API
    builder
      .addCase(getDoctors.pending, (state: DoctorsState) => {
        state.getDoctorsLoading = true
      })
      .addCase(getDoctors.fulfilled, (state: DoctorsState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.doctors = action.payload.data
          if (state.doctors?.current) {
            state.doctors?.current.map((doctor: Doctor, key: number) => {
              if (state.doctors?.current)
                state.doctors.current[key].full_name = `${doctor.first_name} ${doctor.last_name}`
            })
          }
        } else {
          state.doctors = null
        }
        state.getDoctorsLoading = false
      })
      .addCase(getDoctors.rejected, (state: DoctorsState) => {
        state.doctors = null
        state.getDoctorsLoading = false
      })

      .addCase(getDoctor.pending, (state: DoctorsState) => {
        state.getDoctorLoading = true
      })
      .addCase(getDoctor.fulfilled, (state: DoctorsState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.doctor = action.payload.data
          if (state.doctor)
            state.doctor.full_name = `${action.payload.data.first_name} ${action.payload.data.last_name}`
        } else {
          state.doctor = null
        }
        state.getDoctorLoading = false
      })
      .addCase(getDoctor.rejected, (state: DoctorsState) => {
        state.doctor = null
        state.getDoctorLoading = false
      })

      .addCase(getPatientsDoctors.pending, (state: DoctorsState) => {
        state.getPatientsDoctorsLoading = true
      })
      .addCase(getPatientsDoctors.fulfilled, (state: DoctorsState, action: DispatchActionReducerInterface) => {
        if (action.payload.status === 'ok') {
          state.patientsDoctors = action.payload.data
          if (state.patientsDoctors?.current) {
            state.patientsDoctors.current.map((doctor: Doctor, key: number) => {
              if (state.patientsDoctors?.current)
                state.patientsDoctors.current[key].full_name = `${doctor.first_name} ${doctor.last_name}`
            })
          }
        } else {
          state.patientsDoctors = null
        }
        state.getPatientsDoctorsLoading = false
      })
      .addCase(getPatientsDoctors.rejected, (state: DoctorsState) => {
        state.patientsDoctors = null
        state.getPatientsDoctorsLoading = false
      })
  },
})

// Extract the action creators object and the reducer
const { reducer } = doctorsSlice
// Extract and export each action creator by name
// export const {} = actions
// Export the reducer, either as a default or named export
export default reducer
