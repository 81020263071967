export const PATIENTS = {
  NAMESPACE: 'patients',
  ACTIONS: {
    GET_PATIENTS: 'GET_PATIENTS',
    GET_DOCTORS_PATIENTS: 'GET_DOCTORS_PATIENTS',
    GET_PATIENT: 'GET_PATIENT',
    DELETE_PATIENT: 'DELETE_PATIENT',
    GET_PATIENT_BY_PIN: 'GET_PATIENT_BY_PIN',
    GET_DOCTORS: 'GET_DOCTORS',
    GET_DOCTOR: 'GET_DOCTOR',
    EDIT_DOCTOR: 'EDIT_DOCTOR',
    DELETE_DOCTOR: 'DELETE_DOCTOR',
    CREATE_DOCTOR: 'CREATE_DOCTOR',
    GET_PATIENTS_DOCTORS: 'GET_PATIENTS_DOCTORS',
    GET_PATIENTS_REQUEST_ACCESS: 'GET_PATIENTS_REQUEST_ACCESS',
    PATIENT_GIVE_DOCTOR_PATIENTS_ACCESS: 'PATIENT_GIVE_DOCTOR_PATIENTS_ACCESS',
    PATIENT_REMOVE_DOCTOR_PATIENTS_ACCESS: 'PATIENT_REMOVE_DOCTOR_PATIENTS_ACCESS',
    ADMIN_GIVE_DOCTOR_PATIENTS_ACCESS: 'ADMIN_GIVE_DOCTOR_PATIENTS_ACCESS',
    ADMIN_REMOVE_DOCTOR_PATIENTS_ACCESS: 'ADMIN_REMOVE_DOCTOR_PATIENTS_ACCESS',
    GET_FAMILY: 'GET_FAMILY',
    GET_FAMILY_MEMBER: 'GET_FAMILY_MEMBER',
    CREATE_FAMILY_MEMBER: 'CREATE_FAMILY_MEMBER',
    DELETE_FAMILY_MEMBER: 'DELETE_FAMILY_MEMBER',
    GET_ANAMNESIS_PERSONAL: 'GET_ANAMNESIS_PERSONAL',
    EDIT_ANAMNESIS_PERSONAL: 'EDIT_ANAMNESIS_PERSONAL',
    GET_ANAMNESIS_MEDICATIONS: 'GET_ANAMNESIS_MEDICATIONS',
    GET_ANAMNESIS_MEDICATION: 'GET_ANAMNESIS_MEDICATION',
    CREATE_ANAMNESIS_MEDICATION: 'CREATE_ANAMNESIS_MEDICATION',
    DELETE_ANAMNESIS_MEDICATION: 'DELETE_ANAMNESIS_MEDICATION',
    EDIT_ANAMNESIS_MEDICATION: 'EDIT_ANAMNESIS_MEDICATION',
    GET_ANAMNESIS_GYNAECOLOGY: 'GET_ANAMNESIS_GYNAECOLOGY',
    EDIT_ANAMNESIS_GYNAECOLOGY: 'EDIT_ANAMNESIS_GYNAECOLOGY',
    GET_ANAMNESIS_GYNAECOLOGY_PREGNANCIES: 'GET_ANAMNESIS_GYNAECOLOGY_PREGNANCIES',
    GET_ANAMNESIS_GYNAECOLOGY_PREGNANCY: 'GET_ANAMNESIS_GYNAECOLOGY_PREGNANCY',
    CREATE_ANAMNESIS_GYNAECOLOGY_PREGNANCY: 'CREATE_ANAMNESIS_GYNAECOLOGY_PREGNANCY',
    EDIT_ANAMNESIS_GYNAECOLOGY_PREGNANCY: 'EDIT_ANAMNESIS_GYNAECOLOGY_PREGNANCY',
    DELETE_ANAMNESIS_GYNAECOLOGY_PREGNANCY: 'DELETE_ANAMNESIS_GYNAECOLOGY_PREGNANCY',
    GET_ANAMNESIS_GYNAECOLOGY_MENSTRUATIONS: 'GET_ANAMNESIS_GYNAECOLOGY_MENSTRUATIONS',
    GET_ANAMNESIS_GYNAECOLOGY_MENSTRUATION: 'GET_ANAMNESIS_GYNAECOLOGY_MENSTRUATION',
    CREATE_ANAMNESIS_GYNAECOLOGY_MENSTRUATION: 'CREATE_ANAMNESIS_GYNAECOLOGY_MENSTRUATION',
    EDIT_ANAMNESIS_GYNAECOLOGY_MENSTRUATION: 'EDIT_ANAMNESIS_GYNAECOLOGY_MENSTRUATION',
    DELETE_ANAMNESIS_GYNAECOLOGY_MENSTRUATION: 'DELETE_ANAMNESIS_GYNAECOLOGY_MENSTRUATION',
    GET_ANAMNESIS_SURGERIES: 'GET_ANAMNESIS_SURGERIES',
    GET_ANAMNESIS_SURGERY: 'GET_ANAMNESIS_SURGERY',
    CREATE_ANAMNESIS_SURGERY: 'CREATE_ANAMNESIS_SURGERY',
    EDIT_ANAMNESIS_SURGERY: 'EDIT_ANAMNESIS_SURGERY',
    DELETE_ANAMNESIS_SURGERY: 'DELETE_ANAMNESIS_SURGERY',
    GET_ANAMNESIS_EPIDEMIOLOGY_LIST: 'GET_ANAMNESIS_EPIDEMIOLOGY_LIST',
    GET_ANAMNESIS_EPIDEMIOLOGY: 'GET_ANAMNESIS_EPIDEMIOLOGY',
    CREATE_ANAMNESIS_EPIDEMIOLOGY: 'CREATE_ANAMNESIS_EPIDEMIOLOGY',
    EDIT_ANAMNESIS_EPIDEMIOLOGY: 'EDIT_ANAMNESIS_EPIDEMIOLOGY',
    DELETE_ANAMNESIS_EPIDEMIOLOGY: 'DELETE_ANAMNESIS_EPIDEMIOLOGY',
    GET_ANAMNESIS_FAMILY: 'GET_ANAMNESIS_FAMILY',
    CREATE_ANAMNESIS_FAMILY: 'CREATE_ANAMNESIS_FAMILY',
    EDIT_ANAMNESIS_FAMILY: 'EDIT_ANAMNESIS_FAMILY',
    DELETE_ANAMNESIS_FAMILY: 'DELETE_ANAMNESIS_FAMILY',
    GET_ANAMNESIS_WORK: 'GET_ANAMNESIS_WORK',
    EDIT_ANAMNESIS_WORK: 'EDIT_ANAMNESIS_WORK',
    GET_ANAMNESIS_SOCIAL: 'GET_ANAMNESIS_SOCIAL',
    EDIT_ANAMNESIS_SOCIAL: 'EDIT_ANAMNESIS_SOCIAL',
    GET_ANAMNESIS_SPORT: 'GET_ANAMNESIS_SPORT',
    EDIT_ANAMNESIS_SPORT: 'EDIT_ANAMNESIS_SPORT',
    GET_ANAMNESIS_ADDICTIVE_SUBSTANCES: 'GET_ANAMNESIS_ADDICTIVE_SUBSTANCES',
    EDIT_ANAMNESIS_ADDICTIVE_SUBSTANCES: 'EDIT_ANAMNESIS_ADDICTIVE_SUBSTANCES',
    GET_CHECKUPS: 'GET_CHECKUPS',
    GET_CHECKUP: 'GET_CHECKUP',
    CREATE_CHECKUP: 'CREATE_CHECKUP',
    EDIT_CHECKUP: 'EDIT_CHECKUP',
    DELETE_CHECKUP: 'DELETE_CHECKUP',
    GET_MEASUREMENTS_GLYCEMIA: 'GET_MEASUREMENTS_GLYCEMIA',
    GET_MEASUREMENT_GLYCEMIA: 'GET_MEASUREMENT_GLYCEMIA',
    CREATE_MEASUREMENT_GLYCEMIA: 'CREATE_MEASUREMENT_GLYCEMIA',
    EDIT_MEASUREMENT_GLYCEMIA: 'EDIT_MEASUREMENT_GLYCEMIA',
    DELETE_MEASUREMENT_GLYCEMIA: 'DELETE_MEASUREMENT_GLYCEMIA',
    GET_MEASUREMENTS_OXYGEN_SATURATION: 'GET_MEASUREMENTS_OXYGEN_SATURATION',
    GET_MEASUREMENT_OXYGEN_SATURATION: 'GET_MEASUREMENT_OXYGEN_SATURATION',
    CREATE_MEASUREMENT_OXYGEN_SATURATION: 'CREATE_MEASUREMENT_OXYGEN_SATURATION',
    EDIT_MEASUREMENT_OXYGEN_SATURATION: 'EDIT_MEASUREMENT_OXYGEN_SATURATION',
    DELETE_MEASUREMENT_OXYGEN_SATURATION: 'DELETE_MEASUREMENT_OXYGEN_SATURATION',
    GET_MEASUREMENTS_ECG: 'GET_MEASUREMENTS_ECG',
    GET_MEASUREMENT_ECG: 'GET_MEASUREMENT_ECG',
    CREATE_MEASUREMENT_ECG: 'CREATE_MEASUREMENT_ECG',
    EDIT_MEASUREMENT_ECG: 'EDIT_MEASUREMENT_ECG',
    DELETE_MEASUREMENT_ECG: 'DELETE_MEASUREMENT_ECG',
    GET_MEASUREMENTS_BPM: 'GET_MEASUREMENTS_BPM',
    GET_MEASUREMENT_BPM: 'GET_MEASUREMENT_BPM',
    CREATE_MEASUREMENT_BPM: 'CREATE_MEASUREMENT_BPM',
    EDIT_MEASUREMENT_BPM: 'EDIT_MEASUREMENT_BPM',
    DELETE_MEASUREMENT_BPM: 'DELETE_MEASUREMENT_BPM',
    GET_MEASUREMENTS_SLEEP: 'GET_MEASUREMENTS_SLEEP',
    GET_MEASUREMENT_SLEEP: 'GET_MEASUREMENT_SLEEP',
    CREATE_MEASUREMENT_SLEEP: 'CREATE_MEASUREMENT_SLEEP',
    EDIT_MEASUREMENT_SLEEP: 'EDIT_MEASUREMENT_SLEEP',
    DELETE_MEASUREMENT_SLEEP: 'DELETE_MEASUREMENT_SLEEP',
    GET_MEASUREMENTS_AIR_POLUTION: 'GET_MEASUREMENTS_AIR_POLUTION',
    GET_MEASUREMENT_AIR_POLUTION: 'GET_MEASUREMENT_AIR_POLUTION',
    CREATE_MEASUREMENT_AIR_POLUTION: 'CREATE_MEASUREMENT_AIR_POLUTION',
    EDIT_MEASUREMENT_AIR_POLUTION: 'EDIT_MEASUREMENT_AIR_POLUTION',
    DELETE_MEASUREMENT_AIR_POLUTION: 'DELETE_MEASUREMENT_AIR_POLUTION',
    GET_MEASUREMENTS_ENDOSCOPY: 'GET_MEASUREMENTS_ENDOSCOPY',
    GET_MEASUREMENT_ENDOSCOPY: 'GET_MEASUREMENT_ENDOSCOPY',
    CREATE_MEASUREMENT_ENDOSCOPY: 'CREATE_MEASUREMENT_ENDOSCOPY',
    EDIT_MEASUREMENT_ENDOSCOPY: 'EDIT_MEASUREMENT_ENDOSCOPY',
    DELETE_MEASUREMENT_ENDOSCOPY: 'DELETE_MEASUREMENT_ENDOSCOPY',
    GET_MEASUREMENTS_TEMPERATURE: 'GET_MEASUREMENTS_TEMPERATURE',
    GET_MEASUREMENT_TEMPERATURE: 'GET_MEASUREMENT_TEMPERATURE',
    CREATE_MEASUREMENT_TEMPERATURE: 'CREATE_MEASUREMENT_TEMPERATURE',
    EDIT_MEASUREMENT_TEMPERATURE: 'EDIT_MEASUREMENT_TEMPERATURE',
    DELETE_MEASUREMENT_TEMPERATURE: 'DELETE_MEASUREMENT_TEMPERATURE',
    GET_MEASUREMENTS_HEIGHT: 'GET_MEASUREMENTS_HEIGHT',
    GET_MEASUREMENT_HEIGHT: 'GET_MEASUREMENT_HEIGHT',
    CREATE_MEASUREMENT_HEIGHT: 'CREATE_MEASUREMENT_HEIGHT',
    EDIT_MEASUREMENT_HEIGHT: 'EDIT_MEASUREMENT_HEIGHT',
    DELETE_MEASUREMENT_HEIGHT: 'DELETE_MEASUREMENT_HEIGHT',
    GET_MEASUREMENTS_WEIGHT: 'GET_MEASUREMENTS_WEIGHT',
    GET_MEASUREMENT_WEIGHT: 'GET_MEASUREMENT_WEIGHT',
    CREATE_MEASUREMENT_WEIGHT: 'CREATE_MEASUREMENT_WEIGHT',
    EDIT_MEASUREMENT_WEIGHT: 'EDIT_MEASUREMENT_WEIGHT',
    DELETE_MEASUREMENT_WEIGHT: 'DELETE_MEASUREMENT_WEIGHT',
    GET_MEASUREMENTS_CHOLESTEROL: 'GET_MEASUREMENTS_CHOLESTEROL',
    GET_MEASUREMENT_CHOLESTEROL: 'GET_MEASUREMENT_CHOLESTEROL',
    CREATE_MEASUREMENT_CHOLESTEROL: 'CREATE_MEASUREMENT_CHOLESTEROL',
    EDIT_MEASUREMENT_CHOLESTEROL: 'EDIT_MEASUREMENT_CHOLESTEROL',
    DELETE_MEASUREMENT_CHOLESTEROL: 'DELETE_MEASUREMENT_CHOLESTEROL',
    GET_MEASUREMENTS_PRESSURE: 'GET_MEASUREMENTS_PRESSURE',
    GET_MEASUREMENT_PRESSURE: 'GET_MEASUREMENT_PRESSURE',
    CREATE_MEASUREMENT_PRESSURE: 'CREATE_MEASUREMENT_PRESSURE',
    EDIT_MEASUREMENT_PRESSURE: 'EDIT_MEASUREMENT_PRESSURE',
    DELETE_MEASUREMENT_PRESSURE: 'DELETE_MEASUREMENT_PRESSURE',
    GET_STORAGE_ATTACHMENTS: 'GET_STORAGE_ATTACHMENTS',
    GET_STORAGE_ATTACHMENT: 'GET_STORAGE_ATTACHMENT',
    CREATE_STORAGE_ATTACHMENT: 'CREATE_STORAGE_ATTACHMENT',
    EDIT_STORAGE_ATTACHMENT: 'EDIT_STORAGE_ATTACHMENT',
    DELETE_STORAGE_ATTACHMENT: 'DELETE_STORAGE_ATTACHMENT',
    DOWNLOAD_STORAGE_ATTACHMENT: 'DOWNLOAD_STORAGE_ATTACHMENT',
    GET_STORAGE_RADIOLOGY: 'GET_STORAGE_RADIOLOGY',
    GET_STORAGE_RADIOLOGY_FILE: 'GET_STORAGE_RADIOLOGY_FILE',
    CREATE_STORAGE_RADIOLOGY_FILE: 'CREATE_STORAGE_RADIOLOGY_FILE',
    EDIT_STORAGE_RADIOLOGY_FILE: 'EDIT_STORAGE_RADIOLOGY_FILE',
    DELETE_STORAGE_RADIOLOGY_FILE: 'DELETE_STORAGE_RADIOLOGY_FILE',
    DOWNLOAD_STORAGE_RADIOLOGY_FILE: 'DOWNLOAD_STORAGE_RADIOLOGY_FILE',
    GET_STORAGE_LABORATORY: 'GET_STORAGE_LABORATORY',
    GET_STORAGE_LABORATORY_FILE: 'GET_STORAGE_LABORATORY_FILE',
    CREATE_STORAGE_LABORATORY_FILE: 'CREATE_STORAGE_LABORATORY_FILE',
    EDIT_STORAGE_LABORATORY_FILE: 'EDIT_STORAGE_LABORATORY_FILE',
    DELETE_STORAGE_LABORATORY_FILE: 'DELETE_STORAGE_LABORATORY_FILE',
    DOWNLOAD_STORAGE_LABORATORY_FILE: 'DOWNLOAD_STORAGE_LABORATORY_FILE',
    GET_ENUM: 'GET_ENUM',
  },
}
