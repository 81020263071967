import moment from 'moment'
import { useEffect, useState } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import store from '../../../../store'
import { getValueFromStore } from '../../../../store/utils'

export const PageHelmet = ({ title: pageTitle }: any) => {
  const defaultTitle = 'InspectLife'
  const [title, setTitle] = useState<string | null>(null)
  const { t } = useTranslation(['routes'])

  useEffect(() => {
    initListener()
  }, [pageTitle])

  const initListener = () => {
    store.subscribe(() => {
      if (pageTitle) {
        if (pageTitle.includes('store')) {
          const value = getValueFromStore(pageTitle)

          if (!value.includes('store')) {
            if (moment(value).isValid()) {
              setTitle(moment(value).format('DD.MM.YYYY'))
            } else {
              setTitle(value)
            }
          }
        } else {
          setTitle(t(pageTitle))
        }
      }
    })
  }

  return (
    <HelmetProvider>
      <Helmet>
        <title>{title ? title + ' | ' + defaultTitle : defaultTitle}</title>
      </Helmet>
    </HelmetProvider>
  )
}

export default PageHelmet
