import { createAsyncThunk } from '@reduxjs/toolkit'
import { API } from '../../../api'
import { callAuthApiEndpoint, callBaseApiEndpoint } from '../../utils'

// typePrefix = '${namespace}/functionName'
export const login: any = createAsyncThunk('auth/login', async (payload, { dispatch }) => {
  return await callAuthApiEndpoint(dispatch, {
    requestData: { ...API.auth.login },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const checkLogin: any = createAsyncThunk('auth/checkLogin', async (payload, { dispatch }) => {
  return await callAuthApiEndpoint(dispatch, {
    requestData: { ...API.auth.checkLogin },
    params: payload,
    throwError: true,
    showErrors: false,
    showSuccess: false,
  }).then((res: any) => res)
})

export const resetPasswordEmail: any = createAsyncThunk('auth/resetPasswordEmail', async (payload, { dispatch }) => {
  return await callAuthApiEndpoint(dispatch, {
    requestData: { ...API.auth.resetPasswordEmail },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const resetPasswordNewPassword: any = createAsyncThunk(
  'auth/resetPasswordNewPassword',
  async (payload, { dispatch }) => {
    return await callAuthApiEndpoint(dispatch, {
      requestData: { ...API.auth.resetPasswordNewPassword },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const register: any = createAsyncThunk('auth/register', async (payload, { dispatch }) => {
  return await callBaseApiEndpoint(dispatch, {
    requestData: { ...API.auth.register },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const registerCheck: any = createAsyncThunk('auth/registerCheck', async (payload, { dispatch }) => {
  return await callBaseApiEndpoint(dispatch, {
    requestData: { ...API.auth.registerCheck },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const authorizeJitsi: any = createAsyncThunk('auth/authorizeJitsi', async (payload, { dispatch }) => {
  return await callAuthApiEndpoint(dispatch, {
    requestData: { ...API.auth.authorizeJitsi },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const changePassword: any = createAsyncThunk('auth/changePassword', async (payload, { dispatch }) => {
  return await callAuthApiEndpoint(dispatch, {
    requestData: { ...API.auth.changePassword },
    params: payload,
    throwError: true,
    showSuccess: true,
  }).then((res: any) => res)
})
