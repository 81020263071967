import { lazy } from 'react'

const OrdersCheckoutStep1 = lazy(() => import('../components/app/orders/components/OrdersCheckoutStep1'))
const OrdersCheckoutStep2 = lazy(() => import('../components/app/orders/components/OrdersCheckoutStep2'))
const OrdersCheckoutStep3 = lazy(() => import('../components/app/orders/components/OrdersCheckoutStep3'))
const OrdersCheckoutStep4 = lazy(() => import('../components/app/orders/components/OrdersCheckoutStep4'))

export const SERVICES_ORDER_STEPS = [
  { name: 'Step 1', component: OrdersCheckoutStep1, href: '#' },
  { name: 'Step 2', component: OrdersCheckoutStep2, href: '#' },
  { name: 'Step 3', component: OrdersCheckoutStep3, href: '#' },
  { name: 'Step 4', component: OrdersCheckoutStep4, href: '#' },
]
