export const TAB_NAMES = {
  PATIENTS: 'tabs.patients.list',
  PATIENTS_DETAIL_OVERVIEW: 'tabs.patients.detail.overview',
  PATIENTS_DETAIL_ANAMNESIS: 'tabs.patients.detail.anamnesis.anamnesis',
  PATIENTS_DETAIL_ANAMNESIS_PERSONAL: 'tabs.patients.detail.anamnesis.personal',
  PATIENTS_DETAIL_ANAMNESIS_MEDICATIONS: 'tabs.patients.detail.anamnesis.medications',
  PATIENTS_DETAIL_ANAMNESIS_ALLERGIES: 'tabs.patients.detail.anamnesis.allergies',
  PATIENTS_DETAIL_ANAMNESIS_GYNAECOLOGY: 'tabs.patients.detail.anamnesis.gynaecology',
  PATIENTS_DETAIL_ANAMNESIS_SURGERIES: 'tabs.patients.detail.anamnesis.surgeries',
  PATIENTS_DETAIL_ANAMNESIS_EPIDEMIOLOGY: 'tabs.patients.detail.anamnesis.epidemiology',
  PATIENTS_DETAIL_ANAMNESIS_ADDICTIVE_SUBSTANCES: 'tabs.patients.detail.anamnesis.addictiveSubstances',
  PATIENTS_DETAIL_ANAMNESIS_FAMILY_HISTORY: 'tabs.patients.detail.anamnesis.familyHistory',
  PATIENTS_DETAIL_ANAMNESIS_WORK_HISTORY: 'tabs.patients.detail.anamnesis.workHistory',
  PATIENTS_DETAIL_ANAMNESIS_SOCIAL_HISTORY: 'tabs.patients.detail.anamnesis.socialHistory',
  PATIENTS_DETAIL_ANAMNESIS_SPORTS_HISTORY: 'tabs.patients.detail.anamnesis.sportsHistory',
  PATIENTS_DETAIL_CHECKUPS: 'tabs.patients.detail.checkups',
  PATIENTS_DETAIL_MEASUREMENTS: 'tabs.patients.detail.measurements.measurements',
  PATIENTS_DETAIL_MEASUREMENTS_GLYCEMIA: 'tabs.patients.detail.measurements.glycemia',
  PATIENTS_DETAIL_MEASUREMENTS_OXYGEN_SATURATION: 'tabs.patients.detail.measurements.oxygenSaturation',
  PATIENTS_DETAIL_MEASUREMENTS_ECG: 'tabs.patients.detail.measurements.ecg',
  PATIENTS_DETAIL_MEASUREMENTS_BPM: 'tabs.patients.detail.measurements.bpm',
  PATIENTS_DETAIL_MEASUREMENTS_SLEEP: 'tabs.patients.detail.measurements.sleep',
  PATIENTS_DETAIL_MEASUREMENTS_AIR_POLUTION: 'tabs.patients.detail.measurements.airPolution',
  PATIENTS_DETAIL_MEASUREMENTS_ENDOSCOPY: 'tabs.patients.detail.measurements.endoscopy',
  PATIENTS_DETAIL_MEASUREMENTS_TEMPERATURE: 'tabs.patients.detail.measurements.temperature',

  PATIENTS_DETAIL_MEASUREMENTS_WEIGHT: 'tabs.patients.detail.measurements.weight',
  PATIENTS_DETAIL_MEASUREMENTS_HEIGHT: 'tabs.patients.detail.measurements.height',
  PATIENTS_DETAIL_MEASUREMENTS_CHOLESTEROL: 'tabs.patients.detail.measurements.cholesterol',
  PATIENTS_DETAIL_MEASUREMENTS_PRESSURE: 'tabs.patients.detail.measurements.pressure',
  PATIENTS_DETAIL_FAMILY: 'tabs.patients.detail.family',
  PATIENTS_DETAIL_DOCTORS: 'tabs.patients.detail.doctors',
  PATIENTS_DETAIL_STORAGE: 'tabs.patients.detail.storage.storage',
  PATIENTS_DETAIL_STORAGE_LABORATORY: 'tabs.patients.detail.storage.laboratory',
  PATIENTS_DETAIL_STORAGE_RADIOLOGY: 'tabs.patients.detail.storage.radiology',
  PATIENTS_DETAIL_STORAGE_ATTACHMENTS: 'tabs.patients.detail.storage.attachments',

  ORDERS_ADMINISTRATION_HISTORY: 'tabs.orders.administration.history',
  ORDERS_ADMINISTRATION_SERVICE_LIST: 'tabs.orders.administration.serviceList',

  ORDERS_ADMINISTRATION_CATEGORY: 'tabs.orders.administration.category.category',
  ORDERS_ADMINISTRATION_CATEGORY_LIST: 'tabs.orders.administration.category.list',
  ORDERS_ADMINISTRATION_CATEGORY_ACCESSORY_LIST: 'tabs.orders.administration.category.accessoryList',

  TELEREHABILITATION_PRESETS: 'tabs.telerehabilitation.presets',
  TELEREHABILITATION_CHARTS: 'tabs.telerehabilitation.charts',

  SETTINGS_PROFILE: 'tabs.settings.profile',
  SETTINGS_PASSWORD: 'tabs.settings.password',
  SETTINGS_NOTIFICATIONS: 'tabs.settings.notifications',
  SETTINGS_APPS: 'tabs.settings.apps',
}
