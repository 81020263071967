import { ExclamationCircleIcon, EyeIcon, EyeOffIcon, LockClosedIcon, MailIcon } from '@heroicons/react/outline'
import { Field, Form, Formik } from 'formik'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { ButtonBackgroundColorsEnum, ButtonSizesEnum, ButtonTextColorsEnum, ButtonTypesEnum } from '../../../interfaces'
import { ROUTES } from '../../../router/routes'
import Button from '../../common/button/Button'

export const LoginForm = (props: { onSubmit: (arg1: any, arg2: any) => void }) => {
  const { onSubmit } = props
  const [passwordVisible, setPasswordVisible] = useState(false)
  const { t } = useTranslation(['login', 'yup'])

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible)
  }

  const loginSchema = Yup.object({
    username: Yup.string().required(t('yup:yup.required')),
    password: Yup.string().required(t('yup:yup.required')),
  })

  return (
    <Formik
      initialValues={{
        username: '',
        password: '',
        remember_me: false,
      }}
      validationSchema={loginSchema}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting)
      }}
    >
      {({ isSubmitting, errors, touched }) => (
        <Form>
          <div>
            <label htmlFor="username" className="block text-sm font-medium text-gray-700">
              {t('login.form.email.label')}
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                <MailIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
              </div>
              <Field
                type="text"
                name="username"
                id="username"
                autoComplete="email"
                className={`${
                  errors.username && touched.username
                    ? 'border-red-600 focus:ring-red-600 focus:border-red-600 pr-10'
                    : 'border-gray-300 focus:ring-brand focus:border-brand'
                } block w-full sm:text-sm rounded-md pl-10`}
                placeholder={t('login.form.email.placeholder')}
              />
              {errors.username && touched.username && (
                <div className="flex absolute inset-y-0 right-0 items-center pr-3 pointer-events-none">
                  <ExclamationCircleIcon className="w-5 h-5 text-red-600" aria-hidden="true" />
                </div>
              )}
            </div>
            <p className="mb-0 h-5 text-sm text-red-600">{errors.username && touched.username && errors.username}</p>
          </div>

          <div className="mt-0 space-y-1">
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
              {t('login.form.password.label')}
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                <LockClosedIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
              </div>
              <Field
                type={passwordVisible ? 'text' : 'password'}
                name="password"
                id="password"
                autoComplete="current-password"
                className={`${
                  errors.password && touched.password
                    ? 'border-red-600 focus:ring-red-600 focus:border-red-600'
                    : 'border-gray-300 focus:ring-brand focus:border-brand'
                } block w-full sm:text-sm rounded-md px-10`}
                placeholder={t('login.form.password.placeholder')}
              />
              <div className="flex absolute inset-y-0 right-0 items-center pr-3" onClick={togglePasswordVisibility}>
                {passwordVisible ? (
                  <EyeIcon className="w-5 h-5 text-gray-400 cursor-pointer" aria-hidden="false" />
                ) : (
                  <EyeOffIcon className="w-5 h-5 text-gray-400 cursor-pointer" aria-hidden="false" />
                )}
              </div>
            </div>
            <p className="mb-0 h-5 text-sm text-red-600">{errors.password && touched.password && errors.password}</p>
          </div>

          <div className="flex justify-end items-center mt-6">
            {/*<div className="flex items-center">*/}
            {/*  <Field*/}
            {/*    id="remember_me"*/}
            {/*    name="remember_me"*/}
            {/*    type="checkbox"*/}
            {/*    className="w-4 h-4 text-brand rounded border-gray-300 focus:ring-brand"*/}
            {/*  />*/}
            {/*  <label htmlFor="remember_me" className="block ml-2 text-sm text-gray-900">*/}
            {/*    {t('login.form.rememberMe')}*/}
            {/*  </label>*/}
            {/*</div>*/}

            <div className="text-sm">
              <Link to={ROUTES.PASSWORD_RESET_EMAIL} className="font-medium text-brand hover:text-brand-dark">
                {t('login.form.forgotYourPassword')}
              </Link>
            </div>
          </div>

          <div className="mt-6">
            <Button
              type={ButtonTypesEnum.Submit}
              textColor={ButtonTextColorsEnum.White}
              backgroundColor={ButtonBackgroundColorsEnum.Brand}
              title={t('login.form.signIn')}
              className="w-full"
              size={ButtonSizesEnum.medium}
              disabled={isSubmitting}
            />
          </div>

          <div className="mt-6 text-center">
            <p className="text-sm">
              {t('login.form.dontHaveAccount')}{' '}
              <Link className="text-brand hover:text-brand-dark" to={`${ROUTES.REGISTER}`}>
                {t('login.form.register')}
              </Link>
            </p>
          </div>
        </Form>
      )}
    </Formik>
  )
}
