import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { APP_SETTINGS } from '../../../../enums'
import { CustomFileInputInterface } from '../../../../interfaces'
import { RootState, useAppDispatch } from '../../../../store'
import { setUploadProgress } from '../../../../store/modules/files/reducer'
import { returnDisabled } from '../../../../store/utils'

export const CustomFileInput = (props: CustomFileInputInterface) => {
  const { disabled, inputClassName, className, label, name, errors, touched, onChange, value } = props
  const { fileUploadProgress } = useSelector((state: RootState) => state.files)
  const { t } = useTranslation(['inputs'])
  const dispatch = useAppDispatch()

  useEffect(() => {
    return () => {
      dispatch(setUploadProgress(0))
    }
  }, [])

  return (
    <div className={`${className ? className : ''}`}>
      {label && (
        <label htmlFor={name} className="block text-sm font-medium text-gray-700">
          {label}
        </label>
      )}
      <div className="flex justify-center px-6 pt-5 pb-6 mt-1 rounded-md border-2 border-gray-300 border-dashed transition-all duration-1000">
        <div className="space-y-1 text-center">
          <svg
            className="mx-auto w-12 h-12 text-gray-400"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 48 48"
            aria-hidden="true"
          >
            <path
              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <ProgressRing radius={50} stroke={3} progress={fileUploadProgress} />

          <div className="flex justify-center text-sm text-gray-600">
            <label
              htmlFor={name}
              className="relative font-medium text-brand hover:text-brand-dark bg-white rounded-md focus-within:ring-2 focus-within:ring-brand focus-within:ring-offset-2 cursor-pointer focus-within:outline-none"
            >
              <span>{t('inputs.file.fileUpload')}</span>
              <input
                type="file"
                id={name}
                name={name}
                disabled={returnDisabled(disabled, {})}
                className={`${
                  errors[name] && touched[name]
                    ? 'border-red-600 focus:ring-red-600 focus:border-red-600 pr-10'
                    : 'border-gray-300 focus:ring-brand focus:border-brand'
                } sm:text-sm rounded-md sr-only ${inputClassName ? inputClassName : ''}
                           ${returnDisabled(disabled, {}) ? 'bg-gray-100' : ''}`}
                onChange={onChange}
              />
            </label>
          </div>
          <p className="text-xs text-gray-500">
            <Trans i18nKey="inputs:inputs.file.maxSize" values={{ size: '1GB' }} />
          </p>
          {value?.name && <p className="text-xs text-gray-500">{value.name}</p>}
        </div>
      </div>
      {errors[name] && touched[name] && <p className="mb-0 h-5 text-sm text-red-600">{errors[name]}</p>}
    </div>
  )
}

const ProgressRing = (props: any) => {
  const { radius, stroke, progress } = props
  const normalizedRadius = radius - stroke * 2
  const circumference = normalizedRadius * 2 * Math.PI
  const strokeDashoffset = circumference - (progress / 100) * circumference

  return (
    <div
      className={`${
        progress === 0 ? 'max-h-0' : 'max-h-full'
      } block relative mx-auto overflow-hidden transition-all duration-1000`}
      style={{ height: radius * 2, width: radius * 2 }}
    >
      <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">{progress} %</span>
      <svg height={radius * 2} width={radius * 2}>
        <circle
          className="transition-all"
          stroke={APP_SETTINGS.BRAND_COLOR}
          fill="transparent"
          strokeDasharray={circumference + ' ' + circumference}
          style={{ strokeDashoffset }}
          strokeWidth={stroke}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
      </svg>
    </div>
  )
}

export default CustomFileInput
