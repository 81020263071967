import { PencilAltIcon, TrashIcon } from '@heroicons/react/outline'
import React, { cloneElement, isValidElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ButtonBackgroundColorsEnum,
  ButtonTextColorsEnum,
  ButtonTypesEnum,
  DescriptionListInterface,
} from '../../../interfaces'
import Button from '../button/Button'
import ConfirmModal from '../modals/ConfirmModal'

export const DescriptionList = (props: DescriptionListInterface) => {
  const [isDeleteOpen, setIsDeleteOpen] = useState(false)
  const [isInput, setIsInput] = useState(false)
  const {
    loading,
    className,
    title,
    description,
    isDeletable,
    isEditable,
    onDeleteClick,
    children,
    isCreate,
    itemID,
    actions,
  } = props
  const { t } = useTranslation(['descriptionList'])
  const [firstLoad, setFirstLoad] = useState(true)
  const [wasLoading, setWasLoading] = useState(false)

  useEffect(() => {
    return () => {
      setFirstLoad(true)
      setWasLoading(false)
    }
  }, [])

  useEffect(() => {
    if (loading) setWasLoading(true)
    if (wasLoading && !loading) setFirstLoad(false)
  }, [loading])

  const deleteHandler = () => {
    setIsDeleteOpen(false)
    if (onDeleteClick) onDeleteClick(itemID)
  }

  if (loading && firstLoad) {
    return (
      <div className={`${className && className} animate-pulse border-gray-100 border-2 grid grid-cols-12`}>
        <div className="col-span-12 py-4 pr-0 pl-6">
          <div className="flex flex-wrap sm:flex-nowrap justify-between items-center -mt-4 -ml-4">
            <div className="mt-4 ml-4 space-y-3">
              <div className="w-44 h-4 bg-gray-300 rounded" />
              <div className="w-64 h-4 bg-gray-300 rounded" />
            </div>
            <div className="flex flex-shrink-0 mt-4 ml-4 space-x-2">
              {isEditable && <div className="w-24 h-10 bg-gray-300 rounded" />}
              {isDeletable && <div className="w-24 h-10 bg-gray-300 rounded" />}
            </div>
          </div>
        </div>
        <div className="col-span-12">
          <div className="sm:p-0 py-5 px-4 border-t border-gray-200">
            <dl className="sm:divide-y sm:divide-gray-200">
              {[...Array(5)].map((value, key) => (
                <div key={key} className="sm:grid sm:grid-cols-3 sm:gap-4 py-4 sm:py-5 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    <div className="w-full h-6 bg-gray-300 rounded" />
                  </dt>
                  <dd className="sm:col-span-2 mt-1 sm:mt-0 text-sm text-gray-900">
                    <div className="w-full h-6 bg-gray-300 rounded" />
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={`${className ? className : ''} grid grid-cols-12`}>
      <div className="col-span-12 py-4 pr-0 pl-6">
        <div className="flex flex-wrap sm:flex-nowrap justify-between items-center -mt-4 -ml-4">
          <div className="mt-4 md:ml-4">
            <h3 className="text-lg font-medium leading-6 text-brand">{title}</h3>
            <p className="mt-1 text-sm text-gray-500">{description}</p>
          </div>
          {!isCreate && (
            <div className="flex-shrink-0 mt-4 md:ml-4 space-x-2">
              {isEditable && (
                <Button
                  type={ButtonTypesEnum.Button}
                  textColor={ButtonTextColorsEnum.Gray}
                  backgroundColor={ButtonBackgroundColorsEnum.White}
                  title={t('descriptionList.editButton')}
                  onClick={() => setIsInput(!isInput)}
                  icon={PencilAltIcon}
                />
              )}
              {isDeletable && (
                <Button
                  type={ButtonTypesEnum.Button}
                  textColor={ButtonTextColorsEnum.Red}
                  backgroundColor={ButtonBackgroundColorsEnum.White}
                  title={t('descriptionList.deleteButton')}
                  onClick={() => setIsDeleteOpen(true)}
                  icon={TrashIcon}
                />
              )}
              {actions &&
                actions.map(action => {
                  const handler = () => {
                    if (itemID) {
                      if (action.onClick) return action.onClick(itemID)
                    }
                  }
                  return (
                    !action.hide && (
                      <Button
                        type={action.type}
                        textColor={action.textColor}
                        backgroundColor={action.backgroundColor}
                        title={action.title}
                        onClick={handler}
                        icon={action.icon}
                      />
                    )
                  )
                })}
            </div>
          )}
        </div>
      </div>

      <div className="col-span-12">
        {
          /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
          /*@ts-ignore*/
          isValidElement(children) && cloneElement(children, { isInput, setIsInput })
        }
      </div>

      <ConfirmModal isConfirmOpen={isDeleteOpen} setIsConfirmOpen={setIsDeleteOpen} onConfirm={deleteHandler} />
    </div>
  )
}

export default DescriptionList
