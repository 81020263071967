import { Dialog, Disclosure, Menu, Transition } from '@headlessui/react'
import { LogoutIcon, MenuIcon } from '@heroicons/react/outline'
import { XIcon } from '@heroicons/react/solid'
import moment from 'moment'
import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import LoadingBar from 'react-top-loading-bar'
import ilLogo from '../../../assets/images/favicon.png'
import logo from '../../../assets/images/logo.png'
import { APP_SETTINGS, FETCH_ALL_CHATS, PARAMS, ROLES } from '../../../enums'
import { NavbarPositionEnum, RouterInterface, RouterUtilsInterface, Subscription } from '../../../interfaces'
import { ROUTES } from '../../../router/routes'
import { RootState, useAppDispatch } from '../../../store'
import { setProgress } from '../../../store/modules/app/reducer'
import { logout } from '../../../store/modules/auth/reducer'
import { getSubscriptions } from '../../../store/modules/chat/actions'
import {
  checkRole,
  classNames,
  getNavbar,
  getValueFromStore,
  isCurrentRoute,
  replaceRouteParams,
} from '../../../store/utils'

export const Navbar = () => {
  const { chatAuthorized, authChat, subscriptions, getSubscriptionsLoading } = useSelector(
    (state: RootState) => state.chat
  )
  const { authUser } = useSelector((state: RootState) => state.auth)
  const { progress } = useSelector((state: RootState) => state.app)
  const [params, setParams] = useState<{ key: string; value?: string }[] | undefined>(undefined)
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const { t } = useTranslation(['navbar', 'chat'])
  const navigation = getNavbar()
  const dispatch = useAppDispatch()

  useEffect(() => {
    authorizeNotifications()
  }, [])

  useEffect(() => {
    sendNotification()
  }, [subscriptions, getSubscriptionsLoading])

  useEffect(() => {
    if (chatAuthorized) {
      getChatSubscriptions()
      const dmInterval = setChatInterval()

      return () => {
        clearInterval(dmInterval)
      }
    }
  }, [chatAuthorized, authChat])

  useEffect(() => {
    if (checkRole(authUser, ROLES.PATIENT)) {
      setParams([{ key: `:${PARAMS.PATIENT_ID}`, value: authUser?.role.object_id }])
    }

    return () => {
      setParams(undefined)
    }
  }, [authUser])

  const setChatInterval = () => {
    return setInterval(() => {
      getChatSubscriptions()
    }, FETCH_ALL_CHATS)
  }

  const getChatSubscriptions = () => {
    return dispatch(getSubscriptions())
  }

  const isNavbarBottom = (item: RouterInterface) => {
    return item.navbar?.position === NavbarPositionEnum.Bottom
  }

  const logoutHandler = () => {
    dispatch(logout())
  }

  const getLink = (route: string) => {
    if (checkRole(authUser, ROLES.PATIENT)) {
      return replaceRouteParams(route, [{ key: `:${PARAMS.PATIENT_ID}`, value: authUser?.role.object_id }])
    }
    return replaceRouteParams(route)
  }

  const hasNewMessage = (item: RouterUtilsInterface) => {
    const subscriptions = getValueFromStore(item.navbar?.badge)

    if (subscriptions) {
      return subscriptions.some((chat: Subscription) => chat.alert)
    }
    return false
  }

  const countNewMessages = (item: RouterUtilsInterface) => {
    const subscriptions = getValueFromStore(item.navbar?.badge)
    let total = 0
    subscriptions.map((chat: Subscription) => (total += chat.unread))
    return total
  }

  const authorizeNotifications = () => {
    if ('Notification' in window && Notification.permission !== 'denied') Notification.requestPermission()
  }

  const sendNotification = () => {
    if (!getSubscriptionsLoading && subscriptions.length > 0) {
      let count = 0
      subscriptions.map(subscription => {
        count += subscription.unread
      })

      if (count.toString() !== sessionStorage.getItem('notification')) {
        if (count.toString() > (sessionStorage.getItem('notification') || 0)) {
          if ('Notification' in window && Notification.permission === 'granted') {
            const text = `${t('chat:chat.notifications.newMessage')} (+${count})`
            const notification = new Notification('InspectLife', {
              body: text,
              icon: ilLogo,
              badge: ilLogo,
              timestamp: moment().seconds(),
              // tag: 'il-chat-notification',
              // renotify: true,
              vibrate: [200, 100, 200],
            })

            // notification.onshow = function () {
            //   notification.close()
            // }

            notification.onclick = function (event) {
              event.preventDefault()
              window.open(`${window.location.origin}${replaceRouteParams(ROUTES.CHAT)}`, '_blank')
            }
          }
        }

        sessionStorage.setItem('notification', count.toString())
      }
    }
  }

  return (
    <>
      <LoadingBar
        color={APP_SETTINGS.BRAND_COLOR}
        progress={progress}
        onLoaderFinished={() => dispatch(setProgress(0))}
      />
      <div className="hidden lg:flex flex-shrink-0">
        <div className="flex flex-col w-64">
          <div className="flex overflow-y-auto flex-col flex-grow pt-5 pb-4 bg-white border-r border-gray-200">
            <div className="flex flex-shrink-0 items-center px-4">
              <Link
                to={
                  checkRole(authUser, ROLES.PATIENT)
                    ? replaceRouteParams(ROUTES.PATIENTS_DETAIL_OVERVIEW, [
                        { key: `:${PARAMS.PATIENT_ID}`, value: authUser?.role.object_id },
                      ])
                    : ROUTES.DASHBOARD
                }
              >
                <img className="w-auto h-8" src={logo} alt="InspectLife logo" />
              </Link>
            </div>
            <div className="flex flex-col flex-grow justify-between mt-5">
              <nav className="px-2 space-y-1 bg-white" aria-label="Sidebar-top">
                {navigation.map(
                  item =>
                    !isNavbarBottom(item) &&
                    item.navbar && (
                      <div key={item.navbar?.name}>
                        <Link
                          to={item.navbar?.redirect ? getLink(item.navbar.redirect) : getLink(item.path)}
                          className={classNames(
                            isCurrentRoute(item.path, params)
                              ? 'bg-gray-100 text-gray-900'
                              : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                            ' group w-full flex justify-between pl-2 py-2 text-sm font-medium rounded-md'
                          )}
                        >
                          <div className="flex">
                            <item.navbar.icon
                              className={classNames(
                                isCurrentRoute(item.path, params)
                                  ? 'text-gray-500'
                                  : 'text-gray-400 group-hover:text-gray-500',
                                'mr-3 flex-shrink-0 h-6 w-6'
                              )}
                              aria-hidden="true"
                            />
                            {t(item.navbar?.name)}
                          </div>
                          {hasNewMessage(item) && (
                            <span className="inline-flex items-center py-0.5 px-2.5 text-xs font-medium text-green-800 bg-green-100 rounded-full">
                              +{countNewMessages(item)}
                            </span>
                          )}
                        </Link>
                      </div>
                    )
                )}
              </nav>
              <nav className="px-2 space-y-1 bg-white" aria-label="Sidebar-bottom">
                {navigation.map(
                  item =>
                    isNavbarBottom(item) &&
                    item.navbar && (
                      <div key={item.navbar?.name}>
                        <Link
                          to={item.navbar.redirect ? getLink(item.navbar.redirect) : getLink(item.path)}
                          className={classNames(
                            isCurrentRoute(item.path, params)
                              ? 'bg-gray-100 text-gray-900'
                              : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                            'group w-full flex items-center pl-2 py-2 text-sm font-medium rounded-md'
                          )}
                        >
                          <item.navbar.icon
                            className={classNames(
                              isCurrentRoute(item.path, params)
                                ? 'text-gray-500'
                                : 'text-gray-400 group-hover:text-gray-500',
                              'mr-3 flex-shrink-0 h-6 w-6'
                            )}
                            aria-hidden="true"
                          />
                          {t(item.navbar?.name)}
                        </Link>
                      </div>
                    )
                )}
                <div>
                  <button
                    onClick={logoutHandler}
                    className="group flex items-center py-2 pl-2 w-full text-sm font-medium text-gray-600 hover:text-gray-900 bg-white hover:bg-gray-50 rounded-md focus:ring-0"
                  >
                    <LogoutIcon
                      className="flex-shrink-0 mr-3 w-6 h-6 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    {t('navbar.logout')}
                  </button>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div className="lg:hidden relative">
        <Disclosure as="nav" className="bg-gray-100">
          {() => (
            <>
              <div className="px-2 md:px-6 lg:px-8 mx-auto max-w-7xl">
                <div className="flex relative justify-between items-center h-16">
                  <div className="flex lg:hidden absolute inset-y-0 left-0 items-center">
                    {/* Mobile menu button*/}
                    <button
                      onClick={() => setSidebarOpen(true)}
                      className="inline-flex justify-center items-center p-2 text-green-500 hover:text-green-600 hover:bg-gray-200 rounded-md focus:ring-2 focus:ring-inset focus:ring-white focus:outline-none"
                    >
                      <span className="sr-only">Open main menu</span>
                      <MenuIcon className="block w-6 h-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="flex flex-1 justify-center lg:justify-start items-center lg:items-stretch">
                    <div className="flex flex-shrink-0 items-center">
                      <Link to={ROUTES.DASHBOARD}>
                        <img className="w-auto h-8" src={logo} alt="InspectLife logo" />
                      </Link>
                    </div>
                  </div>
                  <div className="flex absolute lg:static lg:inset-auto inset-y-0 right-0 items-center pr-2 lg:pr-0 lg:ml-6">
                    {/* Profile dropdown */}
                    <Menu as="div" className="relative ml-3">
                      {({ open }) => (
                        <>
                          <div>
                            <Menu.Button className="flex text-sm bg-gray-100 rounded-full focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-200 focus:outline-none">
                              <span className="sr-only">Open user menu</span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-6 h-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                              </svg>
                            </Menu.Button>
                          </div>
                          <Transition
                            show={open}
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items
                              static
                              className="absolute right-0 z-30 py-1 mt-2 w-48 bg-white rounded-md ring-1 ring-black ring-opacity-5 shadow-lg origin-top-right focus:outline-none"
                            >
                              <Menu.Item>
                                {() => (
                                  <>
                                    {navigation.map(
                                      item =>
                                        isNavbarBottom(item) &&
                                        item.navbar && (
                                          <div key={item.navbar?.name}>
                                            <Link
                                              to={
                                                item.navbar.redirect
                                                  ? getLink(item.navbar.redirect)
                                                  : getLink(item.path)
                                              }
                                              className={classNames(
                                                isCurrentRoute(item.path, params)
                                                  ? 'bg-gray-100 text-gray-900'
                                                  : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                                'group w-full flex items-center pl-2 py-2 text-sm font-medium rounded-md'
                                              )}
                                            >
                                              <item.navbar.icon
                                                className={classNames(
                                                  isCurrentRoute(item.path, params)
                                                    ? 'text-gray-500'
                                                    : 'text-gray-400 group-hover:text-gray-500',
                                                  'mr-3 flex-shrink-0 h-6 w-6'
                                                )}
                                                aria-hidden="true"
                                              />
                                              {t(item.navbar?.name)}
                                            </Link>
                                          </div>
                                        )
                                    )}
                                  </>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {() => (
                                  <div>
                                    <button
                                      onClick={logoutHandler}
                                      className="group flex items-center py-2 pl-2 w-full text-sm font-medium text-gray-600 hover:text-gray-900 bg-white hover:bg-gray-50 rounded-md focus:ring-0"
                                    >
                                      <LogoutIcon
                                        className="flex-shrink-0 mr-3 w-6 h-6 text-gray-400 group-hover:text-gray-500"
                                        aria-hidden="true"
                                      />
                                      {t('navbar.logout')}
                                    </button>
                                  </div>
                                )}
                              </Menu.Item>
                            </Menu.Items>
                          </Transition>
                        </>
                      )}
                    </Menu>
                  </div>
                </div>
              </div>

              <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog as="div" className="flex lg:hidden fixed inset-0 z-40" onClose={() => setSidebarOpen(false)}>
                  <Transition.Child
                    as={Fragment}
                    enter="transition-opacity ease-linear duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity ease-linear duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                  </Transition.Child>
                  <Transition.Child
                    as={Fragment}
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="-translate-x-full"
                    enterTo="translate-x-0"
                    leave="transition ease-in-out duration-300 transform"
                    leaveFrom="translate-x-0"
                    leaveTo="-translate-x-full"
                  >
                    <div className="flex relative flex-col flex-1 pt-5 pb-4 w-full max-w-xs bg-white">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <div className="absolute top-0 right-0 pt-2 -mr-12">
                          <button
                            onClick={() => setSidebarOpen(false)}
                            className="flex justify-center items-center ml-1 w-10 h-10 text-white rounded-full focus:ring-2 focus:ring-inset focus:ring-white focus:outline-none"
                          >
                            <span className="sr-only">Open main menu</span>
                            <XIcon className="block w-6 h-6" aria-hidden="true" />
                          </button>
                        </div>
                      </Transition.Child>
                      <div className="flex flex-shrink-0 items-center px-4">
                        <img className="w-auto h-8" src={logo} alt="InspectLife" />
                      </div>
                      <div className="overflow-y-auto flex-1 mt-5 h-0">
                        <nav className="px-2">
                          <div className="space-y-1">
                            {navigation.map(
                              item =>
                                !isNavbarBottom(item) &&
                                item.navbar && (
                                  <div key={item.navbar?.name}>
                                    <Link
                                      to={item.navbar?.redirect ? getLink(item.navbar.redirect) : getLink(item.path)}
                                      onClick={() => setSidebarOpen(false)}
                                      className={classNames(
                                        isCurrentRoute(item.path, params)
                                          ? 'bg-gray-100 text-gray-900'
                                          : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                        ' group w-full flex justify-between pl-2 py-2 text-sm font-medium rounded-md'
                                      )}
                                    >
                                      <div className="flex">
                                        <item.navbar.icon
                                          className={classNames(
                                            isCurrentRoute(item.path, params)
                                              ? 'text-gray-500'
                                              : 'text-gray-400 group-hover:text-gray-500',
                                            'mr-3 flex-shrink-0 h-6 w-6'
                                          )}
                                          aria-hidden="true"
                                        />
                                        {t(item.navbar?.name)}
                                      </div>
                                      {hasNewMessage(item) && (
                                        <span className="inline-flex items-center py-0.5 px-2.5 text-xs font-medium text-green-800 bg-green-100 rounded-full">
                                          +{countNewMessages(item)}
                                        </span>
                                      )}
                                    </Link>
                                  </div>
                                )
                            )}
                          </div>
                        </nav>
                      </div>
                    </div>
                  </Transition.Child>
                  <div className="flex-shrink-0 w-14" aria-hidden="true">
                    {/* Dummy element to force sidebar to shrink to fit close icon */}
                  </div>
                </Dialog>
              </Transition.Root>
            </>
          )}
        </Disclosure>
      </div>
    </>
  )
}

export default Navbar
