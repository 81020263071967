import { createSlice } from '@reduxjs/toolkit'
import { DispatchActionReducerInterface, ICDState } from '../../../interfaces'
import { getICDTitle, getICDWhisper, getMedicationTitle, getMedicationWhisper } from './actions'

import { ICD } from './types'

const initialState: ICDState = {
  icdWhisper: [],
  getICDWhisperLoading: false,

  icdTitle: null,
  getICDTitleLoading: false,

  medicationWhisper: [],
  getMedicationWhisperLoading: false,

  medicationTitle: null,
  getMedicationTitleLoading: false,
}

const icdSlice = createSlice({
  name: ICD.NAMESPACE,
  initialState,
  // Only frontend Logic
  reducers: {},
  extraReducers: builder => {
    // Reducers with calling external API
    builder
      .addCase(getICDWhisper.pending, (state: ICDState) => {
        state.getICDWhisperLoading = true
      })
      .addCase(getICDWhisper.fulfilled, (state: ICDState, action: DispatchActionReducerInterface) => {
        if (action.payload?.status === 'ok') {
          state.icdWhisper = action.payload.data
        } else {
          state.icdWhisper = []
        }
        state.getICDWhisperLoading = false
      })
      .addCase(getICDWhisper.rejected, (state: ICDState) => {
        state.icdWhisper = []
        state.getICDWhisperLoading = false
      })

      .addCase(getICDTitle.pending, (state: ICDState) => {
        state.getICDTitleLoading = true
      })
      .addCase(getICDTitle.fulfilled, (state: ICDState, action: DispatchActionReducerInterface) => {
        if (action.payload?.status === 'ok') {
          state.icdTitle = action.payload.data
        } else {
          state.icdTitle = null
        }
        state.getICDTitleLoading = false
      })
      .addCase(getICDTitle.rejected, (state: ICDState) => {
        state.icdTitle = null
        state.getICDTitleLoading = false
      })

      .addCase(getMedicationWhisper.pending, (state: ICDState) => {
        state.getMedicationWhisperLoading = true
      })
      .addCase(getMedicationWhisper.fulfilled, (state: ICDState, action: DispatchActionReducerInterface) => {
        if (action.payload?.status === 'ok') {
          state.medicationWhisper = action.payload.data
        } else {
          state.medicationWhisper = []
        }
        state.getMedicationWhisperLoading = false
      })
      .addCase(getMedicationWhisper.rejected, (state: ICDState) => {
        state.medicationWhisper = []
        state.getMedicationWhisperLoading = false
      })

      .addCase(getMedicationTitle.pending, (state: ICDState) => {
        state.getMedicationTitleLoading = true
      })
      .addCase(getMedicationTitle.fulfilled, (state: ICDState, action: DispatchActionReducerInterface) => {
        if (action.payload?.status === 'ok') {
          state.medicationTitle = action.payload.data
        } else {
          state.medicationTitle = null
        }
        state.getMedicationTitleLoading = false
      })
      .addCase(getMedicationTitle.rejected, (state: ICDState) => {
        state.medicationTitle = null
        state.getMedicationTitleLoading = false
      })
  },
})

// Extract the action creators object and the reducer
const { reducer } = icdSlice
// Extract and export each action creator by name
// export const {} = actions
// Export the reducer, either as a default or named export
export default reducer
