import { PARAMS } from '../../enums'
import store from '../../store'
import { setUploadProgress } from '../../store/modules/files/reducer'
import { FILES } from '../../store/modules/files/types'

export default {
  uploadFile: {
    id: FILES.ACTIONS.UPLOAD_FILE,
    request: (data: any) => ({
      url: `/v1/files/`,
      method: 'POST',
      data,
      onUploadProgress: (progressEvent: any) => {
        const progress = Math.round((100 * progressEvent.loaded) / progressEvent.total)
        store.dispatch(setUploadProgress(progress))
      },
    }),
  },
  uploadExercise: {
    id: FILES.ACTIONS.UPLOAD_EXERCISE,
    request: (data: any) => ({
      url: `/v1/exercise/upload`,
      method: 'POST',
      data,
      onUploadProgress: (progressEvent: any) => {
        const progress = Math.round((100 * progressEvent.loaded) / progressEvent.total)
        store.dispatch(setUploadProgress(progress))
      },
      headers: {
        // Pokud se nepošle, tak přestane ten endpoint fungovat a začne házet CORS
        'X-Role-Id': '-',
      },
    }),
  },
  getFile: {
    id: FILES.ACTIONS.GET_FILE,
    request: (data: any) => ({
      url: `/v1/files/${data[PARAMS.FILE_ID]}`,
      method: 'GET',
      data,
    }),
  },
}
