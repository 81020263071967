import React from 'react'
import { useParams } from 'react-router-dom'
import { PARAMS } from '../../enums'
import { BaseParamsInterface } from '../../interfaces'
import { useAppDispatch } from '../../store'
import { resetPasswordNewPassword } from '../../store/modules/auth/actions'
import PasswordResetNewPasswordForm from './components/PasswordResetNewPasswordForm'

export const PasswordResetNewPasswordHandler = () => {
  const dispatch = useAppDispatch()
  const params: BaseParamsInterface = useParams()

  const handleNewPasswordChange = (values: any, { setSubmitting }: any) => {
    values.token = params[PARAMS.TOKEN]
    dispatch(resetPasswordNewPassword(values)).then(() => {
      setSubmitting(false)
    })
  }

  return (
    <>
      <PasswordResetNewPasswordForm handleNewPasswordChange={handleNewPasswordChange} />
    </>
  )
}

export default PasswordResetNewPasswordHandler
