import React from 'react'
import { Link } from 'react-router-dom'
import register_img from '../../assets/images/login.jpg'
import logo from '../../assets/images/logo.png'
import RegisterHandler from '../../components/register/RegisterHandler'
import { ROUTES } from '../../router/routes'

export const RegisterPage = () => {
  return (
    <div className="flex min-h-screen bg-white">
      <div className="flex flex-col flex-1 lg:flex-none justify-center py-12 px-4 sm:px-6 lg:px-20 xl:px-24">
        <div className="mx-auto w-140">
          <div className="pb-8">
            <Link to={ROUTES.DASHBOARD}>
              <img src={logo} alt="InspectLife logo" />
            </Link>
          </div>
          <RegisterHandler />
        </div>
      </div>
      <div className="hidden lg:block relative flex-1 w-0">
        <img className="object-cover absolute inset-0 w-full h-full" src={register_img} alt="" />
      </div>
    </div>
  )
}

export default RegisterPage
