export const CHAT = {
  NAMESPACE: 'chat',
  ACTIONS: {
    GET_DIRECT_MESSAGE: 'GET_DIRECT_MESSAGE',
    CREATE_DIRECT_MESSAGE: 'CREATE_DIRECT_MESSAGE',
    GET_GROUP_CHAT: 'GET_GROUP_CHAT',
    DELETE_GROUP_CHAT: 'DELETE_GROUP_CHAT',
    ADD_GROUP_CHAT_OWNER: 'ADD_GROUP_CHAT_OWNER',
    INVITE_GROUP_CHAT_MEMBER: 'INVITE_GROUP_CHAT_MEMBER',
    KICK_GROUP_CHAT_MEMBER: 'KICK_GROUP_CHAT_MEMBER',
    REMOVE_GROUP_CHAT_OWNER: 'REMOVE_GROUP_CHAT_OWNER',
    GET_GROUP_CHAT_MEMBERS: 'GET_GROUP_CHAT_MEMBERS',
    LEAVE_GROUP_CHAT: 'LEAVE_GROUP_CHAT',
    CREATE_GROUP_CHAT: 'CREATE_GROUP_CHAT',
    SEND_MESSAGE: 'SEND_MESSAGE',
    AUTHORIZE_CHAT: 'AUTHORIZE_CHAT',
    GET_CHAT_USERS: 'GET_CHAT_USERS',
    GET_SUBSCRIPTIONS: 'GET_SUBSCRIPTIONS',
    GET_SUBSCRIPTION: 'GET_SUBSCRIPTION',
    MARK_CHAT_AS_READ: 'MARK_CHAT_AS_READ',
  },
}
