import React from 'react'
import { useSelector } from 'react-redux'
import { TABLE_LIMITS } from '../../../enums'
import { RootState } from '../../../store'
import { getDoctors } from '../../../store/modules/doctors/actions'
import { getPatients } from '../../../store/modules/patients/actions'
import AppLayout from '../../common/layout/AppLayout'
import ContentLayout from '../../common/layout/ContentLayout'
import DoctorsTable from '../doctors/DoctorsTable'
import PatientsTable from '../patients/PatientsTable'

export const DashboardAdminView = () => {
  const { patients, getPatientsLoading } = useSelector((state: RootState) => state.patients)
  const { doctors, getDoctorsLoading } = useSelector((state: RootState) => state.doctors)

  return (
    <AppLayout>
      <ContentLayout showRoleButtons={false}>
        <div className="flex flex-col space-y-6">
          <div className="space-y-6">
            <PatientsTable
              limit={TABLE_LIMITS.DASHBOARD_PATIENTS_LIST}
              isSearchable={false}
              dispatchCall={getPatients}
              data={patients}
              loading={getPatientsLoading}
              canAdd={false}
              canGiveAccess={false}
              canRegister={false}
              canViewDetail={true}
              canRemoveAccess={false}
              canViewTelerehabilitation={false}
            />
          </div>
          <div className="space-y-6">
            <DoctorsTable
              data={doctors}
              loading={getDoctorsLoading}
              dispatchCall={getDoctors}
              isSearchable={false}
              limit={TABLE_LIMITS.DASHBOARD_DOCTORS_LIST}
              canViewDetail={true}
              canAdd={true}
              canCreateChat={true}
              canCreateCall={true}
              canAddAccess={false}
              canRemoveAccess={false}
            />
          </div>
        </div>
      </ContentLayout>
    </AppLayout>
  )
}

export default DashboardAdminView
