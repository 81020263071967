export const ORDERS = {
  NAMESPACE: 'orders',
  ACTIONS: {
    CREATE_ORDER: 'CREATE_ORDER',
    GET_ORDER: 'GET_ORDER',
    GET_PATIENT_ORDER_HISTORY_LIST: 'GET_PATIENT_ORDER_HISTORY_LIST',
    GET_ORDER_HISTORY_LIST: 'GET_ORDER_HISTORY_LIST',
    CANCEL_ORDER: 'CANCEL_ORDER',
    GET_SERVICES: 'GET_SERVICES',
    CREATE_SERVICE: 'CREATE_SERVICE',
    GET_SERVICE: 'GET_SERVICE',
    EDIT_SERVICE: 'EDIT_SERVICE',
    DELETE_SERVICE: 'DELETE_SERVICE',
    GET_CATEGORIES: 'GET_CATEGORIES',
    GET_CATEGORY: 'GET_CATEGORY',
    CREATE_CATEGORY: 'CREATE_CATEGORY',
    EDIT_CATEGORY: 'EDIT_CATEGORY',
    DELETE_CATEGORY: 'DELETE_CATEGORY',
    GET_ACCESSORIES: 'GET_ACCESSORIES',
    CREATE_ACCESSORY: 'CREATE_ACCESSORY',
    GET_ACCESSORY: 'GET_ACCESSORY',
    EDIT_ACCESSORY: 'EDIT_ACCESSORY',
    DELETE_ACCESSORY: 'DELETE_ACCESSORY',
  },
}
