import { PARAMS } from '../../enums'
import { CUSTOM_MEDICATIONS } from '../../store/modules/custom-medications/types'

export default {
  getCustomMedications: {
    id: CUSTOM_MEDICATIONS.ACTIONS.GET_CUSTOM_MEDICATIONS,
    request: (data: any) => ({
      url: '/v1/medications',
      method: 'GET',
      params: {
        limit: data?.limit,
        offset: data?.offset,
      },
    }),
  },
  getCustomMedication: {
    id: CUSTOM_MEDICATIONS.ACTIONS.GET_CUSTOM_MEDICATION,
    request: (data: any) => ({
      url: `/v1/medications/${data[PARAMS.CUSTOM_MEDICATION_ID]}`,
      method: 'GET',
      data,
    }),
  },
  createCustomMedication: {
    id: CUSTOM_MEDICATIONS.ACTIONS.CREATE_CUSTOM_MEDICATIONS,
    request: (data: any) => ({
      url: `/v1/medications`,
      method: 'POST',
      data,
    }),
  },
  editCustomMedication: {
    id: CUSTOM_MEDICATIONS.ACTIONS.EDIT_CUSTOM_MEDICATIONS,
    request: (data: any) => ({
      url: `/v1/medications/${data[PARAMS.CUSTOM_MEDICATION_ID]}`,
      method: 'PUT',
      data,
    }),
  },
  deleteCustomMedication: {
    id: CUSTOM_MEDICATIONS.ACTIONS.DELETE_CUSTOM_MEDICATIONS,
    request: (data: any) => ({
      url: `/v1/medications/${data[PARAMS.CUSTOM_MEDICATION_ID]}`,
      method: 'DELETE',
      data,
    }),
  },
}
