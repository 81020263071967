import React from 'react'
import { AppLayoutInterface } from '../../../interfaces'
import Navbar from '../navbar/Navbar'

export const AppLayout = (props: AppLayoutInterface) => {
  const { children } = props
  return (
    <>
      <div className="flex overflow-x-hidden flex-col lg:flex-row h-screen">
        <Navbar />
        {children}
      </div>
    </>
  )
}

export default AppLayout
