import { PARAMS } from '../../enums'
import { CALENDAR } from '../../store/modules/calendar/types'

export default {
  getEvents: {
    id: CALENDAR.ACTIONS.GET_EVENTS,
    request: (data: any) => ({
      url: '/v1/events',
      method: 'GET',
      params: {
        limit: data?.limit,
        offset: data?.offset,
      },
    }),
  },
  getEvent: {
    id: CALENDAR.ACTIONS.GET_EVENT,
    request: (data: any) => ({
      url: `/v1/events/${data[PARAMS.EVENT_ID]}`,
      method: 'GET',
      data,
    }),
  },
  createEvent: {
    id: CALENDAR.ACTIONS.CREATE_EVENT,
    request: (data: any) => ({
      url: `/v1/events`,
      method: 'POST',
      data,
    }),
  },
  editEvent: {
    id: CALENDAR.ACTIONS.EDIT_EVENT,
    request: (data: any) => ({
      url: `/v1/events/${data[PARAMS.EVENT_ID]}`,
      method: 'PUT',
      data,
    }),
  },
  deleteEvent: {
    id: CALENDAR.ACTIONS.DELETE_EVENT,
    request: (data: any) => ({
      url: `/v1/events/${data[PARAMS.EVENT_ID]}`,
      method: 'DELETE',
      data,
    }),
  },
  rsvpEvent: {
    id: CALENDAR.ACTIONS.RSVP_EVENT,
    request: (data: any) => ({
      url: `/v1/events/${data[PARAMS.EVENT_ID]}/rsvp`,
      method: 'PUT',
      data,
    }),
  },
  getDoctorsAttendees: {
    id: CALENDAR.ACTIONS.GET_DOCTORS_ATTENDEES,
    request: (data: any) => ({
      url: `/v1/event/users`,
      method: 'GET',
      params: {
        limit: data?.limit,
        offset: data?.offset,
        search: data?.search,
      },
    }),
  },
}
