import { createAsyncThunk } from '@reduxjs/toolkit'
import { API } from '../../../api'
import { callOrderApiEndpoint } from '../../utils'

// typePrefix = '${namespace}/functionName'
export const createOrder: any = createAsyncThunk('orders/createOrder', async (payload, { dispatch }) => {
  return await callOrderApiEndpoint(dispatch, {
    requestData: { ...API.orders.createOrder },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const getOrder: any = createAsyncThunk('orders/getOrder', async (payload, { dispatch }) => {
  return await callOrderApiEndpoint(dispatch, {
    requestData: { ...API.orders.getOrder },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const getPatientOrderHistoryList: any = createAsyncThunk(
  'orders/getPatientOrderHistoryList',
  async (payload, { dispatch }) => {
    return await callOrderApiEndpoint(dispatch, {
      requestData: { ...API.orders.getPatientOrderHistoryList },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const getOrderHistoryList: any = createAsyncThunk(
  'orders/getOrderHistoryList',
  async (payload, { dispatch }) => {
    return await callOrderApiEndpoint(dispatch, {
      requestData: { ...API.orders.getOrderHistoryList },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const downloadOrderInvoice: any = createAsyncThunk(
  'orders/downloadOrderInvoice',
  async (payload, { dispatch }) => {
    return await callOrderApiEndpoint(dispatch, {
      requestData: { ...API.orders.downloadOrderInvoice },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const cancelOrder: any = createAsyncThunk('orders/cancelOrder', async (payload, { dispatch }) => {
  return await callOrderApiEndpoint(dispatch, {
    requestData: { ...API.orders.cancelOrder },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const getServices: any = createAsyncThunk('orders/getServices', async (payload, { dispatch }) => {
  return await callOrderApiEndpoint(dispatch, {
    requestData: { ...API.orders.getServices },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const getService: any = createAsyncThunk('orders/getService', async (payload, { dispatch }) => {
  return await callOrderApiEndpoint(dispatch, {
    requestData: { ...API.orders.getService },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const createService: any = createAsyncThunk('orders/createService', async (payload, { dispatch }) => {
  return await callOrderApiEndpoint(dispatch, {
    requestData: { ...API.orders.createService },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const editService: any = createAsyncThunk('orders/editService', async (payload, { dispatch }) => {
  return await callOrderApiEndpoint(dispatch, {
    requestData: { ...API.orders.editService },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const deleteService: any = createAsyncThunk('orders/deleteService', async (payload, { dispatch }) => {
  return await callOrderApiEndpoint(dispatch, {
    requestData: { ...API.orders.deleteService },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const getCategories: any = createAsyncThunk('orders/getCategories', async (payload, { dispatch }) => {
  return await callOrderApiEndpoint(dispatch, {
    requestData: { ...API.orders.getCategories },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const getCategory: any = createAsyncThunk('orders/getCategory', async (payload, { dispatch }) => {
  return await callOrderApiEndpoint(dispatch, {
    requestData: { ...API.orders.getCategory },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const createCategory: any = createAsyncThunk('orders/createCategory', async (payload, { dispatch }) => {
  return await callOrderApiEndpoint(dispatch, {
    requestData: { ...API.orders.createCategory },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const editCategory: any = createAsyncThunk('orders/editCategory', async (payload, { dispatch }) => {
  return await callOrderApiEndpoint(dispatch, {
    requestData: { ...API.orders.editCategory },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const deleteCategory: any = createAsyncThunk('orders/deleteCategory', async (payload, { dispatch }) => {
  return await callOrderApiEndpoint(dispatch, {
    requestData: { ...API.orders.deleteCategory },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const getAccessories: any = createAsyncThunk('orders/getAccessories', async (payload, { dispatch }) => {
  return await callOrderApiEndpoint(dispatch, {
    requestData: { ...API.orders.getAccessories },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const getAccessory: any = createAsyncThunk('orders/getAccessory', async (payload, { dispatch }) => {
  return await callOrderApiEndpoint(dispatch, {
    requestData: { ...API.orders.getAccessory },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const createAccessory: any = createAsyncThunk('orders/createAccessory', async (payload, { dispatch }) => {
  return await callOrderApiEndpoint(dispatch, {
    requestData: { ...API.orders.createAccessory },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const editAccessory: any = createAsyncThunk('orders/editAccessory', async (payload, { dispatch }) => {
  return await callOrderApiEndpoint(dispatch, {
    requestData: { ...API.orders.editAccessory },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const deleteAccessory: any = createAsyncThunk('orders/deleteAccessory', async (payload, { dispatch }) => {
  return await callOrderApiEndpoint(dispatch, {
    requestData: { ...API.orders.deleteAccessory },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})
