import React from 'react'
import { ChatSectionInterface } from '../../../interfaces'
import ContentLoader from '../../common/loader/ContentLoader'
import ChatIcon from './ChatIcon'

export const ChatSection = (props: ChatSectionInterface) => {
  const { name, content, loading, setMobileSidebarOpen } = props

  if (loading) {
    return <ContentLoader size="md" color="white" />
  }

  return (
    <div>
      <span className="block px-6 mb-4 text-sm text-gray-300">{name}</span>
      {content.map((instance, key) => (
        <ChatIcon setMobileSidebarOpen={setMobileSidebarOpen} className="px-6" key={key} {...instance} />
      ))}
    </div>
  )
}

export default ChatSection
