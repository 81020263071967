import { PARAMS } from '../../enums'
import { TELEREHABILITATION } from '../../store/modules/telerehabilitation/types'

export default {
  getExercises: {
    id: TELEREHABILITATION.ACTIONS.GET_EXERCISES,
    request: (data: any) => ({
      url: `/v1/exercises/${data[PARAMS.PATIENT_ID]}`,
      method: 'GET',
      params: {
        limit: data.limit,
        offset: data.offset,
      },
    }),
  },
  getExercisesDay: {
    id: TELEREHABILITATION.ACTIONS.GET_EXERCISES_DAY,
    request: (data: any) => ({
      url: `/v1/exercises/${data[PARAMS.PATIENT_ID]}/day/${data[PARAMS.DAY_ID]}`,
      method: 'GET',
      data,
    }),
  },
  deleteExercisesDay: {
    id: TELEREHABILITATION.ACTIONS.DELETE_EXERCISES_DAY,
    request: (data: any) => ({
      url: `/v1/exercises/${data[PARAMS.PATIENT_ID]}/day/${data[PARAMS.DAY_ID]}`,
      method: 'DELETE',
      data,
    }),
  },
  getExercise: {
    id: TELEREHABILITATION.ACTIONS.GET_EXERCISE,
    request: (data: any) => ({
      url: `/v1/exercises/${data[PARAMS.PATIENT_ID]}/day/${data[PARAMS.DAY_ID]}/exercise/${data[PARAMS.EXERCISE_ID]}`,
      method: 'GET',
      data,
    }),
  },
  getExerciseUploadToken: {
    id: TELEREHABILITATION.ACTIONS.GET_EXERCISE_TOKEN,
    request: (data: any) => ({
      url: `/v1/exercises/${data[PARAMS.PATIENT_ID]}/day/${data[PARAMS.DAY_ID]}/exercise/${
        data[PARAMS.EXERCISE_ID]
      }/upload_token`,
      method: 'GET',
      data,
    }),
  },
  deleteExercise: {
    id: TELEREHABILITATION.ACTIONS.DELETE_EXERCISE,
    request: (data: any) => ({
      url: `/v1/exercises/${data[PARAMS.PATIENT_ID]}/day/${data[PARAMS.DAY_ID]}/exercise/${data[PARAMS.EXERCISE_ID]}`,
      method: 'DELETE',
      data,
    }),
  },
  createExercise: {
    id: TELEREHABILITATION.ACTIONS.CREATE_EXERCISE,
    request: (data: any) => ({
      url: `/v1/exercises/${data[PARAMS.PATIENT_ID]}`,
      method: 'POST',
      data,
    }),
  },
  createExerciseVideo: {
    id: TELEREHABILITATION.ACTIONS.CREATE_EXERCISE_VIDEO,
    request: (data: any) => ({
      url: `/v1/exercise/assign`,
      method: 'PUT',
      data,
    }),
  },
  getPresets: {
    id: TELEREHABILITATION.ACTIONS.GET_PRESETS,
    request: (data: any) => ({
      url: `/v1/exercise_presets`,
      method: 'GET',
      params: {
        limit: data?.limit,
        offset: data?.offset,
      },
    }),
  },
  getPreset: {
    id: TELEREHABILITATION.ACTIONS.GET_PRESET,
    request: (data: any) => ({
      url: `/v1/exercise_presets/${data[PARAMS.PRESET_ID]}`,
      method: 'GET',
      data,
    }),
  },
  createPreset: {
    id: TELEREHABILITATION.ACTIONS.CREATE_PRESET,
    request: (data: any) => ({
      url: `/v1/exercise_presets`,
      method: 'POST',
      data,
    }),
  },
  editPreset: {
    id: TELEREHABILITATION.ACTIONS.EDIT_PRESET,
    request: (data: any) => ({
      url: `/v1/exercise_presets/${data[PARAMS.PRESET_ID]}`,
      method: 'PUT',
      data,
    }),
  },
  deletePreset: {
    id: TELEREHABILITATION.ACTIONS.DELETE_PRESET,
    request: (data: any) => ({
      url: `/v1/exercise_presets/${data[PARAMS.PRESET_ID]}`,
      method: 'DELETE',
      data,
    }),
  },
  getCharts: {
    id: TELEREHABILITATION.ACTIONS.GET_CHARTS,
    request: (data: any) => ({
      url: `/v1/exercise_presets/graphs`,
      method: 'GET',
      params: {
        limit: data?.limit,
        offset: data?.offset,
        search: data?.search,
      },
    }),
  },
  getChart: {
    id: TELEREHABILITATION.ACTIONS.GET_CHART,
    request: (data: any) => ({
      url: `/v1/exercise_presets/graphs/${data[PARAMS.CHART_ID]}`,
      method: 'GET',
      data,
    }),
  },
  createChart: {
    id: TELEREHABILITATION.ACTIONS.CREATE_CHART,
    request: (data: any) => ({
      url: `/v1/exercise_presets/graphs`,
      method: 'POST',
      data,
    }),
  },
  editChart: {
    id: TELEREHABILITATION.ACTIONS.EDIT_CHART,
    request: (data: any) => ({
      url: `/v1/exercise_presets/graphs/${data[PARAMS.CHART_ID]}`,
      method: 'PUT',
      data,
    }),
  },
  deleteChart: {
    id: TELEREHABILITATION.ACTIONS.DELETE_CHART,
    request: (data: any) => ({
      url: `/v1/exercise_presets/graphs/${data[PARAMS.CHART_ID]}`,
      method: 'DELETE',
      data,
    }),
  },
  copyExercisePresets: {
    id: TELEREHABILITATION.ACTIONS.COPY_EXERCISE_PRESETS,
    request: (data: any) => ({
      url: `/v1/exercises/${data[PARAMS.PATIENT_ID]}/clone`,
      method: 'POST',
      data,
    }),
  },
  checkPatientExercises: {
    id: TELEREHABILITATION.ACTIONS.CHECK_PATIENT_EXERCISES,
    request: (data: any) => ({
      url: `/v1/exercises/clone/check`,
      method: 'POST',
      data,
    }),
  },
}
