import React from 'react'
import { useAppDispatch } from '../../store'
import { resetPasswordEmail } from '../../store/modules/auth/actions'
import PasswordResetEmailForm from './components/PasswordResetEmailForm'

export const PasswordResetEmailHandler = () => {
  const dispatch = useAppDispatch()

  const handleEmailSend = (values: any, { setSubmitting }: any) => {
    setSubmitting(false)
    dispatch(resetPasswordEmail(values)).then(() => {
      setSubmitting(false)
    })
  }

  return (
    <>
      <PasswordResetEmailForm handleEmailSend={handleEmailSend} />
    </>
  )
}

export default PasswordResetEmailHandler
