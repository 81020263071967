import { createSlice } from '@reduxjs/toolkit'
import { AppState } from '../../../interfaces'
import { APP } from './types'

const initialState: AppState = {
  progress: 0,
}

const appSlice = createSlice({
  name: APP.NAMESPACE,
  initialState,
  // Only frontend Logic
  reducers: {
    setProgress(state, action) {
      state.progress = action.payload
    },
  },
})

// Extract the action creators object and the reducer
const { actions, reducer } = appSlice
// Extract and export each action creator by name
export const { setProgress } = actions
// Export the reducer, either as a default or named export
export default reducer
