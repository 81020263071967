export const CUSTOM_MEDICATIONS = {
  NAMESPACE: 'customMedications',
  ACTIONS: {
    GET_CUSTOM_MEDICATIONS: 'GET_CUSTOM_MEDICATIONS',
    GET_CUSTOM_MEDICATION: 'GET_CUSTOM_MEDICATION',
    CREATE_CUSTOM_MEDICATIONS: 'CREATE_CUSTOM_MEDICATIONS',
    EDIT_CUSTOM_MEDICATIONS: 'EDIT_CUSTOM_MEDICATIONS',
    DELETE_CUSTOM_MEDICATIONS: 'DELETE_CUSTOM_MEDICATIONS',
  },
}
