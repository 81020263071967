import { PlusIcon } from '@heroicons/react/outline'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import DescriptionListForm from '../../../components/common/description-list/components/DescriptionListForm'
import DescriptionList from '../../../components/common/description-list/DescriptionList'
import AppLayout from '../../../components/common/layout/AppLayout'
import ContentLayout from '../../../components/common/layout/ContentLayout'
import TableModal from '../../../components/common/table/components/TableModal'
import Table from '../../../components/common/table/Table'
import { PARAMS, TABLE_LIMITS } from '../../../enums'
import {
  ButtonBackgroundColorsEnum,
  ButtonInterface,
  ButtonTextColorsEnum,
  ButtonTypesEnum,
  DispatchActionInterface,
  FormikOnSubmitType,
  InputByTypeRow,
  InputTypeEnum,
  TextByTypeColumnsInterface,
} from '../../../interfaces'
import { RootState, useAppDispatch } from '../../../store'
import {
  createCustomMedication,
  deleteCustomMedication,
  editCustomMedication,
  getCustomMedication,
  getCustomMedications,
} from '../../../store/modules/custom-medications/actions'
import { getEnums } from '../../../store/modules/patients/actions'
import {
  createArrayOfEnumObjectsFromArrayOfValues,
  createEnumObjectFromValue,
  getValueFromObject,
} from '../../../store/utils'

export const CustomMedicationsPage = () => {
  const { customMedications, getCustomMedicationsLoading, customMedication, getCustomMedicationLoading } = useSelector(
    (state: RootState) => state.customMedications
  )
  const { enums } = useSelector((state: RootState) => state.patients)
  const { t } = useTranslation(['customMedications', 'enums'])
  const dispatch = useAppDispatch()
  const [isExternalOpen, setIsExternalOpen] = useState(false)
  const [isExternalCreate, setIsExternalCreate] = useState(false)

  const medicationForms = createArrayOfEnumObjectsFromArrayOfValues('medicationForm', enums['MedicationForm'])
  const medicationMethodOfAdministrations = createArrayOfEnumObjectsFromArrayOfValues(
    'medicationMethodOfAdministrations',
    enums['MedicationMethodOfAdministration']
  )

  useEffect(() => {
    loadEnum()
  }, [])

  const loadData = (limit: number, offset: number) => {
    dispatch(getCustomMedications({ limit, offset }))
  }

  const loadEnum = () => {
    dispatch(getEnums({ enums: ['MedicationForm', 'MedicationMethodOfAdministration'] }))
  }

  const openCreateModal = () => {
    setIsExternalCreate(true)
    setIsExternalOpen(true)
  }

  const submitHandler: FormikOnSubmitType = (values, setSubmitting) => {
    const send = { ...values }
    send[PARAMS.CUSTOM_MEDICATION_ID] = send._id
    send.form = getValueFromObject(values.form)
    send.method_of_administration = getValueFromObject(values.method_of_administration)

    if (send._id) {
      dispatch(editCustomMedication(send)).then((action: DispatchActionInterface) => {
        setSubmitting(false)
        if (action.payload?.status === 'ok') {
          loadData(TABLE_LIMITS.CUSTOM_MEDICATIONS, 0)
          setIsExternalOpen(false)
        }
      })
    } else {
      dispatch(createCustomMedication(send)).then((action: DispatchActionInterface) => {
        setSubmitting(false)
        if (action.payload?.status === 'ok') {
          loadData(TABLE_LIMITS.CUSTOM_MEDICATIONS, 0)
          setIsExternalOpen(false)
        }
      })
    }
  }

  const detailHandler = (_id: string) => {
    dispatch(getCustomMedication({ [PARAMS.CUSTOM_MEDICATION_ID]: _id })).then((action: DispatchActionInterface) => {
      if (action.payload?.status === 'ok') {
        setIsExternalCreate(false)
        setIsExternalOpen(true)
      }
    })
  }

  const deleteHandler = (_id: string) => {
    dispatch(deleteCustomMedication({ [PARAMS.CUSTOM_MEDICATION_ID]: _id })).then((action: DispatchActionInterface) => {
      if (action.payload?.status === 'ok') {
        setIsExternalOpen(false)
      }
    })
  }

  const buttons: ButtonInterface[] = [
    {
      title: t('customMedications.addRecordButton'),
      icon: PlusIcon,
      type: ButtonTypesEnum.Button,
      onClick: openCreateModal,
      backgroundColor: ButtonBackgroundColorsEnum.Brand,
      textColor: ButtonTextColorsEnum.White,
    },
  ]

  const columns: TextByTypeColumnsInterface[] = [
    {
      label: t('customMedications.title'),
      values: 'name',
    },
    {
      label: t('customMedications.activeSubstance'),
      values: 'active_substance',
    },
    {
      label: t('customMedications.atcCode'),
      values: 'atc_code',
    },
    {
      values: 'form',
      label: t('customMedications.form'),
      t,
      namespace: 'enums:enums.medicationForm.',
    },
    {
      label: t('customMedications.methodOfAdministration'),
      values: 'method_of_administration',
      t,
      namespace: 'enums:enums.medicationMethodOfAdministrations.',
    },
    {
      label: t('customMedications.packagingType'),
      values: 'packaging_type',
    },
    {
      label: t('customMedications.strength'),
      values: 'strength',
    },
  ]

  const rows: InputByTypeRow[] = [
    {
      list_label: t('customMedications.name'),
      name: 'name',
      type: InputTypeEnum.Text,
      value: customMedication?.name,
    },
    {
      list_label: t('customMedications.activeSubstance'),
      name: 'active_substance',
      type: InputTypeEnum.Text,
      value: customMedication?.active_substance,
    },
    {
      list_label: t('customMedications.atcCode'),
      name: 'atc_code',
      type: InputTypeEnum.Text,
      value: customMedication?.atc_code,
    },
    {
      list_label: t('customMedications.form'),
      name: 'form',
      type: InputTypeEnum.SearchSelect,
      value: customMedication?.form,
      options: medicationForms,
      t,
      namespace: 'enums:enums.medicationForm.',
    },
    {
      list_label: t('customMedications.methodOfAdministration'),
      name: 'method_of_administration',
      type: InputTypeEnum.SearchSelect,
      value: customMedication?.method_of_administration,
      options: medicationMethodOfAdministrations,
      t,
      namespace: 'enums:enums.medicationMethodOfAdministrations.',
    },
    {
      list_label: t('customMedications.packagingType'),
      name: 'packaging_type',
      type: InputTypeEnum.Text,
      value: customMedication?.packaging_type,
    },
    {
      list_label: t('customMedications.strength'),
      name: 'strength',
      type: InputTypeEnum.Text,
      value: customMedication?.strength,
    },
  ]

  const initialForm = createEnumObjectFromValue('medicationForm', customMedication?.form)
  const initialMethod = createEnumObjectFromValue(
    'medicationMethodOfAdministrations',
    customMedication?.method_of_administration
  )

  const initialValues = {
    name: isExternalCreate ? '' : customMedication?.name,
    active_substance: isExternalCreate ? '' : customMedication?.active_substance,
    atc_code: isExternalCreate ? '' : customMedication?.atc_code,
    form: isExternalCreate ? null : initialForm,
    method_of_administration: isExternalCreate ? null : initialMethod,
    issue_type: isExternalCreate ? '' : customMedication?.issue_type,
    packaging_type: isExternalCreate ? '' : customMedication?.packaging_type,
    strength: isExternalCreate ? '' : customMedication?.strength,
  }

  return (
    <AppLayout>
      <ContentLayout title={t('customMedications.componentTitle')} buttons={buttons} showRoleButtons={false}>
        <Table
          columns={columns}
          data={customMedications}
          loading={getCustomMedicationsLoading}
          fetch={loadData}
          limit={TABLE_LIMITS.CUSTOM_MEDICATIONS}
          onEmptyStateClick={openCreateModal}
          emptyState={!customMedications?.total_count}
          actions={[
            {
              title: 'Detail',
              type: ButtonTypesEnum.Button,
              onClick: detailHandler,
              backgroundColor: ButtonBackgroundColorsEnum.Transparent,
              textColor: ButtonTextColorsEnum.Brand,
            },
          ]}
        />
        <TableModal isOpen={isExternalOpen} setIsOpen={setIsExternalOpen}>
          <DescriptionList
            isCreate={isExternalCreate}
            itemID={customMedication?._id}
            loading={getCustomMedicationLoading}
            title={isExternalCreate ? t('customMedications.createModalTitle') : customMedication?.atc_code}
            onDeleteClick={deleteHandler}
            isEditable={true}
            isDeletable={true}
          >
            <DescriptionListForm
              rows={rows}
              isCreate={isExternalCreate}
              initialValues={initialValues}
              itemID={customMedication?._id}
              onSubmitClick={submitHandler}
            />
          </DescriptionList>
        </TableModal>
      </ContentLayout>
    </AppLayout>
  )
}

export default CustomMedicationsPage
