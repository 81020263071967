import React, { Suspense } from 'react'
import ContentLoader from '../loader/ContentLoader'
import PageHelmet from './components/PageHelmet'

export const PageWithTitle = ({ component: Component, title: title, ...rest }: any) => {
  return (
    <React.Fragment>
      <PageHelmet title={title} />
      <Suspense fallback={<ContentLoader />}>
        <Component {...rest} />
      </Suspense>
    </React.Fragment>
  )
}

export default PageWithTitle
