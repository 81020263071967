import { PlusIcon } from '@heroicons/react/outline'
import icon from '../../../assets/images/empty_icon.svg'
import {
  ButtonBackgroundColorsEnum,
  ButtonTextColorsEnum,
  ButtonTypesEnum,
  EmptyStateInterface,
} from '../../../interfaces'
import Button from '../button/Button'

export const EmptyState = (props: EmptyStateInterface) => {
  const { onClick, buttonTitle, text } = props

  return (
    <div className="flex flex-col items-center py-36 mx-auto max-w-md">
      <img src={icon} alt="Empty state icon" />
      <span className="my-12 text-xl font-semibold text-center text-brand">{text}</span>
      {buttonTitle && onClick && (
        <Button
          type={ButtonTypesEnum.Button}
          backgroundColor={ButtonBackgroundColorsEnum.Brand}
          textColor={ButtonTextColorsEnum.White}
          onClick={onClick}
          title={buttonTitle}
          icon={PlusIcon}
          className="w-full"
        />
      )}
    </div>
  )
}

export default EmptyState
