import { PlusIcon } from '@heroicons/react/outline'
import { Formik } from 'formik'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { PARAMS, PHONE_NUMBER_CODES } from '../../../enums'
import {
  BaseParamsInterface,
  ButtonBackgroundColorsEnum,
  ButtonTextColorsEnum,
  ButtonTypesEnum,
  DispatchActionInterface,
  FormikOnSubmitType,
  InputByTypeRow,
  InputTypeEnum,
  PatientsTableInterface,
  TableActionsInterface,
  TextByTypeColumnsInterface,
} from '../../../interfaces'
import { ROUTES } from '../../../router/routes'
import { RootState, useAppDispatch } from '../../../store'
import {
  adminGiveDoctorPatientsAccess,
  adminRemoveDoctorPatientsAccess,
  registerPatientViaDoctor,
} from '../../../store/modules/doctors/actions'
import { getEnums, getPatientByPIN, getPatientsRequestAccess } from '../../../store/modules/patients/actions'
import { createArrayOfEnumObjectsFromArrayOfValues, getValueFromObject } from '../../../store/utils'
import Button from '../../common/button/Button'
import FormWrapper from '../../common/form/FormWrapper'
import CustomSearchInput from '../../common/inputs/components/CustomSearchInput'
import ConfirmModal from '../../common/modals/ConfirmModal'
import CustomInputModal from '../../common/modals/CustomInputModal'
import Table from '../../common/table/Table'

export const PatientsTable = (props: PatientsTableInterface) => {
  const {
    limit,
    isSearchable,
    dispatchCall,
    data,
    loading,
    canAdd,
    canGiveAccess,
    canRegister,
    canRemoveAccess,
    canViewDetail,
    canViewTelerehabilitation,
  } = props
  const { enums } = useSelector((state: RootState) => state.patients)
  const { authUser } = useSelector((state: RootState) => state.auth)
  const [patientsFirstLoad, setPatientsFirstLoad] = useState(true)
  const [actions, setActions] = useState<TableActionsInterface[]>([])
  const [search, setSearch] = useState<string | undefined>(undefined)
  const [isOpenAddModal, setIsOpenAddModal] = useState(false)
  const [isOpenRegisterModal, setIsOpenRegisterModal] = useState(false)
  const [isOpenAccessModal, setIsOpenAccessModal] = useState(false)
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  const [patientId, setPatientId] = useState('')
  const dispatch = useAppDispatch()
  const params: BaseParamsInterface = useParams()
  const { t } = useTranslation(['patients'])

  useEffect(() => {
    loadEnums()
  }, [])

  useEffect(() => {
    getActions()
  }, [authUser])

  const loadPatients = (limit: number, offset: number, search?: string) => {
    dispatch(dispatchCall({ [PARAMS.DOCTOR_ID]: params[PARAMS.DOCTOR_ID], limit, offset, search })).then(() =>
      setPatientsFirstLoad(false)
    )
  }

  const loadEnums = () => {
    dispatch(getEnums({ enums: ['Country', 'HealthInsuranceCompany'] }))
  }

  const onRegisterSubmitHandler: FormikOnSubmitType = (values, setSubmitting) => {
    const send = { ...values }
    send.country = getValueFromObject(send.country)
    send.health_insurance_company_code = getValueFromObject(send.health_insurance_company_code)
    send.phone_number_code = getValueFromObject(send.phone_number_code)
    dispatch(registerPatientViaDoctor(send)).then((action: DispatchActionInterface) => {
      if (action.payload?.status === 'ok') {
        setIsOpenRegisterModal(false)
        loadPatients(limit, 0)
      }
      setSubmitting(false)
    })
  }

  const onAddSubmitHandler: FormikOnSubmitType = (values, setSubmitting) => {
    values[PARAMS.PATIENT_ID] = getValueFromObject(values.patient, 'value')
    dispatch(getPatientsRequestAccess(values)).then((action: DispatchActionInterface) => {
      setSubmitting(false)
      if (action.payload?.status === 'ok') {
        loadPatients(limit, 0)
        setIsOpenAddModal(false)
      }
    })
  }

  const onGiveAccessSubmitHandler: FormikOnSubmitType = (values, setSubmitting) => {
    const send = { ...values }
    send[PARAMS.PATIENT_ID] = getValueFromObject(send.patient)
    send[PARAMS.DOCTOR_ID] = params[PARAMS.DOCTOR_ID]

    dispatch(adminGiveDoctorPatientsAccess(send)).then((action: DispatchActionInterface) => {
      setSubmitting(false)
      if (action.payload?.status === 'ok') {
        if (setIsOpenAccessModal) setIsOpenAccessModal(false)
        loadPatients(limit, 0)
      }
    })
  }

  const adminRemoveDoctorPatientsAccessHandler = (_id: string) => {
    dispatch(
      adminRemoveDoctorPatientsAccess({ [PARAMS.PATIENT_ID]: _id, [PARAMS.DOCTOR_ID]: params[PARAMS.DOCTOR_ID] })
    ).then((action: DispatchActionInterface) => {
      if (action.payload?.status === 'ok') {
        loadPatients(limit, 0)
        setIsConfirmOpen(false)
      }
    })
  }

  const getActions = () => {
    const tmp: TableActionsInterface[] = []

    if (canViewDetail) {
      tmp.push({
        title: t('patients.patientsTable.detailButton'),
        type: ButtonTypesEnum.Link,
        dynamicLink: {
          path: ROUTES.PATIENTS_DETAIL_OVERVIEW,
          key: `:${PARAMS.PATIENT_ID}`,
          value: 'patient_id',
        },
        backgroundColor: ButtonBackgroundColorsEnum.Transparent,
        textColor: ButtonTextColorsEnum.Brand,
      })
    }

    if (canViewTelerehabilitation) {
      tmp.push(
        // @todo: až bude role terapeut, tak zobrazit jenom jedno tlačítko
        {
          title: t('patients.patientsTable.telerehabilitationButton'),
          type: ButtonTypesEnum.Link,
          dynamicLink: {
            path: ROUTES.TELEREHABILITATION_PATIENT,
            key: `:${PARAMS.PATIENT_ID}`,
            value: 'patient_id',
          },
          backgroundColor: ButtonBackgroundColorsEnum.Transparent,
          textColor: ButtonTextColorsEnum.Brand,
        }
      )
    }

    if (canRemoveAccess) {
      tmp.push({
        title: t('patients.patientsTable.removeAccessButton'),
        type: ButtonTypesEnum.Button,
        onClickKey: 'patient_id',
        onClick: _id => {
          setPatientId(_id)
          setIsConfirmOpen(true)
        },
        backgroundColor: ButtonBackgroundColorsEnum.Transparent,
        textColor: ButtonTextColorsEnum.Red,
      })
    }

    setActions(tmp)
  }

  const countries = createArrayOfEnumObjectsFromArrayOfValues('country', enums['Country'])
  const healthInsuranceCompanies = createArrayOfEnumObjectsFromArrayOfValues(
    'healthInsuranceCompany',
    enums['HealthInsuranceCompany']
  )

  const patientsColumns: TextByTypeColumnsInterface[] = [
    {
      label: t('patients.patientsTable.name'),
      values: 'name',
    },
    {
      label: t('patients.patientsTable.sex.sex'),
      values: 'sex',
      t,
      namespace: 'patients.patientsTable.sex.',
    },
    {
      label: t('patients.patientsTable.birthDate'),
      values: 'date_of_birth',
      type: InputTypeEnum.Date,
    },
    {
      label: t('patients.patientsTable.phoneNumber'),
      values: 'phone_number',
    },
    {
      label: t('patients.patientsTable.insuranceNumber'),
      values: 'pin',
    },
  ]

  const addPatientModalRows: InputByTypeRow[] = [
    {
      label: t('patients.patientsTable.patient'),
      name: 'patient',
      type: InputTypeEnum.ApiSearchSelect,
      className: 'col-span-12',
      placeholder: t('patients.patientsTable.patientPlaceholder'),
      searchSelectDispatch: getPatientByPIN,
      searchSelectStructure: { name: ['first_name', 'last_name'], value: '_id' },
    },
  ]

  const giveAccessRows: InputByTypeRow[] = [
    {
      label: t('patients.patientsTable.patient'),
      name: 'patient',
      type: InputTypeEnum.ApiSearchSelect,
      className: 'col-span-12',
      placeholder: t('patients.patientsTable.patientPlaceholder'),
      searchSelectDispatch: getPatientByPIN,
      searchSelectStructure: { name: ['first_name', 'last_name'], value: '_id' },
    },
  ]

  const registerPatientRows: InputByTypeRow[] = [
    {
      label: t('patients.patientsTable.dateOfBirth'),
      name: 'date_of_birth',
      type: InputTypeEnum.Date,
      className: 'col-span-12',
    },
    {
      label: t('patients.patientsTable.firstName'),
      name: 'first_name',
      type: InputTypeEnum.Text,
      className: 'col-span-12',
    },
    {
      label: t('patients.patientsTable.lastName'),
      name: 'last_name',
      type: InputTypeEnum.Text,
      className: 'col-span-12',
    },
    {
      label: t('patients.patientsTable.email'),
      name: 'email',
      type: InputTypeEnum.Text,
      className: 'col-span-12',
    },
    {
      label: t('patients.patientsTable.pin'),
      name: 'pin',
      type: InputTypeEnum.PIN,
      className: 'col-span-12',
    },
    {
      label: t('patients.patientsTable.country'),
      name: 'country',
      type: InputTypeEnum.Select,
      options: countries,
      value: countries[0],
      className: 'col-span-12',
    },
    {
      label: t('patients.patientsTable.healthInsuranceCompanyCode'),
      name: 'health_insurance_company_code',
      type: InputTypeEnum.Select,
      options: healthInsuranceCompanies,
      value: healthInsuranceCompanies[0],
      className: 'col-span-12',
    },
  ]

  const registerPatientInitialValues = {
    first_name: '',
    last_name: '',
    country: countries[0],
    email: '',
    phone_number: '',
    phone_number_code: PHONE_NUMBER_CODES[0],
    pin: '',
    health_insurance_company_code: healthInsuranceCompanies[0],
    permanent_residence_address: {
      street: '',
      street_number: '',
      city: '',
      postal_code: '',
    },
  }

  const addPatientModalInitialValues = {
    patient: '',
    reason: '',
  }

  const giveAccessInitialValues = {
    doctor_id: '',
  }

  const registerPatientValidationSchema = Yup.object({
    date_of_birth: Yup.string().required(t('yup:yup.required')),
    first_name: Yup.string().required(t('yup:yup.required')),
    last_name: Yup.string().required(t('yup:yup.required')),
    country: Yup.object().typeError(t('yup:yup.required')),
    email: Yup.string().required(t('yup:yup.required')),
    pin: Yup.string().required(t('yup:yup.required')),
    health_insurance_company_code: Yup.object().typeError(t('yup:yup.required')),
  })

  const giveAccessValidationSchema = Yup.object({
    doctor: Yup.object().typeError(t('yup:yup.required')),
  })

  return (
    <>
      <div className="flex flex-wrap justify-between space-y-4 md:space-y-0">
        <div>
          <h2 className="text-3xl font-bold">{t('patients.patientsTable.componentTitle')}</h2>
        </div>

        <div className="flex space-x-3">
          {canAdd && (
            <Button
              type={ButtonTypesEnum.Button}
              icon={PlusIcon}
              onClick={() => setIsOpenAddModal(true)}
              title={t('patients.patientsTable.addRecordButton')}
              backgroundColor={ButtonBackgroundColorsEnum.Brand}
              textColor={ButtonTextColorsEnum.White}
            />
          )}
          {canRegister && (
            <Button
              type={ButtonTypesEnum.Button}
              icon={PlusIcon}
              onClick={() => setIsOpenRegisterModal(true)}
              title={t('patients.patientsTable.registerPatientButton')}
              backgroundColor={ButtonBackgroundColorsEnum.Brand}
              textColor={ButtonTextColorsEnum.White}
            />
          )}
          {canGiveAccess && (
            <Button
              type={ButtonTypesEnum.Button}
              icon={PlusIcon}
              onClick={() => setIsOpenAccessModal(true)}
              title={t('patients.patientsTable.giveAccessButton')}
              backgroundColor={ButtonBackgroundColorsEnum.Brand}
              textColor={ButtonTextColorsEnum.White}
            />
          )}
        </div>
      </div>
      {isSearchable && (
        <div>
          <Formik
            initialValues={{
              search: '',
            }}
            onSubmit={({ search }) => {
              setSearch('')
              setSearch(search)
            }}
          >
            {() => (
              <FormWrapper>
                <CustomSearchInput
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
                  className="col-start-1 col-end-13 md:col-end-6"
                />
              </FormWrapper>
            )}
          </Formik>
        </div>
      )}
      <Table
        columns={patientsColumns}
        data={data}
        loading={loading && patientsFirstLoad}
        pagination
        fetch={loadPatients}
        limit={limit}
        emptyState={false}
        actions={actions}
        search={search}
      />
      {canAdd && (
        <CustomInputModal
          title={t('patients.patientsTable.addPatientModal')}
          rows={addPatientModalRows}
          isCustomModalOpen={isOpenAddModal}
          setIsCustomModalOpen={setIsOpenAddModal}
          onSubmit={onAddSubmitHandler}
          initialValues={addPatientModalInitialValues}
        />
      )}
      {canRegister && (
        <CustomInputModal
          title={t('patients.patientsTable.registerPatientModal')}
          rows={registerPatientRows}
          isCustomModalOpen={isOpenRegisterModal}
          setIsCustomModalOpen={setIsOpenRegisterModal}
          onSubmit={onRegisterSubmitHandler}
          initialValues={registerPatientInitialValues}
          validationSchema={registerPatientValidationSchema}
        />
      )}
      {canGiveAccess && (
        <CustomInputModal
          title={t('patients.patientsTable.giveAccessModal')}
          rows={giveAccessRows}
          isCustomModalOpen={isOpenAccessModal}
          setIsCustomModalOpen={setIsOpenAccessModal}
          onSubmit={onGiveAccessSubmitHandler}
          initialValues={giveAccessInitialValues}
          validationSchema={giveAccessValidationSchema}
        />
      )}
      <ConfirmModal
        isConfirmOpen={isConfirmOpen}
        setIsConfirmOpen={setIsConfirmOpen}
        onConfirm={() => adminRemoveDoctorPatientsAccessHandler(patientId)}
      />
    </>
  )
}

export default PatientsTable
