import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { CustomMultiselectInterface } from '../../../../interfaces'

const brand = '#009229'

const selectStyles = {
  control: (styles: any) => ({ ...styles, backgroundColor: 'white' }),
  option: (styles: { [x: string]: any }, { isFocused }: any) => {
    return {
      ...styles,
      backgroundColor: isFocused ? brand : 'white',
      color: isFocused ? 'white' : 'black',
    }
  },
  multiValue: (styles: any) => {
    return {
      ...styles,
    }
  },
  multiValueRemove: (styles: any, { data }: any) => ({
    ...styles,
    color: data.color,
    ':hover': {
      backgroundColor: data.color,
      color: brand,
    },
  }),
}

export const CustomMultiselect = (props: CustomMultiselectInterface) => {
  const { name, onChange, options, className, label, errors, touched, defaultValue, placeholder } = props
  const { t } = useTranslation(['inputs'])

  return (
    <div className={`${className ? className : ''}`}>
      {label && (
        <label htmlFor="allergies" className="block text-sm font-medium text-gray-700">
          {label}
        </label>
      )}
      <div className="mt-1 rounded-md shadow-sm">
        <Select
          isMulti
          // isSearchable={false}
          closeMenuOnSelect={false}
          styles={selectStyles}
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: brand,
            },
          })}
          noOptionsMessage={() => t('inputs.searchSelect.noOptions')}
          value={defaultValue}
          onChange={onChange}
          options={options}
          placeholder={placeholder ? placeholder : t('inputs.multiSelect.placeholder')}
          getOptionLabel={option => `${option.name}`}
          className={`${
            errors[name] && touched[name] ? 'border-red-600 focus:ring-red-600 focus:border-red-600' : ''
          } custom-search-select`}
          classNamePrefix="custom-search-select"
        />
      </div>
      {errors[name] && touched[name] && <p className="mb-0 h-5 text-sm text-red-600">{errors[name]}</p>}
    </div>
  )
}

export default CustomMultiselect
