import { ChatIcon, CheckIcon, PhoneOutgoingIcon } from '@heroicons/react/outline'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { PARAMS, ROLES } from '../../../enums'
import {
  ButtonBackgroundColorsEnum,
  ButtonInterface,
  ButtonTextColorsEnum,
  ButtonTypesEnum,
  ContentLayoutInterface,
  DispatchActionInterface,
  DispatchActionReducerRocketChatInterface,
} from '../../../interfaces'
import { ROUTES } from '../../../router/routes'
import { RootState, useAppDispatch } from '../../../store'
import { createDirectMessage } from '../../../store/modules/chat/actions'
import { getPatient, verifyPatient } from '../../../store/modules/patients/actions'
import { callHandler, checkRole, replaceRouteParams } from '../../../store/utils'
import Breadcrumbs from '../breadcrumbs/Breadcrumbs'
import Button from '../button/Button'
import ConfirmModal from '../modals/ConfirmModal'

export const ContentLayout = (props: ContentLayoutInterface) => {
  const { authUser } = useSelector((state: RootState) => state.auth)
  const { patient } = useSelector((state: RootState) => state.patients)
  const { buttons, title, showRoleButtons = true } = props
  const [roleButtons, setRoleButtons] = useState<ButtonInterface[]>([])
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  const { t } = useTranslation(['patients'])
  const dispatch = useAppDispatch()
  const history = useHistory()

  useEffect(() => {
    if (showRoleButtons) buttonsByRole()
  }, [authUser, patient?.rocketchat_username, patient?.is_verified, showRoleButtons])

  const createChatHandler = () => {
    dispatch(createDirectMessage({ username: patient?.rocketchat_username })).then(
      (action: DispatchActionReducerRocketChatInterface) => {
        if (action.payload?.success) {
          history.push(
            replaceRouteParams(ROUTES.CHAT_DM, [{ key: `:${PARAMS.CHAT_DM_ID}`, value: action.payload.room.rid }])
          )
        }
      }
    )
  }

  const createCallHandler = () => {
    callHandler(patient?.rocketchat_username)
  }

  const verifyHandler = () => {
    dispatch(verifyPatient({ [PARAMS.PATIENT_ID]: patient?._id })).then((action: DispatchActionInterface) => {
      if (action.payload?.status === 'ok') {
        setIsConfirmOpen(false)
        dispatch(getPatient({ [PARAMS.PATIENT_ID]: patient?._id }))
      }
    })
  }

  const buttonsByRole = () => {
    if (checkRole(authUser, [ROLES.DOCTOR, ROLES.ADMIN])) {
      const tmp = []
      if (!patient?.is_verified) {
        tmp.push({
          title: t('patients.verifyButton'),
          onClick: () => setIsConfirmOpen(true),
          icon: CheckIcon,
          type: ButtonTypesEnum.Button,
          backgroundColor: ButtonBackgroundColorsEnum.Brand,
          textColor: ButtonTextColorsEnum.White,
        })
      }

      tmp.push(
        {
          title: t('patients.chatButton'),
          onClick: createChatHandler,
          icon: ChatIcon,
          type: ButtonTypesEnum.Button,
          backgroundColor: ButtonBackgroundColorsEnum.White,
          textColor: ButtonTextColorsEnum.Gray,
        },
        {
          title: t('patients.callButton'),
          icon: PhoneOutgoingIcon,
          type: ButtonTypesEnum.Button,
          onClick: createCallHandler,
          backgroundColor: ButtonBackgroundColorsEnum.White,
          textColor: ButtonTextColorsEnum.Gray,
        }
      )

      setRoleButtons(tmp)
    }
  }

  const showBar = () => {
    return buttons || title || showRoleButtons
  }

  return (
    <>
      <div className="overflow-y-auto py-10 px-5 sm:px-20 space-y-5 w-full h-full">
        <Breadcrumbs />
        {showBar() && (
          <div className="flex flex-wrap gap-y-4 justify-between">
            <div className="flex flex-col md:flex-row flex-grow-0 pr-10 space-y-4 md:space-y-0 md:space-x-6">
              <h2 className="hidden md:block text-3xl font-bold">{title}</h2>
              <div className="flex flex-col justify-center">
                <div>
                  {showRoleButtons &&
                    (patient?.is_verified ? (
                      <span className="inline-flex items-center py-0.5 px-3 text-sm font-medium text-green-800 bg-green-100 rounded-full">
                        {t(`patients.verified.${patient?.is_verified}`)}
                      </span>
                    ) : (
                      <span className="inline-flex items-center py-0.5 px-3 text-sm font-medium text-red-800 bg-red-100 rounded-full">
                        {t(`patients.verified.${patient?.is_verified}`)}
                      </span>
                    ))}
                </div>
              </div>
              <h2 className="md:hidden text-3xl font-bold">{title}</h2>
            </div>

            <div className="flex md:flex-shrink-0 items-end">
              <div className="flex space-x-3">
                {buttons && buttons.map((button, key) => <Button key={key} {...button} />)}
                {roleButtons && roleButtons.map((button, key) => <Button key={key} {...button} />)}
              </div>
            </div>
          </div>
        )}

        {props.children}
      </div>
      <ConfirmModal
        isConfirmOpen={isConfirmOpen}
        setIsConfirmOpen={setIsConfirmOpen}
        onConfirm={verifyHandler}
        customIcon={{
          icon: CheckIcon,
          backgroundColor: 'bg-green-100',
          textColor: 'text-green-600',
        }}
        customTitle={t('patients.verifyConfirmTitle')}
        customText={t('patients.verifyConfirmText')}
        customButton={{
          title: t('patients.verifyConfirmButton'),
          backgroundColor: ButtonBackgroundColorsEnum.Brand,
          textColor: ButtonTextColorsEnum.White,
        }}
      />
    </>
  )
}

export default ContentLayout
