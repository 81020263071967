import { LazyExoticComponent, SVGProps } from 'react'

export interface RouterInterface {
  path: string
  title: string
  component?: LazyExoticComponent<() => JSX.Element> | (() => JSX.Element)
  exact?: boolean
  private?: boolean
  role?: string[]
  breadcrumb?: RouterBreadcrumbInterface
  tab?: RouterTabInterface
  navbar?: RouterNavbarInterface
  routes?: RouterInterface[]
}

export interface RouterBreadcrumbInterface {
  name: string
  isLink?: boolean
  show?: boolean
  redirect?: string | { [field: string]: string }
  role?: string[]
}

export interface RouterNavbarInterface {
  name: string
  icon: (props: SVGProps<SVGSVGElement>) => JSX.Element
  role?: string[]
  position?: NavbarPositionEnum
  redirect?: string | { [field: string]: string }
  badge?: string
}

export interface RouterTabInterface {
  name: string
  role?: string[]
  redirect?: string | { [field: string]: string }
}

export enum NavbarPositionEnum {
  Top,
  Bottom,
}

export interface RouterUtilsInterface extends RouterInterface {
  navbar?: NavbarInterface
  breadcrumb?: BreadcrumbsInterface
  tab?: TabInterface
}

export interface NavbarInterface extends RouterNavbarInterface {
  redirect?: string
}

export interface BreadcrumbsInterface extends RouterBreadcrumbInterface {
  redirect?: string
}

export interface TabInterface extends RouterTabInterface {
  redirect?: string
}
