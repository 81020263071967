import { MailIcon } from '@heroicons/react/outline'
import { CheckIcon } from '@heroicons/react/solid'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { ButtonBackgroundColorsEnum, ButtonSizesEnum, ButtonTextColorsEnum, ButtonTypesEnum } from '../../../interfaces'
import { ROUTES } from '../../../router/routes'
import { RootState } from '../../../store'
import Button from '../../common/button/Button'
import FormWrapper from '../../common/form/FormWrapper'
import CustomInput from '../../common/inputs/components/CustomInput'

export const PasswordResetEmailForm = (props: { handleEmailSend: any }) => {
  const { resetPasswordEmailSend } = useSelector((state: RootState) => state.auth)
  const { t } = useTranslation(['passwordReset', 'yup'])

  const resetSchema = Yup.object({
    email: Yup.string().email().required(t('yup:yup.required')),
  })

  return (
    <>
      <div>
        <h2 className="mt-6 text-3xl font-extrabold text-gray-900">{t('passwordReset.emailForm.title')}</h2>
      </div>
      <div className="mt-8">
        <div className="mt-16">
          {!resetPasswordEmailSend ? (
            <Formik
              initialValues={{
                email: '',
              }}
              validationSchema={resetSchema}
              onSubmit={props.handleEmailSend}
            >
              {({ isSubmitting, errors, touched }) => (
                <FormWrapper className="space-y-4">
                  <CustomInput
                    type="email"
                    label={t('passwordReset.emailForm.email')}
                    name="email"
                    errors={errors}
                    touched={touched}
                    icon={MailIcon}
                    inputClassName="pl-10"
                    className="col-span-12"
                  />

                  <Button
                    type={ButtonTypesEnum.Submit}
                    className="col-span-12"
                    title={t('passwordReset.emailForm.submitButton')}
                    backgroundColor={ButtonBackgroundColorsEnum.Brand}
                    textColor={ButtonTextColorsEnum.White}
                    size={ButtonSizesEnum.medium}
                    disabled={isSubmitting}
                  />

                  <Button
                    type={ButtonTypesEnum.Link}
                    className="col-span-12"
                    title={t('passwordReset.emailForm.cancelButton')}
                    backgroundColor={ButtonBackgroundColorsEnum.White}
                    textColor={ButtonTextColorsEnum.Gray}
                    size={ButtonSizesEnum.medium}
                    path={ROUTES.LOGIN}
                  />
                </FormWrapper>
              )}
            </Formik>
          ) : (
            <div>
              <div className="flex justify-center items-center mx-auto w-12 h-12 bg-green-50 rounded-full">
                <CheckIcon className="w-6 h-6 text-brand" aria-hidden="true" />
              </div>
              <div className="mt-3 sm:mt-5 text-center">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  {t('passwordReset.emailForm.successTitle')}
                </h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">{t('passwordReset.emailForm.successSubtitle')}</p>
                </div>
              </div>
              <div className="mt-16">
                <Link
                  to={ROUTES.LOGIN}
                  className="flex justify-center py-3 px-12 w-full text-sm font-medium text-white bg-brand hover:bg-brand-dark rounded-md border border-transparent focus:ring-2 focus:ring-brand-light focus:ring-offset-2 shadow-sm focus:outline-none"
                >
                  {t('passwordReset.emailForm.loginLink')}
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default PasswordResetEmailForm
