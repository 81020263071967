import { createSlice } from '@reduxjs/toolkit'
import { DispatchActionInterface, TelerehabilitationState } from '../../../interfaces'
import {
  getChart,
  getCharts,
  getExercise,
  getExercises,
  getExercisesDay,
  getExerciseUploadToken,
  getPreset,
  getPresets,
} from './actions'

import { TELEREHABILITATION } from './types'

const initialState: TelerehabilitationState = {
  exercises: null,
  getExercisesLoading: false,

  exercisesDay: null,
  getExercisesDayLoading: false,

  exercisesDayRows: [],

  exercise: null,
  getExerciseLoading: false,

  presets: null,
  getPresetsLoading: false,

  preset: null,
  getPresetLoading: false,

  charts: null,
  getChartsLoading: false,

  chart: null,
  getChartLoading: false,

  uploadToken: null,
  getUploadTokenLoading: false,
}

const telerehabilitationSlice = createSlice({
  name: TELEREHABILITATION.NAMESPACE,
  initialState,
  // Only frontend Logic
  reducers: {},
  extraReducers: builder => {
    // Reducers with calling external API
    builder
      .addCase(getExercises.pending, (state: TelerehabilitationState) => {
        state.getExercisesLoading = true
      })
      .addCase(getExercises.fulfilled, (state: TelerehabilitationState, action: DispatchActionInterface) => {
        if (action.payload?.status === 'ok') {
          state.exercises = action.payload.data
        } else {
          state.exercises = null
        }
        state.getExercisesLoading = false
      })
      .addCase(getExercises.rejected, (state: TelerehabilitationState) => {
        state.exercises = null
        state.getExercisesLoading = false
      })

      .addCase(getExercisesDay.pending, (state: TelerehabilitationState) => {
        state.getExercisesDayLoading = true
      })
      .addCase(getExercisesDay.fulfilled, (state: TelerehabilitationState, action: DispatchActionInterface) => {
        if (action.payload?.status === 'ok') {
          state.exercisesDay = action.payload.data
        } else {
          state.exercisesDay = null
        }
        state.getExercisesDayLoading = false
      })
      .addCase(getExercisesDay.rejected, (state: TelerehabilitationState) => {
        state.exercisesDay = null
        state.getExercisesDayLoading = false
      })

      .addCase(getExercise.pending, (state: TelerehabilitationState) => {
        state.getExerciseLoading = true
      })
      .addCase(getExercise.fulfilled, (state: TelerehabilitationState, action: DispatchActionInterface) => {
        if (action.payload?.status === 'ok') {
          state.exercise = action.payload.data
        } else {
          state.exercise = null
        }
        state.getExerciseLoading = false
      })
      .addCase(getExercise.rejected, (state: TelerehabilitationState) => {
        state.exercise = null
        state.getExerciseLoading = false
      })

      .addCase(getPresets.pending, (state: TelerehabilitationState) => {
        state.getPresetsLoading = true
      })
      .addCase(getPresets.fulfilled, (state: TelerehabilitationState, action: DispatchActionInterface) => {
        if (action.payload?.status === 'ok') {
          state.presets = action.payload.data
        } else {
          state.presets = null
        }
        state.getPresetsLoading = false
      })
      .addCase(getPresets.rejected, (state: TelerehabilitationState) => {
        state.presets = null
        state.getPresetsLoading = false
      })

      .addCase(getPreset.pending, (state: TelerehabilitationState) => {
        state.getPresetLoading = true
      })
      .addCase(getPreset.fulfilled, (state: TelerehabilitationState, action: DispatchActionInterface) => {
        if (action.payload?.status === 'ok') {
          state.preset = action.payload.data
        } else {
          state.preset = null
        }
        state.getPresetLoading = false
      })
      .addCase(getPreset.rejected, (state: TelerehabilitationState) => {
        state.preset = null
        state.getPresetLoading = false
      })

      .addCase(getCharts.pending, (state: TelerehabilitationState) => {
        state.getChartsLoading = true
      })
      .addCase(getCharts.fulfilled, (state: TelerehabilitationState, action: DispatchActionInterface) => {
        if (action.payload?.status === 'ok') {
          state.charts = action.payload.data
        } else {
          state.charts = null
        }
        state.getChartsLoading = false
      })
      .addCase(getCharts.rejected, (state: TelerehabilitationState) => {
        state.charts = null
        state.getChartsLoading = false
      })

      .addCase(getChart.pending, (state: TelerehabilitationState) => {
        state.getChartLoading = true
      })
      .addCase(getChart.fulfilled, (state: TelerehabilitationState, action: DispatchActionInterface) => {
        if (action.payload?.status === 'ok') {
          state.chart = action.payload.data
        } else {
          state.chart = null
        }
        state.getChartLoading = false
      })
      .addCase(getChart.rejected, (state: TelerehabilitationState) => {
        state.chart = null
        state.getChartLoading = false
      })

      .addCase(getExerciseUploadToken.pending, (state: TelerehabilitationState) => {
        state.getUploadTokenLoading = true
      })
      .addCase(getExerciseUploadToken.fulfilled, (state: TelerehabilitationState, action: DispatchActionInterface) => {
        if (action.payload?.status === 'ok') {
          state.uploadToken = action.payload.data.upload_token
        } else {
          state.uploadToken = null
        }
        state.getUploadTokenLoading = false
      })
      .addCase(getExerciseUploadToken.rejected, (state: TelerehabilitationState) => {
        state.uploadToken = null
        state.getUploadTokenLoading = false
      })
  },
})

// Extract the action creators object and the reducer
const { reducer } = telerehabilitationSlice
// Extract and export each action creator by name
// export const { } = actions
// Export the reducer, either as a default or named export
export default reducer
