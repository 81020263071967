import { createAsyncThunk } from '@reduxjs/toolkit'
import { API } from '../../../api'
import { callBaseApiEndpoint } from '../../utils'

// typePrefix = '${namespace}/functionName'
export const getICDWhisper: any = createAsyncThunk('icd/getICDWhisper', async (payload, { dispatch }) => {
  return await callBaseApiEndpoint(dispatch, {
    requestData: { ...API.icd.getICDWhisper },
    params: payload,
    throwError: true,
    showErrors: false,
    showSuccess: false,
  }).then((res: any) => res)
})

export const getICDTitle: any = createAsyncThunk('icd/getICDTitle', async (payload, { dispatch }) => {
  return await callBaseApiEndpoint(dispatch, {
    requestData: { ...API.icd.getICDTitle },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const getMedicationWhisper: any = createAsyncThunk('icd/getMedicationWhisper', async (payload, { dispatch }) => {
  return await callBaseApiEndpoint(dispatch, {
    requestData: { ...API.icd.getMedicationWhisper },
    params: payload,
    throwError: true,
    showErrors: false,
    showSuccess: false,
  }).then((res: any) => res)
})

export const getMedicationTitle: any = createAsyncThunk('icd/getMedicationTitle', async (payload, { dispatch }) => {
  return await callBaseApiEndpoint(dispatch, {
    requestData: { ...API.icd.getMedicationTitle },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})
