import { createAsyncThunk } from '@reduxjs/toolkit'
import { API } from '../../../api'
import { callTelerehabilitationApiEndpoint } from '../../utils'

// typePrefix = '${namespace}/functionName'
export const getExercises: any = createAsyncThunk('telerehabilitation/getExercises', async (payload, { dispatch }) => {
  return await callTelerehabilitationApiEndpoint(dispatch, {
    requestData: { ...API.telerehabilitation.getExercises },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const getExercisesDay: any = createAsyncThunk(
  'telerehabilitation/getExercisesDay',
  async (payload, { dispatch }) => {
    return await callTelerehabilitationApiEndpoint(dispatch, {
      requestData: { ...API.telerehabilitation.getExercisesDay },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const deleteExercisesDay: any = createAsyncThunk(
  'telerehabilitation/deleteExercisesDay',
  async (payload, { dispatch }) => {
    return await callTelerehabilitationApiEndpoint(dispatch, {
      requestData: { ...API.telerehabilitation.deleteExercisesDay },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const getExercise: any = createAsyncThunk('telerehabilitation/getExercise', async (payload, { dispatch }) => {
  return await callTelerehabilitationApiEndpoint(dispatch, {
    requestData: { ...API.telerehabilitation.getExercise },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const getExerciseUploadToken: any = createAsyncThunk(
  'telerehabilitation/getExerciseUploadToken',
  async (payload, { dispatch }) => {
    return await callTelerehabilitationApiEndpoint(dispatch, {
      requestData: { ...API.telerehabilitation.getExerciseUploadToken },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const deleteExercise: any = createAsyncThunk(
  'telerehabilitation/deleteExercise',
  async (payload, { dispatch }) => {
    return await callTelerehabilitationApiEndpoint(dispatch, {
      requestData: { ...API.telerehabilitation.deleteExercise },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const createExercise: any = createAsyncThunk(
  'telerehabilitation/createExercise',
  async (payload, { dispatch }) => {
    return await callTelerehabilitationApiEndpoint(dispatch, {
      requestData: { ...API.telerehabilitation.createExercise },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const createExerciseVideo: any = createAsyncThunk(
  'telerehabilitation/createExerciseVideo',
  async (payload, { dispatch }) => {
    return await callTelerehabilitationApiEndpoint(dispatch, {
      requestData: { ...API.telerehabilitation.createExerciseVideo },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const getPresets: any = createAsyncThunk('telerehabilitation/getPresets', async (payload, { dispatch }) => {
  return await callTelerehabilitationApiEndpoint(dispatch, {
    requestData: { ...API.telerehabilitation.getPresets },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const getPreset: any = createAsyncThunk('telerehabilitation/getPreset', async (payload, { dispatch }) => {
  return await callTelerehabilitationApiEndpoint(dispatch, {
    requestData: { ...API.telerehabilitation.getPreset },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const createPreset: any = createAsyncThunk('telerehabilitation/createPreset', async (payload, { dispatch }) => {
  return await callTelerehabilitationApiEndpoint(dispatch, {
    requestData: { ...API.telerehabilitation.createPreset },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const editPreset: any = createAsyncThunk('telerehabilitation/editPreset', async (payload, { dispatch }) => {
  return await callTelerehabilitationApiEndpoint(dispatch, {
    requestData: { ...API.telerehabilitation.editPreset },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const deletePreset: any = createAsyncThunk('telerehabilitation/deletePreset', async (payload, { dispatch }) => {
  return await callTelerehabilitationApiEndpoint(dispatch, {
    requestData: { ...API.telerehabilitation.deletePreset },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const getCharts: any = createAsyncThunk('telerehabilitation/getCharts', async (payload, { dispatch }) => {
  return await callTelerehabilitationApiEndpoint(dispatch, {
    requestData: { ...API.telerehabilitation.getCharts },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const getChart: any = createAsyncThunk('telerehabilitation/getChart', async (payload, { dispatch }) => {
  return await callTelerehabilitationApiEndpoint(dispatch, {
    requestData: { ...API.telerehabilitation.getChart },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const createChart: any = createAsyncThunk('telerehabilitation/createChart', async (payload, { dispatch }) => {
  return await callTelerehabilitationApiEndpoint(dispatch, {
    requestData: { ...API.telerehabilitation.createChart },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const editChart: any = createAsyncThunk('telerehabilitation/editChart', async (payload, { dispatch }) => {
  return await callTelerehabilitationApiEndpoint(dispatch, {
    requestData: { ...API.telerehabilitation.editChart },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const deleteChart: any = createAsyncThunk('telerehabilitation/deleteChart', async (payload, { dispatch }) => {
  return await callTelerehabilitationApiEndpoint(dispatch, {
    requestData: { ...API.telerehabilitation.deleteChart },
    params: payload,
    throwError: true,
    showSuccess: false,
  }).then((res: any) => res)
})

export const copyExercisePresets: any = createAsyncThunk(
  'telerehabilitation/copyExercisePresets',
  async (payload, { dispatch }) => {
    return await callTelerehabilitationApiEndpoint(dispatch, {
      requestData: { ...API.telerehabilitation.copyExercisePresets },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const checkPatientExercises: any = createAsyncThunk(
  'telerehabilitation/checkPatientExercises',
  async (payload, { dispatch }) => {
    return await callTelerehabilitationApiEndpoint(dispatch, {
      requestData: { ...API.telerehabilitation.checkPatientExercises },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)
