import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'
import { Formik } from 'formik'
import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useParams } from 'react-router-dom'
import { PARAMS } from '../../../enums'
import {
  BaseParamsInterface,
  CustomSelectOptionsInterface,
  FormikOnSubmitType,
  RouterInterface,
  TabsInterface,
} from '../../../interfaces'
import { classNames, getRedirectByRole, isCurrentRoute, replaceRouteParams } from '../../../store/utils'
import FormWrapper from '../form/FormWrapper'
import CustomSelect from '../inputs/components/CustomSelect'

export const Tabs = (props: TabsInterface) => {
  const { className, tabs } = props
  const urlParams: BaseParamsInterface = useParams()
  const params = [{ key: `:${PARAMS.PATIENT_ID}`, value: urlParams[PARAMS.PATIENT_ID] }]
  const { t } = useTranslation(['tabs'])
  const [tabsOptions, setTabsOptions] = useState<CustomSelectOptionsInterface[]>([])
  const history = useHistory()

  useEffect(() => {
    const tmp: CustomSelectOptionsInterface[] = []
    tabs.map(route => {
      if (route?.routes) {
        route.routes.map(subroute => {
          tmp.push({
            name: t(`${subroute.tab?.name || ''}`),
            value: subroute.tab?.redirect ? getRedirectByRole(subroute.tab?.redirect) : subroute.path,
          })
        })
      } else {
        tmp.push({
          name: t(`${route.tab?.name || ''}`),
          value: route.tab?.redirect ? route.tab?.redirect : route.path,
        })
      }
    })

    setTabsOptions(tmp)
  }, [tabs])

  const initialValues = {
    tab: tabsOptions.find(tab => isCurrentRoute(String(tab.value), params)),
  }

  const submitHandler: FormikOnSubmitType = (values, setSubmitting) => {
    setSubmitting(false)
    history.push(replaceRouteParams(values.tab.value, params))
  }

  return (
    <div className={`${className ? className : ''}`}>
      <div className="md:hidden">
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting }) => submitHandler(values, setSubmitting)}
        >
          {({ errors, touched, submitForm, values, setFieldValue }) => (
            <FormWrapper>
              <CustomSelect
                options={tabsOptions}
                value={values.tab}
                onChange={e => {
                  setFieldValue('tab', e)
                  submitForm()
                }}
                name={'tab'}
                errors={errors}
                touched={touched}
                className="col-span-12"
              />
            </FormWrapper>
          )}
        </Formik>
      </div>
      <nav className="hidden md:flex space-x-4" aria-label="Tabs">
        {tabs.map((tab, key: number) =>
          tab.routes ? (
            <Menu key={key} as="div" className="inline-block relative text-left">
              {({ open }) => (
                <>
                  <div>
                    <Menu.Button
                      className={`${
                        tab.routes?.find((route: RouterInterface) => isCurrentRoute(route.path, params))
                          ? 'text-white bg-brand hover:bg-brand-dark'
                          : 'text-gray-500 bg-whit hover:text-gray-700'
                      } border-transparent inline-flex justify-center w-full rounded-md border px-4 py-2 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-brand`}
                    >
                      {tab.tab?.name && t(tab.tab.name)}
                      <ChevronDownIcon className="-mr-1 ml-2 w-5 h-5" aria-hidden="true" />
                    </Menu.Button>
                  </div>

                  <Transition
                    show={open}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      static
                      className="absolute right-0 z-30 mt-2 w-56 bg-white rounded-md ring-1 ring-black ring-opacity-5 shadow-lg origin-top-right focus:outline-none"
                    >
                      <div className="py-1">
                        {tab.routes?.map((route, key: number) => (
                          <Menu.Item key={key}>
                            {({ active }) => (
                              <Link
                                to={replaceRouteParams(route.path, params)}
                                className={classNames(
                                  isCurrentRoute(route.path, params)
                                    ? 'bg-brand text-white'
                                    : active
                                    ? 'bg-gray-100 text-gray-900'
                                    : 'text-gray-700',
                                  'block px-4 py-2 text-sm'
                                )}
                              >
                                {route.tab?.name && t(route.tab.name)}
                              </Link>
                            )}
                          </Menu.Item>
                        ))}
                      </div>
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
          ) : (
            <Link
              key={key}
              to={
                tab.tab?.redirect ? replaceRouteParams(tab.tab.redirect, params) : replaceRouteParams(tab.path, params)
              }
              className={classNames(
                isCurrentRoute(tab.path, params) ? 'bg-brand text-white' : 'text-gray-500 hover:text-gray-700',
                'px-3 py-2 font-medium text-sm rounded-md focus:ring-2 focus:ring-brand'
              )}
              aria-current={isCurrentRoute(tab.path, params) && 'page'}
            >
              {tab.tab?.name && t(tab.tab.name)}
            </Link>
          )
        )}
      </nav>
    </div>
  )
}

export default Tabs
