import auth from './modules/auth'
import calendar from './modules/calendar'
import chat from './modules/chat'
import doctors from './modules/doctors'
import edg from './modules/edg'
import files from './modules/files'
import icd from './modules/icd'
import medications from './modules/medications'
import notification from './modules/notification'
import orders from './modules/orders'
import patients from './modules/patients'
import telerehabilitation from './modules/telerehabilitation'

export const API = {
  auth,
  patients,
  calendar,
  chat,
  orders,
  telerehabilitation,
  notification,
  files,
  edg,
  icd,
  doctors,
  medications,
}
