export const TABLE_LIMITS = {
  NOTIFICATIONS: 5,
  DASHBOARD_PATIENTS_LIST: 5,
  DASHBOARD_DOCTORS_LIST: 5,
  PATIENTS_LIST: 10,

  DASHBOARD_EVENTS: 5,
  PATIENTS_DETAIL_OVERVIEW_EVENTS: 5,

  PATIENTS_DETAIL_ANAMNESIS_PERSONAL: 10,
  PATIENTS_DETAIL_ANAMNESIS_MEDICATIONS: 10,
  PATIENTS_DETAIL_ANAMNESIS_GYNAECOLOGY_MENSTRUATIONS: 4,
  PATIENTS_DETAIL_ANAMNESIS_GYNAECOLOGY_PREGNANCIES: 4,
  PATIENTS_DETAIL_ANAMNESIS_SURGERIES: 10,
  PATIENTS_DETAIL_ANAMNESIS_EPIDEMIOLOGY: 10,
  PATIENTS_DETAIL_ANAMNESIS_FAMILY_HISTORY: 10,
  PATIENTS_DETAIL_CHECKUPS: 10,
  PATIENTS_DETAIL_MEASUREMENTS_GLYCEMIA: 10,
  PATIENTS_DETAIL_MEASUREMENTS_OXYGEN_SATURATION: 10,
  PATIENTS_DETAIL_MEASUREMENTS_ECG: 10,
  PATIENTS_DETAIL_MEASUREMENTS_BPM: 10,
  PATIENTS_DETAIL_MEASUREMENTS_SLEEP: 10,
  PATIENTS_DETAIL_MEASUREMENTS_AIR_POLUTION: 10,
  PATIENTS_DETAIL_MEASUREMENTS_ENDOSCOPY: 10,
  PATIENTS_DETAIL_MEASUREMENTS_TEMPERATURE: 10,
  PATIENTS_DETAIL_MEASUREMENTS_WEIGHT: 10,
  PATIENTS_DETAIL_MEASUREMENTS_HEIGHT: 10,
  PATIENTS_DETAIL_MEASUREMENTS_CHOLESTEROL: 10,
  PATIENTS_DETAIL_MEASUREMENTS_PRESSURE: 10,
  PATIENTS_DETAIL_STORAGE_LABORATORY: 10,
  PATIENTS_DETAIL_STORAGE_RADIOLOGY: 10,
  PATIENTS_DETAIL_STORAGE_ATTACHMENTS: 10,

  DOCTORS_LIST: 10,

  TELEREHABILITATION_PRESETS: 10,
  TELEREHABILITATION_PATIENT: 10,
  TELEREHABILITATION_CHARTS: 10,

  ORDERS_PATIENT_HISTORY: 10,
  ORDERS_ADMINISTRATION_SERVICE_LIST: 10,
  ORDERS_ADMINISTRATION_CATEGORY_LIST: 10,
  ORDERS_ADMINISTRATION_CATEGORY_ACCESSORY_LIST: 10,

  CUSTOM_MEDICATIONS: 10,
}
