import { ErrorBoundary } from '@sentry/react'
import React, { Suspense } from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import ReactTooltip from 'react-tooltip'
import PageLoader from './components/common/page/PageLoader'
import RootRouter from './RootRouter'
import ErrorBoundaryFallback from './views/error/ErrorBoundaryFallback'

function App() {
  return (
    <Suspense fallback={<PageLoader />}>
      <ErrorBoundary fallback={({ error }) => <ErrorBoundaryFallback error={error} />}>
        <BrowserRouter>
          <ReactTooltip />
          <Route path="/" component={RootRouter} />
        </BrowserRouter>

        <ToastContainer />
      </ErrorBoundary>
    </Suspense>
  )
}

export default App
