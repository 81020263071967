import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import * as Yup from 'yup'
import { PARAMS, ROLES } from '../../../enums'
import {
  BaseParamsInterface,
  ButtonBackgroundColorsEnum,
  ButtonTextColorsEnum,
  ButtonTypesEnum,
  DispatchActionInterface,
  FormikOnSubmitType,
  InputByTypeRow,
  InputTypeEnum,
  TextByTypeColumnsInterface,
} from '../../../interfaces'
import { RootState, useAppDispatch } from '../../../store'
import {
  createEvent,
  deleteEvent,
  editEvent,
  getPatientsAttendees,
  getDoctorsAttendees,
  getEvent,
  getEvents,
  rsvpEvent,
} from '../../../store/modules/calendar/actions'
import { checkRole, createArrayOfObjectsFromArrayOfObjects, getValueFromObject } from '../../../store/utils'
import DescriptionListForm from '../../common/description-list/components/DescriptionListForm'
import DescriptionList from '../../common/description-list/DescriptionList'
import TableModal from '../../common/table/components/TableModal'
import Table from '../../common/table/Table'

interface EventsTableInterface {
  limit: number
}

export const EventsTable = (props: EventsTableInterface) => {
  const { limit } = props
  const { authUser } = useSelector((state: RootState) => state.auth)
  const { events, event, eventAttendees } = useSelector((state: RootState) => state.calendar)
  const [isOpen, setIsOpen] = useState(false)
  const [isCreate, setIsCreate] = useState(false)
  const [rows, setRows] = useState<InputByTypeRow[]>([])
  const { t } = useTranslation(['patients', 'yup', 'doctors'])
  const dispatch = useAppDispatch()
  const params: BaseParamsInterface = useParams()

  useEffect(() => {
    loadAttendees()
  }, [authUser])

  useEffect(() => {
    getRows()
  }, [authUser, eventAttendees, event])

  const loadAttendees = () => {
    if (checkRole(authUser, ROLES.PATIENT)) {
      dispatch(getPatientsAttendees({ [PARAMS.PATIENT_ID]: params[PARAMS.PATIENT_ID] }))
    }
  }

  const loadData = (limit: number, offset: number) => {
    dispatch(getEvents({ limit, offset }))
  }

  const submitHandler: FormikOnSubmitType = (values, setSubmitting) => {
    const send = { ...values }
    send[PARAMS.EVENT_ID] = send._id
    send.attendees = getValueFromObject(values.attendees)

    if (send._id) {
      dispatch(editEvent(send)).then((action: DispatchActionInterface) => {
        setSubmitting(false)
        if (action.payload?.status === 'ok') {
          setIsOpen(false)
          loadData(limit, 0)
        }
      })
    } else {
      dispatch(createEvent(send)).then((action: DispatchActionInterface) => {
        setSubmitting(false)
        if (action.payload?.status === 'ok') {
          setIsOpen(false)
          loadData(limit, 0)
        }
      })
    }
  }

  const deleteHandler = (_id: string) => {
    dispatch(deleteEvent({ [PARAMS.EVENT_ID]: _id })).then((action: DispatchActionInterface) => {
      if (action.payload?.status === 'ok') {
        setIsOpen(false)
        loadData(limit, 0)
      }
    })
  }

  const detailHandler = (_id: string) => {
    dispatch(getEvent({ [PARAMS.EVENT_ID]: _id })).then((action: DispatchActionInterface) => {
      if (action.payload?.status === 'ok') {
        setIsCreate(false)
        setIsOpen(true)
      }
      setTimeout(() => {
        ReactTooltip.rebuild()
      }, 400)
    })
  }

  const createEventHandler = () => {
    setIsOpen(true)
    setIsCreate(true)
  }

  const cancelAttendanceHandler = (_id: string) => {
    dispatch(rsvpEvent({ [PARAMS.EVENT_ID]: _id, rsvp: 'no' })).then(() => {
      setIsOpen(false)
      loadData(limit, 0)
    })
  }

  const getRows = () => {
    const tmp: InputByTypeRow[] = []

    tmp.push(
      {
        list_label: t('patients.events.title'),
        name: 'title',
        type: InputTypeEnum.Text,
        value: event?.title,
      },
      {
        list_label: t('patients.events.description'),
        name: 'description',
        type: InputTypeEnum.Textarea,
        value: event?.description,
      },
      {
        list_label: t('patients.events.organizer'),
        name: 'organizer',
        type: InputTypeEnum.ReadOnly,
        value: getValueFromObject(event?.organizer, 'full_name'),
      }
    )

    if (checkRole(authUser, [ROLES.PATIENT])) {
      tmp.push({
        list_label: t('patients.events.attendees'),
        name: 'attendees',
        type: InputTypeEnum.PatientsAttendees,
        value: createArrayOfObjectsFromArrayOfObjects(event?.attendees, [
          { name: 'value', value: 'full_name' },
          { name: 'badge', value: 'rsvp' },
        ]),
        options: attendees,
        t,
        namespace: 'patients.events.rsvp.',
      })
    } else {
      tmp.push({
        list_label: t('patients.events.attendees'),
        name: 'attendees',
        type: InputTypeEnum.DoctorsAttendees,
        searchSelectDispatch: getDoctorsAttendees,
        searchSelectStructure: { name: 'name', value: '_id' },
        searchSelectStructureMulti: true,
        value: createArrayOfObjectsFromArrayOfObjects(event?.attendees, [
          { name: 'value', value: 'full_name' },
          { name: 'badge', value: 'rsvp' },
        ]),
        t,
        namespace: 'patients.events.rsvp.',
        placeholder: checkRole(authUser, ROLES.DOCTOR) ? t('doctors:doctors.events.attendees.placeholder') : undefined,
      })
    }

    tmp.push(
      {
        list_label: t('patients.events.from'),
        name: 'from',
        type: InputTypeEnum.DateTime,
        value: event?.from,
      },
      {
        list_label: t('patients.events.to'),
        name: 'to',
        type: InputTypeEnum.DateTime,
        value: event?.to,
      }
    )

    setRows(tmp)
  }

  const eventSchema = Yup.object({
    title: Yup.string().required(t('yup:yup.required')),
    description: Yup.string().required(t('yup:yup.required')),
    attendees: Yup.array().min(1, t('yup:yup.required')),
    from: Yup.date().required(t('yup:yup.required')),
    to: Yup.date().required(t('yup:yup.required')),
  })

  const attendees = createArrayOfObjectsFromArrayOfObjects(eventAttendees?.current, [
    { name: 'name', value: ['first_name', 'last_name'] },
    { name: 'value', value: '_id' },
  ])

  const cols: TextByTypeColumnsInterface[] = [
    {
      label: t('patients.events.title'),
      values: 'title',
    },
    {
      label: t('patients.events.from'),
      values: 'from',
      type: InputTypeEnum.DateTime,
    },
    {
      label: t('patients.events.to'),
      values: 'to',
      type: InputTypeEnum.DateTime,
    },
  ]

  const attendeesInitial = createArrayOfObjectsFromArrayOfObjects(event?.attendees, [
    { name: 'name', value: 'full_name' },
    { name: 'value', value: 'base_id' },
  ])

  const initialValues = {
    title: isCreate ? '' : event?.title,
    description: isCreate ? '' : event?.description,
    attendees: isCreate ? [] : attendeesInitial,
    from: isCreate ? '' : event?.from,
    to: isCreate ? '' : event?.to,
  }

  return (
    <>
      <Table
        columns={cols}
        data={events}
        title={t('patients.events.componentTitle')}
        onCreateClick={createEventHandler}
        pagination={true}
        fetch={loadData}
        limit={limit}
        loading={false}
        emptyState={false}
        actions={[
          {
            title: t('patients.events.detailButton'),
            type: ButtonTypesEnum.Button,
            backgroundColor: ButtonBackgroundColorsEnum.Transparent,
            textColor: ButtonTextColorsEnum.Brand,
            onClick: detailHandler,
          },
        ]}
      />
      <TableModal isOpen={isOpen} setIsOpen={setIsOpen}>
        <DescriptionList
          title={t('patients.events.componentTitle')}
          loading={false}
          isDeletable={event?.can_edit}
          isEditable={event?.can_edit}
          actions={[
            {
              title: t('patients.events.cancelAttendanceButton'),
              type: ButtonTypesEnum.Button,
              onClick: cancelAttendanceHandler,
              backgroundColor: ButtonBackgroundColorsEnum.White,
              textColor: ButtonTextColorsEnum.Gray,
              hide: event?.can_edit,
            },
          ]}
          itemID={event?._id}
          isCreate={isCreate}
          onDeleteClick={deleteHandler}
        >
          <DescriptionListForm
            rows={rows}
            initialValues={initialValues}
            onSubmitClick={submitHandler}
            itemID={event?._id}
            isCreate={isCreate}
            validationSchema={eventSchema}
          />
        </DescriptionList>
      </TableModal>
    </>
  )
}

export default EventsTable
