import { ChevronRightIcon } from '@heroicons/react/outline'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { BaseParamsInterface } from '../../../interfaces'
import ROUTER from '../../../router'
import { RootState } from '../../../store'
import { getBreadcrumbs, getValueFromStore, isCurrentRoute } from '../../../store/utils'

export const Breadcrumbs = () => {
  const { authUser } = useSelector((state: RootState) => state.auth)
  const [params, setParams] = useState<{ key: string; value?: string }[] | undefined>(undefined)
  const breadcrumbs = getBreadcrumbs(ROUTER, params)
  const { t } = useTranslation(['breadcrumbs'])
  const urlParams: BaseParamsInterface = useParams()

  useEffect(() => {
    getParams()

    return () => {
      setParams(undefined)
    }
  }, [authUser, urlParams])

  const getParams = () => {
    // @todo: Review
    // Proč jsem to dělal ?? Nechám to tu pro jistotu, kdyby se něco po.....
    // if (checkRole(authUser,ROLES.PATIENT)) {
    //   setParams([{ key: `:${PARAMS.PATIENT_ID}`, value: authUser?.role.object_id }])
    // }

    const tmp: { key: string; value?: string }[] = []
    Object.keys(urlParams).map(key =>
      tmp.push({
        key: `:${key}`,
        value: urlParams[key],
      })
    )

    setParams(tmp)
  }

  const isLink = (isLink?: boolean, path?: string) => {
    return !!(isLink && path)
  }

  const isLast = (length: number, key: number) => {
    return length === key + 1
  }

  const getValue = (value: string) => {
    return moment.utc(getValueFromStore(value)).isValid()
      ? moment.utc(getValueFromStore(value)).format('DD.MM.YYYY')
      : t(getValueFromStore(value))
  }

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex flex-wrap gap-3 items-center">
        {breadcrumbs.map(
          (breadcrumb, key: number) =>
            breadcrumb.breadcrumb && (
              <li key={key}>
                <div className="flex gap-3 items-center">
                  {isLink(breadcrumb.breadcrumb?.isLink, breadcrumb.path) ? (
                    <Link
                      to={breadcrumb.breadcrumb?.redirect ? breadcrumb.breadcrumb.redirect : breadcrumb.path}
                      className="text-sm font-medium text-gray-500 hover:text-gray-700"
                      aria-current={isCurrentRoute(breadcrumb.path, params, true) && 'page'}
                    >
                      {getValue(breadcrumb.breadcrumb.name)}
                    </Link>
                  ) : (
                    <span className="text-sm font-medium text-gray-500 hover:text-gray-700">
                      {getValue(breadcrumb.breadcrumb.name)}
                    </span>
                  )}
                  {!isLast(breadcrumbs.length, key) && (
                    <ChevronRightIcon className="flex-shrink-0 w-5 h-5 text-gray-400" aria-hidden="true" />
                  )}
                </div>
              </li>
            )
        )}
      </ol>
    </nav>
  )
}

export default Breadcrumbs
