import { createAsyncThunk } from '@reduxjs/toolkit'
import { API } from '../../../api'
import { callBaseApiEndpoint } from '../../utils'

// typePrefix = '${namespace}/functionName'
export const getCustomMedications: any = createAsyncThunk(
  'customMedications/getCustomMedications',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.medications.getCustomMedications },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const getCustomMedication: any = createAsyncThunk(
  'customMedications/getCustomMedication',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.medications.getCustomMedication },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const editCustomMedication: any = createAsyncThunk(
  'customMedications/editCustomMedication',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.medications.editCustomMedication },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const createCustomMedication: any = createAsyncThunk(
  'customMedications/createCustomMedication',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.medications.createCustomMedication },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)

export const deleteCustomMedication: any = createAsyncThunk(
  'customMedications/deleteCustomMedication',
  async (payload, { dispatch }) => {
    return await callBaseApiEndpoint(dispatch, {
      requestData: { ...API.medications.deleteCustomMedication },
      params: payload,
      throwError: true,
      showSuccess: false,
    }).then((res: any) => res)
  }
)
