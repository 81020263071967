import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { Fragment, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ButtonBackgroundColorsEnum,
  ButtonSizesEnum,
  ButtonTextColorsEnum,
  ButtonTypesEnum,
  TableModalInterface,
} from '../../../../interfaces'
import Button from '../../button/Button'

export const TableModal = (props: TableModalInterface) => {
  const { children, isOpen, setIsOpen, fileUploading } = props
  const closeButtonRef = useRef(null)
  const { t } = useTranslation(['table'])

  useEffect(() => {
    if (fileUploading) {
      window.addEventListener('beforeunload', confirmCloseTab)
    }

    return () => {
      window.removeEventListener('beforeunload', confirmCloseTab)
    }
  }, [fileUploading])

  const confirmCloseTab = (e: any) => {
    e.preventDefault()
    e.returnValue = ''
  }

  const onClose = () => {
    if (fileUploading) {
      setIsOpen(true)
      alert(t('table.modal.fileUploading'))
    } else {
      setIsOpen(false)
    }
  }

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="overflow-y-auto fixed inset-0 z-10"
        onClose={onClose}
        initialFocus={closeButtonRef}
      >
        <div className="flex sm:block justify-center items-center sm:p-0 px-4 pt-4 pb-20 min-h-screen text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block sm:p-6 px-4 pt-5 pb-4 sm:my-8 w-full max-w-lg text-left align-bottom sm:align-middle bg-white rounded-lg shadow-xl transition-all transform">
              <div className="grid grid-cols-12">
                <div className="col-span-12 text-right">
                  <Button
                    type={ButtonTypesEnum.Button}
                    backgroundColor={ButtonBackgroundColorsEnum.Transparent}
                    textColor={ButtonTextColorsEnum.Gray}
                    onClick={onClose}
                    size={ButtonSizesEnum.small}
                    icon={XIcon}
                    buttonRef={closeButtonRef}
                  />
                </div>
              </div>

              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default TableModal
