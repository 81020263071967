import axios from 'axios'
import i18n from 'i18next'
import StructuredFormErrors from '../components/common/errors/StructuredFormErrors'
import { config } from '../config'
import { LOCAL_STORAGE } from '../enums'
import { BaseParamsInterface } from '../interfaces'
import store from '../store'
import { setProgress } from '../store/modules/app/reducer'
import { logout } from '../store/modules/auth/reducer'
import { notifyError, notifySuccess, notifyWarning } from '../store/utils'

const apiClient = axios.create({
  baseURL: config.backend.apiBaseUrl,
  withCredentials: false,
  method: 'GET',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
  },
})

const createRequest = (route: any) => (data: any) => {
  return send({
    baseUrl: `${config.backend.apiBaseUrl}${route}`,
    ...data,
  })
}

let interval: any = 0,
  start = 70
const startProgressBar = () => {
  start = 70
  clearInterval(interval)
  store.dispatch(setProgress(start))

  interval = setInterval(() => {
    if (start >= 98) {
      clearInterval(interval)
    } else {
      store.dispatch(setProgress(start))
      start = start + 3
    }
  }, 500)
}

const send = (config: any) => {
  const { method, body, baseUrl } = config
  let { url } = config

  url = `${baseUrl}${url}`

  const headers = config.headers || {}

  return new Promise((resolve, reject) => {
    if (!method) {
      reject(new Error('The request must have method.'))
    }

    if (!url) {
      reject(new Error('The request must have url.'))
    }

    const bearerToken = localStorage.getItem(LOCAL_STORAGE.USER_BEARER_TOKEN)
    if (bearerToken) {
      headers['Authorization'] = `Bearer ${bearerToken}`
    }

    const roleId = localStorage.getItem(LOCAL_STORAGE.USER_ROLE_ID)
    if (roleId) {
      headers['X-Role-Id'] = roleId
    }

    resolve(apiClient.request({ ...config, url, headers: { ...headers }, body: JSON.stringify(body) }))
  })
}

const call = (
  { requestData, params = null, throwError = false, showErrors = true, showSuccess = false }: any,
  sender: any
) => {
  const { id, errorType, request, reduce } = requestData
  if (!id) {
    throw new Error('Request must have id.')
  }

  startProgressBar()

  return new Promise((resolve, reject) => {
    return sender(request(params))
      .then((res: any) => {
        if (reduce) {
          resolve(reduce(res.data))
        }

        resolve(res.data)
        if (res.data.status === 'ok' && showSuccess) {
          onSuccess(res.data.data?.message)
        }
      })
      .catch((err: any) => {
        // eslint-disable-next-line
        console.error('Response ERROR!', err)
        if (err.response) {
          // logout
          if (err.response.status && err.response.status === 401) {
            store.dispatch(logout())
            // return
          }

          if (showErrors) {
            onError(
              id,
              err.response.data?.data?.code ? err.response.data.data.code : 'UNKNOWN',
              errorType,
              err.response.data || []
            )
          }
        } else {
          // unknown error
          onError(id, 'UNKNOWN', errorType)
        }

        // stopSending(store.commit, id)
        if (throwError) {
          reject(err)
        }
      })
      .finally(() => {
        store.dispatch(setProgress(100))
        clearInterval(interval)
      })
  })
}

const onError = (
  id: string,
  errorCode: any,
  errorType: any,
  values?: {
    data: { message: string }
    fields: { [key: string]: string[] }
  }
) => {
  if (!errorType) {
    if (values) {
      if (!values.data?.message || values.data.message === '') return

      if (values?.fields) {
        notifyWarning(StructuredFormErrors(values.fields))
      }

      notifyError(i18n.t(`apiMessages:apiMessages.${values.data.message}`))
    }
  }
}

const onSuccess = (message?: string) => {
  if (!message || message === '') return
  notifySuccess(i18n.t(`apiMessages:apiMessages.${message}`))
}

const callAuthApi = (params: BaseParamsInterface) => call(params, createRequest(config.backend.authUrl))
const callBaseApi = (params: BaseParamsInterface) => call(params, createRequest(config.backend.baseUrl))
const callNotificationApi = (params: BaseParamsInterface) => call(params, createRequest(config.backend.notificationUrl))
const callCalApi = (params: BaseParamsInterface) => call(params, createRequest(config.backend.calUrl))
const callFileApi = (params: BaseParamsInterface) => call(params, createRequest(config.backend.fileUrl))
const callOrderApi = (params: BaseParamsInterface) => call(params, createRequest(config.backend.orderUrl))
const callTelerehabilitationApi = (params: BaseParamsInterface) =>
  call(params, createRequest(config.backend.telerehabilitationUrl))
const callEdgApi = (params: BaseParamsInterface) => call(params, createRequest(config.backend.edgUrl))

export {
  callAuthApi,
  callBaseApi,
  callCalApi,
  callFileApi,
  callOrderApi,
  callTelerehabilitationApi,
  callNotificationApi,
  callEdgApi,
}
