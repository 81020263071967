import { ButtonInterface } from './ButtonInterface'
import { Pagination } from './index'
import { TextByTypeColumnsInterface } from './InputInterface'

export interface TableLinkInterface {
  path: string
  key: string
  value: string
}

export enum TableWrapperDispatchTypeEnum {
  Submit,
  Delete,
  Detail,
  Download,
}

export interface TableInterface {
  title?: string
  onCreateClick?: () => void
  columns: TextByTypeColumnsInterface[]
  data: Pagination | any
  actions?: TableActionsInterface[]

  pagination?: boolean
  fetch: (limit: number, offset: number, search?: string) => void
  search?: string | undefined
  limit?: number

  loading: boolean

  emptyState?: boolean
  emptyStateText?: string
  emptyStateAddButtonTitle?: string
  onEmptyStateClick?: () => void
}

export interface TableActionsInterface extends ButtonInterface {
  dynamicLink?: TableActionsDynamicLinkInterface
  onClickKey?: string
}

export interface TableActionComponentInterface {
  action: TableActionsInterface
  row: any
  className?: string
}

export interface TableActionsDynamicLinkInterface {
  path: string
  key: string
  value: string
}

export interface TableModalInterface {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  children: JSX.Element | JSX.Element[]
  fileUploading?: boolean
}

export enum DetailEnum {
  None,
  Detail,
  Modal,
}

export interface TableListInterface {
  title: string
  columns: TextByTypeColumnsInterface[]
  rows: any[]
}
