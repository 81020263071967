import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CustomSelectInterface } from '../../../../interfaces'
import { classNames } from '../../../../store/utils'

export const CustomSelect = (props: CustomSelectInterface) => {
  const { isChild, hideLabel, name, loading, onChange, value, options, className, label, errors, touched } = props
  const [selectedValue, setSelectedValue] = useState<{ name: string; value: string }>()
  const { t } = useTranslation(['inputs'])

  useEffect(() => {
    if (value === '' || !value) {
      setSelectedValue({ name: t('inputs.select.loading'), value: 'loading' })
    } else {
      setSelectedValue(value)
    }
  }, [value])

  return (
    <div className={`${className ? className : ''}`}>
      <Listbox disabled={loading} value={selectedValue} onChange={current => onChange(current)}>
        {({ open }) => (
          <>
            {!hideLabel && (
              <Listbox.Label className="block mb-1 text-sm font-medium text-gray-700">{label}</Listbox.Label>
            )}
            <div className="relative">
              <Listbox.Button
                className={`${
                  isChild
                    ? 'relative w-full border-transparent bg-transparent rounded-md pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-2 focus:ring-brand focus:border-brand sm:text-sm'
                    : `bg-white relative w-full border  rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 sm:text-sm ${
                        errors[name] && touched[name]
                          ? 'border-red-600 focus:ring-red-600 focus:border-red-600'
                          : 'border-gray-300 focus:ring-brand focus:border-brand'
                      }`
                }`}
              >
                <span className="block truncate">{selectedValue?.name}</span>
                <span className="flex absolute inset-y-0 right-0 items-center pr-2 pointer-events-none">
                  <SelectorIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options
                  static
                  className="overflow-auto absolute z-30 py-1 mt-1 w-full max-h-60 text-base sm:text-sm bg-white rounded-md ring-1 ring-black ring-opacity-5 shadow-lg focus:outline-none"
                >
                  {!loading &&
                    options &&
                    options.map(row => (
                      <Listbox.Option
                        key={row.value}
                        className={({ active }) =>
                          classNames(
                            active ? 'text-white bg-brand' : 'text-gray-900',
                            'cursor-default select-none relative py-2 pl-3 pr-9'
                          )
                        }
                        value={row}
                      >
                        {({ selected, active }) => (
                          <>
                            <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                              {row.name}
                            </span>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? 'text-white' : 'text-brand',
                                  'absolute inset-y-0 right-0 flex items-center pr-4'
                                )}
                              >
                                <CheckIcon className="w-5 h-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
      {!isChild && errors[name] && touched[name] && <p className="mb-0 h-5 text-sm text-red-600">{errors[name]}</p>}
    </div>
  )
}

export default CustomSelect
