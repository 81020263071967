import { PARAMS } from '../../enums'

export const ROUTES = {
  LOGIN: '/login',
  ROLE_SELECT: '/role-select',

  REGISTER: '/register',
  REGISTER_ADDITIONAL_DATA: '/additional-data',

  DASHBOARD: '/dashboard',
  PASSWORD_RESET_EMAIL: '/password-reset',
  PASSWORD_RESET_NEW_PASSWORD: `/password-reset/:${PARAMS.TOKEN}`,

  PATIENTS: '/patients',
  PATIENTS_LIST: '/patients/list',

  PATIENTS_DETAIL: `/patients/:${PARAMS.PATIENT_ID}`,
  PATIENTS_DETAIL_OVERVIEW: `/patients/:${PARAMS.PATIENT_ID}/overview`,
  PATIENTS_DETAIL_ANAMNESIS: `/patients/:${PARAMS.PATIENT_ID}/anamnesis`,
  PATIENTS_DETAIL_ANAMNESIS_PERSONAL: `/patients/:${PARAMS.PATIENT_ID}/anamnesis/personal`,
  PATIENTS_DETAIL_ANAMNESIS_MEDICATIONS: `/patients/:${PARAMS.PATIENT_ID}/anamnesis/medications`,
  PATIENTS_DETAIL_ANAMNESIS_ALLERGIES: `/patients/:${PARAMS.PATIENT_ID}/anamnesis/allergies`,
  PATIENTS_DETAIL_ANAMNESIS_GYNAECOLOGY: `/patients/:${PARAMS.PATIENT_ID}/anamnesis/gynaecology`,
  PATIENTS_DETAIL_ANAMNESIS_SURGERIES: `/patients/:${PARAMS.PATIENT_ID}/anamnesis/surgeries`,
  PATIENTS_DETAIL_ANAMNESIS_EPIDEMIOLOGY: `/patients/:${PARAMS.PATIENT_ID}/anamnesis/epidemiology`,
  PATIENTS_DETAIL_ANAMNESIS_ADDICTIVE_SUBSTANCES: `/patients/:${PARAMS.PATIENT_ID}/anamnesis/addictive-substances`,
  PATIENTS_DETAIL_ANAMNESIS_FAMILY: `/patients/:${PARAMS.PATIENT_ID}/anamnesis/family`,
  PATIENTS_DETAIL_ANAMNESIS_WORK: `/patients/:${PARAMS.PATIENT_ID}/anamnesis/work`,
  PATIENTS_DETAIL_ANAMNESIS_SOCIAL: `/patients/:${PARAMS.PATIENT_ID}/anamnesis/social`,
  PATIENTS_DETAIL_ANAMNESIS_SPORT: `/patients/:${PARAMS.PATIENT_ID}/anamnesis/sport`,
  PATIENTS_DETAIL_CHECKUPS: `/patients/:${PARAMS.PATIENT_ID}/checkups`,
  PATIENTS_DETAIL_MEASUREMENTS: `/patients/:${PARAMS.PATIENT_ID}/measurements`,
  PATIENTS_DETAIL_MEASUREMENTS_GLYCEMIA: `/patients/:${PARAMS.PATIENT_ID}/measurements/glycemia`,
  PATIENTS_DETAIL_MEASUREMENTS_OXYGEN_SATURATION: `/patients/:${PARAMS.PATIENT_ID}/measurements/oxygen-saturation`,
  PATIENTS_DETAIL_MEASUREMENTS_ECG: `/patients/:${PARAMS.PATIENT_ID}/measurements/ecg`,
  PATIENTS_DETAIL_MEASUREMENTS_BPM: `/patients/:${PARAMS.PATIENT_ID}/measurements/bpm`,
  PATIENTS_DETAIL_MEASUREMENTS_SLEEP: `/patients/:${PARAMS.PATIENT_ID}/measurements/sleep`,
  PATIENTS_DETAIL_MEASUREMENTS_AIR_POLUTION: `/patients/:${PARAMS.PATIENT_ID}/measurements/air-polution`,
  PATIENTS_DETAIL_MEASUREMENTS_ENDOSCOPY: `/patients/:${PARAMS.PATIENT_ID}/measurements/endoscopy`,
  PATIENTS_DETAIL_MEASUREMENTS_TEMPERATURE: `/patients/:${PARAMS.PATIENT_ID}/measurements/temperature`,
  PATIENTS_DETAIL_MEASUREMENTS_WEIGHT: `/patients/:${PARAMS.PATIENT_ID}/measurements/weight`,
  PATIENTS_DETAIL_MEASUREMENTS_HEIGHT: `/patients/:${PARAMS.PATIENT_ID}/measurements/height`,
  PATIENTS_DETAIL_MEASUREMENTS_CHOLESTEROL: `/patients/:${PARAMS.PATIENT_ID}/measurements/cholesterol`,
  PATIENTS_DETAIL_MEASUREMENTS_PRESSURE: `/patients/:${PARAMS.PATIENT_ID}/measurements/pressure`,
  PATIENTS_DETAIL_FAMILY: `/patients/:${PARAMS.PATIENT_ID}/family`,
  PATIENTS_DETAIL_DOCTORS: `/patients/:${PARAMS.PATIENT_ID}/doctors`,
  PATIENTS_DETAIL_STORAGE: `/patients/:${PARAMS.PATIENT_ID}/storage`,
  PATIENTS_DETAIL_STORAGE_LABORATORY: `/patients/:${PARAMS.PATIENT_ID}/storage/laboratory`,
  PATIENTS_DETAIL_STORAGE_RADIOLOGY: `/patients/:${PARAMS.PATIENT_ID}/storage/radiology`,
  PATIENTS_DETAIL_STORAGE_ATTACHMENTS: `/patients/:${PARAMS.PATIENT_ID}/storage/attachments`,

  DOCTORS: `/doctors`,
  DOCTORS_LIST: `/doctors/list`,
  DOCTORS_DETAIL: `/doctors/:${PARAMS.DOCTOR_ID}`,

  CHAT: `/chat`,
  CHAT_DM: `/chat/dm/:${PARAMS.CHAT_DM_ID}`,
  CHAT_GROUP: `/chat/group/:${PARAMS.CHAT_GROUP_ID}`,

  TELEREHABILITATION: `/telerehabilitation`,
  TELEREHABILITATION_PRESETS: `/telerehabilitation/presets`,
  TELEREHABILITATION_CHARTS: `/telerehabilitation/charts`,
  TELEREHABILITATION_PATIENT: `/telerehabilitation/:${PARAMS.PATIENT_ID}`,
  TELEREHABILITATION_PATIENT_DAY: `/telerehabilitation/:${PARAMS.PATIENT_ID}/day/:${PARAMS.DAY_ID}`,
  TELEREHABILITATION_PATIENT_EXERCISE: `/telerehabilitation/:${PARAMS.PATIENT_ID}/day/:${PARAMS.DAY_ID}/exercise/:${PARAMS.EXERCISE_ID}`,
  TELEREHABILITATION_PATIENT_EXERCISE_UPLOAD: `/upload/:${PARAMS.TELEREHABILITATION_UPLOAD_TOKEN}`,

  ORDERS: `/orders`,
  ORDERS_ADMINISTRATION: `/orders/administration`,
  ORDERS_ADMINISTRATION_HISTORY: `/orders/administration/history`,
  ORDERS_ADMINISTRATION_SERVICE_LIST: `/orders/administration/service/list`,

  ORDERS_ADMINISTRATION_CATEGORY: `/orders/administration/category`,
  ORDERS_ADMINISTRATION_CATEGORY_LIST: `/orders/administration/category/list`,
  ORDERS_ADMINISTRATION_CATEGORY_ACCESSORY_LIST: `/orders/administration/category/:${PARAMS.ACCESSORY_CATEGORY_ID}/accessories`,

  ORDERS_PATIENT: `/orders/:${PARAMS.PATIENT_ID}`,
  ORDERS_PATIENT_HISTORY: `/orders/:${PARAMS.PATIENT_ID}/history`,
  ORDERS_PATIENT_CHECKOUT: `/orders/:${PARAMS.PATIENT_ID}/service/:${PARAMS.SERVICE_ID}`,
  ORDERS_PATIENT_LIST: `/orders/:${PARAMS.PATIENT_ID}/list`,
  ORDERS_PATIENT_DETAIL: `/orders/:${PARAMS.PATIENT_ID}/detail/:${PARAMS.ORDER_ID}`,

  CUSTOM_MEDICATIONS: '/medications',

  SETTINGS: '/settings',
  SETTINGS_PROFILE: '/settings/profile',
  SETTINGS_PASSWORD: '/settings/password',
  SETTINGS_NOTIFICATIONS: '/settings/notifications',
  SETTINGS_APPS: '/settings/apps',

  JITSI: `/call_auth/:${PARAMS.JITSI_ID}`,

  ERROR_404: '/404',
  ERROR_403: '/403',
}
