export const PARAMS = {
  PATIENT_ID: 'patient_id',
  DOCTOR_ID: 'doctor_id',
  USER_ID: 'user_id',
  TOKEN: 'token',
  MENSTRUATION_ID: 'menstruation_id',
  PREGNANCY_ID: 'pregnancy_id',
  CHECKUP_ID: 'checkup_id',
  EVENT_ID: 'event_id',
  EPIDEMIOLOGY_ID: 'epidemiology_id',
  FAMILY_MEMBER_ID: 'family_member_id',
  MEDICATION_ID: 'id_medication',
  SURGERY_ID: 'surgery_id',
  GLYCEMIA_ID: 'glycemia_id',
  OXYGEN_ID: 'oxygen_id',
  ECG_ID: 'ecg_id',
  BPM_ID: 'bpm_id',
  SLEEP_ID: 'sleep_id',
  AIR_POLUTION_ID: 'air_polution_id',
  TEMPERATURE_ID: 'temperature_id',
  ENDOSCOPY_ID: 'endoscopy_id',
  HEIGHT_ID: 'height_id',
  WEIGHT_ID: 'weight_id',
  CHOLESTEROL_ID: 'cholesterol_id',
  PRESSURE_ID: 'pressure_id',
  STORAGE_ATTACHMENT_ID: 'storage_attachment_id',
  STORAGE_RADIOLOGY_ID: 'storage_radiology_id',
  STORAGE_LABORATORY_ID: 'storage_laboratory_id',
  CHAT_DM_ID: 'chat_dm_id',
  CHAT_GROUP_ID: 'chat_group_id',
  SERVICE_ID: 'service_id',
  ACCESSORY_CATEGORY_ID: 'accessory_category_id',
  ACCESSORY_ID: 'accessory_id',
  DAY_ID: 'day_id',
  EXERCISE_ID: 'exercise_id',
  ORDER_ID: 'order_id',
  FILE_ID: 'file_id',
  EDG_SERVICE_TYPE: 'edg_service_type',
  JITSI_ID: 'jitsi_id',
  CUSTOM_MEDICATION_ID: 'custom_medication_id',
  TELEREHABILITATION_UPLOAD_TOKEN: 'telerehabilitation_upload_token',
  PRESET_ID: 'preset_id',
  CHART_ID: 'chart_id',
}
