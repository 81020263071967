export interface LineChartInterface {
  series: LineChartSerieInterface[]
  chartType: LineChartTypeEnum
  min?: number
  max?: number
  annotations?: ApexAnnotations
}

export interface DonutChartInterface {
  series: DonutChartSerieInterface
}

export interface WebGLChartInterface {
  data: WebGLDataChartInterface[]
}

export interface WebGLDataChartInterface {
  name: string
  x: number[]
  y: number[]
}

export interface LineChartSerieInterface {
  name: string
  data: string[] | number[] | string[][] | number[][]
  suffix?: string
}

export interface DonutChartSerieInterface {
  labels: string[]
  series: number[]
  colors: string[]
}

export enum LineChartTypeEnum {
  Measurement,
  Timeline,
}
